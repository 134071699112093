import * as React from 'react';
import IBook from '../../types/book_type';

interface IRemoveBooksButtonsProps {
    book: IBook;
    removeBook(book: IBook): void;
}

const RemoveBooksButtons = (props: IRemoveBooksButtonsProps) => {
    let book = props.book;
    return (
        <button type="button" className="btn btn-danger" onClick={() => props.removeBook(book)}>Remove</button>
    );
};

export default RemoveBooksButtons;