import * as toastr from 'toastr';
import { catchError } from '../helpers/error_helper';
import IWish from '../types/wish_type';
import { CREATE_WISH_SUCCESS, DELETE_WISH_SUCCESS, LIST_ALL_WISHES_LOADING, LIST_ALL_WISHES_SUCCESS, 
    SEARCH_WISHES_SUCCESS, CHANGE_WISH_STATUS } from '../constants/action_types';
import wishlistService from '../services/wishlist_service';
import { IThunkDispatch } from '../types/react_types';

export function listAllWishes() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllWishesLoading());
        return wishlistService.listAll()
            .then(wishlist => {
                dispatch(listAllWishesSuccess(wishlist));
                return true;
            })
            .catch(catchError);
    };
} 

export function listAllWishesLoading() {
    return {
        type: LIST_ALL_WISHES_LOADING,
        payload: {
            wishlistLoading: true
        }
    };
}

export function listAllWishesSuccess(wishlist: IWish[]) {
    return {
        type: LIST_ALL_WISHES_SUCCESS,
        payload: {
            wishlist: wishlist,
            wishlistLoading: false
        }
    };
}

export function createWishSuccess(wish: IWish) {
    return {
        type: CREATE_WISH_SUCCESS,
        payload: {
            wish: wish
        }
    };
}

export function createWish(wish: IWish) {
    return function(dispatch: IThunkDispatch) {
        return wishlistService.create(wish)
            //tslint:disable-next-line:no-any
            .then((wishResult: any) => {
                toastr.success('Wish created');
                dispatch(createWishSuccess(wishResult));
                return true;
            })
            .catch(catchError);
    };
}

//tslint:disable-next-line:no-any
export function createCustomWish(wishParams: any) {
    return function(dispatch: IThunkDispatch) {
        return wishlistService.createCustomWish(wishParams)
            .then((wish: IWish) => {
                toastr.success('Wish created');
                dispatch(createWishSuccess(wish));
                return true;
            })
            .catch(catchError);
    };
}

export function deleteWishSuccess(wish: IWish) {
    return {
        type: DELETE_WISH_SUCCESS,
        payload: {
            wish: wish
        }
    };
}

export function deleteWish(wish: IWish) {
    return function(dispatch: IThunkDispatch) {
        return wishlistService.delete(wish.id)
            .then(() => {
                toastr.success('Wish deleted');
                dispatch(deleteWishSuccess(wish));
                return true;
            })
            .catch(catchError);
    };
}

export function searchWishes(userId: string, status: string) {
    return function(dispatch: IThunkDispatch) {
        return wishlistService.search(userId, status)
            .then(wishlist => {
                dispatch(searchWishesSuccess(wishlist));
                return true;
            })
            .catch(catchError);
    };
} 

export function searchWishesSuccess(wishlist: IWish[]) {
    return {
        type: SEARCH_WISHES_SUCCESS,
        payload: {
            wishlist: wishlist,
            wishlistLoading: false
        }
    };
}

export function changeWishStatus(newStatus: string, wishId: string) {
    return function(dispatch: IThunkDispatch) {
        return wishlistService.changeWishStatus(newStatus, wishId)
            .then(wish => {
                toastr.success('Wish status updated');
                dispatch(changeWishStatusSuccess(wish));
                return true;
            })
            .catch(catchError);
    };
}

export function changeWishStatusSuccess(wish: IWish) {
    return {
        type: CHANGE_WISH_STATUS,
        payload: {
            wish: wish
        }
    };
}

export function transformWish(wishId: string) {
    return function(dispatch: IThunkDispatch) {
        return wishlistService.transformWish(wishId)
            .then(book => {
                toastr.success('Wish transformed into book');
                return book.id;
            })
            .catch(catchError);
    };
}
