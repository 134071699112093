import * as React from 'react';
import { connect } from 'react-redux';
import { getTop5BestRatedBooks, getTop5MostReadBooks } from '../../actions/books_actions';
import { getTop5MostActiveUsers } from '../../actions/users_actions';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import TopBook from './top_book';
import TopUser from './top_user';
import { History } from 'history';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { IThunkDispatch } from '../../types/react_types';

interface IToplistProps {
    bestRatedBooks: IBook[];
    bestRatedBooksLoading: boolean;
    mostReadBooks: IBook[];
    mostReadBooksLoading: boolean;
    mostActiveUsers: IUser[];
    history: History;
    mostActiveUsersLoading: boolean;
    getTop5BestRatedBooks(): void;
    getTop5MostReadBooks(): void;
    getTop5MostActiveUsers(): void;
}

class Toplist extends React.Component<IToplistProps, {}> {

    constructor(props: IToplistProps) {
        super(props);
        this.goToProfile = this.goToProfile.bind(this);
    }

    public componentWillMount() {
        this.props.getTop5BestRatedBooks();
        this.props.getTop5MostReadBooks();
        this.props.getTop5MostActiveUsers();
    }

    public render() {
        if (this.props.bestRatedBooksLoading || this.props.mostActiveUsersLoading || this.props.mostReadBooksLoading) {
            return <h3>Loading...</h3>;
        }
        return (
            <div>
                <h1>Top list:</h1>
                <h3>Best rated books: </h3>
                <div className="card-items-wrap">
                    {this.props.bestRatedBooks.map((book: IBook) => <TopBook key={book.id} book={book}/>)}
                </div>
                <br/><br/>
                <h3>Most read books: </h3>
                <div className="card-items-wrap">
                    {this.props.mostReadBooks.map((book: IBook) => <TopBook key={book.id} book={book}/>)}
                </div>
                <br/><br/>
                <h3>Users who read most books: </h3>
                <div className="card-items-wrap">
                    {this.props.mostActiveUsers.map((user: IUser) => (
                        <TopUser 
                            key={user.id} 
                            user={user} 
                            goToProfile={() => this.goToProfile(user.id)}
                        />
                    ))}
                </div>
            </div>
        );
    }

    goToProfile = (userId: string) => {
        this.props.history.push('/profile/' + userId);
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        bestRatedBooks: store.booksReducer.bestRatedBooks,
        bestRatedBooksLoading: store.booksReducer.bestRatedBooksLoading,
        mostReadBooks: store.booksReducer.mostReadBooks,
        mostReadBooksLoading: store.booksReducer.mostReadBooksLoading,
        mostActiveUsers: store.usersReducer.mostActiveUsers,
        mostActiveUsersLoading: store.usersReducer.mostActiveUsersLoading
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    getTop5BestRatedBooks: () => dispatch(getTop5BestRatedBooks()),
    getTop5MostReadBooks: () => dispatch(getTop5MostReadBooks()),
    getTop5MostActiveUsers: () => dispatch(getTop5MostActiveUsers())
});

export default connect(mapStateToProps, matchDispatchToProps)(Toplist);