import * as React from 'react';
import { Link } from 'react-router-dom';

const NotAuthorized = () => {
    return (
        <div>
            <h1>You are not authorized to access this page.</h1>
            <br/>
            <div className="col-md-offset-6">
                <Link to={'/'}>Go to Home page</Link>
            </div>
        </div>
    );
};
export default NotAuthorized;