import * as React from 'react';
import IComment from '../../types/comment_type';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import CommentRow from '../comments/comment';
import { connect } from 'react-redux';
import { addComment, updateComment, deleteComment } from '../../actions/books_actions';
import { MODAL_STYLES } from '../../constants/other_constants';
import { isAllowed } from '../../helpers/checkAuthorization';
import AreYouSureModal from '../are_you_sure_modal';
import Modal from 'react-modal';
import * as toastr from 'toastr';
import { IFormEvent, IChangeEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface ICommentsProps {
    comments: IComment[];
    book: IBook;
    user: IUser | null;
    addComment(comment: IComment): Promise<boolean>;
    updateComment(comment: IComment): Promise<boolean>;
    deleteComment(comment: IComment): Promise<boolean>;
}

interface ICommentsState {
    editing: boolean;
    openModal: boolean;
    comment: IComment;
    areYouSureModalParams: {
        title: string,
        action: Function,
        isOpen: boolean
    };
    saveClicked: boolean;
}

class Comments extends React.Component<ICommentsProps, ICommentsState> {
    constructor(props: ICommentsProps) {
        super(props);
        this.state = {
            editing: false,
            openModal: false,
            comment: this.getNewComment(),
            areYouSureModalParams: { title: '', action: () => {}, isOpen: false },
            saveClicked: false
        };
    }

    getNewComment = () => (
        {
            id: '', 
            user: {
                id: '', 
                username: '', 
                role: { 
                    id: '', 
                    name: '', 
                    permissions: []
                }, 
                rank: {
                    id: '', 
                    name: '', 
                    minimumCheckouts: 0, 
                    avatar: '',
                    newRankMessage: ''
                }, 
                email: '', 
                isActive: true, 
                firstName: '', 
                lastName: '', 
                readBooks: 0
            }, 
            bookId: this.props.book.id, 
            text: '', 
            lastModifiedOn: null
        }
    )

    public componentWillMount() {

    }

    public render() {
        let createForm = this.state.openModal ? this.renderModal(this.state.comment) : <div/>;
        return (
            <div>
               <h4>Comments:</h4>
                { isAllowed(this.props.user, 'IBookService.CommentBook') ?
                    <div>
                        <button  
                            className="btn btn-success" 
                            onClick={this.createButton} 
                        >
                            Comment &nbsp;
                            <span className="glyphicon glyphicon-comment"/>
                        </button>
                    </div>
                    : <div/>
                }
                <br/>
                <div className="">
                    {this.listComments()}
                </div>
                <div id="divModal" ref="divModal">
                    {createForm}
                </div>
                <br /><br />
                <AreYouSureModal 
                    title={this.state.areYouSureModalParams.title} 
                    isOpen={this.state.areYouSureModalParams.isOpen} 
                    action={this.state.areYouSureModalParams.action}
                    close={this.closeAreYouSureModal} 
                    description={null} 
                />
            </div>
        );
    }

    public renderModal = (comment: IComment) => {
        return (
        <Modal isOpen={this.state.openModal} style={MODAL_STYLES}>
            <h3>{this.state.editing ? 'Edit comment ' : 'Create new comment'}</h3>
            <form onSubmit={this.handleSubmitSave}>
                <div className="form-group">
                    <label> Text:</label>
                    <textarea 
                        rows={5} 
                        max-rows={5} 
                        value={this.state.comment.text}  
                        name="text" 
                        className="form-control no-resize" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <button type="button" className="btn btn-default" onClick={this.closeModal}>Cancel</button>
                <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
            </form>
        </Modal> 
        );
    }

    listComments = () => {
        return this.props.comments.map((comment, index) => {
            return (
                <div className="card-item-box" key={comment.id}>
                    <CommentRow 
                        comment={comment} 
                        edit={this.editButton} 
                        delete={this.deleteButton} 
                        user={this.props.user}  
                        openAreYouSureModal={this.openAreYouSureModal}
                    />
                </div>
            );
        });
    }
    
    createButton = () => {
        this.setState({ openModal : true, editing: false, comment: this.getNewComment() });
        Modal.setAppElement('#divModal');
    }

    editButton = (comment: IComment) => {
        this.setState({openModal: true, editing: true, comment: comment});
        Modal.setAppElement('#divModal');
    }

    deleteButton = (comment: IComment) => {
        return this.props.deleteComment(comment);
    }

    closeModal = () => {
        this.setState({
            openModal: false,
        });
    }

    enableSave = () => {
      this.setState({ saveClicked: false });
    }

    handleSubmitSave = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.validateForm(this.state.comment)) {
            return;
        }
        this.setState({ saveClicked: true });
        if (this.state.editing) {
            this.props.updateComment(this.state.comment).then((success: boolean) => {
                this.setState({
                    openModal: false, editing: false, comment: this.getNewComment(),
                    saveClicked: false
                });
            })
            .catch(this.enableSave);
        } else {
            this.props.addComment(this.state.comment).then((success: boolean) => {
                if (success) {
                    this.setState({
                        openModal: false, editing: false, comment: this.getNewComment(),
                        saveClicked: false
                    });
                }
            })
            .catch(this.enableSave);
        }
    }

    handleInputChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        const value = target.value;

        this.setState((prevState) => {
            let newComment = Object.assign({}, prevState.comment, {
                text: value
            });
            return {comment: newComment};
        });
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    validateForm = (comment: IComment) => {
        if (!comment.text || comment.text.trim() === '') {
            toastr.error('Comment cannot be empty.');
            return false;
        }
        return true;
    }
}

const mapStateToProps = (store: ILibrarianStore) => ({
    comments: store.booksReducer.comments,
    user: store.accountReducer.loggedInUser.user
});

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    addComment: (comment: IComment) => dispatch(addComment(comment)),
    updateComment: (comment: IComment) => dispatch(updateComment(comment)),
    deleteComment: (comment: IComment) => dispatch(deleteComment(comment)),
});

export default connect(mapStateToProps, matchDispatchToProps)(Comments);