import IKeyword from '../types/keyword_type';
import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class KeywordService {

    listAllKeywords() {
        return fetch(LINK_BASE + '/Keywords', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IKeyword[]>);
    }

    addKeyword(keyword: IKeyword) {
        return  fetch(LINK_BASE + '/Keywords', {
            method: 'post',
            body: JSON.stringify({ 'Id': EMPTY_GUID, 'Name': keyword.name  }),
            headers:  getAuthorizedContentTypeHeaders()
        }). then(handleErrors).then(response => response.json() as Promise<IKeyword>);
    }

    updateKeyword(keyword: IKeyword) {
        return  fetch(LINK_BASE + '/Keywords', {
            method: 'put',
            body: JSON.stringify(keyword),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<IKeyword>);
    }

    deleteKeyword(keyword: IKeyword) {
        return fetch(LINK_BASE + '/Keywords/' + keyword.id, {
            method: 'delete',
            headers:  getAuthorizedHeaders()
        }).then(handleErrors);
    }
}

export default new KeywordService();