import * as React from 'react';
import LanguageRow from './language_row';
import AreYouSureModal from '../are_you_sure_modal';
import { connect } from 'react-redux';
import { listAllLanguages, deleteLanguage } from '../../actions/languages_actions';
import ILanguage from '../../types/language_type';
import LanguageModal from '../languages/language_modal';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import Modal from 'react-modal';
import { History } from 'history';
import { IClickEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface ILanguageProps {
    languages: ILanguage[];
    loggedInUser: IUser | null;
    history: History;
    listAllLanguages(): Promise<boolean>;
    deleteLanguage(lang: ILanguage): Promise<boolean>;
}

interface ILanguageState {
    openModal: boolean;
    editing: boolean;
    language: ILanguage;
    loading: boolean;
    areYouSureModalParams: {
        title: string;
        action: Function,
        isOpen: boolean;
    };
}

class Languages extends React.Component<ILanguageProps, ILanguageState> {
    
    constructor(props: ILanguageProps ) {
        super(props);
        this.state = {
            openModal: false,
            editing: false,
            language: {id: '', name: ''},
            loading: true,
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            }
        };
    }
    
    public componentWillMount() {
        this.props.listAllLanguages().then( () => this.setState( {loading: false} ));
    }

    public render() {
        let createForm = this.state.openModal ? this.renderModal(this.state.language) : <div/>;
        let loader = this.state.loading ? <p><em>Loading...</em></p> : <div/>;
        return (
            <div>
                <h1>List of Languages</h1>
                {loader}
                { isAllowed(this.props.loggedInUser, 'ILanguageService.Create') ?
                    <button onClick={this.createButton} className="btn btn-default">
                        Create New   <span className="glyphicon glyphicon-plus"/>
                    </button>
                    : <div/>
                }
                <br /><br />
                { isAllowed(this.props.loggedInUser, 'ILanguageService.ListAll') ?
                    <table className="table table-striped"> 
                        <thead>
                            <tr>
                                <td className="bold">#&nbsp;</td>
                                <td className="bold">Name</td>
                                <td className="bold">Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.listLanguages()}
                        </tbody>
                    </table>
                    : <div/>
                }
                <div id="divModal">
                { isAllowed(this.props.loggedInUser, 'ILanguageService.Create') ?
                    createForm 
                    : <div/>
                }
                </div>
                <AreYouSureModal 
                    title={this.state.areYouSureModalParams.title} 
                    isOpen={this.state.areYouSureModalParams.isOpen} 
                    action={this.state.areYouSureModalParams.action}
                    close={this.closeAreYouSureModal} 
                    description={null} 
                />
            </div>
        );
    } 

    renderModal = (language: ILanguage) => {
        return (
            <LanguageModal 
                closing={!this.state.openModal} 
                closeModal={this.closeModal} 
                editing={this.state.editing} 
                editedLanguage={this.state.language}
            /> 
        );
    }

    listLanguages = () => {
        return this.props.languages.map((language, index) => (
            <LanguageRow 
                openAreYouSureModal={this.openAreYouSureModal} 
                key={language.id}
                number={index + 1} 
                language={language} 
                edit={this.editButton} 
                delete={this.handleDelete}  
                loggedInUser={this.props.loggedInUser}
            />
        ));
    }

    createButton = () => {
        this.setState({ openModal : true, loading: false, editing: false });
        Modal.setAppElement('#divModal');
    }

    editButton = (language: ILanguage, e: IClickEvent) => {
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({openModal: true, editing: true, language: language, loading: false});
            Modal.setAppElement('#divModal');
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false,
            language: {id: '', name: ''}
        });
    }

    handleDelete = (language: ILanguage) => {
       return this.props.deleteLanguage(language);
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: ILanguageState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: ILanguageState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        languages: store.languageReducer.languages,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllLanguages: () => dispatch(listAllLanguages()),
    deleteLanguage: (lang: ILanguage) => dispatch(deleteLanguage(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
