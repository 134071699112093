import * as React from 'react';
import IGenre from '../../types/genre_type';
import { connect } from 'react-redux';
import { MODAL_STYLES } from '../../constants/other_constants';
import Modal from 'react-modal';
import * as toastr from 'toastr';
import { addGenre, updateGenre } from '../../actions/genres_actions';
import { IFormEvent, IChangeEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IGenreModalState {
    genre: IGenre;
    editing: boolean;
    closing: boolean;
    saveClicked: boolean;
}

interface IGenreModalProps {
    editing: boolean;
    editedGenre: IGenre;
    closing: boolean;
    closeModal(): void;
    addGenre(genre: IGenre): Promise<boolean>;
    updateGenre(genre: IGenre): Promise<boolean>;
}

class GenreModal extends React.Component<IGenreModalProps, IGenreModalState> {
    constructor(props: IGenreModalProps) {
        super(props);
        this.state = {
            genre: {
                id: this.props.editedGenre.id ? this.props.editedGenre.id : '', 
                name: this.props.editedGenre.name ? this.props.editedGenre.name : ''
            },
            editing: props.editing,
            closing: props.closing,
            saveClicked: false
        };
    }
    
    render() {
        if (!this.state.closing) {
            return (
                
                <div>
                <Modal isOpen={!this.state.closing} style={MODAL_STYLES}>
                <h3>{this.state.editing ? 'Edit genre ' : 'Create new genre'}</h3>
                <form onSubmit={this.handleSubmitSave}>
                    <div className="form-group">
                        <label className="required-field"> Name:</label>
                        <input type="text"  value={this.state.genre.name}  name="name" className="form-control" onChange={this.handleInputChange}/>
                    </div>
                        <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                        <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                    </form>
                 </Modal>
                </div>
            );
        } else { 
            return <div/>;
        }
    }

    handleSubmitSave = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.validateForm(this.state.genre)) {
            return;
        }
        this.setState({ saveClicked: true });
        if (this.state.editing) {
             this.props.updateGenre(this.state.genre).then((success: boolean) => {
                this.setState({
                    editing: false, genre : {id: '', name: ''}, saveClicked: false
                });
                this.props.closeModal();
            }).catch(() => this.setState({ saveClicked: false }));
        } else {
            this.props.addGenre(this.state.genre).then((success: boolean) => {
                if (success) {
                    this.setState({
                        editing: false, genre : {id: '', name: ''}, saveClicked: false
                    });
                    this.props.closeModal();
                }
            }).catch(() => this.setState({ saveClicked: false }));
        }
    }

    handleInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newGenre = Object.assign({}, prevState.genre, {
                [name]: value
            });
            return {genre: newGenre};
        });
    }
    
    validateForm = (genre: IGenre) => {
        if (!genre.name || genre.name.trim() === '') {
            toastr.error('Genre name cannot be empty.');
            return false;
        }
        return true;
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    addGenre: (genre: IGenre) => dispatch(addGenre(genre)),
    updateGenre: (genre: IGenre) => dispatch(updateGenre(genre))
});

export default connect(mapStateToProps, matchDispatchToProps)(GenreModal);