import * as React from 'react';

interface IExpandButtonProps {
  isExpanded: boolean;
  toggleExpand(): void;
}

const ExpandButtonProps = (props: IExpandButtonProps) => (
  <button className="btn btn-default" onClick={() => props.toggleExpand()}>
    {props.isExpanded ? 'Collapse' : 'Expand'}&nbsp;
    <span className={props.isExpanded ? 'glyphicon glyphicon-chevron-up' : 'glyphicon glyphicon-chevron-down'} />
  </button>
);

export default ExpandButtonProps;