import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/nuget/content/content/toastr.min.css';
import './css/site.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
//import registerServiceWorker from './js/registerServiceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './js/reducers/root_reducer';
import thunk from 'redux-thunk';
import Login from './js/components/login';
import App from './js/components/app';
import PrivateApp from './js/components/private_app';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
//import { composeWithDevTools } from 'redux-devtools-extension';

//const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route 
                        render={(props: any) => (
                        (!localStorage.getItem('user') && props.location.pathname !== '/login')
                            ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            : <PrivateApp {...props}/>
                        )} 
                    />
                </Switch>
            </App>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement
); 
//registerServiceWorker();
