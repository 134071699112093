import * as React from 'react';
import AuthorTable from './author_table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listAllAuthors, deleteAuthor, searchAuthors } from '../../actions/authors_actions';
import IAuthor from '../../types/author_type';
import CreateUpdateAuthorModal from './create_update_author_modal';
import FilterAuthorsForm from './filter_authors_form';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import AreYouSureModal from '../are_you_sure_modal';
import ExpandButton from '../shared/expand_button';
import { IClickEvent, IThunk, IAnyTargetType, IFormEvent, IChangeEvent, IDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { History } from 'history';
import Modal from 'react-modal';

interface IAuthorsProps {
    authors: IAuthor[];
    authorsLoading: boolean;
    loggedInUser: IUser | null;
    history: History;
    listAllAuthors(): IThunk<boolean>;
    searchAuthors(name: string, pseudonym: string, nationality: string): IThunk<boolean>;
    deleteAuthor(author: IAuthor): IThunk<boolean>;
}

interface IAuthorsState {
    isModalOpen: boolean;
    editing: boolean;
    author: IAuthor;
    filterExpanded: boolean;
    filterAuthorsParams: {
        name: string;
        pseudonym: string;
        nationality: string;
    };
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
}

class Authors extends React.Component<IAuthorsProps, IAuthorsState> {

    constructor(props: IAuthorsProps) {
        super(props);
        this.state = {
            isModalOpen: false,
            editing: false,
            author: {
                id: '',
                name: '',
                pseudonym: '',
                yearOfBirth: undefined,
                nationality: ''
            },
            filterAuthorsParams: {
                name: '',
                pseudonym: '',
                nationality: ''
            },
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            },
            filterExpanded: false
        };
    }

    public componentWillMount() {
        this.props.listAllAuthors();
    }

    public render() {
        if (this.props.authorsLoading) {
            return <h3>Loading...</h3>;
        } else {
            let modal = this.state.isModalOpen ?
                (
                <CreateUpdateAuthorModal 
                    editedAuthor={this.state.author} 
                    closing={!this.state.isModalOpen} 
                    editing={this.state.editing}
                    closeModal={this.closeModal} 
                />
                ) :
                <div/> ;
            return (
                <div>
                    <div className="col-md-12">
                        <h1>List of Authors</h1>
                        { isAllowed(this.props.loggedInUser, 'IAuthorService.Create') ?
                        <button type="button" className="btn btn-default" onClick={this.openCreateAuthorModal}>
                            Create new <span className="glyphicon glyphicon-plus"/>
                        </button> : <div/>}
                        { isAllowed(this.props.loggedInUser, 'IAuthorService.Search') ?
                        <div>
                        <h3>
                          Filter:&nbsp;&nbsp;
                          <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                        </h3>
                        {
                          this.state.filterExpanded ?
                            <FilterAuthorsForm 
                                filterParams={this.state.filterAuthorsParams} 
                                filter={this.filterAuthors} 
                                clearFilter={this.clearAuthorsFilter}
                                handleInputChange={this.handleAuthorsFilterParamsChange} 
                            />
                            : null
                        }
                        </div> : <div/>}
                        { isAllowed(this.props.loggedInUser, 'IAuthorService.ListAll') ?
                        <AuthorTable 
                            authors={this.props.authors} 
                            openEditAuthorModal={this.openEditAuthorModal} 
                            deleteAuthor={this.props.deleteAuthor} 
                            loggedInUser={this.props.loggedInUser}  
                            openAreYouSureModal={this.openAreYouSureModal}
                        />
                        : <div/>}
                    </div>
                    { isAllowed(this.props.loggedInUser, 'IAuthorService.Create') ?
                    <div id="authorsModal">{modal}</div> : <div/>}
                    <AreYouSureModal 
                        title={this.state.areYouSureModalParams.title} 
                        isOpen={this.state.areYouSureModalParams.isOpen} 
                        action={this.state.areYouSureModalParams.action}
                        close={this.closeAreYouSureModal} 
                        description={null} 
                    />
                </div>
            );
        }
    }

    openEditAuthorModal = (author: IAuthor, e: IClickEvent): void => {
        Modal.setAppElement('#authorsModal');
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({
                isModalOpen: true,
                editing: true,
                author: author
            });
        }
    }

    openCreateAuthorModal = () => {
        Modal.setAppElement('#authorsModal');
        this.setState({
            isModalOpen: true,
            editing: false,
            author: {
                id: '',
                name: '',
                pseudonym: '',
                yearOfBirth: undefined,
                nationality: ''
            }
        });
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false,
            author: {
                id: '',
                name: '',
                pseudonym: '',
                yearOfBirth: undefined,
                nationality: ''
            }
        });
    }

    toggleExpandFilter = () => {
      this.setState(prevState => ({
        filterExpanded: !prevState.filterExpanded
      }));
    }

    openAreYouSureModal = (action: Function, title: string): void => {
        this.setState((prevState: IAuthorsState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IAuthorsState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    handleAuthorsFilterParamsChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        let value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newSearch = Object.assign({}, prevState.filterAuthorsParams, {
                [name]: value
            });
            return { filterAuthorsParams: newSearch };
        });
    }

    filterAuthors = (event: IFormEvent) => {
        event.preventDefault();
        let {name, pseudonym, nationality} = this.state.filterAuthorsParams;
        this.props.searchAuthors(name, pseudonym, nationality);
    }

    clearAuthorsFilter = () => {
        this.props.searchAuthors('', '', '');
        this.setState({
            filterAuthorsParams: {
                name: '',
                pseudonym: '',
                nationality: ''
            }
        });
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        authors: store.authorsReducer.authors,
        authorsLoading: store.authorsReducer.authorsLoading,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

function matchDispatchToProps(dispatch: IDispatch) {
    return bindActionCreators(
        {
            listAllAuthors: listAllAuthors,
            deleteAuthor: deleteAuthor,
            searchAuthors: searchAuthors
        }, 
        dispatch
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(Authors);