import * as React from 'react';
import CheckoutTable from './checkout_table';
import { connect } from 'react-redux';
import ICheckout from '../../types/checkout_type';
import { listAllCheckouts, deleteCheckout, returnBook, searchCheckouts } from '../../actions/checkouts_actions';
import { parseDateStringFromPicker } from '../../helpers/date_helper';
import FilterCheckoutsForm from './filter_checkouts_form';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import { listAllBooks } from '../../actions/books_actions';
import { listAllUsers } from '../../actions/users_actions';
import { EMPTY_GUID } from '../../constants/other_constants';
import { isAllowed } from '../../helpers/checkAuthorization';
import AreYouSureModal from '../are_you_sure_modal';
import ExpandButton from '../shared/expand_button';
import { RouteComponentProps } from 'react-router';
import { IFormEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface ICheckoutRouteProps {
    bookId: string;
    userId: string;
}

interface ICheckoutsProps extends RouteComponentProps<ICheckoutRouteProps> {
    checkouts: ICheckout[];
    checkoutsLoading: boolean;
    books: IBook[];
    users: IUser[];
    loggedInUser: IUser | null;
    usersLoading: boolean;
    booksLoading: boolean;
    listAllUsers(): Promise<boolean>;
    listAllBooks(sortBy: string, asc: boolean): Promise<boolean>;
    searchCheckouts(
        bookId: string, userId: string, fromCheckoutTime: Date|string|null, toCheckoutTime: Date|string|null, 
        fromReturnTime: Date|string|null, toReturnTime: Date|string|null, librarianId: string, returned: string|null): Promise<boolean>;
    listAllCheckouts(): Promise<boolean>;
    returnBook(checkout: ICheckout): void;
    deleteCheckout(checkout: ICheckout): void;
}

interface ICheckoutsState {
    filterCheckoutsParams: {
        user: string;
        book: string;
        librarian: string;
        checkedOutFrom: Date | null;
        checkedOutTo: Date | null;
        returnedTo: Date | null;
        returnedFrom: Date | null;
        returned: string | null;
    };
    title: string;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
        description: string | React.ReactNode;
    };
    filterExpanded: boolean;
}

class Checkouts extends React.Component<ICheckoutsProps, ICheckoutsState> {
    constructor(props: ICheckoutsProps) {
        super(props);
        this.state = {
            filterCheckoutsParams: {
                user: '',
                book: '',
                librarian: '',
                checkedOutFrom: null,
                checkedOutTo: null,
                returnedFrom: null,
                returnedTo: null,
                returned: 'null'
            },
            title: 'List of Checkouts',
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false,
                description: null
            },
            filterExpanded: false
        };
    }

    public componentWillMount() {
        let bookId = this.props.match.params.bookId;
        let userId = this.props.match.params.userId;
        let bothEmpty = bookId === EMPTY_GUID && userId === EMPTY_GUID;
        if ((bookId || userId) && !bothEmpty) {
            this.props.searchCheckouts(bookId, userId, '', '', '', '', '', '');
        } else {
            if (!isAllowed(this.props.loggedInUser, 'ICheckoutService.ListAll')) {
                this.props.history.push('/notAuthorized');
            } else {
                this.props.listAllCheckouts();
            }
        }
        this.props.listAllBooks('name', true);
        this.props.listAllUsers();
    }

    public componentDidMount() {
        let bookId = this.props.match.params.bookId;
        let userId = this.props.match.params.userId;
        if (!bookId) {
            bookId = '';
        }
        if (!userId) {
            userId = '';
        }
        this.setState((prevState: ICheckoutsState) => {
            let newFilter = Object.assign({}, prevState.filterCheckoutsParams, {book: bookId, user: userId});
            let newTitle = 
                (bookId === '' || bookId === EMPTY_GUID) && this.props.loggedInUser && userId === this.props.loggedInUser.id ? 
                'My Checkout history' 
                : 'List of Checkouts';
            return {filterCheckoutsParams: newFilter, title: newTitle};
        });
    }

    componentWillReceiveProps(nextProps: ICheckoutsProps, nextState: ICheckoutsState) {
        if (this.props.match.params.bookId !== nextProps.match.params.bookId || this.props.match.params.userId !== nextProps.match.params.userId) {
            if (nextProps.match.params.bookId || nextProps.match.params.userId) {
                this.props.searchCheckouts(nextProps.match.params.bookId, nextProps.match.params.userId, '', '', '', '', '', '');
            } else {
                this.props.listAllCheckouts();
            }
            let bookId = nextProps.match.params.bookId;
            let userId = nextProps.match.params.userId;
            this.setState((prevState: ICheckoutsState) => {
                let newFilter = Object.assign({}, prevState.filterCheckoutsParams, {book: bookId, user: userId});
                let newTitle = (!bookId || bookId === '' || bookId === EMPTY_GUID) 
                    && this.props.loggedInUser && userId === this.props.loggedInUser.id ? 
                    'My Checkout History' 
                    : 'List of Checkouts';
                return {filterCheckoutsParams: newFilter, title: newTitle};
            });
        }
    }

    public render() {
        if (this.props.checkoutsLoading || this.props.usersLoading || this.props.booksLoading) {
            return <h3>Loading...</h3>;
        } else {
            return (
                <div>
                    <h1>{this.state.title}</h1>
                    <h3>
                      Filter&nbsp;&nbsp;
                      <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                    </h3>
                    {
                      this.state.filterExpanded ? 
                        <FilterCheckoutsForm 
                            filterParams={this.state.filterCheckoutsParams} 
                            users={this.props.users} 
                            books={this.props.books} 
                            handleInputChange={this.handleInputChange} 
                            filter={this.filter} 
                            clearFilter={this.cancelFilter} 
                            loggedInUser={this.props.loggedInUser}
                        />
                        : null
                    }
                    <CheckoutTable 
                        checkouts={this.props.checkouts} 
                        deleteCheckout={this.props.deleteCheckout} 
                        returnBook={this.props.returnBook} 
                        loggedInUser={this.props.loggedInUser} 
                        openAreYouSureModal={this.openAreYouSureModal} 
                    />
                    <AreYouSureModal 
                        title={this.state.areYouSureModalParams.title} 
                        isOpen={this.state.areYouSureModalParams.isOpen} 
                        action={this.state.areYouSureModalParams.action}
                        close={this.closeAreYouSureModal} 
                        description={this.state.areYouSureModalParams.description} 
                    />
                </div>
            );
        }
    }

    //tslint:disable-next-line:no-any
    handleInputChange = (type: string, value: any) => {
        //tslint:disable-next-line:no-any
        let newValue: any;
        if ((type === 'checkedOutFrom' || type === 'checkedOutTo' || type === 'returnedFrom' || type === 'returnedTo')) {
            if (value.target.value !== '') {
                newValue = parseDateStringFromPicker(value.target.value);
                this.setState((prevState) => {
                    let newFilter = Object.assign({}, prevState.filterCheckoutsParams, {
                        [type]: newValue
                    });
                    return {filterCheckoutsParams: newFilter};
                });
            }
        } else {
            this.setState((prevState) => {
                let newFilter = Object.assign({}, prevState.filterCheckoutsParams, {
                    [type]: value
                });
                return {filterCheckoutsParams: newFilter};
            });
        }
        
    }

    filter = (e: IFormEvent) => {
        e.preventDefault();
        let {user, book, librarian, checkedOutFrom, checkedOutTo, returnedFrom, returnedTo, returned} = this.state.filterCheckoutsParams;
        if (returned === 'null') {
            returned = null;
        } else if (returned === 'unreturned') {
            returnedFrom = null;
            returnedTo = null;
        }
        this.props.searchCheckouts(
            book, user, checkedOutFrom ? checkedOutFrom : null, checkedOutTo ? checkedOutTo : null,
            returnedFrom ? returnedFrom : null, returnedTo ? returnedTo : null, librarian, returned
        );
    }

    cancelFilter = () => {
        this.props.searchCheckouts('', '', '', '', '', '', '', '');
        this.setState({
            filterCheckoutsParams: {
                user: '',
                book: '',
                librarian: '',
                checkedOutFrom: null,
                checkedOutTo: null,
                returnedFrom: null,
                returnedTo: null,
                returned: 'null'
            }
        });
    }

    openAreYouSureModal = (action: Function, title: string, description: string | React.ReactNode) => {
        this.setState((prevState: ICheckoutsState) => {
            let newAreYouSureModalParams = Object.assign(
                {}, 
                prevState.areYouSureModalParams, 
                { isOpen: true, action: action, title: title, description: description }
            );
            return { areYouSureModalParams: newAreYouSureModalParams };
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: ICheckoutsState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    toggleExpandFilter = () => {
      this.setState(prevState => ({
        filterExpanded: !prevState.filterExpanded
      }));
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        checkouts: store.checkoutsReducer.checkouts,
        checkoutsLoading: store.checkoutsReducer.checkoutsLoading,
        users: store.usersReducer.users,
        usersLoading: store.usersReducer.usersLoading,
        books: store.booksReducer.books,
        booksLoading: store.booksReducer.booksLoading,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllCheckouts: () => dispatch(listAllCheckouts()),
    deleteCheckout: (checkout: ICheckout) => dispatch(deleteCheckout(checkout)),
    returnBook: (checkout: ICheckout) => dispatch(returnBook(checkout)),
    listAllUsers: () => dispatch(listAllUsers()),
    listAllBooks: (sortBy: string, asc: boolean) => dispatch(listAllBooks(sortBy, asc)),
    searchCheckouts: (
        bookId: string, userId: string, fromCheckoutTime: Date|string|null, toCheckoutTime: Date|string|null, 
        fromReturnTime: Date|string|null, toReturnTime: Date|string|null, librarianId: string, returned: string|null) => 
        
        dispatch(searchCheckouts(bookId, userId, fromCheckoutTime, toCheckoutTime, fromReturnTime, toReturnTime, librarianId, returned))
});

export default connect(mapStateToProps, matchDispatchToProps)(Checkouts);
