import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IPublisher from '../../types/publisher_type';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IPublisherRowProps {
    publisher: IPublisher;
    loggedInUser: IUser | null;
    number: number;
    edit(pub: IPublisher, e: IClickEvent): void;
    openAreYouSureModal(func: Function, title: string): void;
    delete(pub: IPublisher): Promise<boolean>;
}

const PublisherRow = (props: IPublisherRowProps) => {
    let publisher = props.publisher;
    return (
        <tr 
            key={publisher.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e: IClickEvent) => isAllowed(props.loggedInUser, 'IPublisherService.Update') ? props.edit(publisher, e) : {}}
        >
            <td className="bold">{props.number}</td>
            <td>{publisher.name}</td>
            <td>{publisher.country}</td>
            <td>
                { isAllowed(props.loggedInUser, 'IPublisherService.Delete') ?
                    <span className="remove-icon">
                        <i 
                            className="fa fa-trash-o fa-2x" 
                            onClick={() => props.openAreYouSureModal(() => props.delete(publisher), 'Deleting publisher ' + publisher.name)} 
                            aria-hidden="true"
                        />
                    </span> 
                    : <div/> 
                }
            </td>
        </tr>
    );
};

export default PublisherRow;