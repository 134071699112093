import { LIST_ALL_USERS_LOADING, LIST_ALL_USERS_SUCCESS, DEACTIVATE_USER_SUCCESS, ACTIVATE_USER_SUCCESS,
    CHANGE_ROLE_SUCCESS, SEARCH_USERS_LOADING, SEARCH_USERS_SUCCESS, LIST_ALL_ACTIVE_USERS_SUCCESS,
     SEARCH_ACTIVE_USERS_SUCCESS, GET_TOP_5_MOST_ACTIVE_USERS_LOADING, GET_TOP_5_MOST_ACTIVE_USERS_SUCCESS,
    GET_USER_LOADING, GET_USER_SUCCESS } from '../constants/action_types';
import usersService from '../services/users_service';
import IUser from '../types/user_type';
import IRole from '../types/role_type';
import * as toastr from 'toastr';
import { catchError } from '../helpers/error_helper';
import { IThunkDispatch } from '../types/react_types';

export function listAllUsersLoading() {
    return {
        type: LIST_ALL_USERS_LOADING,
        payload: {
            usersLoading: true
        }
    };
}

export function listAllUsersSuccess(users: IUser[]) {
    return {
        type: LIST_ALL_USERS_SUCCESS,
        payload: {
            users: users,
            usersLoading: false
        }
    };
}

export function listAllUsers(sortBy?: string, asc?: boolean) {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllUsersLoading());
        return usersService.listAll(sortBy, asc)
            .then(users => {
                dispatch(listAllUsersSuccess(users));
                return true;
            })
            .catch(catchError);
    };
}

export function listAllActiveUsersSuccess(users: IUser[]) {
    return {
        type: LIST_ALL_ACTIVE_USERS_SUCCESS,
        payload: {
            activeUsers: users,
            usersLoading: false
        }
    };
}

export function listAllActiveUsers(sortBy: string, asc: boolean) {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllUsersLoading());
        return usersService.listAllActive(sortBy, asc)
            .then(users => {
                dispatch(listAllActiveUsersSuccess(users));
                return true;
            })
            .catch(catchError);
    };
}

export function deactivateUser(user: IUser) {
    return function(dispatch: IThunkDispatch) {
        return usersService.ban(user)
            .then(() => {
                toastr.success('User deactivated.');
                let bannedUser = Object.assign({}, user, {isActive: false});
                dispatch(deactivateUserSuccess(bannedUser));
                return true;
            })
            .catch(catchError);
    };
}

export function deactivateUserSuccess(user: IUser) {
    return {
        type: DEACTIVATE_USER_SUCCESS,
        payload: {
            user: user
        }
    };
}

export function activateUser(user: IUser) {
    return function(dispatch: IThunkDispatch) {
        return usersService.unban(user)
            .then(() => {
                toastr.success('User activated.');
                let unbannedUser = Object.assign({}, user, {isActive: true});
                dispatch(activateUserSuccess(unbannedUser));
                return true;
            })
            .catch(catchError);
    };
}

export function activateUserSuccess(user: IUser) {
    return {
        type: ACTIVATE_USER_SUCCESS,
        payload: {
            user: user
        }
    };
}

export function changeRoleSuccess(user: IUser) {
    return {
        type: CHANGE_ROLE_SUCCESS,
        payload: {
            user: user
        }
    };
}

export function changeRole(user: IUser, role: IRole) {
    return function(dispatch: IThunkDispatch) {
        return usersService.changeRole(user, role)
            .then(returnUser => {
                toastr.success('User\'s role changed.');
                dispatch(changeRoleSuccess(returnUser));
                return true;
            })
            .catch(catchError);
    };
}

export function searchUsersLoading() {
    return {
        type: SEARCH_USERS_LOADING,
        payload: {
            usersLoading: true
        }
    };
}

export function searchUsersSuccess(users: IUser[]) {
    return {
        type: SEARCH_USERS_SUCCESS,
        payload: {
            users: users,
            usersLoading: false
        }
    };
}

export function searchUsers(username: string, firstName: string, lastName: string, sortBy: string, asc: boolean) {
    return function(dispatch: IThunkDispatch) {
        //dispatch(searchUsersLoading());
        return usersService.search(username, firstName, lastName, sortBy, asc)
            .then(users => {
                dispatch(searchUsersSuccess(users));
                return true;
            })
            .catch(catchError);
    };
}

export function searchActiveUsersSuccess(users: IUser[]) {
    return {
        type: SEARCH_ACTIVE_USERS_SUCCESS,
        payload: {
            activeUsers: users,
            usersLoading: false
        }
    };
}

export function searchActiveUsers(username: string, firstName: string, lastName: string, sortBy: string, asc: boolean) {
    return function(dispatch: IThunkDispatch) {
        //dispatch(searchUsersLoading());
        return usersService.searchActive(username, firstName, lastName, sortBy, asc)
            .then(users => {
                dispatch(searchActiveUsersSuccess(users));
                return true;
            })
            .catch(catchError);
    };
}

export function getTop5MostActiveUsersSuccess(users: IUser[]) {
    return {
        type: GET_TOP_5_MOST_ACTIVE_USERS_SUCCESS,
        payload: {
            mostActiveUsers: users,
            mostActiveUsersLoading: false
        }
    };
}

export function getTop5MostActiveUsersLoading() {
    return {
        type: GET_TOP_5_MOST_ACTIVE_USERS_LOADING,
        payload: {
            mostActiveUsersLoading: true
        }
    };
}

export function getTop5MostActiveUsers() {
    return function(dispatch: IThunkDispatch) {
        dispatch(getTop5MostActiveUsersLoading());
        return usersService.getTop5MostActiveUsers()
        .then((users: IUser[]) => {
            dispatch(getTop5MostActiveUsersSuccess(users));
            return true;
        })
        .catch(catchError);
    };
}

export function getUserLoading() {
    return {
        type: GET_USER_LOADING,
        payload: {
            userLoading: true
        }
    };
}

export function getUserSuccess(user: IUser) {
    return {
        type: GET_USER_SUCCESS,
        payload: {
            user: user,
            userLoading: false
        }
    };
}

export function getUser(id: string) {
    return function(dispatch: IThunkDispatch) {
        dispatch(getUserLoading());
        return usersService.get(id)
        .then((user: IUser) => {
            dispatch(getUserSuccess(user));
            return true;
        })
        .catch(catchError);
    };
}