import { LIST_ALL_PUBLISHERS_SUCCESS, ADD_PUBLISHER_SUCCESS, UPDATE_PUBLISHER_SUCCESS, DELETE_PUBLISHER_SUCCESS } from '../constants/action_types';
import IPublisher from '../types/publisher_type';
import publisherService from '../services/publishers_service';
import { catchError } from '../helpers/error_helper';
import * as toastr from 'toastr';
import { IThunkDispatch } from '../types/react_types';

export function listAllPublishers() {
    return function (dispatch: IThunkDispatch) {
        return publisherService.listAllPublishers()
        .then(publishers => {
            dispatch(listAllPublishersSuccess(publishers));
            return true;
        })
        .catch(catchError);
    };
}

export function listAllPublishersSuccess(publishers: IPublisher[]) {
    return {
        type: LIST_ALL_PUBLISHERS_SUCCESS,
        payload: {
            publishers: publishers
        }
    };
}

export function addPublisher(publisher: IPublisher) {
    return function (dispatch: IThunkDispatch) {
        return publisherService.addPublisher(publisher)
        .then(publisherNew => {
            toastr.success('Publisher created');
            dispatch(addPublisherSuccess(publisherNew));
            return true;
        })
        .catch(catchError);
    };
}

export function addPublisherSuccess(publisher: IPublisher) {
    return {
        type: ADD_PUBLISHER_SUCCESS,
        payload: {
            publisher: publisher
        }
    };
}

export function updatePublisher(publisher: IPublisher) {
    return function (dispatch: IThunkDispatch) {
        return publisherService.updatePublisher(publisher)
        .then(publisherNew => {
            toastr.success('Publisher updated');
            dispatch(updatePublisherSuccess(publisherNew));
            return true;
        })
        .catch(catchError);
    };
}

export function updatePublisherSuccess(publisher: IPublisher) {
    return {
        type: UPDATE_PUBLISHER_SUCCESS,
        payload: {
            publisher: publisher
        }
    };
}

export function deletePublisher(publisher: IPublisher) {
    return function (dispatch: IThunkDispatch) {
        return publisherService.deletePublisher(publisher)
        .then( () => {
            toastr.success('Publisher deleted');
            dispatch(deletePublisherSuccess(publisher));
            return true;
        })
        .catch(catchError);
    };
}

export function deletePublisherSuccess(publisher: IPublisher) {
    return {
        type: DELETE_PUBLISHER_SUCCESS,
        payload: {
            publisher: publisher
        }
    };
}