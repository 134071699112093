import { DELETE_CHECKOUT_SUCCESS, CHECKOUT_BOOKS_SUCCESS, LIST_ALL_CHECKOUTS_LOADING, LIST_ALL_CHECKOUTS_SUCCESS, 
    RETURN_BOOK_SUCCESS, SEARCH_CHECKOUTS_SUCCESS, EMPTY_CHECKOUTS_LIST } from '../constants/action_types';
import ICheckout from '../types/checkout_type';
import { IAction } from '../types/react_types';

export interface ICheckoutsReducer {
    checkouts: ICheckout[];
    checkoutsLoading: boolean;
}

var defaultState: ICheckoutsReducer = {
    checkouts: [],
    checkoutsLoading: false
};

export default function (state: ICheckoutsReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_CHECKOUTS_LOADING:
        case LIST_ALL_CHECKOUTS_SUCCESS:
        case SEARCH_CHECKOUTS_SUCCESS:
        case EMPTY_CHECKOUTS_LIST:
            return Object.assign({}, state, action.payload);
        case CHECKOUT_BOOKS_SUCCESS:
            let checkoutsAfterCreate: ICheckout[] = state.checkouts.slice();
            checkoutsAfterCreate.concat(action.payload.checkouts);
            return Object.assign({}, state, {checkouts: checkoutsAfterCreate});
        case DELETE_CHECKOUT_SUCCESS:
            let deletedCheckout = action.payload.checkout;
            let checkoutsAfterDelete: ICheckout[] = state.checkouts.slice();
            let index = state.checkouts.findIndex((checkout: ICheckout) => checkout.id === deletedCheckout.id);
            checkoutsAfterDelete.splice(index, 1);
            return Object.assign({}, state, {checkouts: checkoutsAfterDelete});
        case RETURN_BOOK_SUCCESS:
            let updatedCheckout = action.payload.checkout;
            let checkoutsAfterReturn = state.checkouts.map((c: ICheckout) => c.id === action.payload.checkout.id ? 
                Object.assign({}, c, updatedCheckout) : c);
            return Object.assign({}, state, {checkouts: checkoutsAfterReturn});
        default:
            return state;
    }
    
}