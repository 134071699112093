export function validateISBN(isbnInput: string|number) {
    let isbn = '';
    if (typeof isbnInput === 'number') {
        isbn = isbnInput.toString();
    } else {
        isbn = isbnInput;
    }
    if (isbn.toString().indexOf('-') >= 0) {
        isbn = isbn.replace(/[^\dX]/gi, '');
    }
    if (isbn.length === 10) {
            let chars = isbn.split('');
            if (chars[9].toUpperCase() === 'X') {
                    chars[9] = '10';
            }
            let sum = 0;
            for (let i = 0; i < chars.length; i++) {
                    sum += ((10 - i) * parseInt(chars[i], 10));
            }
            return (sum % 11 === 0);
    } else if (isbn.length === 13) {
            let chars = isbn.split('');
            let sum = 0;
            for (let i = 0; i < chars.length; i++) {
                    if (i % 2 === 0) {
                            sum += parseInt(chars[i], 10);
                    } else {
                            sum += parseInt(chars[i], 10) * 3;
                    }
            }
            return (sum % 10 === 0);
    } else {
            return false;
    }
}

export function addZerosToISBN(isbn: string, type: number) {
  if (!isbn) {
      return '';
  }
  let str = isbn.toString();
  while (str.length < type && str.length > 1) {
      str = '0' + str;
  }
  return str;
}