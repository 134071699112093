import * as React from 'react';
import { printDateWithMonthName, compareStringDates, calucalatePeriod } from '../../helpers/date_helper';
import { mapListToString } from '../../helpers/list_helper';
import { isAllowed } from '../../helpers/checkAuthorization';
import ICheckout from '../../types/checkout_type';
import IUser from '../../types/user_type';

interface ICheckoutRowProps {
    checkout: ICheckout;
    number: number;
    loggedInUser: IUser | null;
    openAreYouSureModal(f: Function, title: string, description: string | React.ReactNode): void;
    delete(checkout: ICheckout): void;
    returnBook(checkout: ICheckout): void;
}

const CheckoutRow = (props: ICheckoutRowProps) => {
    let checkout = props.checkout;
    return (
        <tr key={checkout.id}>
            <td className="bold">{props.number}</td>
            <td><a href={`/profile/${checkout.user.id}`}>{checkout.user.firstName + ' ' + checkout.user.lastName}</a></td>
            <td><a href={`/bookDetails/${checkout.book.id}`}>{checkout.book.name}</a></td>
            <td>{mapListToString(checkout.book.authors, 'name')}</td>
            <td>{checkout.book.edition}</td>
            <td>{printDateWithMonthName(checkout.checkoutTime)}</td>
            <td>{printDateWithMonthName(checkout.expectedReturnTime)}</td>
            <td>
            {
                checkout.returnTime ? 
                    printDateWithMonthName(checkout.returnTime)
                    :
                    compareStringDates(checkout.expectedReturnTime, new Date().toString()) < 0 ?
                        <span className="label label-danger">Late!</span>
                        :
                        <div/>
            }
            </td>
            <td>{checkout.returnTime ? calucalatePeriod(checkout.checkoutTime, checkout.returnTime) : ''}</td>
            <td>
                { 
                    checkout.librarian ? 
                        <a href={`/profile/${checkout.librarian.id}`}>
                            {checkout.librarian.firstName + ' ' + checkout.librarian.lastName}
                        </a> 
                        : ''
                }
            </td>
            <td style={{ display: 'flex'}}>
                {
                    !checkout.returnTime && 
                    isAllowed(
                        props.loggedInUser, 
                        props.loggedInUser && checkout.user.id === props.loggedInUser.id ? 
                            'IBookService.ReturnBook' 
                            : 'IBookService.ReturnBookForSomeoneElse'
                    ) ?
                        <button 
                            type="button" 
                            onClick={() => props.openAreYouSureModal(
                                () => props.returnBook(checkout), 
                                'Returning book', 
                                <div>
                                    <h4>User: {props.checkout.user.firstName + ' ' + props.checkout.user.lastName}</h4>
                                    <h4>Book: {props.checkout.book.name}</h4>
                                </div>
                            )}
                            className="btn btn-success"
                        >
                            Return
                        </button>
                        : <div/>
                }
                &nbsp;
                {
                    !checkout.returnTime && 
                    isAllowed(
                        props.loggedInUser, 
                        props.loggedInUser && checkout.user.id === props.loggedInUser.id ? 
                            'ICheckoutService.Delete' 
                            : 'ICheckoutService.DeleteSomeoneElsesCheckout'
                        ) ?
                            <span className="remove-icon">
                                <i 
                                    className="fa fa-trash-o fa-2x" 
                                    style={{marginLeft: '5%'}} 
                                    onClick={() => props.openAreYouSureModal(
                                        () => props.delete(checkout), 
                                        'Deleting checkout', 
                                        <div>
                                            <h4>User: {props.checkout.user.firstName + ' ' + props.checkout.user.lastName}</h4>
                                            <h4>Book: {props.checkout.book.name}</h4>
                                        </div>
                                    )} 
                                    aria-hidden="true"
                                />
                            </span>
                            : <div/>
                }
            </td>
        </tr>
    );
}; 

export default CheckoutRow;