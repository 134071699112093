import { combineReducers } from 'redux';
import authorsReducer, { IAuthorsReducer } from './authors_reducer';
import usersReducer, { IUsersReducer } from './users_reducer';
import rolesReducer, { IRolesReducer } from './roles_reducer';
import permissionsReducer, { IPermissionsReducer } from './permissions_reducer';
import ranksReducer, { IRanksReducer } from './ranks_reducer';
import languageReducer, { ILanguagesReducer } from './languages_reducer';
import keywordReducer, { IKeywordsReducer } from './keywords_reducer';
import genreReducer, { IGenresReducer } from './genres_reducer';
import publisherReducer, { IPublishersReducer } from './publishers_reducer';
import booksReducer, { IBooksReducer } from './books_reducer';
import accountReducer, { IAccountReducer } from './account_reducer';
import checkoutsReducer, { ICheckoutsReducer } from './checkouts_reducer';
import subscriptionsReducer, { ISubscriptionsReducer } from './subscriptions_reducer';
import wishlistReducer, { IWishlistReducer } from './wishlist_reducer';
import googleBooksReducer, { IGoogleBooksReducer } from './google_books_reducer';
import configurationsReducer, { IConfigurationsReducer } from './configurations_reducer';

export interface ILibrarianStore {
    authorsReducer: IAuthorsReducer;
    usersReducer: IUsersReducer;
    rolesReducer: IRolesReducer;
    permissionsReducer: IPermissionsReducer;
    ranksReducer: IRanksReducer;
    languageReducer: ILanguagesReducer;
    keywordReducer: IKeywordsReducer;
    genreReducer: IGenresReducer;
    publisherReducer: IPublishersReducer;
    booksReducer: IBooksReducer;
    accountReducer: IAccountReducer;
    checkoutsReducer: ICheckoutsReducer;
    subscriptionsReducer: ISubscriptionsReducer;
    wishlistReducer: IWishlistReducer;
    googleBooksReducer: IGoogleBooksReducer;
    configurationsReducer: IConfigurationsReducer;
}

const allReducers = combineReducers({
    authorsReducer: authorsReducer,
    usersReducer: usersReducer,
    rolesReducer: rolesReducer,
    permissionsReducer: permissionsReducer,
    ranksReducer: ranksReducer,
    languageReducer: languageReducer,
    keywordReducer: keywordReducer,
    genreReducer: genreReducer,
    publisherReducer: publisherReducer,
    booksReducer: booksReducer,
    accountReducer: accountReducer,
    checkoutsReducer: checkoutsReducer,
    subscriptionsReducer: subscriptionsReducer,
    wishlistReducer: wishlistReducer,
    googleBooksReducer: googleBooksReducer,
    configurationsReducer: configurationsReducer
});

export default allReducers;