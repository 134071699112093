import * as React from 'react';
import { connect } from 'react-redux';
import IRank from '../../types/rank_type';
import { listAllRanks, createRank, updateRank, deleteRank } from '../../actions/ranks_actions';
import CreateUpdateRankModal from './create_update_rank_modal';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import RanksTable from './ranks_table';
import * as toastr from 'toastr';
import AreYouSureModal from '../are_you_sure_modal';
import { IThunkDispatch, IChangeEvent, IClickEvent, IAnyTargetType } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IRanksProps {
    ranks: IRank[];
    ranksLoading: boolean;
    loggedInUser: IUser | null;
    listAllRanks(): Promise<boolean>;
    createRank(rank: IRank): Promise<boolean>;
    updateRank(rank: IRank): Promise<boolean>;
    deleteRank(id: IRank): Promise<boolean>;
}

interface IRanksState {
    isModalOpen: boolean;
    editing: boolean;
    rank: IRank;
    image: string;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
    imageChanged: boolean;
}

class Ranks extends React.Component<IRanksProps, IRanksState> {
    constructor(props: IRanksProps) {
        super(props);
        this.state = {
            isModalOpen: false,
            editing: false,
            rank: {
                id: '',
                name: '',
                minimumCheckouts: 0,
                avatar: '',
                newRankMessage: ''
            },
            image: '',
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            },
            imageChanged: false
        };
    }

    public componentWillMount() {
        this.props.listAllRanks();
    }

    public render() {
        if (this.props.ranksLoading) {
            return <h3>Loading...</h3>;
        } else {
            let modal = this.state.isModalOpen ? 
                (
                    <CreateUpdateRankModal 
                        rank={this.state.rank} 
                        editing={this.state.editing} 
                        isModalOpen={this.state.isModalOpen} 
                        callFileChooser={this.callFileChooser}
                        removeImage={this.removeImage}
                        handleSubmitSave={this.handleSubmitSave} 
                        handleInputChange={this.handleModalInputChange} 
                        closeModal={this.closeModal} 
                        chooseImage={this.chooseImage} 
                        avatar={this.state.image} 
                        imageChanged={this.state.imageChanged}
                    /> 
                )
                : <div/>;
            return (
                <div className="col-md-12">
                    <h1>List of Ranks: </h1>
                    <div className="row-item">
                        { isAllowed(this.props.loggedInUser, 'IRankService.Create') ?
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                onClick={(e) => this.openModal(null, e)}
                            >
                                Create new <span className="glyphicon glyphicon-plus"/>
                            </button>
                            : <div/>
                        }
                    </div>
                    { isAllowed(this.props.loggedInUser, 'IRankService.ListAll') ?
                    <RanksTable 
                        ranks={this.props.ranks} 
                        openEdit={this.openModal} 
                        delete={this.props.deleteRank} 
                        loggedInUser={this.props.loggedInUser}
                        openAreYouSureModal={this.openAreYouSureModal} 
                    />
                    : <div/>}
                    {modal}          
                    <AreYouSureModal 
                        title={this.state.areYouSureModalParams.title} 
                        isOpen={this.state.areYouSureModalParams.isOpen} 
                        action={this.state.areYouSureModalParams.action}
                        close={this.closeAreYouSureModal} 
                        description={null} 
                    />          
                </div>
            );
        }
    }

    handleSubmitSave = () => {
        if (!this.validateForm(this.state.rank)) {
            return Promise.reject('Invalid form');
        }
        let rank = Object.assign({}, this.state.rank, {avatar: this.state.image});
        if (this.state.editing) {
            return this.props.updateRank(rank)
                .then((success: boolean) => {
                    if (success) {
                        this.setState({
                            isModalOpen: false,
                            imageChanged: false
                        });
                    }
                });
        } else {
            return this.props.createRank(rank)
                .then((success: boolean) => {
                    if (success) {
                        this.setState({
                            isModalOpen: false,
                            imageChanged: false
                        });
                    }
                });
        }
    }

    handleModalInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        let value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newRank = Object.assign({}, prevState.rank, {
                [name]: value
            });
            return {rank: newRank};
        });
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false,
            imageChanged: false
        });
    }

    validateForm = (rank: IRank) => {
        if (!rank) {
            return false;
        }
        if (!rank.name || rank.name.trim() === '') {
            toastr.error('Rank name cannot be empty.');
            return false;
        }
        if (rank.minimumCheckouts < 0) {
            toastr.error('Minimum checkouts for a rank must be 0 or greater.');
            return false;
        }
        return true;
    }

    openModal = (rank: IRank | null, e: IClickEvent) => {
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({
                isModalOpen: true,
                editing: rank !== null,
                rank: rank ? rank : {
                    id: '',
                    name: '',
                    minimumCheckouts: 0,
                    avatar: '',
                    newRankMessage: ''
                },
                image: rank ? rank.avatar : ''
            });
        }
    }

    // tslint:disable-next-line:no-any
    callFileChooser = (e: any) => {
        // tslint:disable-next-line:no-any
        var el: any = e.refs.photoUpload;
        if (el != null) {
            el.click();
        }
    }

    chooseImage = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        var reader = new FileReader();

        reader.addEventListener('loadend', this.finishReadingFile);

        reader.readAsDataURL(target.files[0]);
    }

    finishReadingFile = (e: Event) => {
        const target: IAnyTargetType = e.currentTarget;
        let buffer = target.result.split(',')[1];
        this.setState({image: buffer, imageChanged: true});
    }

    removeImage = (event: IClickEvent) => {
        event.preventDefault();
        this.setState({image: ''});
        // tslint:disable-next-line
        const element: any = document.getElementById('photoUpload');
        element.value = '';
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IRanksState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IRanksState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        ranks: store.ranksReducer.ranks,
        ranksLoading: store.ranksReducer.ranksLoading,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllRanks: () => dispatch(listAllRanks()),
    createRank: (rank: IRank) => dispatch(createRank(rank)),
    updateRank: (rank: IRank) => dispatch(updateRank(rank)),
    deleteRank: (rank: IRank) => dispatch(deleteRank(rank))
});

export default connect(mapStateToProps, matchDispatchToProps)(Ranks);