import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IRole from '../../types/role_type';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IRoleRowProps {
    role: IRole;
    loggedInUser: IUser | null;
    allowRowSelect: boolean;
    actionButtons: JSX.Element;
    number: number;
    openEditRoleModal?(role: IRole, e: IClickEvent): void;
}

const RoleRow = (props: IRoleRowProps) => {
    let role = props.role;
    return (
        <tr 
            key={role.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e: IClickEvent) => 
                props.allowRowSelect 
                && props.openEditRoleModal 
                && isAllowed(props.loggedInUser, 'IRoleService.Update') ? 
                    props.openEditRoleModal(role, e) 
                    : {}
            }
        >
            <td className="bold">{props.number}</td>
            <td>{role.name}</td>
            <td>
                {props.actionButtons}
            </td>
        </tr>
    );
};

export default RoleRow;