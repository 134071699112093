import { DEFAULT_CONTENT_TYPE_HEADERS } from '../constants/other_constants';

export function getAuthorizedContentTypeHeaders() {
    let userString = localStorage.getItem('user');
    if (userString == null) {
        return DEFAULT_CONTENT_TYPE_HEADERS;
    }
    let user = JSON.parse(userString ? userString : '');
    if (user && user.jwt) {
        return new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + user.jwt
        });
    } else {
        return DEFAULT_CONTENT_TYPE_HEADERS;
    }
}

export function getAuthorizedHeaders() {
    let userString = localStorage.getItem('user');
    if (userString == null) {
        return new Headers();
    }
    let user = JSON.parse(userString ? userString : '');
    if (user && user.jwt) {
        return new Headers({
            'Authorization': 'Bearer ' + (user ? user.jwt : '')
        }); 
    } else {
        return new Headers({});
    }
}