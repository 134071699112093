import IBook from '../types/book_type';
import IComment from '../types/comment_type';
import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';
import ICheckout from '../types/checkout_type';

class BookService {

    listAllBooks(sortBy: string, asc: boolean) {
        return fetch(LINK_BASE + `/Books/ListAll?sortBy=${sortBy}&asc=${asc}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IBook[]>);
    }

    listAllAvailableBooks(sortBy: string, asc: boolean) {
        return fetch(LINK_BASE + `/Books/ListAllAvailableBooks?sortBy=${sortBy}&asc=${asc}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IBook[]>);
    }

    addBook(book: IBook) {
        var isbn13 = book.isbN13 ? book.isbN13.trim() : null;
        var isbn10 = book.isbN10 ? book.isbN10.trim() : null;
        if (book.isbN10 && book.isbN10.indexOf('-') >= 0) {
            isbn10 = book.isbN10.replace(/[^\dX]/gi, '');
        }
        if (book.isbN13 && book.isbN13.indexOf('-') >= 0) {
            isbn13 = book.isbN13.replace(/[^\dX]/gi, '');
        }
        var reqBody = JSON.stringify({
            id: EMPTY_GUID,
            name: book.name,
            publicationDate: book.publicationDate,
            rating : 0,
            description: book.description,
            ISBN13: isbn13,
            ISBN10: isbn10,
            edition: book.edition,
            totalCopies: book.totalCopies,
            authors: book.authors,
            keywords: book.keywords,
            genres: book.genres,
            language: book.language,
            publisher: book.publisher,
            image: book.image,
            imageUrl: book.imageUrl 
        });
        return  fetch(LINK_BASE + '/Books', {
            method: 'post',
            body: reqBody,
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<IBook>);
    }
    updateBook(book: IBook) {
        var isbn13 = book.isbN13 ? book.isbN13.trim() : null;
        var isbn10 = book.isbN10 ? book.isbN10.trim() : null;
        if (book.isbN10 && book.isbN10.indexOf('-') >= 0) {
            isbn10 = book.isbN10.replace(/[^\dX]/gi, '');
        }
        if (book.isbN13 && book.isbN13.indexOf('-') >= 0) {
            isbn13 = book.isbN13.replace(/[^\dX]/gi, '');
        }
        return  fetch(LINK_BASE + '/Books', {
            method: 'put',
            body: JSON.stringify({
                id: book.id,
                name: book.name,
                publicationDate: book.publicationDate,
                rating : 0,
                description: book.description,
                ISBN13: isbn13,
                ISBN10: isbn10,
                edition: book.edition,
                totalCopies: book.totalCopies,
                authors: book.authors,
                keywords: book.keywords,
                genres: book.genres,
                language: book.language,
                publisher: book.publisher,
                image: book.image ,
                imageUrl: book.imageUrl
            }),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<IBook>);
    }
    get(id: string) {
        let path = LINK_BASE + '/Books/' + id;
        return fetch(path, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IBook>);
    }
    
    deleteBook(book: IBook) {
        return fetch(LINK_BASE + '/Books/' + book.id, {
            method: 'delete',
            headers:  getAuthorizedHeaders()
        }).then(handleErrors);
    }

    rateBook(book: IBook, grade: number) {
        return fetch(LINK_BASE + '/Books/RateBook', {
            method: 'post',
            body: JSON.stringify({
               bookId: book.id,
               grade: grade 
            }),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors)
        .then((response) => response.json() as Promise<IBook>);
    }

    getUserRating(book: IBook) {
        return fetch(LINK_BASE + '/Books/GetRating/' + book.id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json());
    }

    checkoutBooks(userId: string, books: IBook[]) {
        let ids = books.map(x => x.id);
        return fetch(LINK_BASE + '/Books/CheckoutBooks', {
            method: 'post',
            body: JSON.stringify({
                userId: userId,
                books: ids
            }),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<ICheckout[]>);
    }

    returnBook(checkout: ICheckout) {
        return fetch(LINK_BASE + '/Books/ReturnBook/' + checkout.id, {
            method: 'put',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<ICheckout>);
    }

    search(name: string, startingRating: number, isbn13: string, isbn10: string, languageId: string, publisherId: string, 
           genreId: string, keywordId: string, authorId: string, sortBy: string, asc: boolean) {
        return fetch(
            LINK_BASE + `/Books/Search?name=${name}&startingRating=${startingRating}&ISBN13=` + 
                `${isbn13 || ''}&ISBN10=${isbn10 || ''}&languageId=${languageId}&` + 
                `publisherId=${publisherId}&genreId=${genreId}&keywordId=${keywordId}&authorId=${authorId}&sortBy=${sortBy}&asc=${asc}`, 
            {
                headers: getAuthorizedHeaders()
            }
        ).then(handleErrors).then(response => response.json() as Promise<IBook[]>);
    }

    searchAvailableBooks(name: string, startingRating: number, isbn13: string, isbn10: string, languageId: string,
                         publisherId: string, genreId: string, keywordId: string, authorId: string, sortBy: string, asc: boolean) {
        return fetch(
            LINK_BASE + `/Books/SearchAvailableBooks?name=${name}&startingRating=${startingRating}&ISBN13=${isbn13 || ''}` + 
                `&ISBN10=${isbn10 || ''}&languageId=${languageId}&` +
                `publisherId=${publisherId}&genreId=${genreId}&keywordId=${keywordId}&authorId=${authorId}&sortBy=${sortBy}&asc=${asc}`, 
            {
                headers: getAuthorizedHeaders()
            }
            ).then(handleErrors).then(response => response.json() as Promise<IBook[]>);
    }
    
    addComment(comment: IComment) {
        return fetch(LINK_BASE + '/Books/CommentBook', {
            method: 'post',
            body: JSON.stringify({
                bookId: comment.bookId,
                text: comment.text
            }),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IBook>);
    }

    updateComment(comment: IComment) {
        return fetch(LINK_BASE + '/Books/UpdateComment', {
            method: 'post',
            body: JSON.stringify({
                id: comment.id,
                bookId: comment.bookId,
                text: comment.text,
                lastModifiedOn: comment.lastModifiedOn
            }),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IBook>);
    }

    deleteComment(comment: IComment) {
        return fetch(LINK_BASE + '/Books/DeleteComment/' + comment.id, {
            method: 'delete',
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors);
    }

    recommendBook(bookId: string, fromUserId: string, toUserIds: string[]) {
        return fetch(LINK_BASE + '/Books/RecommendBook', {
            method: 'post',
            body: JSON.stringify({
                bookId: bookId,
                fromUserId: fromUserId,
                toUsersId: toUserIds
            }),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors);
    }

    isSubscribed(userId: string, bookId: string) {
        return fetch(LINK_BASE + '/Books/IsSubscribed/' + userId + '/' + bookId , {
            method: 'get',
            headers: getAuthorizedHeaders()
        })
        .then((response) => response.json() as Promise<boolean>);
    }
    
    subscribe(bookId: string) {
        return fetch(LINK_BASE + '/Books/SubscribeSelf/' + bookId, {
            method: 'post',
            headers: getAuthorizedHeaders()
        });
    }

    unsubscribe(bookId: string) {
        return fetch(LINK_BASE + '/Books/UnsubscribeSelf/' + bookId, {
            method: 'delete',
            headers: getAuthorizedHeaders()
        });
    }

    subscribeToAll() {
        return fetch(LINK_BASE + '/Books/SubscribeSelfToAll', {
            method: 'post',
            headers: getAuthorizedHeaders()
        });
    }

    unsubscribeFromAll() {
        return fetch(LINK_BASE + '/Books/UnsubscribeSelfFromAll', {
            method: 'delete',
            headers: getAuthorizedHeaders()
        });
    }

    subscribeUserToBook(userId: string, bookId: string) {
        return fetch(LINK_BASE + '/Books/SubscribeUser/' + userId + '/' + bookId, {
            method: 'post',
            headers: getAuthorizedHeaders()
        });
    }

    unsubscribeUserFromBook(userId: string, bookId: string) {
        return fetch(LINK_BASE + '/Boooks/UnsubscribeUser/' + userId + '/' + bookId, {
            method: 'delete',
            headers: getAuthorizedHeaders()
        });
    }

    getTop5BooksByRating() {
        return fetch(LINK_BASE + '/Books/GetTop5BestRatedBooks', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IBook[]>);
    }

    getTop5MostReadBooks() {
        return fetch(LINK_BASE + '/Books/GetTop5MostReadBooks', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IBook[]>);
    }
}
 
export default new BookService();
