import { LINK_BASE } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders } from '../helpers/header_helper';
import ISubscription from '../types/subscription_type';

class SubscriptionsService {

    listAll() {
        return fetch(LINK_BASE + '/Books/ListAllSubscriptions', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<ISubscription[]>);
    }

    deleteSubscriptionForUser(userId: string, bookId: string, sameUser: boolean) {
        if (sameUser) {
            return fetch(LINK_BASE + '/Books/UnsubscribeSelf/' + bookId, {
                method: 'delete',
                headers: getAuthorizedHeaders()
            })
            .then(handleErrors);
        } else {
            return fetch(LINK_BASE + '/Books/UnsubscribeUser/' + userId + '/' + bookId, {
                method: 'delete',
                headers: getAuthorizedHeaders()
            })
            .then(handleErrors);
        }
    }

    searchSubscriptions(userId: string, bookId: string) {
        return fetch(LINK_BASE + '/Books/SearchSubscriptions/' + userId + '/' + bookId, {
            method: 'get',
            headers: getAuthorizedHeaders()
        }) 
        .then(handleErrors)
        .then((response) => response.json() as Promise<ISubscription[]>);
    }

    subscribeUser(userId: string, bookId: string) {
        return fetch(LINK_BASE + '/Books/SubscribeUser/' + userId + '/' + bookId, {
            method: 'post',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<ISubscription>);
    }

    subscribeUserToAll(userId: string) {
        return fetch(LINK_BASE + '/Books/SubscribeUserToAll/' + userId, {
            method: 'post',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    unsubscribeUserFromAll(userId: string) {
        return fetch(LINK_BASE + '/Books/UnsubscribeUserFromAll/' + userId, {
            method: 'delete',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }
}

export default new SubscriptionsService();