import * as React from 'react';
import { MODAL_STYLES_WISH } from '../../constants/other_constants';
import Modal from 'react-modal';
import Select from 'react-select';
import { IFormEvent, IClickEvent, IChangeEvent } from '../../types/react_types';

interface INewWishModalProps {
  isOpen: boolean;
  title: string;
  wishParams: {
    isOpen: boolean,
    bookName: string,
    authors: string,
    publisher: string,
    isbn13: string,
    isbn10: string,
    statusId: string
  };
  handleInputChange: Function;
  listAuthors(): { label: string, value: string }[];
  //tslint:disable-next-line:no-any
  removeAuthor(e: IClickEvent, index: number): any;
  addAuthor(): void;
  listPublishers(): { label: string, value: string }[];
  closeModal(): void;
  handleSubmitSave(): Promise<boolean>;
  //tslint:disable-next-line:no-any
  handlePublisherChange(e: any): void;
  //tslint:disable-next-line:no-any
  handleAuthorChange(e: any): void;
}

interface INewWishModalState {
  saveClicked: boolean;
}

class NewWishModal extends React.Component<INewWishModalProps, INewWishModalState> {

  state = {
    saveClicked: false
  };

  enableSave = () => {
    this.setState({ saveClicked: false });
  }

  onFormSubmit = (e: IFormEvent) => {
    e.preventDefault();
    this.setState({ saveClicked: true });
    this.props.handleSubmitSave().then(this.enableSave).catch(this.enableSave);
  }

  render() {
    const props = this.props;
    if (props.isOpen) {
        return (
            <Modal isOpen={props.isOpen} style={MODAL_STYLES_WISH} >
                <h2>{props.title}</h2>
                <form onSubmit={this.onFormSubmit}>
                    <div className="form-group">
                        <label> Book Name:</label>
                        <input 
                            type="text"  
                            value={props.wishParams.bookName}  
                            name="bookName" 
                            className="form-control" 
                            onChange={(e: IChangeEvent) => props.handleInputChange(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label> Author(s):</label>
                        <Select
                            className="selectList2"
                            closeOnSelect={true}
                            multi={true}
                            onChange={props.handleAuthorChange}
                            placeholder="Select author(s)"
                            simpleValue={true}
                            value={props.wishParams.authors}
                            options={props.listAuthors()}
                            name="authors"
                        />
                        <br/>
                        { props.wishParams.authors.split(',').map((author: string, index: number) => {
                            return (
                                <div className="input-compound" key={index}>
                                    <input 
                                        type="text" 
                                        value={author} 
                                        key={index} 
                                        name="author" 
                                        className="form-control" 
                                        onChange={(e: IChangeEvent) => props.handleInputChange(e, index)}
                                        placeholder="Type in author's name"
                                    />
                                    <i
                                        className="input-compound-icon glyphicon glyphicon-remove"
                                        onClick={(e: IClickEvent) => props.removeAuthor(e, index)}
                                    />
                                </div>
                            ); 
                        })}
                        <br/>
                        <button type="button" className="btn btn-default" onClick={() => props.addAuthor()}>Add Author</button>
                    </div>
                    <div className="form-group">
                        <label>Publisher:</label>
                        <Select
                            className="selectList2"
                            closeOnSelect={true}
                            onChange={props.handlePublisherChange}
                            placeholder="Select publisher"
                            simpleValue={true}
                            value={props.wishParams.publisher}
                            options={props.listPublishers()}
                            name="publisher"
                        />
                        <br/>
                        <input 
                            type="text"  
                            value={props.wishParams.publisher} 
                            name="publisher" 
                            className="form-control"
                            onChange={(e: IChangeEvent) => props.handleInputChange(e)} 
                            placeholder="Type in publisher's name"
                        />
                    </div>
                    <div className="form-group">
                        <label> ISBN13:</label>
                        <input 
                            type="text" 
                            value={props.wishParams.isbn13}  
                            name="isbn13" 
                            className="form-control" 
                            onChange={(e: IChangeEvent) => props.handleInputChange(e)}
                        />
                    </div>
                    <div className="form-group">
                    <label> ISBN10</label>
                    <input 
                        type="text"  
                        value={props.wishParams.isbn10}  
                        name="isbn10" 
                        className="form-control" 
                        onChange={(e: IChangeEvent) => props.handleInputChange(e)} 
                    />
                </div>
                    <button type="button" className="btn btn-default" onClick={() => props.closeModal()}>Cancel</button>
                    <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                </form>
            </Modal>
        );
    } else {
        return <div/>;
    }
  }
    
}

export default NewWishModal;