import * as React from 'react';
import IBook from '../../types/book_type';

interface IChooseBooksButtonsProps {
    book: IBook;
    addedBooks: IBook[];
    handleCheckboxChanged(added: boolean, book: IBook): void;
}

const ChooseBooksButtons = (props: IChooseBooksButtonsProps) => {
    let book = props.book;
    let addedBooks = props.addedBooks;
    let added = addedBooks.find((b: IBook) => b.id === book.id);
    return (
        <div>
            <div className="checkbox">
                <label>
                    <input type="checkbox" onChange={() => props.handleCheckboxChanged(!!added, book)} defaultChecked={!!added} value="" />
                </label>
            </div>
        </div>
    );
};

export default ChooseBooksButtons;