import { LIST_ALL_USERS_LOADING, LIST_ALL_USERS_SUCCESS, DEACTIVATE_USER_SUCCESS, ACTIVATE_USER_SUCCESS,
    CHANGE_ROLE_SUCCESS, SEARCH_USERS_LOADING, SEARCH_USERS_SUCCESS, LIST_ALL_ACTIVE_USERS_SUCCESS, 
    SEARCH_ACTIVE_USERS_SUCCESS, GET_TOP_5_MOST_ACTIVE_USERS_LOADING, GET_TOP_5_MOST_ACTIVE_USERS_SUCCESS,
    GET_USER_LOADING, GET_USER_SUCCESS } from '../constants/action_types';
import IUser from '../types/user_type';
import { IAction } from '../types/react_types';

export interface IUsersReducer {
    users: IUser[];
    usersLoading: boolean;
    user: IUser | null;
    userLoading: boolean;
    activeUsers: IUser[];
    mostActiveUsers: IUser[];
    mostActiveUsersLoading: boolean;
}

var defaultState: IUsersReducer = {
    users: [],
    usersLoading: false,
    user: null,
    userLoading: false,
    activeUsers: [],
    mostActiveUsers: [],
    mostActiveUsersLoading: false
};

export default function (state: IUsersReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_USERS_LOADING:
        case LIST_ALL_USERS_SUCCESS:
        case SEARCH_USERS_LOADING:
        case LIST_ALL_ACTIVE_USERS_SUCCESS:
        case SEARCH_USERS_SUCCESS:
        case SEARCH_ACTIVE_USERS_SUCCESS:
        case GET_TOP_5_MOST_ACTIVE_USERS_LOADING:
        case GET_TOP_5_MOST_ACTIVE_USERS_SUCCESS:
        case GET_USER_LOADING:
        case GET_USER_SUCCESS:
            return Object.assign({}, state, action.payload);
        case DEACTIVATE_USER_SUCCESS:
        case ACTIVATE_USER_SUCCESS:
        case CHANGE_ROLE_SUCCESS: 
            let updatedUser = action.payload.user;
            let usersAfterUpdate = state.users.map((u: IUser) => u.id === updatedUser.id ? Object.assign({}, u, updatedUser) : u);
            return Object.assign({}, state, {users: usersAfterUpdate, user: updatedUser});
        default:
            return state;
    }
}