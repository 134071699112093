import { LIST_ALL_GENRES_SUCCESS, ADD_GENRE_SUCCESS, UPDATE_GENRE_SUCCESS, DELETE_GENRE_SUCCESS } from '../constants/action_types';
import IGenre from '../types/genre_type';
import genreService from '../services/genres_service';
import { catchError } from '../helpers/error_helper';
import * as toastr from 'toastr';
import { IThunkDispatch } from '../types/react_types';

export function listAllGenres() {
    return function ( dispatch: IThunkDispatch ) {
        return genreService.listAllGenres()
        .then(genres => {
            dispatch(listAllGenresSuccess(genres));
            return true;
        })
        .catch(catchError);
    };
}

export function listAllGenresSuccess (genres: IGenre[]) {
    return {
        type: LIST_ALL_GENRES_SUCCESS,
        payload: {
            genres: genres
        }
    };
}

export function addGenre (genre: IGenre) {
    return function (dispatch: IThunkDispatch) {
        return genreService.addGenre(genre)
        .then(genreNew => {
            toastr.success('Genre created');
            dispatch(addGenreSuccess(genreNew));
            return true;
        })
        .catch(catchError);
    };
}

export function addGenreSuccess(genre: IGenre) {
    return {
        type: ADD_GENRE_SUCCESS,
        payload: {
            genre: genre
        }
    };
}

export function updateGenre(genre: IGenre) {
    return function (dispatch: IThunkDispatch) {
        return genreService.updateGenre(genre)
        .then(genreNew => {
            toastr.success('Genre updated');
            dispatch(updateGenreSuccess(genreNew));
            return true;
        })
        .catch(catchError);
    };
}

export function updateGenreSuccess(genre: IGenre) {
    return {
        type: UPDATE_GENRE_SUCCESS,
        payload: {
            genre: genre
        }
    };
}

export function deleteGenre(genre: IGenre) {
    return function (dispatch: IThunkDispatch) {
        return genreService.deleteGenre(genre)
        .then( () => {
            toastr.success('Genre deleted');
            dispatch(deleteGenreSuccess(genre));
            return true;
        })
        .catch(catchError);
    };
}

export function deleteGenreSuccess(genre: IGenre) {
    return {
        type: DELETE_GENRE_SUCCESS,
        payload: {
            genre: genre
        }
    };
}
