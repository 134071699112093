import * as React from 'react';
import BookTable from '../books/book_table';
import IUser from '../../types/user_type';
import IBook from '../../types/book_type';
import { History } from 'history';

interface ICheckoutSummaryProps {
  show: boolean;
  user: IUser | null;
  addedBooks: IBook[];
  history: History;
  submitCheckouts(): Promise<void>;
  back(): void;
  renderBookRowActionButtons(book: IBook): JSX.Element;
}

interface ICheckoutSummaryState {
  submitClicked: boolean;
}

class CheckoutSummary extends React.Component<ICheckoutSummaryProps, ICheckoutSummaryState> {

  state = {
    submitClicked: false
  };

  enableSubmit = () => {
    this.setState({ submitClicked: false });
  }

  handleSubmit = () => {
    this.setState({ submitClicked: true });
    this.props.submitCheckouts().then(this.enableSubmit).catch(this.enableSubmit);
  }

  render() {
    const props = this.props;
    if (props.show) {
      return (
          <div>
              <div className="head-stripe">
                <button 
                    type="button" 
                    className="btn btn-default" 
                    onClick={props.back}
                >
                    <span className="glyphicon glyphicon-menu-left"/>
                    Back
                </button>
              </div>
              <div className="row-item">
                  <h3>Checkout summary</h3>
                  <div className="entry">
                      <div className="label-name">User: </div>
                      <h4 className="main-title">{props.user ? props.user.firstName + ' ' + props.user.lastName : ''}</h4>
                  </div>
              </div>
              <div className="row-item">
                  <h3>Added books</h3>
                  <BookTable 
                    books={props.addedBooks} 
                    renderRowActionButtons={props.renderBookRowActionButtons} 
                    loggedInUser={props.user} 
                    history={props.history} 
                  />
              </div>
              <div className="row">
                  <div className="col-md-4">
                      <label>Do you want to finalize this Checkout?</label>
                  </div>
                  <div className="col-md-3">
                      <button 
                        type="button" 
                        className="btn btn-success" 
                        onClick={this.handleSubmit} 
                        disabled={this.state.submitClicked}
                      >
                        Submit
                      </button>
                  </div>
              </div>
          </div>
      );
    } else {
        return <div/>;
    }
  }

}

export default CheckoutSummary;