import * as React from 'react';
import SuggestedImage from './suggested_image';
import { CARDS_BY_PAGE } from '../../constants/other_constants';

interface ISuggestedImagesListProps {
    //tslint:disable:no-any
    searchResult: any;
    currentPage: number;
    changePage(by: number): void;
    chooseSuggestedImage(src: string): void;
}

const SuggestedImagesList = (props: ISuggestedImagesListProps) => {
    let searchResult = props.searchResult;
    let books = searchResult && searchResult.items ? searchResult.items : [];
    if (books.length !== 0) {
        return (
            <div>

                <div>
                    <p>
                        Found: {searchResult.totalItems} images, showing results: 
                        {1 + (CARDS_BY_PAGE) * props.currentPage} - {CARDS_BY_PAGE * (props.currentPage + 1)}
                    </p>
                    <p>Choose one:</p>
                </div>
                {searchResult.totalItems > 9 ?
                    <div>
                        <button 
                            type="button" 
                            className="btn btn-default" 
                            onClick={() => props.changePage(-1)} 
                            disabled={props.currentPage === 0}
                        >
                            <span className="glyphicon glyphicon-menu-left"/> Previous page
                        </button> 
                        &nbsp;
                        <button 
                            type="button" 
                            className="btn btn-default" 
                            onClick={() => props.changePage(1)} 
                            disabled={(props.currentPage + 1) * CARDS_BY_PAGE >= searchResult.totalItems}
                        >
                            Next page 
                            <span className="glyphicon glyphicon-menu-right"/>
                        </button>
                        <br/><br/>
                    </div>
                    : ''
                }

                {books.filter((x: any) => x.volumeInfo.imageLinks && x.volumeInfo.imageLinks.smallThumbnail).map((x: any, index: number) => 
                    <SuggestedImage imgSrc={x.volumeInfo.imageLinks.smallThumbnail} key={index} chooseSuggestedImage={props.chooseSuggestedImage} />)}

                <div className="row">
                    {searchResult.totalItems > 9 ?
                        <div>
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                onClick={() => props.changePage(-1)} 
                                disabled={props.currentPage === 0}
                            >
                                <span className="glyphicon glyphicon-menu-left"/>
                                Previous page
                            </button> 
                            &nbsp;
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                onClick={() => props.changePage(1)} 
                                disabled={(props.currentPage + 1) * CARDS_BY_PAGE >= searchResult.totalItems}
                            >
                                Next page 
                                <span className="glyphicon glyphicon-menu-right"/>
                            </button>
                            <br/>
                        </div>
                        : ''
                    }
                </div>
            </div>
        );
    } else { 
        return (
            <div>
                <h4>No images to suggest.</h4>
            </div>
        );
    }
};

export default SuggestedImagesList;