import * as React from 'react';
import IAuthor from '../../types/author_type';
import IBook from '../../types/book_type';
import IKeyword from '../../types/keyword_type';
import IUser from '../../types/user_type';
import IGenre from '../../types/genre_type';
import { getBook,  rateBook, getUserRating, addComment, updateComment, deleteComment, recommendBooks, isSubscribed, subscribeToBook, 
    unsubscribeToBook, deleteBook } from '../../actions/books_actions';
import { listAllUsers } from '../../actions/users_actions';
import Ratings from '../ratings/ratings';
import Comments from '../comments/comments';
import { connect } from 'react-redux';
import RecommendBook from './recommendBook';
import { isAllowed } from '../../helpers/checkAuthorization';
import AreYouSureModal from '../are_you_sure_modal';
import { NavLink } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import { LINK_BASE } from '../../constants/other_constants';
import { RouteComponentProps } from 'react-router';
import { IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';
import IComment from '../../types/comment_type';

interface IBookDetailsRouteParams {
    id: string;
}

interface IBookDetailsProps extends RouteComponentProps<IBookDetailsRouteParams>  {
    book: IBook | null;
    grade: number;
    user: IUser | null;
    users: IUser[];
    isSubscribed: Function;
    subscribed: boolean;
    getBook(id: string): Promise<IBook|boolean>;
    getUserRating(book: IBook): Promise<boolean>;
    addComment(comment: IComment): Promise<boolean>;
    updateComment(comment: IComment): Promise<boolean>;
    deleteComment(comment: IComment): Promise<boolean>;
    listAllUsers(sortBy?: string, asc?: boolean): Promise<boolean>;
    subscribe(bookId: string): Promise<boolean>;
    unSubscribe(bookId: string): Promise<boolean>;
    deleteBook(book: IBook): Promise<boolean>;
    rateBook(book: IBook, grade: number): Promise<boolean>;
    recommendBooks(bookId: string, fromUserId: string, toUserIds: string[]): Promise<boolean>;
}

interface IBookDetailsState {
    loading: boolean;
    editing: boolean;
    currentBook: IBook;
    rated: boolean;
    recommending: boolean;
    subscribed: boolean;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
}

class BookDetails extends React.Component<IBookDetailsProps, IBookDetailsState> {
    constructor(props: IBookDetailsProps) {
        super(props);
        this.state = {
             loading: true,
             editing: false,
             currentBook: {id: '', name: '', publicationDate: undefined, totalCopies: 0, rating: 0, description: '',
             isbN13: '', isbN10: '', edition: '', language: {id: '', name: ''}, publisher: {id: '', name: '', country: ''}, 
             keywords: [], genres: [], authors: [], image: '', comments: [], availableCopies: 0, imageUrl: null},
             rated: false,
             recommending: false,
             subscribed: false,
             areYouSureModalParams: {
                 title: '',
                 action: () => {},
                 isOpen: false
             }
        };
    }

    public componentWillMount() {
        let id = this.props.match.params.id;
        if (id) {
            this.props.getBook(id).then((book: IBook|boolean) => {
                if (typeof book === 'boolean') {
                    return;
                }
                if (!book) {
                  this.props.history.push('/notfound');
                }
                this.setState({
                    loading: false,
                    editing: false,
                    currentBook: book
                });
                if (this.props.user) {
                    this.props.isSubscribed(this.props.user.id, this.state.currentBook.id).then(() => {
                        this.setState({
                            subscribed: this.props.subscribed
                        });
                    });
                }
                this.props.getUserRating(this.state.currentBook).then(( rated: boolean) => {
                    this.setState({
                        rated: rated
                    });
                });
            });
        }
    }

    public render() {
        if (this.state.loading) {
            return <h3>Loading...</h3>;
        } else {
            return this.renderPage();
        }
    }

    renderPage = () => {
        let authors = this.state.currentBook.authors && this.state.currentBook.authors.length > 0 ? 
            (
            <ul>
                {
                    this.state.currentBook.authors.map(function(author: IAuthor) {
                    return <li key={author.id}>{author.name}</li>;   }) 
                }
            </ul>
            )
            : '';
        let keywords = this.state.currentBook.keywords && this.state.currentBook.keywords.length > 0 ? 
            (
            <ul>
                {
                    this.state.currentBook.keywords.map(function(keyword: IKeyword) {
                    return <li key={keyword.id}>{keyword.name}</li>;   }) 
                }
            </ul>
            )
            : '';
        let genres = this.state.currentBook.genres && this.state.currentBook.genres.length > 0 ? 
            (
            <ul>
                {
                    this.state.currentBook.genres.map(function(genre: IGenre) {
                    return <li key={genre.id}>{genre.name}</li>;   }) 
                }
            </ul>
            )
            : '';
        let date = this.state.currentBook.publicationDate || '';
        let recommend = this.state.recommending ? 
            (
            <RecommendBook 
                closing={false} 
                closeModal={this.closeModal} 
                book={this.state.currentBook} 
                recommendBooks={this.props.recommendBooks}
            /> 
            )
            : '';
        let unSubscription = this.state.subscribed && isAllowed(this.props.user, 'IBookService.UnsubscribeSelf') ? 
            <button type="button" onClick={this.unSubscribe} className="btn btn-warning btn-inline ">Unsubscribe</button> 
            : '';
        let subscription = !this.state.subscribed &&  isAllowed(this.props.user, 'IBookService.SubscribeSelf') ? 
            <button type="button" onClick={this.subscribe} className="btn btn-warning btn-inline ">Subscribe</button>
            : '';
        return (
            <div>
                <div className="row">
                    <div className="col-md-2">
                        <img 
                            className="img-offset" 
                            src={this.state.currentBook.image ? LINK_BASE + this.state.currentBook.image : require('../../../images/no_image.png')} 
                            alt="Image not available" 
                        />
                    </div>
                    <div className="col-md-10">
                        <div className="entry">
                            <div className="row row-item">
                                <div className="col-md-8">
                                    <h3 className="h2 title-underline">{this.state.currentBook.name}</h3>
                                    <h4>Description:</h4>
                                    <p>{this.state.currentBook.description}</p>
                                    { isAllowed(this.props.user, 'IBookService.Update') ?
                                    <NavLink exact={true} to={'/bookForm/' + this.state.currentBook.id} activeClassName="active">
                                    <button 
                                        type="button" 
                                        className="btn btn-warning btn-inline "
                                    >
                                            Edit &nbsp;
                                            <span className="glyphicon glyphicon-edit"/>
                                    </button>
                                    </NavLink>
                                    : <div/>}
                                    { isAllowed(this.props.user, 'IBookService.Delete') ?
                                    <button 
                                        type="button"  
                                        className="btn btn-danger btn-inline " 
                                        onClick={() => this.openAreYouSureModal(
                                            () => this.deleteBook(), 'Deleting book "' + this.state.currentBook.name + '"'
                                        )}
                                    >
                                        Delete  &nbsp;<span className="glyphicon glyphicon-remove"/>
                                    </button>
                                    : <div/>}
                                </div>
                                <div className="col-md-4">
                                    { isAllowed(this.props.user, 'IBookService.RateBook') ?
                                    <Ratings 
                                        rateBook={this.props.rateBook} 
                                        setCurrentBook={this.setCurrentBook} 
                                        book={this.props.book} 
                                        grade={this.props.grade} 
                                        rated={this.state.rated}
                                    />
                                    : <div/>}
                                        { isAllowed(this.props.user, 'IBookService.RecommendBook') ?
                                    <button 
                                        type="button" 
                                        onClick={this.recommendBook}  
                                        className="btn btn-warning "
                                    >
                                        Recommend Book &nbsp;
                                        <span className="glyphicon glyphicon-envelope"/>
                                    </button>
                                    : <div/>}&nbsp;
                                    {unSubscription}{subscription}

                                    { isAllowed(this.props.user, 'ICheckoutService.History') ?
                                    <NavLink to={'/checkouts/' + (this.props.book ? this.props.book.id : '')}>
                                        <button type="button" className="btn btn-default">See checkout history</button>
                                    </NavLink>
                                    : <div/>}
                                </div>
                            </div>
                            <h4>Book details:</h4>
                            <div className="row row-item">
                                <div className="col-md-4">
                                    <div className="entry">
                                        <div className="row-item">
                                            <div className="label-name">Name: </div>
                                            {this.state.currentBook.name}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Authors: </div>
                                            {authors}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Publication year: </div>
                                            {date}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Total copies: </div>
                                            {this.state.currentBook.totalCopies}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Available copies: </div>
                                            { this.state.currentBook.availableCopies !== 0 ? 
                                                this.state.currentBook.availableCopies 
                                                : <span className="label label-danger">Currently unavailable</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="entry">
                                        <div className="row-item">
                                            <div className="label-name">Edition: </div>
                                            {this.state.currentBook.edition}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Publisher: </div>
                                            {this.state.currentBook.publisher !== null ? this.state.currentBook.publisher.name : ''}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Language: </div>
                                            {this.state.currentBook.language !== null ? this.state.currentBook.language.name : ''}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Keywords: </div>
                                            {keywords}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="entry">
                                        <div className="row-item">
                                            <div className="label-name">Genres: </div>
                                            {genres}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">ISBN13: </div>
                                            {this.state.currentBook.isbN13}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">ISBN10: </div>
                                            {this.state.currentBook.isbN10}
                                        </div>
                                        <div className="row-item">
                                            <div className="label-name">Rating: </div>
                                            {this.state.currentBook.rating}
                                        </div>
                                    </div>
                                </div>
                                {recommend}
                            </div>
                        </div>
                    </div>
                </div>
                { isAllowed(this.props.user, 'IBookService.GetCommentsForBook') ?
                <div className="row">
                    <div className="col-md-offset-2">
                        <Comments 
                            book={this.state.currentBook} 
                        />
                    </div>
                </div> : <div/>}
                <AreYouSureModal 
                    title={this.state.areYouSureModalParams.title} 
                    isOpen={this.state.areYouSureModalParams.isOpen} 
                    action={this.state.areYouSureModalParams.action}
                    close={this.closeAreYouSureModal} 
                    description={null} 
                />
            </div>
        );
    }

    setCurrentBook = () => {
        if (this.props.book) {
            this.setState({
                currentBook: this.props.book
            });
        }
    }

    recommendBook = () => {
        this.props.listAllUsers()
        .then(() => 
            this.setState({
                recommending: true
            })
        );
    }
    closeModal = () => {
        this.setState({
            recommending: false
        });
    }

    subscribe = () => {
        this.props.subscribe(this.state.currentBook.id)
        .then(() => {
            this.setState({
                subscribed: this.props.subscribed
            });
        });
    }
    
    unSubscribe = () => {
        this.props.unSubscribe(this.state.currentBook.id)
        .then(() => {
            this.setState({
                subscribed: this.props.subscribed
            });
        });
        
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IBookDetailsState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IBookDetailsState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    deleteBook = () => {
        return this.props.deleteBook(this.state.currentBook)
            .then((success: boolean) => {
                if (success) { 
                    this.props.history.push('/books');
                }
            });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        book: store.booksReducer.book,
        grade: store.booksReducer.grade,
        user: store.accountReducer.loggedInUser.user,
        users: store.usersReducer.users,
        subscribed: store.booksReducer.subscribedToBook
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    getBook: (id: string) => dispatch(getBook(id)),
    rateBook: (book: IBook, grade: number) => dispatch(rateBook(book, grade)),
    getUserRating: (book: IBook) => dispatch(getUserRating(book)),
    addComment: (comment: IComment) => dispatch(addComment(comment)),
    updateComment: (comment: IComment) => dispatch(updateComment(comment)),
    deleteComment: (comment: IComment) => dispatch(deleteComment(comment)),
    listAllUsers: (sortBy?: string, asc?: boolean) => dispatch(listAllUsers(sortBy, asc)),
    recommendBooks: (bookId: string, fromUserId: string, toUserIds: string[]) => dispatch(recommendBooks(bookId, fromUserId, toUserIds)),
    isSubscribed: (userId: string, bookId: string) => dispatch(isSubscribed(userId, bookId)),
    subscribe: (bookId: string) => dispatch(subscribeToBook(bookId)),
    unSubscribe: (bookId: string) => dispatch(unsubscribeToBook(bookId)),
    deleteBook: (book: IBook) => dispatch(deleteBook(book))
});

export default connect(mapStateToProps, matchDispatchToProps)(BookDetails);