import * as React from 'react';
import IRole from '../../types/role_type';

interface IChooseRolesButtonsProps {
    role: IRole;
    addedRoles: IRole[];
    handleCheckboxChanged(added: boolean, role: IRole): void;
}

const ChooseRolesButtons = (props: IChooseRolesButtonsProps) => {
    let role = props.role;
    let addedRoles = props.addedRoles;
    let added = addedRoles.find((r: IRole) => r.id === role.id) !== undefined;
    return (
        <div className="checkbox">
            <label>
                <input type="checkbox" onChange={() => props.handleCheckboxChanged(added, role)} checked={added} />
            </label>
        </div>
    );
};

export default ChooseRolesButtons;