import * as React from 'react';
import { MODAL_STYLES } from '../../constants/other_constants';
import Modal from 'react-modal';
import IRole from '../../types/role_type';
import IUser from '../../types/user_type';
import { IChangeEvent, IFormEvent } from '../../types/react_types';

interface IChangeRoleModalProps {
  isModalOpen: boolean;
  role: IRole | null | undefined;
  user: IUser | null;
  rolesLoading: boolean;
  roles: IRole[];
  changeRole(): Promise<void>;
  handleSelectInModalChange(e: IChangeEvent): void;
  closeModal(): void;
}

interface IChangeRoleModalState {
  saveClicked: boolean;
}

class ChangeRoleModal extends React.Component<IChangeRoleModalProps, IChangeRoleModalState> {
  state = {
    saveClicked: false
  };

  onSaveClicked = (e: IFormEvent) => {
    e.preventDefault();
    this.setState({ saveClicked: true });
    return this.props.changeRole()
      .then(() => this.setState({ saveClicked: false }))
      .catch(() => this.setState({ saveClicked: false }));
  }

  render() {
    const props = this.props;
    let role = props.role;
    let user = props.user;
    return (
        <Modal isOpen={props.isModalOpen} style={MODAL_STYLES}>
            <h3>Change user's role</h3>
            {props.rolesLoading ? 
                <h5>Loading...</h5> : 
                <form onSubmit={this.onSaveClicked}>
                    <div className="form-group">
                        <label>Current role: </label>
                        <input 
                            name="currentRole"
                            value={user && user.role ? user.role.name : ''} 
                            type="text" 
                            className="form-control" 
                            disabled={true}
                        />
                    </div>
                    <div className="form-group">
                        <label>New role: </label>
                        <select className="form-control" value={role ? role.id : undefined} onChange={props.handleSelectInModalChange}>
                            {props.roles.map((r: IRole) => <option key={r.id} value={r.id}>{r.name}</option>)}
                        </select>
                    </div>
                    <button type="button" className="btn btn-default" onClick={props.closeModal}>Cancel</button>
                    <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                </form>
            }
        </Modal>
    );
  }
}

export default ChangeRoleModal;