import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';

interface IEditUserButtonsProps {
    user: IUser;
    loggedInUser: IUser | null;
    openAreYouSureModal(func: Function, message: string): void;
    deactivateUser(user: IUser): void;
    activateUser(user: IUser): void;
    openChangeRoleModal(user: IUser): void;
}

const EditUserButtons = (props: IEditUserButtonsProps) => {
    let user = props.user;
    let active = user.isActive && isAllowed(props.loggedInUser, 'IUserService.Deactivate') ? 
        (
        <button 
            type="button" 
            className="btn btn-danger"
            onClick={() => props.openAreYouSureModal(() => props.deactivateUser(user), 'Deactivating user ' + user.username)}
        > 
            Deactivate user <span className="glyphicon glyphicon-remove" />
        </button>) 
        : '';
    let inactive = !user.isActive && isAllowed(props.loggedInUser, 'IUserService.Activate') ? 
        (
        <button 
            type="button" 
            className="btn btn-warning"
            onClick={() => props.openAreYouSureModal(() => props.activateUser(user), 'Activating user ' + user.username)}
        >
            Activate user <span className="glyphicon glyphicon-repeat" />
        </button>)
        : '';
    return (
        <div>
            {active}
            {inactive}
            &nbsp;
            {
                user.isActive &&  isAllowed(props.loggedInUser, 'IUserService.ChangeRole') ? 
                    <button type="button" className="btn btn-warning" onClick={() => props.openChangeRoleModal(user)}>Change role</button>
                    : <div/>
            }
        </div>
    );
};

export default EditUserButtons;