import * as React from 'react';
import { MODAL_STYLES } from '../constants/other_constants';
import Modal from 'react-modal';

interface IAreYouSureModalProps {
  isOpen: boolean;
  title: string;
  description: string | React.ReactNode;
  action: Function;
  close(): void;
}

interface IAreYouSureModalState {
  buttonClicked: boolean;
}

class AreYouSureModal extends React.Component<IAreYouSureModalProps, IAreYouSureModalState> {

  state = {
    buttonClicked: false
  };

  enableConfirm = () => {
    this.setState({ buttonClicked: false });
  }

  onButtonClick = () => {
    this.setState({ buttonClicked: true });
    this.props.action().then(() => {
      this.enableConfirm();
      this.props.close();
    })
    .catch(this.enableConfirm);
  }

  render() {
    const props = this.props;
    if (props.isOpen) {
      return (
        <Modal isOpen={props.isOpen} style={MODAL_STYLES}>
            <h3>{props.title}</h3>
            {props.description || ''}
            <p>Are you sure?</p>
            <button type="button" className="btn btn-default" onClick={props.close}>Cancel</button>
            <button 
              type="button" 
              className="btn btn-danger pull-right"
              onClick={this.onButtonClick}
              disabled={this.state.buttonClicked}
            >
              Confirm
            </button>
        </Modal>
      );
    } else {
      return <div/>;
    }
  }
}

export default AreYouSureModal;