import * as React from 'react';
import { connect } from 'react-redux';
import { searchGoogleBooks, emptyGoogleBooksSearch } from '../../actions/google_books_actions';
import * as toastr from 'toastr';
import GoogleBooksList from './google_books_list';
import { createWish } from '../../actions/wishlist_actions';
import { History } from 'history';
import { IChangeEvent, IAnyTargetType, IFormEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';
import IWish from '../../types/wish_type';

//tslint:disable:no-any
interface ICreateWishProps {
    searchResult: any;
    searchGoogleBooks: Function;
    createWish: Function;
    history: History;
    emptyGoogleBooksSearch: Function;
}

interface ICreateWishState {
    searchParams: string;
    searchClicked: boolean;
    currentPage: number;
}

class CreateWish extends React.Component<ICreateWishProps, ICreateWishState> {

    constructor(props: ICreateWishProps) {
        super(props);
        this.state = {
            searchParams: '',
            searchClicked: false,
            currentPage: 0
        };
        this.searchSumbit = this.searchSumbit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changePage = this.changePage.bind(this);
        this.createWish = this.createWish.bind(this);
       // this.downloadFile = this.downloadFile.bind(this);
       // this.load = this.load.bind(this);
    }

    public componentWillMount() {
        this.props.emptyGoogleBooksSearch();
    }

    public render() {
        return (
            <div style={{paddingRight: '1%', paddingBottom: '1%'}}>
                <h1>Add to wishlist</h1>
                <form className="filter-form" onSubmit={this.searchSumbit}>
                    <div className="row">
                        <div className="filter-col col-md-4">
                            <label>Search books: </label>
                            <input type="text" name="searchParams" className="form-control" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-success">Search <span className="glyphicon glyphicon-search"/></button>
                </form>
                <GoogleBooksList 
                    searchResult={this.props.searchResult} 
                    changePage={this.changePage} 
                    currentPage={this.state.currentPage} 
                    createWish={this.createWish} 
                />
            </div>
        );
    }

    handleInputChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({searchParams: value});
    }

    searchSumbit = (e: IFormEvent) => {
        e.preventDefault();
        if (this.state.searchParams === '' || this.state.searchParams.trim() === '') {
            toastr.error('Please enter something in search.');
            return;
        }
        this.search();
    }

    changePage = (by: number) => {
        this.setState(
            (prevState: ICreateWishState) => {
                let newCurrentPage = prevState.currentPage + by;
                return {currentPage: newCurrentPage};
            }, 
            this.search
        );
    }

    search = () => {
        this.props.searchGoogleBooks(this.state.searchParams, this.state.currentPage)
            .then(() => this.setState({searchClicked: true}));
    }

    createWish = (book: any) => {
        let bookName = book.volumeInfo.title || null;
        let publisher = book.volumeInfo.publisher || null;
        let isbn13 = book.volumeInfo.industryIdentifiers ? 
            book.volumeInfo.industryIdentifiers.filter((x: any) => x.type === 'ISBN_13').map((x: any) => x.identifier)[0] || null 
            : null;
        let isbn10 = book.volumeInfo.industryIdentifiers ? 
            book.volumeInfo.industryIdentifiers.filter((x: any) => x.type === 'ISBN_10').map((x: any) => x.identifier)[0] || null 
            : null;
        let url = book.volumeInfo.infoLink || null;
        let image = book.volumeInfo.imageLinks.smallThumbnail;
        let wish = {
            bookName: bookName,
            publisher: publisher,
            isbN13: Number(isbn13),
            isbN10: Number(isbn10),
            url: url,
            imageUrl: image,
            authors: book.volumeInfo.authors ? book.volumeInfo.authors.join() : '',
            statusId: 'Pending'
        };
        this.props.createWish(wish)
            .then((success: boolean) => {
                if (success) { 
                    this.props.history.push('/wishlist');
                }
            });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        searchResult: store.googleBooksReducer.searchResult
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    searchGoogleBooks: (params: string, page: number) => dispatch(searchGoogleBooks(params, page)),
    createWish: (wish: IWish) => dispatch(createWish(wish)),
    emptyGoogleBooksSearch: () => dispatch(emptyGoogleBooksSearch())
});

export default connect(mapStateToProps, matchDispatchToProps)(CreateWish);
