import IPublisher from '../types/publisher_type';
import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class PublisherService {

    listAllPublishers() {
        return fetch(LINK_BASE + '/Publishers', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IPublisher[]>);
    }

    addPublisher(publisher: IPublisher) {
        return fetch(LINK_BASE + '/Publishers', {
            method: 'post',
            body: JSON.stringify( { 'id': EMPTY_GUID, 'name': publisher.name, 'country': publisher.country}),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<IPublisher>);

    }

    updatePublisher(publisher: IPublisher) {
        return fetch(LINK_BASE + '/Publishers', {
            method: 'put',
            body: JSON.stringify(publisher),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<IPublisher>);
    }

    deletePublisher(publisher: IPublisher) {
        return fetch(LINK_BASE + '/Publishers/' + publisher.id, {
            method: 'delete',
            headers: getAuthorizedHeaders()
        }).then(handleErrors);
    }

}

export default new PublisherService();