import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';

interface ISubscribeUserButtonsProps {
    user: IUser;
    loggedInUser: IUser | null;
    subscribeUserToAll(id: string): void;
    unsubscribeUserFromAll(id: string): void;
}

const SubscribeUserButtons = (props: ISubscribeUserButtonsProps) => {
    let user = props.user;
    return (
        <div>
            { isAllowed(props.loggedInUser, 'IBookService.SubscribeUser') ?
                <button type="button" className="btn btn-warning" onClick={() => props.subscribeUserToAll(user.id)} >Subscribe to All Books</button>
                : <div/>
            }
            &nbsp;
            {
                (user.isActive && isAllowed(props.loggedInUser, 'IBookService.SubscribeUser')) ? 
                    <button 
                        type="button" 
                        className="btn btn-warning" 
                        onClick={() => props.unsubscribeUserFromAll(user.id)}
                    >
                        Unsubscribe from All Books
                    </button> 
                    : <div/>
            }
        </div>
    );
};

export default SubscribeUserButtons;