import * as React from 'react';
import { connect } from 'react-redux';
import ISubscription from '../../types/subscription_type';
import { listAllSubscriptions, deleteSubscriptionForUser, searchSubscriptions,  subscribeUser } from '../../actions/subscriptions_actions';
import { listAllBooks } from '../../actions/books_actions';
import { listAllUsers } from '../../actions/users_actions';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import SubscriptionTable from './subscriptions_table';
import FilterSubscriptions from './filter_subscriptions_form';
import { EMPTY_GUID } from '../../constants/other_constants';
import { isAllowed } from '../../helpers/checkAuthorization';
import * as toastr from 'toastr';
import { IFormEvent, IThunkDispatch } from '../../types/react_types';
import { History } from 'history';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { RouteComponentProps } from 'react-router';
import ExpandButton from '../shared/expand_button';

interface IMatchParams {
    userId: string;
    bookId: string;
}

interface ISubscriptionsProps extends RouteComponentProps<IMatchParams> {
    subscriptions: ISubscription[];
    subscriptionsLoading: boolean;
    users: IUser[];
    books: IBook[];
    loggedInUser: IUser | null;
    history: History;
    listAllSubscriptions(): Promise<boolean>;
    deleteSubscriptionForUser(userId: string, bookId: string, sameUser: boolean): Promise<boolean>;
    searchSubscriptions(userId: string, bookId: string): Promise<boolean>;
    listAllUsers(sortBy?: string, asc?: boolean): Promise<boolean>;
    listAllBooks(sortBy: string, asc: boolean): Promise<boolean>;
    subscribeUser(userId: string, bookId: string): Promise<boolean>;
}

interface ISubscriptionsState {
    filterParams: {
        user: string;
        book: string;
    };
    filter: boolean;
    openModal: boolean;
    title: string;
    filterExpanded: boolean;
}

class Subscriptions extends React.Component<ISubscriptionsProps, ISubscriptionsState> {
 
    constructor(props: ISubscriptionsProps) {
        super(props);
        let shouldFilter = true;
        if (this.props.match.params.userId !== EMPTY_GUID) {
            shouldFilter = false;
        }
        this.state = {
            filterParams: {
                user: EMPTY_GUID,
                book: EMPTY_GUID
            },
            filter: shouldFilter,
            openModal: false,
            title: 'List of Subscriptions',
            filterExpanded: false
        };
    }

    componentWillMount() {
        let bookId = this.props.match.params.bookId;
        let userId = this.props.match.params.userId;
        const loggedInUser = this.props.loggedInUser;
        if (loggedInUser && userId !== loggedInUser.id && !isAllowed(this.props.loggedInUser, 'IBookService.SearchSubscriptionsForOtherUser')) {
            this.props.history.push('/subscriptions/' + EMPTY_GUID + '/' + loggedInUser.id);
        }
        let bothEmpty = bookId === EMPTY_GUID && userId === EMPTY_GUID;
        if ((bookId || userId) && !bothEmpty) {
            this.props.searchSubscriptions(userId, bookId);
        } else {
            this.props.listAllSubscriptions();
        }
        this.props.listAllBooks('name', true);
        this.props.listAllUsers();
    }

    public componentDidMount() {
        let bookId = this.props.match.params.bookId;
        let userId = this.props.match.params.userId;
        const loggedInUser = this.props.loggedInUser;
        if (!bookId) { 
            bookId = EMPTY_GUID;
        }
        if (!userId) { 
            userId = EMPTY_GUID;
        }
        this.setState((prevState: ISubscriptionsState) => {
            let newFilter = Object.assign({}, prevState.filterParams, {book: bookId, user: userId});
            let newTitle = (bookId === '' || bookId === EMPTY_GUID) && loggedInUser && userId === loggedInUser.id ? 
                'My Subscriptions' : 
                'List of Subscriptions';
            return {filterParams: newFilter, title: newTitle};
        });
    }

    componentWillReceiveProps(nextProps: ISubscriptionsProps, nextState: ISubscriptionsState) {
        this.setState((prevState: ISubscriptionsState) => {
            let newFilter = Object.assign({}, prevState.filterParams, {book: nextProps.match.params.bookId, user: nextProps.match.params.userId});
            
            return {filterParams: newFilter};
        });
        let bookId = nextProps.match.params.bookId;
        let userId = nextProps.match.params.userId;
        const loggedInUser = this.props.loggedInUser;
        let newTitle = (!bookId || bookId === '' || bookId === EMPTY_GUID) && loggedInUser && userId === loggedInUser.id ? 
            'My Subscriptions' : 
            'List of Subscriptions';
        if (this.props.match.params.bookId !== nextProps.match.params.bookId || this.props.match.params.userId !== nextProps.match.params.userId) {
            if (nextProps.match.params.bookId || nextProps.match.params.userId) {
                this.setState({
                    filter : false,
                    title: newTitle
                });
                this.props.searchSubscriptions(nextProps.match.params.userId, nextProps.match.params.bookId);
            } else {
                this.setState({
                    filter : true,
                    title: newTitle
                });
                this.props.listAllSubscriptions();
            }
        }
    }

    renderTable = () => {
      return this.props.subscriptionsLoading ? 
        <h4>Loading...</h4> :
        (
        <SubscriptionTable 
            subscriptions={this.props.subscriptions} 
            deleteSubscription={this.handleDeleteSubscription} 
            loggedInUser={this.props.loggedInUser}
        />);
    }

    render() {
        let filter = this.state.filter && this.state.openModal === false && isAllowed(this.props.loggedInUser, 'IBookService.SearchSubscriptions') ? 
            (
            <div>
                <h3>
                    Filter&nbsp;&nbsp;
                    <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                </h3> 
                {
                    this.state.filterExpanded &&
                    <div>
                        <FilterSubscriptions 
                            filterParams={this.state.filterParams} 
                            users={this.props.users} 
                            books={this.props.books} 
                            handleInputChange={this.handleInputChange} 
                            filter={this.filter} 
                            clearFilter={this.cancelFilter} 
                            filtering={true} 
                        />
                        <br/>
                        <button type="submit" onClick={this.openSubscribeModal} className="btn btn-primary float-right">Subscribe User</button>
                        <br/><br/><br/>
                    </div>    
                }
            </div>
            )
            : <div/>;
        let modal = this.state.openModal && isAllowed(this.props.loggedInUser, 'IBookService.SubscribeUser') ? 
            (
            <div>
                <h3>Subscribe User</h3>
                <FilterSubscriptions 
                    filterParams={this.state.filterParams} 
                    users={this.props.users} 
                    books={this.props.books} 
                    handleInputChange={this.handleInputChange} 
                    filter={this.handleSaveSubscription} 
                    clearFilter={this.handleCloseModal} 
                    filtering={false}
                />
            </div>
            ) 
            : <div/>;
        return (
            <div>
                <h1>List of subscriptions</h1>
                {filter}
                {modal}
                { isAllowed(this.props.loggedInUser, 'IBookService.ListAllSubscriptions') || 
                  isAllowed(this.props.loggedInUser, 'IBookService.SearchSubscriptions') ?
                    this.renderTable()
                    : <div/>
                }
            </div>
        );
    }

    toggleExpandFilter = () => {
        this.setState(prevState => ({
          filterExpanded: !prevState.filterExpanded
        }));
      }

    handleDeleteSubscription = (userId: string, bookId: string, sameUser: boolean) => {
        this.props.deleteSubscriptionForUser(userId, bookId, sameUser)
        .then(() => {
            if (this.props.match.params.bookId || this.props.match.params.userId) {
                this.props.searchSubscriptions(this.props.match.params.userId, this.props.match.params.bookId);
            } else {
                this.props.listAllSubscriptions();
            }
        });
    }

    handleInputChange = (type: string, value: string) => {
        this.setState((prevState) => {
            let newFilter = Object.assign({}, prevState.filterParams, {
                [type]: value
            });
            return {filterParams: newFilter};
        });
    }

    filter = (e: IFormEvent) => {
        e.preventDefault();
        let {user, book} = this.state.filterParams;
        this.props.searchSubscriptions(user, book);
    }

    cancelFilter = () => {
        this.props.listAllSubscriptions();
        this.setState({
            filterParams: {
                user: EMPTY_GUID,
                book: EMPTY_GUID
            }
        });
    }

    openSubscribeModal = () => {
        this.setState ({
            openModal: true
        });
    }

    handleSaveSubscription = (event: IFormEvent) => {
        event.preventDefault();
        let {user, book} = this.state.filterParams;
        if (!this.validateForm(user, book)) {
            return;
        }
        this.props.subscribeUser(user, book)
        .then((success: boolean) => {
            if (success) {
                this.setState({
                    openModal: false
                });
                console.log(this.props.subscriptions.length);
            }
        });
    }

    validateForm = (user: string, book: string) => {
        if (!user || user.trim() === '') {
            toastr.error('User must be selected.');
            return false;
        }
        if (!book || book.trim() === '') {
            toastr.error('Book must be selected.');
            return false;
        }
        return true;
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false
        });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        subscriptions: store.subscriptionsReducer.subscriptions,
        subscriptionsLoading: store.subscriptionsReducer.subscriptionsLoading,
        users: store.usersReducer.users,
        books: store.booksReducer.books,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllSubscriptions: () => dispatch(listAllSubscriptions()),
    deleteSubscriptionForUser: (userId: string, bookId: string, sameUser: boolean) => dispatch(deleteSubscriptionForUser(userId, bookId, sameUser)),
    searchSubscriptions: (userId: string, bookId: string) => dispatch(searchSubscriptions(userId, bookId)),
    listAllUsers: (sortBy?: string, asc?: boolean) => dispatch(listAllUsers(sortBy, asc)),
    listAllBooks: (sortBy: string, asc: boolean) => dispatch(listAllBooks(sortBy, asc)),
    subscribeUser: (userId: string, bookId: string) => dispatch(subscribeUser(userId, bookId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
