import * as React from 'react';
import { mapListToString } from '../../helpers/list_helper';
import { isAllowed } from '../../helpers/checkAuthorization';
import { addZerosToISBN } from '../../helpers/ISBN_helper';
import { LINK_BASE } from '../../constants/other_constants';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import { IClickEvent, IAnyTargetType } from '../../types/react_types';
import { History } from 'history';

interface IBookRowProps {
    book: IBook;
    actionButtons: JSX.Element | undefined;
    loggedInUser: IUser | null;
    number: number;
    history: History;
    openAreYouSureModal?(func: Function, title: string): void;
    delete?(book: IBook): Promise<boolean>;
}

class BookRow extends React.Component<IBookRowProps, {}> {
    onRowClick = (e: IClickEvent) => {
        const target: IAnyTargetType = e.target;
        if (isAllowed(this.props.loggedInUser, 'IBookService.Get') && target.tagName !== 'I') {
            this.props.history.push(`/bookDetails/${this.props.book.id}`);
        }
    }

    onDefaultDeleteClick = () => {
        if (this.props.openAreYouSureModal && this.props.delete) {
            this.props.openAreYouSureModal(
                () => this.props.delete && this.props.delete(this.props.book),
                `Deleting book "${this.props.book.name}"`
            );
        }
    }

    render() {
        const props = this.props;
        let book = props.book;
        let buttons = props.actionButtons ? 
            this.props.actionButtons
            : 
            (
                <div>
                    { isAllowed(props.loggedInUser, 'IBookService.Delete') ?
                        <span className="remove-icon">
                            <i 
                                className="fa fa-trash-o fa-2x" 
                                style={{ marginLeft: '5%' }} 
                                onClick={this.onDefaultDeleteClick} 
                                aria-hidden="true" 
                            />
                        </span>
                        : <div/>
                    }
                </div>
            );
        let onclickHandler = props.actionButtons ?
            () => {} : 
            this.onRowClick;
        let trId = props.actionButtons ? '' : 'tableRow';
        return (
            <tr className="table-row" id={trId} key={book.id} onClick={onclickHandler} >
                <td className="bold">{props.number}</td>
                <td>
                    <img 
                        src={book.image ? LINK_BASE + book.image : require('../../../images/no_image.png')} 
                        className="table-book-img" 
                        alt="Image not available" 
                    />
                </td>
                <td>{book.name}</td>
                <td>{mapListToString(book.authors, 'name')}</td>
                <td>{book.edition}</td>
                <td>{mapListToString(book.genres, 'name')}</td>
                <td>{addZerosToISBN(book.isbN10, 10)}</td>
                <td>{addZerosToISBN(book.isbN13, 13)}</td>
                <td>{book.language !== null ? book.language.name : ''}</td>
                <td>{book.publisher !== null ? book.publisher.name : ''}</td>
                <td>{book.totalCopies}</td>
                <td>{book.availableCopies !== 0 ? book.availableCopies : <span className="label label-danger">Currently unavailable</span>}</td>
                <td>
                    {buttons}
                </td>
            </tr>
        );
    }
}

export default BookRow;