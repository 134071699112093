import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IRole from '../../types/role_type';
import IUser from '../../types/user_type';

interface IManageRolesButtonsProps {
    role: IRole;
    loggedInUser: IUser | null;
    deleteRole(role: IRole): Promise<boolean>;
    openAreYouSureModal(func: Function, title: string): void;
}

const ManageRolesButtons = (props: IManageRolesButtonsProps) => {
    let role = props.role;
    if (isAllowed(props.loggedInUser, 'IRoleService.Delete')) {
        return (
            <div>
                <span className="remove-icon">
                    <i 
                        className="fa fa-trash-o fa-2x" 
                        onClick={() => props.openAreYouSureModal(() => props.deleteRole(role), 'Deleting role ' + role.name)} 
                        aria-hidden="true"
                    />
                </span>
            </div>
        );
    } else {
        return <div/>;
    }
};

export default ManageRolesButtons;