import IUser from '../types/user_type';
import IRole from '../types/role_type';
import { LINK_BASE } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders } from '../helpers/header_helper';

class UsersService {

    ban(user: IUser) {
        return fetch(LINK_BASE + '/Users/' + user.id, {
            method: 'DELETE',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    unban(user: IUser) {
        return fetch(LINK_BASE + '/Users/Activate/' + user.id, {
            method: 'PUT',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    listAll(sortBy?: string, asc?: boolean) {
        return fetch(LINK_BASE + `/Users/ListAll?sortBy=${sortBy}&asc=${asc}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IUser[]>);
    }

    listAllActive(sortBy: string, asc: boolean) {
        return fetch(LINK_BASE + `/Users/ListAllActive?sortBy=${sortBy}&asc=${asc}`, {
            headers: getAuthorizedHeaders()
        })
            .then(handleErrors)
            .then(response => response.json() as Promise<IUser[]>);
    }

    changeRole(user: IUser, role: IRole) {
        return fetch(LINK_BASE + '/Users/ChangeRole?userId=' + user.id + '&roleId=' + role.id, {
            method: 'PUT',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IUser>);
    }

    search(username: string, firstName: string, lastName: string, sortBy: string, asc: boolean) {
        return fetch(LINK_BASE + `/Users/Search?username=${username}&firstName=${firstName}&lastName=${lastName}&sortBy=${sortBy}&asc=${asc}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IUser[]>);
    }

    searchActive(username: string, firstName: string, lastName: string, sortBy: string, asc: boolean) {
        return fetch(LINK_BASE + `/Users/SearchActive?username=${username}&firstName=${firstName}&lastName=${lastName}&sortBy=${sortBy}&asc=${asc}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IUser[]>);
    }

    getTop5MostActiveUsers() {
        return fetch(LINK_BASE + '/Users/GetTop5MostActiveUsers', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IUser[]>);
    }

    get(id: string) {
        return fetch(LINK_BASE + '/Users/' + id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IUser>);
    }
}

export default new UsersService();