import * as React from 'react';
import { IChangeEvent, IFormEvent } from '../../types/react_types';

interface IFilterAuthorsFormProps {
    filterParams: {
        name: string;
        pseudonym: string;
        nationality: string;
    };
    filter(e: IFormEvent): void;
    handleInputChange(e: IChangeEvent): void;
    clearFilter(): void;
}

const FilterAuthorsForm = (props: IFilterAuthorsFormProps) => {
    return (
        <form className="filter-form" onSubmit={props.filter}>
            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Name:</label>
                    <input type="text" name="name" value={props.filterParams.name} className="form-control" onChange={props.handleInputChange} />
                </div>
                <div className="filter-col col-md-4">
                    <label>Pseudonym: </label>
                    <input 
                        type="text" 
                        name="pseudonym" 
                        value={props.filterParams.pseudonym} 
                        className="form-control" 
                        onChange={props.handleInputChange} 
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Nationality:</label>
                    <input 
                        type="text" 
                        name="nationality"
                        value={props.filterParams.nationality} 
                        className="form-control" 
                        onChange={props.handleInputChange} 
                    />
                </div>
            </div>
            <div className="row-item">
                <button type="submit" className="btn btn-primary">Filter</button>&nbsp;
                <button type="button" className="btn btn-default" onClick={props.clearFilter}>Clear filter</button>
            </div>
        </form>
    );
};

export default FilterAuthorsForm;