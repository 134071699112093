import { LIST_ALL_SUBSCRIPTIONS_SUCCESS, SAVE_SUBSCRIPTION_SUCCESS, LIST_ALL_SUBSCRIPTIONS_START } from '../constants/action_types';
import ISubscription from '../types/subscription_type';
import { IAction } from '../types/react_types';

export interface ISubscriptionsReducer {
    subscriptions: ISubscription[];
    subscriptionsLoading: boolean;
}

var defaultState: ISubscriptionsReducer = {
    subscriptions : [],
    subscriptionsLoading: false
};

export default function (state: ISubscriptionsReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_SUBSCRIPTIONS_START:
        case LIST_ALL_SUBSCRIPTIONS_SUCCESS:
          return Object.assign({}, state, action.payload);
        case SAVE_SUBSCRIPTION_SUCCESS:
            let newSubscriptions: ISubscription[] = state.subscriptions.slice();
            newSubscriptions.push(action.payload.subscription);
            let afterSubscription = Object.assign({}, state, {subscriptions: newSubscriptions});
            return afterSubscription;
        default:
            return state;
    }
}
