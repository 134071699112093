import * as toastr from 'toastr';
//tslint:disable:no-any

export function handleErrors(response: Response) {
    if (!response.ok) {
        throw new ErrorWithMessage(response);
    }
    return response;
}

class ErrorWithMessage extends Error {
    errorData: any;
    constructor(err: any) {
        super();
        this.errorData = err;
    }
}

export function catchError(error: any) {
    console.log(error);
    if (!error || !error.errorData) {
        return false;
    }
    error.errorData.json().then((data: any) => {
        toastr.error(data.message || data.Message);
    }).catch(() => {});
    return false;
}