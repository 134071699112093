import * as React from 'react';
import IUser from '../../types/user_type';

interface IChooseUserButtonsProps {
    user: IUser;
    chosenUser: IUser | null;
    selectUser(user: IUser): void;
}

const ChooseUserButtons = (props: IChooseUserButtonsProps) => {
    let user = props.user;
    let chosenUser = props.chosenUser;
    if (chosenUser && user.id === chosenUser.id) {
        return (
            <button 
                type="button" 
                className="btn btn-success normal-cursor" 
                disabled={true}
            >
                Selected 
                <span className="glyphicon glyphicon-ok"/>
            </button> 
        );
    } else {
        return (
            <div>
                <button type="button" className="btn btn-default" onClick={() => props.selectUser(user)}>Select</button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        );
    }
};

export default ChooseUserButtons;