import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import { addZerosToISBN } from '../../helpers/ISBN_helper';
import { LINK_BASE } from '../../constants/other_constants';
import IUser from '../../types/user_type';
import IWish from '../../types/wish_type';
import { printDateWithMonthName } from '../../helpers/date_helper';

interface IWishRowProps {
  wish: IWish;
  number: number;
  loggedInUser: IUser | null;
  delete(wish: IWish): void;
  openAreYouSureModal(f: Function, title: string): void;
  changeStatus(status: string, id: string): Promise<boolean>;
  transform(id: string): Promise<void>;
}

interface IWishRowState {
  clickedButton: boolean;
}

class WishRow extends React.Component<IWishRowProps, IWishRowState> {
  constructor(props: IWishRowProps) {
    super(props);
    this.state = {
      clickedButton: false
    };
  }

  transformToBook = () => {
    if (this.state.clickedButton) {
      return;
    }
    this.setButtonClicked(true);
    this.props.transform(this.props.wish.id)
    .then(() => {
      this.setButtonClicked(false);
    })
    .catch(() => {
      this.setButtonClicked(false);
    });
  }

  markAsOrdered = () => {
    if (this.state.clickedButton) {
      return;
    }
    this.setButtonClicked(true);
    this.props.changeStatus('Ordered', this.props.wish.id)
    .then(() => {
      this.setButtonClicked(false);
    })
    .catch(() => {
      this.setButtonClicked(false);
    });
  }

  setButtonClicked = (status: boolean) => {
    this.setState({ clickedButton: status });
  }

  render() {
    const props = this.props;
    let wish = props.wish;
    let authors = wish.authors ? wish.authors.split(',').map((author: string, index: number) => <li key={index} >{author}</li>) : '';
    return (
      <tr key={wish.id}>  
        <td className="bold">{props.number}</td>
        <td>{wish.user.firstName + ' ' + wish.user.lastName}</td>
        <td>{wish.bookName}</td>
        <td>{authors}</td>
        <td>{wish.publisher}</td>
        <td>{addZerosToISBN(wish.isbN13, 13)}</td>
        <td>{addZerosToISBN(wish.isbN10, 10)}</td>
        <td>{wish.url ? <a href={wish.url} target="_blank">Go to</a> : '[No link]'}</td>
        <td>
          <img 
            src={wish.imageUrl ? LINK_BASE + wish.imageUrl : require('../../../images/no_image.png')} 
            className="table-book-img" 
            alt="Image not available" 
          />
        </td>
        <td>{wish.statusId}</td>
        <td>{printDateWithMonthName(wish.submitTime.toString())}</td>
        {  
            <td>
              <div style={{ display: 'flex'}}>
                {isAllowed(props.loggedInUser, 'IWishlistService.ChangeStatus') ?
                    (wish.statusId === 'Pending') ? 
                      (
                      <button 
                        type="submit" 
                        className="btn btn-success btn-inline" 
                        onClick={this.markAsOrdered} 
                        disabled={this.state.clickedButton}
                      >
                        Mark as ordered
                      </button>) : 
                      (wish.statusId === 'Fulfilled') ? '' : isAllowed(props.loggedInUser, 'IWishlistService.TransformWish') ? 
                        (
                        <button 
                          type="submit" 
                          className="btn btn-success" 
                          onClick={this.transformToBook} 
                          disabled={this.state.clickedButton}
                        >
                          Transform to Book
                        </button> )
                        : ''
                : ''}
                {isAllowed(props.loggedInUser, (props.loggedInUser && props.loggedInUser.id === wish.user.id) ? 
                  'IWishlistService.Delete' : 
                  'IWishlistService.DeleteSomeoneElsesWish') ?
                    <span className="remove-icon">
                      <i
                        className="fa fa-trash-o fa-2x wishlistDelete" 
                        onClick={() => props.openAreYouSureModal(() => props.delete(wish), 'Deleting wish for book "' + wish.bookName + '"')} 
                        aria-hidden="true"
                      />
                    </span> 
                : ''}
            </div>
            </td>
        }
      </tr>
    );
  }
} 

export default WishRow;