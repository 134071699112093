import { LIST_ALL_PERMISSIONS_LOADING, LIST_ALL_PERMISSIONS_SUCCESS } from '../constants/action_types';
import { catchError } from '../helpers/error_helper';
import IPermission from '../types/permission_type';
import permissionsService from '../services/permissions_service';
import { IThunkDispatch } from '../types/react_types';

export function listAllPermissionsLoading() {
    return {
        type: LIST_ALL_PERMISSIONS_LOADING,
        payload: {
            permissionsLoading: true
        }
    };
}

export function listAllPermissionsSuccess(permissions: IPermission[]) {
    return {
        type: LIST_ALL_PERMISSIONS_SUCCESS,
        payload: {
            permissions: permissions,
            permissionsLoading: false
        }
    };
}

export function listAllPermissions() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllPermissionsLoading());
        return permissionsService.listAll()
            .then(permissions => {
                dispatch(listAllPermissionsSuccess(permissions));
                return true;
            })
            .catch(catchError);
    };
}