import { LIST_ALL_BOOKS_SUCCESS, ADD_BOOK_SUCCESS, GET_BOOK_SUCCESS, UPDATE_BOOK_SUCCESS, DELETE_BOOK_SUCCESS, RATE_BOOK_SUCCESS, 
    GET_USER_RATING_SUCCESS, ADD_COMMENT_SUCCESS, DELETE_COMMENT_SUCCESS, LIST_ALL_BOOKS_LOADING, SEARCH_BOOKS_LOADING, 
    SEARCH_BOOKS_SUCCESS, IS_SUBSCRIBED_SUCCESS, LIST_ALL_AVAILABLE_BOOKS_LOADING, LIST_ALL_AVAILABLE_BOOKS_SUCCESS, 
    SEARCH_AVAILABLE_BOOKS_LOADING, SEARCH_AVAILABLE_BOOKS_SUCCESS, GET_TOP_5_MOST_READ_BOOKS_SUCCESS, GET_TOP_5_BEST_RATED_BOOKS_LOADING,
    GET_TOP_5_BEST_RATED_BOOKS_SUCCESS, GET_TOP_5_MOST_READ_BOOKS_LOADING } from '../constants/action_types';

import IBook from '../types/book_type';
import IComment from '../types/comment_type';
import { IAction } from '../types/react_types';
 
export interface IBooksReducer {
    books: IBook[];
    book: IBook | null;
    rated: boolean;
    grade: number;
    comments: IComment[];
    booksLoading: boolean;
    subscribedToBook: boolean;
    mostReadBooks: IBook[];
    mostReadBooksLoading: boolean;
    bestRatedBooks: IBook[];
    bestRatedBooksLoading: boolean;
}

var defaultState: IBooksReducer = {
    books: [],
    book: null,
    rated: false,
    grade: 0,
    comments: [],
    booksLoading: false,
    subscribedToBook: false,
    mostReadBooks: [],
    mostReadBooksLoading: false,
    bestRatedBooks: [],
    bestRatedBooksLoading: false
};

export default function (state: IBooksReducer = defaultState, action: IAction) {
    let grade;
    switch (action.type) {
        case LIST_ALL_BOOKS_LOADING:
        case LIST_ALL_BOOKS_SUCCESS:
        case SEARCH_BOOKS_LOADING:
        case SEARCH_BOOKS_SUCCESS:
        case LIST_ALL_AVAILABLE_BOOKS_LOADING:
        case LIST_ALL_AVAILABLE_BOOKS_SUCCESS:
        case SEARCH_AVAILABLE_BOOKS_LOADING:
        case SEARCH_AVAILABLE_BOOKS_SUCCESS:
        case GET_TOP_5_BEST_RATED_BOOKS_LOADING:
        case GET_TOP_5_BEST_RATED_BOOKS_SUCCESS:
        case GET_TOP_5_MOST_READ_BOOKS_LOADING:
        case GET_TOP_5_MOST_READ_BOOKS_SUCCESS:
            return Object.assign({}, state, action.payload);
        case ADD_BOOK_SUCCESS:
            let booksAfterCreate: IBook[] = state.books.slice();
            booksAfterCreate.push(action.payload.book);
            return Object.assign({}, state, { books: booksAfterCreate });
        case GET_BOOK_SUCCESS:
            return Object.assign({}, state, {book: action.payload.book, comments: action.payload.comments });
        case UPDATE_BOOK_SUCCESS:
            let updatedBook = action.payload.book;
            let booksAfterUpdate = state.books.map((b: IBook) =>
                b.id === action.payload.book.id ? 
                    Object.assign({}, b, updatedBook) :
                    b
            );
            return Object.assign({}, state, {books: booksAfterUpdate});
        case DELETE_BOOK_SUCCESS:
            let deletedBook = action.payload.book;
            let booksAfterDelete = state.books.filter((b: IBook) => b.id !== deletedBook.id  );
            return Object.assign({}, state, { books: booksAfterDelete });
        case RATE_BOOK_SUCCESS:
            let ratedBook = action.payload.book;
            grade = action.payload.grade;
            return Object.assign({}, state,  {book: ratedBook, grade: grade, rated: true});
        case GET_USER_RATING_SUCCESS:
            grade = action.payload.grade;
            return Object.assign({}, state,  { grade: grade});
        case ADD_COMMENT_SUCCESS:
            let book = action.payload.book;
            return Object.assign({}, state, { book: book, comments: book.comments });
        case DELETE_COMMENT_SUCCESS:
            let deletedComment = action.payload.comment;
            let commentsAfterDelete = state.comments.filter ( (comment: IComment) => comment.id !== deletedComment.id  );
            return Object.assign({}, state, { comments: commentsAfterDelete }); 
        case IS_SUBSCRIBED_SUCCESS:
            return Object.assign({}, state, { subscribedToBook: action.payload.subscribedToBook }); 
        default:
            return state;
    }
    
} 
