import * as React from 'react';
import { connect } from 'react-redux';
import IWish from '../../types/wish_type';
import { listAllWishes, deleteWish, searchWishes, createCustomWish, changeWishStatus, transformWish } from '../../actions/wishlist_actions';
import { listAllPublishers } from '../../actions/publishers_actions';
import { listAllAuthors } from '../../actions/authors_actions';
import WishlistTable from './wishlist_table';
import IUser from '../../types/user_type';
import IAuthor from '../../types/author_type';
import IPublisher from '../../types/publisher_type';
import { listAllUsers } from '../../actions/users_actions';
import FilterWishesForm from './filter_wishes_form';
import { isAllowed } from '../../helpers/checkAuthorization';
import { Link } from 'react-router-dom';
import AreYouSureModal from '../are_you_sure_modal';
import NewWishModal from './new_wish_modal';
import { validateISBN } from '../../helpers/ISBN_helper';
import *  as toastr from 'toastr';
import ExpandButton from '../shared/expand_button';
import { History } from 'history';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { IThunkDispatch, IFormEvent, IChangeEvent, IAnyTargetType, IClickEvent } from '../../types/react_types';

interface IWishlistProps {
    wishlist: IWish[];
    wishlistLoading: boolean;
    users: IUser[];
    usersLoading: boolean;
    loggedInUser: IUser | null;
    authors: IAuthor[];
    publishers: IPublisher[];
    history: History;
    listAllWishes(): Promise<boolean>;
    deleteWish(wish: IWish): Promise<boolean>;
    listAllUsers(sortBy?: string, asc?: boolean): Promise<boolean>;
    searchWishes(userId: string, status: string): Promise<boolean>;
    //tslint:disable-next-line:no-any
    createCustomWish(wishParams: any): Promise<boolean>;
    listAllAuthors(): Promise<boolean>;
    listAllPublishers(): Promise<boolean>;
    changeWishStatus(newStatus: string, wishId: string): Promise<boolean>;
    transformWish(wishId: string): Promise<string|boolean>;
}

interface IWishlistState {
    filterParams: {
        user: string;
        status: string;
    };
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
    newWishModalParams: {
        isOpen: boolean;
        bookName: string;
        authors: string;
        publisher: string;
        isbn13: string;
        isbn10: string;
        statusId: string;
    };
    filterExpanded: boolean;
}

class Wishlist extends React.Component<IWishlistProps, IWishlistState> {

    constructor(props: IWishlistProps) {
        super(props);
        this.state = {
            filterParams: {
                user: '',
                status: ''
            },
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            },
            newWishModalParams: {
                isOpen: false,
                bookName: '',
                authors: '',
                publisher: '',
                isbn13: '',
                isbn10: '',
                statusId: 'Pending'
            },
            filterExpanded: false
        };
    }

    public componentWillMount() {
        this.props.listAllWishes();
        this.props.listAllAuthors();
        this.props.listAllUsers();
        this.props.listAllPublishers();
    }

    public render() {
        if (this.props.wishlistLoading || this.props.usersLoading) {
            return <h3>Loading...</h3>;
        } else {
            return (
                <div className="col-md-12">
                    <h1>Wishlist</h1>
                    { isAllowed(this.props.loggedInUser, 'IWishlistService.Create') ?
                        <div>
                            <button type="button" onClick={this.openNewWishModal} className="btn btn-default">
                                Create new <span className="glyphicon glyphicon-plus"/>
                            </button>&nbsp;&nbsp;
                            <Link to="/createWish">
                                <button type="button" className="btn btn-default">
                                    Find book on Google <span className="glyphicon glyphicon-search"/>
                                </button>
                            </Link>
                        </div>
                    : ''}
                    <br/>
                    { isAllowed(this.props.loggedInUser, 'IWishlistService.Search') ?
                        <div>
                            <h3>
                                Filter:&nbsp;&nbsp;
                                <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                            </h3>
                            {
                            this.state.filterExpanded ?
                                <FilterWishesForm 
                                    users={this.props.users} 
                                    handleInputChange={this.handleUserSelectChange} 
                                    handleStatusChange={this.handleInputChange} 
                                    filterParams={this.state.filterParams} 
                                    filter={this.filter} 
                                    clearFilter={this.clearFilter} 
                                />
                                : null
                            }
                        </div>
                    : ''}
                    { isAllowed(this.props.loggedInUser, 'IWishlistService.ListAll') ?
                        <WishlistTable 
                            wishlist={this.props.wishlist} 
                            deleteWish={this.props.deleteWish} 
                            loggedInUser={this.props.loggedInUser}
                            openAreYouSureModal={this.openAreYouSureModal} 
                            changeStatus={this.changeStatus} 
                            transform={this.transform}
                        />
                    : ''}
                    <AreYouSureModal 
                        title={this.state.areYouSureModalParams.title} 
                        isOpen={this.state.areYouSureModalParams.isOpen} 
                        action={this.state.areYouSureModalParams.action}
                        close={this.closeAreYouSureModal} 
                        description={null} 
                    />
                    <NewWishModal 
                        title="Create a Wish" 
                        isOpen={this.state.newWishModalParams.isOpen} 
                        closeModal={this.closeNewWishModal} 
                        wishParams={this.state.newWishModalParams}
                        handleInputChange={this.handleInputChange} 
                        handleSubmitSave={this.submitCustomWish} 
                        addAuthor={this.addAuthor} 
                        removeAuthor={this.removeAuthor}
                        listAuthors={this.listAuthors} 
                        handleAuthorChange={this.handleAuthorChange} 
                        listPublishers={this.listPublishers} 
                        handlePublisherChange={this.handlePublisherChange}
                    />
                </div>
            );
        }
    }

    filter = (e: IFormEvent) => {
        e.preventDefault();
        let userId = this.state.filterParams.user;
        let status = this.state.filterParams.status;
        this.props.searchWishes(userId, status);
    }

    clearFilter = () => {
        this.props.searchWishes('', '');
        this.setState((prevState: IWishlistState) => {
            let newFilter = Object.assign({}, prevState.filterParams, {user: '', status: ''});
            return {filterParams: newFilter};
        });
    }

    handleUserSelectChange = (value: IChangeEvent) => {
        this.setState((prevState) => {
            let newSearch = Object.assign({}, prevState.filterParams, {
                'user': value
            });
            return {filterParams: newSearch};
        });
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IWishlistState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IWishlistState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    openNewWishModal = () => {
        this.setState((prevState: IWishlistState) => {
            let newWishModalParamsNew = Object.assign({}, prevState.newWishModalParams, {isOpen: true});
            return {newWishModalParams: newWishModalParamsNew};
        });
    }

    closeNewWishModal = () => {
        this.setState((prevState: IWishlistState) => {
            let newWishModalParamsNew = Object.assign({}, prevState.newWishModalParams, {isOpen: false});
            return {newWishModalParams: newWishModalParamsNew};
        });
    }

    handleInputChange = (event: IChangeEvent, editedIndex: number) => {
        const target: IAnyTargetType = event.target;
        const value: string = target.value;
        const name = target.name;

        this.setState((prevState: IWishlistState) => {
            let newWishParams = prevState.newWishModalParams;
            let filterParams = prevState.filterParams;
            switch (name) {
                case 'status': 
                    filterParams = Object.assign({}, prevState.filterParams, {
                        'status': value
                    });
                    break;
                case 'isbn10':
                case 'isbn13':
                case 'publisher':
                case 'bookName': 
                    newWishParams = Object.assign({}, prevState.newWishModalParams, {
                        [name]: value
                    }); 
                    break;
                default: 
                    let newAuthors = prevState.newWishModalParams.authors.split(',').map((author: string, index: number) => 
                        (index === editedIndex) ? value : author
                    );
                    newWishParams = Object.assign({}, prevState.newWishModalParams, {
                        authors: newAuthors.join()
                    });
            } 
            return {newWishModalParams: newWishParams, filterParams};
        });
    }

    listAuthors = () => {
        const values: {label: string, value: string}[] = [];
        this.props.authors.forEach(author => values.push({ label: author.name, value: author.name }));
        return values;
    }

    addAuthor = () => {
        this.setState((prevState: IWishlistState) => {
            let newWishModalParamsNew = Object.assign({}, prevState.newWishModalParams, {authors: prevState.newWishModalParams.authors.concat(',')});
            return {newWishModalParams: newWishModalParamsNew};
        });
    }

    removeAuthor = (e: IClickEvent, deletedIndex: number) => {
        this.setState((prevState: IWishlistState) => {
            let newAuthors = prevState.newWishModalParams.authors
                .split(',')
                .filter((author: string, index: number) => index !== deletedIndex)
                .join();
            let newWishParams = Object.assign({}, prevState.newWishModalParams, {
                authors: newAuthors
            });
            return {newWishModalParams: newWishParams};
        });
    }

    handleAuthorChange = (value: IChangeEvent) => {   
        this.setState((prevState: IWishlistState) => {
            let newWishParams = Object.assign({}, prevState.newWishModalParams, {
            authors: value
            });
            return {newWishModalParams: newWishParams};
        });
    }

    listPublishers = () => {
        const values: {label: string, value: string}[] = [];
        this.props.publishers.forEach(publisher => values.push({ label: publisher.name, value: publisher.name }));
        return values;
    }

    handlePublisherChange = (value: string) => {
        this.setState((prevState: IWishlistState) => {
            let newWishParams = Object.assign({}, prevState.newWishModalParams, {publisher: value});
            return {newWishModalParams: newWishParams};
        });
    }

    changeStatus = (value: string, id: string) => {
       return this.props.changeWishStatus(value, id);
    }

    transform = (id: string) => {
        return this.props.transformWish(id)
        .then((bookId: string | boolean) => {
            this.props.history.push('/bookDetails/' + bookId);
        });
    }

    submitCustomWish = () => {
        if (!this.validateForm()) {
            return Promise.reject('Invalid form');
        }
        this.setState({
          newWishModalParams: {
              isOpen: false,
              bookName: '',
              authors: '',
              publisher: '',
              isbn13: '',
              isbn10: '',
              statusId: 'Pending'
          } 
        });
        return this.props.createCustomWish(this.state.newWishModalParams);
    }
    
    validateForm = () => {
        if (!this.state.newWishModalParams.bookName) {
          toastr.error('Book name cannot be empty');
          return false;
        }
        let isbn13 = this.state.newWishModalParams.isbn13;
        let isbn10 = this.state.newWishModalParams.isbn10;
        if (isbn13 && !validateISBN(isbn13.trim())) {
            toastr.error('Invalid isbn13');
            return false;
        }
        if (isbn10 && !validateISBN(isbn10.trim())) {
            toastr.error('Invalid isbn10');
            return false;
        }
        return true;
    }

    toggleExpandFilter = () => {
      this.setState(prevState => ({
        filterExpanded: !prevState.filterExpanded
      }));
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        wishlist: store.wishlistReducer.wishlist,
        wishlistLoading: store.wishlistReducer.wishlistLoading,
        users: store.usersReducer.users,
        usersLoading: store.usersReducer.usersLoading,
        loggedInUser: store.accountReducer.loggedInUser.user,
        authors: store.authorsReducer.authors,
        publishers: store.publisherReducer.publishers
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllWishes: () => dispatch(listAllWishes()),
    deleteWish: (wish: IWish) => dispatch(deleteWish(wish)),
    listAllUsers: (sortBy?: string, asc?: boolean) => dispatch(listAllUsers(sortBy, asc)),
    searchWishes: (userId: string, status: string) => dispatch(searchWishes(userId, status)),
    //tslint:disable-next-line:no-any
    createCustomWish: (wishParams: any) => dispatch(createCustomWish(wishParams)),
    listAllAuthors: () => dispatch(listAllAuthors()),
    listAllPublishers: () => dispatch(listAllPublishers()),
    changeWishStatus: (newStatus: string, wishId: string) => dispatch(changeWishStatus(newStatus, wishId)),
    transformWish: (wishId: string) => dispatch(transformWish(wishId))
});

export default connect(mapStateToProps, matchDispatchToProps)(Wishlist);
