import ISubscription from '../types/subscription_type';
import subscriptionsService from '../services/subscriptions_service';
import { LIST_ALL_SUBSCRIPTIONS_SUCCESS, SAVE_SUBSCRIPTION_SUCCESS, LIST_ALL_SUBSCRIPTIONS_START } from '../constants/action_types';
import { catchError } from '../helpers/error_helper';
import * as toastr from 'toastr';
import { IThunkDispatch } from '../types/react_types';

export function listAllSubscriptions() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllSubscriptionsStart());
        return subscriptionsService.listAll()
            .then((subscriptions: ISubscription[]) => {
                dispatch(listAllSubscriptionsSuccess(subscriptions));
                return true;
            })
            .catch(catchError);
    };
}

export function listAllSubscriptionsStart() {
  return {
      type: LIST_ALL_SUBSCRIPTIONS_START,
      payload: {
          subscriptionsLoading: true
      }
  };
}

export function listAllSubscriptionsSuccess(subscriptions: ISubscription[]) {
    return {
        type: LIST_ALL_SUBSCRIPTIONS_SUCCESS,
        payload: {
            subscriptions: subscriptions,
            subscriptionsLoading: false
        }
    };
}

export function deleteSubscriptionForUser(userId: string, bookId: string, sameUser: boolean) {
    return function(dispatch: IThunkDispatch) {
        return subscriptionsService.deleteSubscriptionForUser(userId, bookId, sameUser)
        .then(() => {
            toastr.success('User unsubscribed.');
            return true;
        })
        .catch(catchError);
    };
}

export function searchSubscriptions(userId: string, bookId: string) {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllSubscriptionsStart());
        return subscriptionsService.searchSubscriptions(userId, bookId)
        .then((subscriptions: ISubscription[]) => {
            dispatch(listAllSubscriptionsSuccess(subscriptions));
            return true;
        })
        .catch(catchError);
    };
}

export function subscribeUser(userId: string, bookId: string) {
    return function(dispatch: IThunkDispatch) {
        return subscriptionsService.subscribeUser(userId, bookId)
        .then((subscription: ISubscription) => {
            toastr.success('User subscribed.');
            dispatch(saveSubscriptionSuccess(subscription));
            return true;
        })
        .catch(catchError);
    };
}
export function saveSubscriptionSuccess(subscription: ISubscription) {
    return {
        type: SAVE_SUBSCRIPTION_SUCCESS,
        payload: {
            subscription: subscription
        }
    };
}

export function subscribeUserToAll(userId: string) {
    return function(dispatch: IThunkDispatch) {
        return subscriptionsService.subscribeUserToAll(userId)
        .then(() => {
            toastr.success('User subscribed.');
            return true;
        })
        .catch(catchError);
    };
}

export function unsubscribeUserFromAll(userId: string) {
    return function(dispatch: IThunkDispatch) {
        return subscriptionsService.unsubscribeUserFromAll(userId)
        .then(() => {
            toastr.success('User unsubscribed.');
            return true;
        })
        .catch(catchError);
    };
}