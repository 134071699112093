import * as React from 'react';
import { connect } from 'react-redux';
import AreYouSureModal from '../are_you_sure_modal';
import { listAllKeywords, deleteKeyword } from '../../actions/keywords_actions';
import IKeyword from '../../types/keyword_type';
import KeywordRow from './keyword_row';
import KeywordModal from './keyword_modal';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import Modal from 'react-modal';
import { History } from 'history';
import { IClickEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IKeywordProps {
    keywords: IKeyword[];
    loggedInUser: IUser | null;
    history: History;
    listAllKeywords(): Promise<boolean>;
    deleteKeyword(keyword: IKeyword): Promise<boolean>;
}

interface IKeywordState {
    openModal: boolean;
    keyword: IKeyword;
    editing: boolean;
    loading: boolean;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
}

class Keywords extends React.Component<IKeywordProps, IKeywordState> {
    
    constructor(props: IKeywordProps) {
        super(props);
        this.state = {
            openModal: false,
            keyword: {id: '', name: ''},
            editing: false,
            loading: true,
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            }
        };
    }
    public componentWillMount() {
        this.props.listAllKeywords().then(() => this.setState({ loading: false}));
    }

    public render() {
        let createForm = this.state.openModal ? this.renderModal(this.state.keyword) : <div/>;
        let loader = this.state.loading ? <p><em>Loading...</em></p> : <div/>;
        return (
            <div>
            <h1>List of Keywords</h1>
            {loader}
            { isAllowed(this.props.loggedInUser, 'IKeywordService.Create') ?
                <button onClick={this.createButton} className="btn btn-default">
                    Create New   <span className="glyphicon glyphicon-plus"/>
                </button>
                : <div/>
            }
            <br /><br />
            { isAllowed(this.props.loggedInUser, 'IKeywordService.ListAll') ?
                <table className="table table-striped"> 
                    <thead>
                        <tr>
                            <td className="bold">#&nbsp;</td>
                            <td className="bold">Name</td>
                            <td className="bold">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.listKeywords()}
                    </tbody>
                </table>
                : <div/>
            }
            <div id="divModal">
            { isAllowed(this.props.loggedInUser, 'IKeywordService.Create') ?
                createForm
                : <div/>
            }
            </div>
            <AreYouSureModal 
                title={this.state.areYouSureModalParams.title} 
                isOpen={this.state.areYouSureModalParams.isOpen} 
                action={this.state.areYouSureModalParams.action}
                close={this.closeAreYouSureModal} 
                description={null} 
            />
        </div>
        );
    }

    renderModal = (keyword: IKeyword) => {
        return (
            <KeywordModal 
                closeModal={this.closeModal} 
                editedKeyword={this.state.keyword} 
                editing={this.state.editing} 
                closing={!this.state.openModal}
            /> 
        );
    }
    
    listKeywords = () => {
        return this.props.keywords.map((keyword, index) => (
            <KeywordRow 
                openAreYouSureModal={this.openAreYouSureModal} 
                key={keyword.id} 
                keyword={keyword} 
                number={index + 1} 
                edit={this.editButton} 
                delete={this.handleDelete} 
                loggedInUser={this.props.loggedInUser} 
            />
        ));
    }

    createButton = () => {
        this.setState({ openModal : true, loading: false , editing: false, keyword: {id: '', name: ''} });
        Modal.setAppElement('#divModal');
    }

    editButton = (keyword: IKeyword, e: IClickEvent) => {
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({openModal: true, editing: true, keyword: keyword, loading: false});
            Modal.setAppElement('#divModal');
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false
        });
    }

    handleDelete = (keyword: IKeyword) => {
       return this.props.deleteKeyword(keyword);
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IKeywordState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IKeywordState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }
    
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        keywords: store.keywordReducer.keywords,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllKeywords: () => dispatch(listAllKeywords()),
    deleteKeyword: (keyword: IKeyword) => dispatch(deleteKeyword(keyword))
});

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);