import { LIST_ALL_ROLES_LOADING, LIST_ALL_ROLES_SUCCESS, DELETE_ROLE_SUCCESS, CREATE_ROLE_SUCCESS, UPDATE_ROLE_SUCCESS, 
    GET_ROLE_LOADING, GET_ROLE_SUCCESS, CHANGE_PERMISSION_ASSIGNMENT_SUCCESS, LIST_ROLES_WITH_PERMISSION_LOADING, 
    LIST_ROLES_WITH_PERMISSION_SUCCESS } from '../constants/action_types';
import IRole from '../types/role_type';
import { IAction } from '../types/react_types';

export interface IRolesReducer {
    roles: IRole[];
    rolesLoading: boolean;
    role: IRole | null;
    roleLoading: boolean;
    rolesWithPermission: IRole[];
    rolesWithPermissionLoading: boolean;
}

var defaultState: IRolesReducer = {
    roles: [],
    rolesLoading: false,
    role: null,
    roleLoading: false,
    rolesWithPermission: [],
    rolesWithPermissionLoading: false
};

export default function (state: IRolesReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_ROLES_LOADING:
        case LIST_ALL_ROLES_SUCCESS:
        case GET_ROLE_LOADING:
        case GET_ROLE_SUCCESS:
        case CHANGE_PERMISSION_ASSIGNMENT_SUCCESS:
        case LIST_ROLES_WITH_PERMISSION_LOADING:
        case LIST_ROLES_WITH_PERMISSION_SUCCESS:
            return Object.assign({}, state, action.payload);
        case CREATE_ROLE_SUCCESS:
            let rolesAfterCreate: IRole[] = state.roles.slice();
            rolesAfterCreate.push(action.payload.role);
            return Object.assign({}, state, {roles: rolesAfterCreate});
        case UPDATE_ROLE_SUCCESS:
            let updatedRole = action.payload.role;
            let rolesAfterUpdate = state.roles.map((r: IRole) => r.id === action.payload.role.id ? 
                Object.assign({}, r, updatedRole) : r);
            return Object.assign({}, state, {roles: rolesAfterUpdate});
        case DELETE_ROLE_SUCCESS:
            let deletedRole = action.payload.role;
            let rolesAfterDelete: IRole[] = state.roles.slice();
            let index = state.roles.findIndex((role: IRole) => role.id === deletedRole.id);
            rolesAfterDelete.splice(index, 1);
            return Object.assign({}, state, {roles: rolesAfterDelete});
        default:
            return state;
    }
}