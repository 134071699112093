import { handleErrors } from '../helpers/error_helper';
import { LINK_BASE } from '../constants/other_constants';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class ConfigurationsService {

    getCheckoutDuration() {
        return fetch(LINK_BASE + '/Configurations/GetCheckoutDuration', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<number>);
    }

    changeCheckoutDuration(value: number) {
        return fetch(LINK_BASE + '/Configurations/ChangeCheckoutDuration', {
            body: value.toString(),
            headers: getAuthorizedContentTypeHeaders(),
            method: 'put'
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<number>);
    }

}

export default new ConfigurationsService();