import { SEARCH_GOOGLE_BOOKS_SUCCESS, SEARCH_GOOGLE_BOOKS_LOADING, EMPTY_GOOGLE_BOOKS_SEARCH }  from '../constants/action_types';
import { IAction } from '../types/react_types';

export interface IGoogleBooksReducer {
    //tslint:disable-next-line:no-any
    searchResult: any;
    booksLoading: boolean;
}

var defaultState: IGoogleBooksReducer = {
    searchResult: null,
    booksLoading: false
};

export default function(state: IGoogleBooksReducer = defaultState, action: IAction) {
    switch (action.type) {
        case SEARCH_GOOGLE_BOOKS_LOADING:
        case SEARCH_GOOGLE_BOOKS_SUCCESS:
        case EMPTY_GOOGLE_BOOKS_SEARCH:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}