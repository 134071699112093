import { LIST_ALL_GENRES_SUCCESS, ADD_GENRE_SUCCESS, UPDATE_GENRE_SUCCESS, DELETE_GENRE_SUCCESS }  from '../constants/action_types';
import IGenre from '../types/genre_type';
import { IAction } from '../types/react_types';

export interface IGenresReducer {
    genres: IGenre[];
}

var defaultState: IGenresReducer = {
    genres : []
};

export default function(state: IGenresReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_GENRES_SUCCESS:
            return Object.assign({}, state, {genres: action.payload.genres});
        case ADD_GENRE_SUCCESS:
            let genresAfterCreate: IGenre[] = state.genres.slice();
            genresAfterCreate.push(action.payload.genre);
            let genre = Object.assign({}, state, {genres: genresAfterCreate});
            return genre;
        case UPDATE_GENRE_SUCCESS:
            let updatedGenre = action.payload.genre;
            let genresAfterUpdate = state.genres.map((g: IGenre) =>
                g.id === action.payload.genre.id ?
                    Object.assign({}, g, updatedGenre) : 
                    g
            );
            return Object.assign({}, state, {genres: genresAfterUpdate});
        case DELETE_GENRE_SUCCESS:
            let deletedGenre = action.payload.genre;
            let genresAfterDelete = state.genres.filter((g: IGenre) => g.id !== deletedGenre.id );
            return Object.assign({}, state, {genres: genresAfterDelete});
        default:
            return state;
    }
}
