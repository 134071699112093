import * as React from 'react';
import IKeyword from '../../types/keyword_type';
import { connect } from 'react-redux';
import { MODAL_STYLES } from '../../constants/other_constants';
import Modal from 'react-modal';
import * as toastr from 'toastr';
import { addKeyword, updateKeyword } from '../../actions/keywords_actions';
import { IFormEvent, IChangeEvent, IThunkDispatch } from '../../types/react_types';

interface IKeywordModalState {
    keyword: IKeyword;
    editing: boolean;
    closing: boolean;
    saveClicked: boolean;
}

interface IKeywordModalProps {
    editing: boolean;
    editedKeyword: IKeyword;
    closing: boolean;
    addKeyword(keyword: IKeyword): Promise<boolean>;
    updateKeyword(keyword: IKeyword): Promise<boolean>;
    closeModal(): void;
}

class KeywordModal extends React.Component<IKeywordModalProps, IKeywordModalState> {
    constructor(props: IKeywordModalProps) {
        super(props);
        this.state = {
            keyword: {
                id: this.props.editedKeyword.id ? this.props.editedKeyword.id : '', 
                name: this.props.editedKeyword.name ? this.props.editedKeyword.name : ''
            },
            editing: props.editing,
            closing: props.closing,
            saveClicked: false
        };
    }
    
    render() {
        if (!this.state.closing) {
            return (
                <Modal isOpen={!this.state.closing} style={MODAL_STYLES}>
                    <h3>{this.state.editing ? 'Edit keyword ' : 'Create new keyword'}</h3>
                    <form onSubmit={this.handleSubmitSaveKeyword}>
                        <div className="form-group">
                            <label className="required-field"> Name:</label>
                            <input 
                                type="text"  
                                value={this.state.keyword.name}  
                                name="name" 
                                className="form-control" 
                                onChange={this.handleInputChangeKeyword}
                            />
                        </div>
                            <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                            <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                        </form>
                 </Modal>
            );
        } else {
            return <div/>;
        }
    }

    handleSubmitSaveKeyword = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.validateFormKeyword(this.state.keyword)) {
            return;
        }
        this.setState({ saveClicked: true });
        if (this.state.editing) {
             this.props.updateKeyword(this.state.keyword).then((success: boolean) => {
                this.setState({
                    editing: false, keyword : {id: '', name: ''}, saveClicked: false
                });
                this.props.closeModal();
            }).catch(() => this.setState({ saveClicked: false }));
        } else {
            this.props.addKeyword(this.state.keyword).then((success: boolean) => {
                if (success) {
                    this.setState({
                        editing: false, keyword : {id: '', name: ''}, saveClicked: false
                    });
                    this.props.closeModal();
                }
            }).catch(() => this.setState({ saveClicked: false }));
        }
    }

    handleInputChangeKeyword = (event: IChangeEvent) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newKeyword = Object.assign({}, prevState.keyword, {
                [name]: value
            });
            return {keyword: newKeyword};
        });
    }
    
    validateFormKeyword = (keyword: IKeyword) => {
        if (!keyword.name || keyword.name.trim() === '') {
            toastr.error('Keyword name cannot be empty.');
            return false;
        }
        return true;
    }
}

function mapStateToProps() {
    return {
        
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    addKeyword: (keyword: IKeyword) => dispatch(addKeyword(keyword)),
    updateKeyword: (keyword: IKeyword) => dispatch(updateKeyword(keyword))
});

export default connect(mapStateToProps, matchDispatchToProps)(KeywordModal);