import * as React from 'react';
import IPublisher from '../../types/publisher_type';
import PublisherRow from './publisher_row';
import { connect } from 'react-redux';
import { listAllPublishers, deletePublisher } from '../../actions/publishers_actions';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import PublisherModal from './publisher_modal';
import AreYouSureModal from '../are_you_sure_modal';
import Modal from 'react-modal';
import { History } from 'history';
import { IClickEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IPublisherProps {
    publishers: IPublisher[];
    loggedInUser: IUser | null;
    history: History;
    listAllPublishers(): Promise<boolean>;
    deletePublisher(pub: IPublisher): Promise<boolean>;
}

interface IPublisherState {
    openModal: boolean;
    editing: boolean;
    loading: boolean;
    publisher: IPublisher;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
}

class Publishers extends React.Component<IPublisherProps, IPublisherState> {
    constructor(props: IPublisherProps) {
        super(props);
        this.state = {
            openModal: false, editing: false, loading: true, publisher: {id: '', name: '', country: ''},
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            }
        };
    }

    public componentWillMount() {
        this.props.listAllPublishers().then( () => this.setState( {loading: false} ));
    }

    public render() {
        let createForm = this.state.openModal ? this.renderModal(this.state.publisher) : <div/>;
        let loader = this.state.loading ? <p><em>Loading...</em></p> : <div/>;
        return (
            <div>
            <h1>List of Publishers</h1>
            {loader}
            { isAllowed(this.props.loggedInUser, 'IPublisherService.Create') ?
                <button onClick={this.createButton} className="btn btn-default">
                    Create New   <span className="glyphicon glyphicon-plus"/>
                </button>
                : <div/>
            }
            <br/><br />
            { isAllowed(this.props.loggedInUser, 'IPublisherService.ListAll') ?
                <table className="table table-striped"> 
                    <thead>
                        <tr>
                            <td className="bold">#&nbsp;</td>
                            <td className="bold">Name</td>
                            <td className="bold">Country</td>
                            <td className="bold">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.listPublishers()}
                    </tbody>
                </table>
                : <div/>
            }
            <div id="divModal">
            { isAllowed(this.props.loggedInUser, 'IPublisherService.Create') ?
                createForm
                : <div/>
            }
            </div>
            <AreYouSureModal 
                title={this.state.areYouSureModalParams.title} 
                isOpen={this.state.areYouSureModalParams.isOpen} 
                action={this.state.areYouSureModalParams.action}
                close={this.closeAreYouSureModal} 
                description={null} 
            />
        </div>
        );
    }

    renderModal = (publisher: IPublisher) => {
        return (
            <PublisherModal 
                closing={!this.state.openModal} 
                closeModal={this.closeModal} 
                editing={this.state.editing} 
                editedPublisher={this.state.publisher}
            /> 
        );
    }

    listPublishers = () => {
        return this.props.publishers.map((publisher, index) => (
            <PublisherRow 
                key={publisher.id}  
                openAreYouSureModal={this.openAreYouSureModal}  
                number={index + 1} 
                publisher={publisher} 
                edit={this.editButton} 
                delete={this.handleDelete} 
                loggedInUser={this.props.loggedInUser}
            />
        ));
    }

    createButton = () => {
        this.setState({ openModal : true, loading: false, editing: false });
        Modal.setAppElement('#divModal');
    }

    editButton = (publisher: IPublisher, e: IClickEvent) => {   
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({openModal: true, editing: true, publisher: publisher, loading: false});
            Modal.setAppElement('#divModal');
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false,
            publisher: {id: '', name: '', country: ''}
        });
    }

    handleDelete = (publisher: IPublisher) => {
       return this.props.deletePublisher(publisher);
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IPublisherState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IPublisherState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }
}

function mapStateToProps (store: ILibrarianStore) {
    return {
        publishers: store.publisherReducer.publishers,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllPublishers: () => dispatch(listAllPublishers()),
    deletePublisher: (pub: IPublisher) => dispatch(deletePublisher(pub))
});

export default connect(mapStateToProps, mapDispatchToProps)(Publishers);