import * as React from 'react';
import AuthorRow from './author_row';
import IAuthor from '../../types/author_type';
import IUser from '../../types/user_type';
import { IClickEvent, IThunk } from '../../types/react_types';

interface IAuthorTableProps {
    authors: IAuthor[];
    loggedInUser: IUser | null;
    openAreYouSureModal(action: Function, title: string): void;
    deleteAuthor(author: IAuthor): IThunk<boolean>;
    openEditAuthorModal(author: IAuthor, e: IClickEvent): void;
}

const AuthorTable = (props: IAuthorTableProps) => {
    let authors = props.authors;
    return (
        <table className="table table-striped">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">Name</td>
                    <td className="bold">Pseudonym</td>
                    <td className="bold">Born in</td>
                    <td className="bold">Nationality</td>
                    <td className="bold">Action</td>
                </tr>
            </thead>
            <tbody>
                {authors.map((author: IAuthor, index: number) => 
                    <AuthorRow 
                        key={author.id} 
                        number={index + 1} 
                        author={author} 
                        edit={props.openEditAuthorModal} 
                        delete={props.deleteAuthor} 
                        loggedInUser={props.loggedInUser} 
                        openAreYouSureModal={props.openAreYouSureModal} 
                    />)}
            </tbody>
        </table>
    );
};

export default AuthorTable;