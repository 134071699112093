import ILanguage from '../types/language_type';
import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class LanguagesService {

    listAllLanguages() {
        return fetch(LINK_BASE + '/Languages', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<ILanguage[]>);
    }

    addLanguage(language: ILanguage) {
        return  fetch(LINK_BASE + '/Languages', {
            method: 'post',
            body: JSON.stringify( { 'Id': EMPTY_GUID, 'Name': language.name  } ),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<ILanguage>);
    }

    updateLanguage(language: ILanguage) {
        return  fetch(LINK_BASE + '/Languages', {
            method: 'put',
            body: JSON.stringify(language),
            headers: getAuthorizedContentTypeHeaders()
        }).then(handleErrors).then(response => response.json() as Promise<ILanguage>);
    }

    deleteLanguage(language: ILanguage) {
        return fetch(LINK_BASE + '/Languages/' + language.id, {
            method: 'delete',
            headers: getAuthorizedHeaders()
        }).then(handleErrors);
    }
} 

export default new LanguagesService();