import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';
import ICheckout from '../types/checkout_type';

class CheckoutsService {

    delete(checkout: ICheckout) {
        return fetch(LINK_BASE + '/Checkouts/' + checkout.id, {
            method: 'delete',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    listAll() {
        return fetch(LINK_BASE + '/Checkouts', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<ICheckout[]>);
    }

    search(
        bookId: string, userId: string, fromCheckoutTime: Date|string|null, toCheckoutTime: Date|string|null, 
        fromReturnTime: Date|string|null, toReturnTime: Date|string|null,
        librarianId: string, returned: string|null) {
       
        let searchObject = JSON.stringify({
            bookId: bookId || EMPTY_GUID, 
            userId: userId || EMPTY_GUID, 
            fromCheckoutTime: fromCheckoutTime, 
            toCheckoutTime: toCheckoutTime, 
            fromReturnTime: fromReturnTime, 
            toReturnTime: toReturnTime, 
            librarianId: librarianId || EMPTY_GUID, 
            returned: returned
        });
        return fetch(LINK_BASE + '/Checkouts/Search', {
            method: 'POST',
            body: searchObject,
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<ICheckout[]>);
    }

}

export default new CheckoutsService();