import { LIST_ALL_PERMISSIONS_LOADING, LIST_ALL_PERMISSIONS_SUCCESS } from '../constants/action_types';
import IPermission from '../types/permission_type';
import { IAction } from '../types/react_types';

export interface IPermissionsReducer {
    permissions: IPermission[];
    permissionsLoading: boolean;
}

var defaultState: IPermissionsReducer = {
    permissions: [],
    permissionsLoading: false
};

export default function (state: IPermissionsReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_PERMISSIONS_LOADING:
        case LIST_ALL_PERMISSIONS_SUCCESS:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}