import * as React from 'react';
import IAuthor from '../../types/author_type';
import IBook from '../../types/book_type';
import IKeyword from '../../types/keyword_type';
import IGenre from '../../types/genre_type';
import ILanguage from '../../types/language_type';
import IPublisher from '../../types/publisher_type';
import { getBook, updateBook } from '../../actions/books_actions';
import { listAllBooks, addBook } from '../../actions/books_actions';
import { listAllAuthors } from '../../actions/authors_actions';
import { listAllGenres } from '../../actions/genres_actions';
import { listAllKeywords } from '../../actions/keywords_actions';
import { listAllLanguages } from '../../actions/languages_actions';
import { listAllPublishers } from '../../actions/publishers_actions';
import { connect } from 'react-redux';
import Select from 'react-select';
import KeywordModal from '../keywords/keyword_modal';
import GenreModal from '../genres/genre_modal';
import LanguageModal from '../languages/language_modal';
import PublisherModal from '../publishers/publisher_modal';
import * as toastr from 'toastr';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import CreateUpdateAuthorModal from '../authors/create_update_author_modal';
import { validateISBN } from '../../helpers/ISBN_helper';
import SuggestedImagesModal from './suggested_images_modal';
import { searchGoogleBooks } from '../../actions/google_books_actions';
import { LINK_BASE } from '../../constants/other_constants';
import { RouteComponentProps } from 'react-router';
import { IKeyEvent, IAnyTargetType, IChangeEvent, IClickEvent, IFormEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

let isUrl = require('is-url');

interface IBookFormRouteParams {
    id: string;
}

interface IBookFormProps extends RouteComponentProps<IBookFormRouteParams> {
    book: IBook | null;
    authors: IAuthor[];
    keywords: IKeyword[];
    genres: IGenre[];
    languages: ILanguage[];
    publishers: IPublisher[];
    //tslint:disable-next-line:no-any
    googleSearchResult: any;
    getBook: Function;
    updateBook(book: IBook): Promise<boolean>;
    listAllBooks(sortBy: string, asc: boolean): Promise<boolean>;
    addBook(book: IBook): Promise<boolean>;
    listAllAuthors(): Promise<boolean>;
    listAllGenres(): Promise<boolean>;
    listAllKeywords(): Promise<boolean>;
    listAllLanguages(): Promise<boolean>;
    listAllPublishers(): Promise<boolean>;
    searchGoogleBooks(params: string, page: number): Promise<boolean>;
}

interface IBookFormState {
    loading: boolean;
    updatedBook: IBook;
    creating: boolean;
    selectedAuthors: string;
    selectedKeywords: string;
    selectedGenres: string;
    selectedLanguage: string;
    selectedPublisher: string;
    modalAuthor: boolean;
    modalPublishers: boolean;
    modalKeywords: boolean;
    modalGenres: boolean;
    modalLanguages: boolean;
    modalSuggestedImages: boolean;
    submitClicked: boolean;
    customUrl: string;
    usingUrlImage: boolean;
    usingServerImage: boolean;
}

class BookForm extends React.Component<IBookFormProps, IBookFormState> {
    constructor(props: IBookFormProps) {
        super(props);
        this.state = {
             loading: false,
             updatedBook:  {id: '', name: '', publicationDate: undefined, totalCopies: 0, rating: 0, description: '',
             isbN13: '', isbN10: '', edition: '', language: null, publisher: null, 
             keywords: [], genres: [], authors: [], image: '', comments: [], availableCopies: 0, imageUrl: null  },
              creating: true,
              selectedAuthors: '',
              selectedGenres: '',
              selectedKeywords: '',
              selectedLanguage: '',
              selectedPublisher: '',
              modalAuthor: false,
              modalPublishers: false,
              modalKeywords: false,
              modalGenres: false,
              modalLanguages: false,
              submitClicked: false,
              modalSuggestedImages: false,
              customUrl: '',
              usingUrlImage: false,
              usingServerImage: false
        };
    }

    public componentWillMount() {
        this.listAllData();
    }

    listAllData = () => {
        let id = this.props.match.params.id;
        if (id) {
            this.props.getBook(id).then((book: IBook) => {
              if (book) {
                let keywordsInBook: string = '';
                for (let keyword of book.keywords) {
                    keywordsInBook += ',' + keyword.id;
                }
                let authorsInBook: string = '';
                for (let author of book.authors) {
                    authorsInBook += ',' + author.id;
                }
                let genresInBook: string = '';
                for (let genre of book.genres) {
                    genresInBook += ',' + genre.id;
                }
                this.setState({
                    creating: false,
                    updatedBook: Object.assign({}, book),
                    selectedPublisher: book.publisher != null ? book.publisher.id : '',
                    selectedLanguage: book.language != null ? book.language.id : '',
                    selectedKeywords:  keywordsInBook,
                    selectedGenres: genresInBook,
                    selectedAuthors: authorsInBook,
                    usingUrlImage: false,
                    usingServerImage: !!book.image
                });
              }
            });
        } else {
            this.setState({
                creating: true
            });
        }
        this.listAll();
    }

    public render() {
        if (this.state.loading) {
            return <h3>Loading...</h3>;
        } else {
            return this.renderPage();
        }
    }

    onKeyPress = (event: IKeyEvent) => {
        const target: IAnyTargetType = event.target;
        if (event.key === 'Enter' && !target.className.includes('description-textarea')) {
            event.preventDefault();
        }
    }

    renderPage = () => {
        let hiddenIcon =  this.state.updatedBook.image || this.state.updatedBook.imageUrl ? 
            'glyphicon glyphicon-picture hidden' : 
            'glyphicon glyphicon-picture';
        let imagePrefix = '';
        if (this.state.usingServerImage) {
          imagePrefix = LINK_BASE;
        } else if (!this.state.usingUrlImage) {
          imagePrefix = 'data:image/jpeg;base64, ';
        }
        let image = (
            <div className="align-center">
                <span id="contact-icon" className={hiddenIcon} />
                <img 
                    id="contact-photo" 
                    src={imagePrefix + (this.state.updatedBook.image || this.state.updatedBook.imageUrl || '')}  
                    className="img-sm"  
                    alt=""
                />
            </div>
        );
        let xMark = !this.state.updatedBook.image && !this.state.updatedBook.imageUrl ? 
            <div/> :  
            <i className="image-remove-icon glyphicon glyphicon-remove fa-2x" onClick={this.removeImage}  aria-hidden="true" />;
        let authorModal = this.state.modalAuthor ? 
            (
            <CreateUpdateAuthorModal  
                closeModal={this.closeModal} 
                closing={!this.state.modalAuthor} 
                editing={false} 
                editedAuthor={{id: '',  name: '', pseudonym: '', yearOfBirth: undefined, nationality: ''}} 
            />
            ) : 
            <div/>;
        let keywordModal = this.state.modalKeywords ? 
            (
            <KeywordModal 
                closeModal={this.closeModal} 
                closing={!this.state.modalKeywords}
                editing={false} 
                editedKeyword={{id: '', name: ''}} 
            />
            )
            : <div/>;
        let genreModal = this.state.modalGenres ? 
            (
            <GenreModal 
                closeModal={this.closeModal} 
                closing={!this.state.modalGenres} 
                editing={false} 
                editedGenre={{id: '', name: ''}} 
            />
            ) 
            : <div/>;
        let languageModal = this.state.modalLanguages ? 
            (
            <LanguageModal 
                closeModal={this.closeModal} 
                closing={!this.state.modalLanguages} 
                editing={false} 
                editedLanguage={{id: '', name: ''}} 
            />
            ) 
            : <div/>;
        let publisherModal = this.state.modalPublishers ? 
            (
            <PublisherModal 
                closeModal={this.closeModal} 
                closing={!this.state.modalPublishers} 
                editing={false} 
                editedPublisher={{id: '', name: '', country: ''}} 
            />
            ) 
            : <div/>;
        let suggestedImagesModal = this.state.modalSuggestedImages ? 
            (
            <SuggestedImagesModal 
                search={this.searchSuggestedImages} 
                searchParams={this.getSearchParams} 
                closeModal={this.closeModal}
                open={this.state.modalSuggestedImages} 
                chooseSuggestedImage={this.chooseSuggestedImage} 
            />
            ) 
            : <div/>;
        return (
            <div>
                 <h3>{this.state.creating ? 'Create new book ' : 'Edit book'}</h3>
                 <br/>
                    <form onKeyPress={this.onKeyPress}>
               <div  className="col-md-6">
                <div className="form-group">
                    <label className="required-field"> Name:</label>
                    <input 
                        type="text"  
                        name="name" 
                        value={this.state.updatedBook.name} 
                        className="form-control" 
                        onChange={this.handleInputChange} 
                        required={true}
                    />
                </div>
                <div className="form-group">
                    <label> Publication year:</label>
                    <input 
                        type="number" 
                        name="publicationDate" 
                        value={this.state.updatedBook.publicationDate} 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label className="required-field"> Total copies:</label>
                    <input 
                        type="number"  
                        name="totalCopies" 
                        value={this.state.updatedBook.totalCopies} 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label> ISBN-13:</label>
                    <input 
                        type="text"  
                        name="isbN13"
                        value={this.state.updatedBook.isbN13} 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label> ISBN-10:</label>
                    <input 
                        type="text"  
                        name="isbN10" 
                        value={this.state.updatedBook.isbN10} 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label> Edition:</label>
                    <input 
                        type="text"  
                        name="edition"
                        value={this.state.updatedBook.edition} 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label> Description:</label>
                    <textarea 
                        rows={5} 
                        name="description" 
                        value={this.state.updatedBook.description} 
                        className="form-control description-textarea" 
                        onChange={this.handleInputChange}
                    />
                </div>
               </div>
               <div  className="col-md-6">
               <div className="form-group">
                    <label > Author(s):</label><br/>
                    <Select
                        className="selectList narrow-inline-left"
                        closeOnSelect={true}
                        multi={true}
                        onChange={this.handleAuthorChange}
                        placeholder="Select author(s)"
                        simpleValue={true}
                        value={this.state.selectedAuthors}
                        options={this.listAuthors()}
                        name="authors"
                    />
                    <button 
                        name="modalAuthor" 
                        className="btn btn-success ml-10"
                        onClick={this.openModal}
                    >
                        <span className="glyphicon glyphicon-plus"/>
                    </button>
                </div>
                <div className="form-group">
                    <label> Keyword(s):</label><br/>
                    <Select
                        className="selectList narrow-inline-left"
                        closeOnSelect={true}
                        multi={true}
                        onChange={this.handleKeywordChange}
                        placeholder="Select keyword(s)"
                        simpleValue={true}
                        value={this.state.selectedKeywords}
                        options={this.listKeywords()}
                        name="keywords"
                    />
                    <button 
                        name="modalKeywords" 
                        className="btn btn-success ml-10" 
                        onClick={this.openModal}
                    >
                        <span className="glyphicon glyphicon-plus"/>
                    </button>
                </div>
                <div className="form-group">
                    <label> Genre(s):</label><br/>
                    <Select
                        className="selectList narrow-inline-left"
                        closeOnSelect={true}
                        multi={true}
                        onChange={this.handleGenreChange}
                        placeholder="Select genre(s)"
                        simpleValue={true}
                        value={this.state.selectedGenres}
                        options={this.listGenres()}
                        name="genres"
                    />
                    <button 
                        name="modalGenres" 
                        className="btn btn-success ml-10" 
                        onClick={this.openModal}
                    >
                        <span className="glyphicon glyphicon-plus"/>
                    </button>
                </div>
                <div className="form-group">
                    <label > Language:</label><br/>
                    <Select
                        className="selectList narrow-inline-left"
                        closeOnSelect={true}
                        onChange={this.handleLanguageChange}
                        placeholder="Select language"
                        simpleValue={true}
                        value={this.state.selectedLanguage}
                        options={this.listLanguages()}
                        name="language"
                    />
                    <button 
                        name="modalLanguages" 
                        className="btn btn-success ml-10" 
                        onClick={this.openModal}
                    >
                        <span className="glyphicon glyphicon-plus"/>
                    </button>
                </div>
                <div className="form-group">
                    <label > Publisher:</label><br />
                    <Select
                        className="selectList narrow-inline-left"
                        closeOnSelect={true}
                        onChange={this.handlePublisherChange}
                        placeholder="Select publisher"
                        simpleValue={true}
                        value={this.state.selectedPublisher}
                        options={this.listPublishers()}
                        name="publisher"
                    />
                    <button 
                        name="modalPublishers" 
                        className="btn btn-success ml-10" 
                        onClick={this.openModal}
                    >
                        <span className="glyphicon glyphicon-plus"/>
                    </button>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <div className="img-wrap">{image}{xMark}</div>
                            <input 
                                type="file" 
                                accept="image/jpeg, image/png" 
                                id="photoUpload" 
                                value=""
                                ref="photoUpload" 
                                onChange={this.chooseImage} 
                                name="image" 
                                className="btn btn-file form-control hidden" 
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <button 
                            type="button" 
                            className="btn btn-success" 
                            onClick={this.callFileChooser}
                        >
                            Upload image 
                            <span className="glyphicon glyphicon-save"/>
                        </button>
                        <br/><br/>
                        <button 
                            type="button" 
                            className="btn btn-success" 
                            onClick={this.seeSuggestedImages}
                        >
                            See suggested images 
                            <span className="glyphicon glyphicon-search"/>
                        </button>
                        <br/><br/>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="customUrl" 
                            placeholder="Enter URL" 
                            value={this.state.customUrl} 
                            onChange={this.handleUrlChange} 
                        /> 
                        <br/>
                        <button type="button" className="btn btn-success" onClick={this.useCustomUrl}>Use URL</button>
                    </div>
                </div>
                <div className="form-group">
                    <input 
                        type="submit"  
                        className="btn btn-success btn-lg pull-right" 
                        style={{marginRight: '15%' }} 
                        disabled={this.state.submitClicked} 
                        onClick={this.handleSubmitSave}  
                        value="Save" 
                    />
                </div>    
                </div>
                </form>
                <div id="divModal" ref="divModal">
                {authorModal}
                {keywordModal}
                {genreModal}
                {languageModal}
                {publisherModal}
                {suggestedImagesModal}
                </div>
                
          </div>
        );
    }

    useCustomUrl = () => {
        if (isUrl(this.state.customUrl)) {
            this.setState((prevState: IBookFormState) => {
                let newUpdatedBook = Object.assign({}, prevState.updatedBook, {
                    image: '',
                    imageUrl: prevState.customUrl,
                });
                return {updatedBook: newUpdatedBook, usingUrlImage: true, usingServerImage: false };
            });
        } else if (this.state.customUrl && this.state.customUrl.startsWith('data:image/jpeg;base64')) {
            this.setState((prevState: IBookFormState) => {
                let newUpdatedBook = Object.assign({}, prevState.updatedBook, {
                    image: this.state.customUrl.split('data:image/jpeg;base64,').pop(),
                    imageUrl: '',
                });
                return {updatedBook: newUpdatedBook, customUrl: '', usingUrlImage: false, usingServerImage: false };
            });
        } else {
            toastr.warning('URL format incorrect.');
        }
    }

    handleUrlChange = (event: IChangeEvent) => {
        event.preventDefault();
        const target: IAnyTargetType = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({customUrl: value});
    }

    seeSuggestedImages = () => {
        let params = this.getSearchParams();
        if (params && params.trim() !== '') {
            this.searchSuggestedImages();
            this.setState({modalSuggestedImages: true});
            Modal.setAppElement('#divModal');
        } else {
            toastr.warning('Please fill out the form first.');
        }
    }

    searchSuggestedImages = (pageNo: number = 0) => {
        this.props.searchGoogleBooks(this.getSearchParams(), pageNo);
    }

    chooseSuggestedImage = (url: string) => {
        this.setState((prevState: IBookFormState) => {
            let newUpdatedBook = Object.assign({}, prevState.updatedBook, {imageUrl: url, image: ''});
            return {
                updatedBook: newUpdatedBook,
                modalSuggestedImages: false,
                customUrl: '',
                usingServerImage: false,
                usingUrlImage: true
            };
        });
    }

    getSearchParams = () => {
        let params = '';
        params += this.state.updatedBook.name;
        params += this.state.updatedBook.edition;
        this.state.updatedBook.authors.map((a: IAuthor) => params += a.name);
        if (this.state.updatedBook.language) {
            params += this.state.updatedBook.language.name;
        }
        if (this.state.updatedBook.publisher) {
            params += this.state.updatedBook.publisher.name;
        }
        params += this.state.updatedBook.isbN10;
        params += this.state.updatedBook.isbN13;
        return params;
    }

    listAuthors = () => {
        return this.props.authors.map(x => ({ label: x.name, value: x.id }));
    }

    listKeywords = () => {
        return this.props.keywords.map(x => ({ label: x.name, value: x.id }));
    }

    listGenres = () => {
        return this.props.genres.map(x => ({ label: x.name, value: x.id }));
    }

    listLanguages = () => {
        return this.props.languages.map(x => ({ label: x.name, value: x.id }));
    }

    listPublishers = () => {
        return this.props.publishers.map(x => ({ label: x.name, value: x.id }));
    }

    openModal = (event: IClickEvent) => {
        event.preventDefault();
        let target: IAnyTargetType = event.target;
        let name = target.name;
        this.setState(prevState => {
            const newState = Object.assign({}, prevState, { [name]: true });
            return newState;
        });
        Modal.setAppElement('#divModal');
    }

    closeModal = () => {
        this.setState({
            modalAuthor: false,
            modalKeywords: false,
            modalGenres: false,
            modalLanguages: false,
            modalPublishers: false,
            modalSuggestedImages: false
        });
    }

    listAll = () => {
        this.props.listAllAuthors();
        this.props.listAllGenres();
        this.props.listAllKeywords();
        this.props.listAllLanguages();
        this.props.listAllPublishers();
        this.setState( { loading: false} );
    }

    handleSubmitSave = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!this.validateForm(this.state.updatedBook)) {
            return;
        }
        this.setState({
            submitClicked: true
        });
        if (this.state.creating) {
            this.props.addBook(this.state.updatedBook).then((success: boolean) => {
                if (success) {
                    this.setState({
                        updatedBook: {id: '', name: '', publicationDate: undefined, totalCopies: 0, availableCopies: 0, rating: 0, description: '',
                        isbN13: '', isbN10: '', edition: '', language: null, publisher: null, 
                        keywords: [], genres: [], authors: [], image: '', comments: [], imageUrl: null}, loading: false,
                        selectedAuthors: '', selectedGenres: '', selectedKeywords: '', selectedLanguage: '', selectedPublisher: ''
                    });
                } else {
                    this.setState({submitClicked: false});
                }
            }).then(() => this.props.history.push('/Books'));
        } else {
            this.props.updateBook(this.state.updatedBook).then((success: boolean) => {
                if (success) {
                    this.setState({
                        updatedBook: {id: '', name: '', publicationDate: undefined, totalCopies: 0, availableCopies: 0, rating: 0, description: '',
                        isbN13: '', isbN10: '', edition: '', language: null, publisher: null, 
                        keywords: [], genres: [], authors: [], image: '', comments: [], imageUrl: null}, loading: false, selectedAuthors: '', 
                        selectedGenres: '', selectedKeywords: '', selectedLanguage: '', selectedPublisher: ''
                    });
                } else {
                    this.setState({submitClicked: false});
                }
            }).then(() => this.props.history.push('/Books'));
        }
    }

    handleInputChange = (event: IChangeEvent) => {
        event.preventDefault();
        const target: IAnyTargetType = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                [name]: value
            });
            return {updatedBook: newBook};
        });
    }

    //tslint:disable-next-line:no-any
    handleAuthorChange = (value: any) => {
        this.setState({ selectedAuthors: value  });
        let values = value.split(',');
        let newAuthors: IAuthor[] = [];

        for (let selectedAuthor of values) {
            let auth: IAuthor | undefined = this.props.authors.find(author => author.id === selectedAuthor);
            if (auth) {
                newAuthors.push(auth);
            }
        }
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                authors: newAuthors
            });
            return {updatedBook: newBook};
        });
    }
    
    //tslint:disable-next-line:no-any
    handleGenreChange = (value: any) => {
        this.setState({ selectedGenres: value  });
        let values = value.split(',');
        let newGenres: IGenre[] = [];

        for (let selectedGenre of values) {
            let genre: IGenre|undefined = this.props.genres.find(g => g.id === selectedGenre);
            if (genre) {
                newGenres.push(genre);
            }
        }
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                genres: newGenres
            });
            return {updatedBook: newBook};
        });
    }
    
    //tslint:disable-next-line:no-any
    handleKeywordChange = (value: any) => {
        this.setState({ selectedKeywords: value  });
        let values = value.split(',');
        let newKeywords: IKeyword[] = [];

        for (let selectedKeyword of values) {
            let keyword: IKeyword|undefined = this.props.keywords.find(k => k.id === selectedKeyword);
            if (keyword) {
                newKeywords.push(keyword);
            }
        }
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                keywords: newKeywords
            });
            return {updatedBook: newBook};
        });
    }
    
    //tslint:disable-next-line:no-any
    handleLanguageChange = (value: any) => {
        let language: ILanguage|undefined = this.props.languages.find(l => l.id === value);
        
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                language: language
            });
            return {updatedBook: newBook, selectedLanguage: value};
        });
    }

    //tslint:disable-next-line:no-any
    handlePublisherChange = (value: any) => {
        let publisher: IPublisher|undefined = this.props.publishers.find(p => p.id === value);
        
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                publisher: publisher
            });
            return {updatedBook: newBook, selectedPublisher: value};
        });
    }

    callFileChooser = () => {
        //tslint:disable-next-line:no-any
        var el: any = this.refs.photoUpload;
        if (el != null) {
            el.click();
        }
    }

    chooseImage = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        var reader = new FileReader();

        reader.addEventListener('loadend', this.finishReadingFile);

        reader.readAsDataURL(target.files[0]);
    }

    //tslint:disable-next-line:no-any
    finishReadingFile = (e: any) => {
        let buffer = e.currentTarget.result.split(',')[1];
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                image: buffer,
                imageUrl: null,
            });
            return {updatedBook: newBook, customUrl: '', usingUrlImage: false, usingServerImage: false};
        });
    }

    removeImage = (event: IClickEvent) => {
        event.preventDefault();
        this.setState((prevState) => {
            let newBook = Object.assign({}, prevState.updatedBook, {
                image: '',
                imageUrl: null,
            });
            return {updatedBook: newBook, customUrl: '', usingUrlImage: false, usingServerImage: false };
        });
        var photoChooser: HTMLElement | null = document.getElementById('photoUpload');
        if (photoChooser != null && photoChooser instanceof HTMLFormElement) {
            photoChooser.value = '';
        }
    }

    validateForm = (book: IBook) => {
        if (!book.name || book.name.trim() === '') {
            toastr.error('Book name cannot be empty.');
            return false;
        }
        if (book.totalCopies === 0) {
            toastr.error('At least one copy of the book must exist.');
            return false;
        }
        if (book.isbN13 && !validateISBN(book.isbN13.trim())) {
            toastr.error('Invalid ISBN-13');
            return false;
        }
        if (book.isbN10 && !validateISBN(book.isbN10.trim())) {
            toastr.error('Invalid ISBN-10');
            return false;
        }
        return true;
    }
    
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        book: store.booksReducer.book,
        books: store.booksReducer.books,
        authors: store.authorsReducer.authors,
        keywords: store.keywordReducer.keywords,
        genres: store.genreReducer.genres,
        languages: store.languageReducer.languages,
        publishers: store.publisherReducer.publishers,
        googleSearchResult: store.googleBooksReducer.searchResult,
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    getBook: (id: string) => dispatch(getBook(id)),
    updateBook: (book: IBook) => dispatch(updateBook(book)),
    listAllBooks: (sortBy: string, asc: boolean) => dispatch(listAllBooks(sortBy, asc)),
    addBook: (book: IBook) => dispatch(addBook(book)),
    listAllAuthors: () => dispatch(listAllAuthors()),
    listAllKeywords: () => dispatch(listAllKeywords()),
    listAllGenres: () => dispatch(listAllGenres()),
    listAllLanguages: () => dispatch( listAllLanguages()),
    listAllPublishers: () => dispatch(listAllPublishers()),
    searchGoogleBooks: (params: string, page: number) => dispatch(searchGoogleBooks(params, page))
});

export default connect(mapStateToProps, matchDispatchToProps)(BookForm);