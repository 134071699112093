import * as React from 'react';
import RankRow from './rank_row';
import IRank from '../../types/rank_type';
import { IClickEvent } from '../../types/react_types';
import IUser from '../../types/user_type';

interface IRanksTableProps {
    ranks: IRank[];
    loggedInUser: IUser | null;
    openEdit(rank: IRank, e: IClickEvent): void;
    openAreYouSureModal(func: Function, title: string): void;
    delete(rank: IRank): Promise<boolean>;
}

const RanksTable = (props: IRanksTableProps) => {
    let ranks = props.ranks;
    return (
        <table className="table table-striped ">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">Avatar</td>
                    <td className="bold">Name</td>
                    <td className="bold">Minimum checkouts</td>
                    <td className="bold">Action</td>
                </tr>
            </thead>
            <tbody>
                {ranks.map((rank: IRank, index: number) => 
                    <RankRow 
                        key={rank.id} 
                        number={index + 1} 
                        rank={rank} 
                        openEdit={props.openEdit} 
                        delete={props.delete}
                        loggedInUser={props.loggedInUser} 
                        openAreYouSureModal={props.openAreYouSureModal} 
                    />
                )}
            </tbody>
        </table>
    );
};

export default RanksTable;