import * as React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import IUser from '../../types/user_type';
import IBook from '../../types/book_type';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../helpers/date_helper';
import { isAllowed } from '../../helpers/checkAuthorization';
import { IFormEvent } from '../../types/react_types';

interface IFilterCheckoutsFormProps {
    loggedInUser: IUser | null;
    filterParams: {
        user: string;
        book: string;
        librarian: string;
        checkedOutFrom: Date | null;
        checkedOutTo: Date | null;
        returnedTo: Date | null;
        returnedFrom: Date | null;
        returned: string | null;
    };
    users: IUser[];
    books: IBook[];
    //tslint:disable-next-line:no-any
    handleInputChange(type: string, value: any): void;
    filter(e: IFormEvent): void;
    clearFilter(): void;
}

const FilterCheckoutsForm = (props: IFilterCheckoutsFormProps) => {
    return (
        <form className="filter-form" onSubmit={props.filter}>
            <div className="row row-item">
                {isAllowed(props.loggedInUser, 'ICheckoutService.CheckoutHistoryForOtherUser') ? 
                <div className="filter-col col-md-4">
                    <label>User:</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('user', value)}
                        placeholder="Select user"
                        simpleValue={true}
                        value={props.filterParams.user}
                        options={props.users.map((u: IUser) => {return {label: u.firstName + ' ' + u.lastName, value: u.id}; })}
                        name="users"
                    />
                </div> : ''}
                <div className="filter-col col-md-4">
                    <label>Book:</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('book', value)}
                        placeholder="Select book"
                        simpleValue={true}
                        value={props.filterParams.book}
                        options={props.books.map((b: IBook) => {return {label: b.name, value: b.id}; })}
                        name="books"
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Librarian:</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('librarian', value)}
                        placeholder="Select librarian"
                        simpleValue={true}
                        value={props.filterParams.librarian}
                        options={props.users
                            .filter((u: IUser) => (u.role && u.role.name.toLowerCase() === 'librarian'))
                            .map((u: IUser) => { 
                                return { label: u.firstName + ' ' + u.lastName, value: u.id}; 
                            })
                        }
                        name="librarians"
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Show:</label>
                    <Select 
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('returned', value)}
                        placeholder="Show"
                        simpleValue={true}
                        value={props.filterParams.returned || 'null'}
                        options={[
                            {label: 'All', value: 'null'}, 
                            {label: 'Only returned', value: 'returned'}, 
                            {label: 'Only unreturned', value: 'unreturned'}, 
                            {label: 'Only late', value: 'late'}]}
                        name="books"
                    />
                </div>
            </div>
            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Checked out from:</label>
                    <input 
                        type="date" 
                        name="checkedOutFrom"  
                        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"  
                        required={true}
                        value={
                            props.filterParams.checkedOutFrom ? 
                                formatDate(props.filterParams.checkedOutFrom && props.filterParams.checkedOutFrom.toString()) 
                                : formatDate(new Date().toString())
                        } 
                        className="form-control"   
                        //tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('checkedOutFrom', value)}
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Checked out to:</label>
                    <input 
                        type="date" 
                        name="checkedOutTo" 
                        required={true}  
                        value={
                            props.filterParams.checkedOutTo ? 
                                formatDate(props.filterParams.checkedOutTo && props.filterParams.checkedOutTo.toString()) 
                                : formatDate(new Date().toString())
                        } 
                        className="form-control"   
                        //tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('checkedOutTo', value)}
                    />
                </div>
            </div>
            {
                props.filterParams.returned !== 'false' ? 
                <div className="row row-item">
                    <div className="filter-col col-md-4">
                        <label>Returned from:</label>
                        <input 
                            type="date" 
                            name="returnedFrom" 
                            required={true}
                            value={
                                props.filterParams.returnedFrom ? 
                                    formatDate(props.filterParams.returnedFrom && props.filterParams.returnedFrom.toString()) 
                                    : formatDate(new Date().toString())
                            } 
                            className="form-control"   
                            //tslint:disable-next-line:no-any
                            onChange={(value: any) => props.handleInputChange('returnedFrom', value)}
                        />
                    </div>
                    <div className="filter-col col-md-4">
                        <label>Returned to:</label>
                        <input 
                            type="date" 
                            name="returnedTo"
                            required={true}
                            value={
                                props.filterParams.returnedTo ? 
                                    formatDate(props.filterParams.returnedTo && props.filterParams.returnedTo.toString()) 
                                    : formatDate(new Date().toString())
                            } 
                            className="form-control"   
                            //tslint:disable-next-line:no-any
                            onChange={(value: any) => props.handleInputChange('returnedTo', value)}
                        />
                    </div>
                </div>
                : ''
            }

            <div className="row-item">
                <button type="submit" className="btn btn-primary">Filter</button>&nbsp;
                <button type="button" className="btn btn-default" onClick={props.clearFilter}>Clear filter</button>
            </div>
        </form>
    );
};

export default FilterCheckoutsForm;