import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IGenre from '../../types/genre_type';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IGenreRowProps {
    genre: IGenre;
    number: number;
    loggedInUser: IUser | null;
    edit(genre: IGenre, e: IClickEvent): void;
    openAreYouSureModal(func: Function, title: string): void;
    delete(genre: IGenre): Promise<boolean>;
}

const GenreRow = (props: IGenreRowProps) => {
    let genre = props.genre;
    return (
        <tr 
            key={genre.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e: IClickEvent) => isAllowed(props.loggedInUser, 'IGenreService.Update') ? props.edit(genre, e) : {}}
        > 
            <td className="bold">{props.number}</td>
            <td>{genre.name}</td>
            <td>
            { isAllowed(props.loggedInUser, 'IGenreService.Delete') ?
                    <span className="remove-icon">
                        <i 
                            className="fa fa-trash-o fa-2x" 
                            onClick={() => props.openAreYouSureModal(() => props.delete(genre), 'Deleting genre ' + genre.name)} 
                            aria-hidden="true" 
                        />
                    </span> 
                    : <div/> }
            </td>
        </tr>
    );
};

export default GenreRow;