import { LIST_ALL_KEYWORDS_SUCCESS, ADD_KEYWORD_SUCCESS, UPDATE_KEYWORD_SUCCESS, DELETE_KEYWORD_SUCCESS }  from '../constants/action_types';
import IKeyword from '../types/keyword_type';
import { IAction } from '../types/react_types';

export interface IKeywordsReducer {
    keywords: IKeyword[];
}

var defaultState: IKeywordsReducer = {
    keywords : []
};

export default function (state: IKeywordsReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_KEYWORDS_SUCCESS: 
            return Object.assign({}, state, { keywords: action.payload.keywords });
        case ADD_KEYWORD_SUCCESS:
            let keywordsAfterCreate: IKeyword[] = state.keywords.slice();
            keywordsAfterCreate.push(action.payload.keyword);
            let a = Object.assign({}, state, { keywords: keywordsAfterCreate });
            return a;
        case UPDATE_KEYWORD_SUCCESS:
            let updatedKeyword = action.payload.keyword;
            let keywordsAfterUpdate = state.keywords.map(( keyword: IKeyword ) =>
                keyword.id === action.payload.keyword.id ? 
                Object.assign({}, keyword, updatedKeyword) : 
                keyword
            );
            return Object.assign({}, state, { keywords: keywordsAfterUpdate });
        case DELETE_KEYWORD_SUCCESS:
            let deletedKeyword = action.payload.keyword;
            let keywordsAfterDelete = state.keywords.filter ( (keyword: IKeyword) => keyword.id !== deletedKeyword.id  );
            return Object.assign({}, state, { keywords: keywordsAfterDelete });
        default:
            return state;
    } 
}