import IAuthor from '../types/author_type';
import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class AuthorsService {
    
    listAll() {
        return fetch(LINK_BASE + '/Authors', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IAuthor[]>);
    }

    get(id: string) {
        return fetch(LINK_BASE + '/Authors/' + id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IAuthor>);
    }

    create(author: IAuthor) {
        author.id = EMPTY_GUID;
        let authorString = JSON.stringify({
            id: EMPTY_GUID,
            name: author.name,
            nationality: author.nationality,
            yearOfBirth: author.yearOfBirth,
            pseudonym: author.pseudonym
        });
        return fetch(LINK_BASE + '/Authors', {
            method: 'POST',
            body: authorString,
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IAuthor>);
    }

    update(author: IAuthor) {
        return fetch(LINK_BASE + '/Authors', {
            method: 'PUT',
            body: JSON.stringify({
                id: author.id,
                name: author.name,
                nationality: author.nationality,
                yearOfBirth: author.yearOfBirth,
                pseudonym: author.pseudonym
            }),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IAuthor>);
    }

    delete(author: IAuthor) {
        return fetch(LINK_BASE + '/Authors/' + author.id, {
            method: 'DELETE',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    search(name: string, pseudonym: string, nationality: string) {
        return fetch(LINK_BASE + `/Authors/Search?name=${name}&pseudonym=${pseudonym}&nationality=${nationality}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IAuthor[]>);
    }

}

export default new AuthorsService();