import { LIST_ALL_LANGUAGES_SUCCESS, ADD_LANGUAGE_SUCCESS,UPDATE_LANGUAGE_SUCCESS, DELETE_LANGUAGE_SUCCESS } from '../constants/action_types';
import ILanguage from '../types/language_type';
import { IAction } from '../types/react_types';

export interface ILanguagesReducer {
    languages: ILanguage[];
}

var defaultState: ILanguagesReducer = {
    languages: []
};

export default function (state: ILanguagesReducer = defaultState , action: IAction) {
    switch (action.type) {
        case LIST_ALL_LANGUAGES_SUCCESS:
            return Object.assign({}, state, { languages: action.payload.languages });
        case ADD_LANGUAGE_SUCCESS:
            let languagesAfterCreate: ILanguage[] = state.languages.slice();
            languagesAfterCreate.push(action.payload.language);
            let a = Object.assign({}, state, { languages: languagesAfterCreate });
            return a;
        case UPDATE_LANGUAGE_SUCCESS:
            let updatedLanguage = action.payload.language;
            let languagesAfterUpdate = state.languages.map((language: ILanguage ) =>
                language.id === action.payload.language.id ? 
                    Object.assign({}, language, updatedLanguage) : 
                    language
            );
            return Object.assign({}, state, { languages: languagesAfterUpdate });
        case DELETE_LANGUAGE_SUCCESS:
            let deletedLanguage = action.payload.language;
            let languagesAfterDelete = state.languages.filter ( (language: ILanguage) => language.id !== deletedLanguage.id  );
            return Object.assign({}, state, { languages: languagesAfterDelete });
        default:
            return state;
    }
}