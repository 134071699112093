import * as React from 'react';
import IBook from '../../types/book_type';
import BookRow from './book_row';
import { History } from 'history';
import IUser from '../../types/user_type';

interface IBookTable {
    books: IBook[];
    loggedInUser: IUser | null;
    history: History;
    delete?(book: IBook): Promise<boolean>;
    openAreYouSureModal?(func: Function, title: string): void;
    renderRowActionButtons?(book: IBook): JSX.Element;
}

const BookTable = (props: IBookTable) => {
    return (
        <table className="table table-striped">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">Cover</td>
                    <td className="bold">Name</td>
                    <td className="bold">Author(s)</td>
                    <td className="bold">Edition</td>
                    <td className="bold">Genre(s)</td>
                    <td className="bold">ISBN10</td>
                    <td className="bold">ISBN13</td>
                    <td className="bold">Language</td>
                    <td className="bold">Publisher</td>
                    <td className="bold">Total copies</td>
                    <td className="bold">Available copies</td>
                    <td className="bold">Actions</td>
                </tr>
            </thead>
            <tbody>
                {props.books.map((book: IBook, index: number) => 
                    <BookRow 
                        key={book.id} 
                        number={index + 1}
                        book={book} 
                        actionButtons={props.renderRowActionButtons && props.renderRowActionButtons(book)}
                        delete={props.delete} 
                        history={props.history} 
                        loggedInUser={props.loggedInUser} 
                        openAreYouSureModal={props.openAreYouSureModal}
                    />
                )}
            </tbody>
        </table>
    );
};

export default BookTable;