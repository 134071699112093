import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import { LINK_BASE } from '../../constants/other_constants';
import IRank from '../../types/rank_type';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IRankRowProps {
    rank: IRank;
    number: number;
    loggedInUser: IUser | null;
    openEdit(rank: IRank, e: IClickEvent): void;
    openAreYouSureModal(func: Function, title: string): void;
    delete(rank: IRank): Promise<boolean>;
}

const RankRow = (props: IRankRowProps) => {
    let rank = props.rank;
    return (
        <tr 
            key={rank.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e) => isAllowed(props.loggedInUser, 'IRankService.Update') ? props.openEdit(rank, e) : {}}
        >
            <td className="bold">{props.number}</td>
            <td>
                <img 
                    src={rank.avatar ? LINK_BASE + rank.avatar : require('../../../images/no_image.png')} 
                    className="table-book-img" 
                    alt="Image not available" 
                />
            </td>
            <td>{rank.name}</td>
            <td>{rank.minimumCheckouts}</td>
            <td>
            { isAllowed(props.loggedInUser, 'IRankService.Delete') ?
                <span className="remove-icon">
                    <i 
                        className="fa fa-trash-o fa-2x" 
                        onClick={() => props.openAreYouSureModal(() => props.delete(rank), 'Deleting rank ' + rank.name)} 
                        aria-hidden="true"
                    />
                </span> 
                : <div/> 
            }
            </td>
        </tr>
    );
};

export default RankRow;