import * as React from 'react';
import { LINK_BASE } from '../../constants/other_constants';
import IBook from '../../types/book_type';

interface ITopBookProps {
    book: IBook;
}

const TopBook = (props: ITopBookProps) => {
    let book = props.book;
    return (
        <div className="card-item-box">
            <a className="card-cta" href={'/bookDetails/' + book.id}>
                <div className="card-item">
                    <div className="card-img">
                        <img src={book.image ? LINK_BASE + book.image : require('../../../images/no_image.png')} height="180" width="150" />
                    </div>
                    <div className="card-body">
                        <div className="entry">
                            <h4 className="main-title">{book.name}</h4>
                            {RatingToStars(book.rating)}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

function RatingToStars(rating: number) {
    let rounded = Math.round(rating * 2) / 2;
    let stars = [];
    for (let i = 0; i < 5; i++) {
        if (rounded === 0.5) {
            stars[i] = (<span key={i} className="fas fa-star-half fa-2x" />);
        } else if (rounded >= 1) {
            stars[i] = (<span key={i} className="fa fa-star fa-2x checked" />);
        } else {
            stars[i] = (<span key={i} className="fa fa-star-o fa-2x" />);
        }
        rounded -= 1;
    }
    return stars;
}

export default TopBook;