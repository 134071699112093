import * as React from 'react';
import { connect } from 'react-redux';
import { listAllAvailableBooks, checkoutBooks, searchAvailableBooks } from '../../actions/books_actions';
import { listAllActiveUsers, searchActiveUsers } from '../../actions/users_actions';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import ChooseUser from './choose_user';
import ChooseBooks from './choose_books';
import CheckoutSummary from './checkout_summary';
import ChooseUserButtons from './choose_user_buttons';
import ChooseBooksButtons from './choose_books_buttons';
import RemoveBooksButtons from './remove_books_buttons';
import * as toastr from 'toastr';
import { listAllAuthors } from '../../actions/authors_actions';
import IAuthor from '../../types/author_type';
import { EMPTY_GUID } from '../../constants/other_constants';
import ILanguage from '../../types/language_type';
import IGenre from '../../types/genre_type';
import IKeyword from '../../types/keyword_type';
import IPublisher from '../../types/publisher_type';
import { listAllLanguages } from '../../actions/languages_actions';
import { listAllPublishers } from '../../actions/publishers_actions';
import { listAllGenres } from '../../actions/genres_actions';
import { listAllKeywords } from '../../actions/keywords_actions';
import { validateISBN } from '../../helpers/ISBN_helper';
import { isAllowed } from '../../helpers/checkAuthorization';
import { History } from 'history';
import { IFormEvent, IChangeEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface ICreateCheckoutProps {
    books: IBook[];
    users: IUser[];
    booksLoading: boolean;
    usersLoading: boolean;
    authors: IAuthor[];
    history: History;
    languages: ILanguage[];
    genres: IGenre[];
    keywords: IKeyword[];
    publishers: IPublisher[];
    loggedInUser: IUser | null;
    listAllAuthors(): Promise<boolean>;
    listAllLanguages(): Promise<boolean>;
    listAllPublishers(): Promise<boolean>;
    listAllKeywords(): Promise<boolean>;
    listAllGenres(): Promise<boolean>;
    listAllAvailableBooks(sortBy: string, asc: boolean): Promise<boolean>;
    listAllActiveUsers(sortBy: string, asc: boolean): Promise<boolean>;
    checkoutBooks(userId: string, books: IBook[]): Promise<boolean>;
    searchActiveUsers(username: string, firstName: string, lastName: string, sortBy: string, asc: boolean): Promise<boolean>;
    searchAvailableBooks(
        name: string, startingRating: number, isbn13: string, isbn10: string, languageId: string, publisherId: string,
        genreId: string, keywordId: string, authorId: string, sortBy: string, asc: boolean
    ): Promise<boolean>;
}

interface ICreateCheckoutState {
    addedBooks: IBook[];
    user: IUser | null;
    showChooseUserMenu: boolean;
    showChooseBooksMenu: boolean;
    showCheckoutSummaryMenu: boolean;
    filterUsersParams: {
        username: string;
        firstName: string;
        lastName: string;
        sortBy: string;
        asc: boolean;
    };
    filterBooksParams: {
        name: string;
        startingRating: number;
        isbn13: string;
        isbn10: string;
        languageId: string;
        publisherId: string;
        genreId: string;
        keywordId: string;
        authorId: string;
        sortBy: string;
        asc: boolean
    };
}

class CreateCheckout extends React.Component<ICreateCheckoutProps, ICreateCheckoutState> {
    constructor(props: ICreateCheckoutProps) {
        super(props);
        this.state = {
            addedBooks: [],
            user: null,
            showChooseUserMenu: true,
            showChooseBooksMenu: false,
            showCheckoutSummaryMenu: false,
            filterUsersParams: {
                username: '',
                firstName: '',
                lastName: '',
                sortBy: 'name',
                asc: true
            },
            filterBooksParams: {
                name: '',
                startingRating: 0,
                isbn13: '',
                isbn10: '',
                languageId: '',
                publisherId: '',
                genreId: '',
                keywordId: '',
                authorId: '',
                sortBy: 'name',
                asc: true
            }
        };
    }

    public componentWillMount() {
        this.props.listAllAvailableBooks('name', true);
        this.props.listAllActiveUsers('name', true);
        this.props.listAllAuthors();
        this.props.listAllGenres();
        this.props.listAllKeywords();
        this.props.listAllLanguages();
        this.props.listAllPublishers();
    }

    public componentWillReceiveProps() {
        if (!isAllowed(this.props.loggedInUser, 'IBookService.CheckoutBookForSomeoneElse')) {
            this.setState({
                user: this.props.loggedInUser,
                showChooseUserMenu: false,
                showChooseBooksMenu: true
            });
        }
    }

    public render() {
        if (this.props.usersLoading || this.props.booksLoading) {
            return <h3>Loading...</h3>;
        }
        return (
            <div>
                <h1>Create new Checkout</h1>
                <ChooseUser 
                    show={this.state.showChooseUserMenu} 
                    users={this.props.users} 
                    isNextButtonDisabled={!this.state.user} 
                    next={this.goToChooseBooksMenu}
                    filterUsersParams={this.state.filterUsersParams} 
                    handleUserFilterParamsChange={this.handleUserFilterParamsChange} 
                    filterUsers={this.filterUsers}
                    clearUserFilter={this.clearUserFilter}
                    renderRowActionButtons={(user: IUser) => 
                        <ChooseUserButtons chosenUser={this.state.user} selectUser={this.selectUser} user={user} />
                    } 
                    loggedInUser={this.props.loggedInUser}
                />
                <ChooseBooks 
                    show={this.state.showChooseBooksMenu} 
                    books={this.props.books} 
                    isNextButtonDisabled={this.state.addedBooks.length === 0} 
                    next={this.goToCheckoutsSummaryMenu} 
                    back={this.goToChooseUserMenu} 
                    filterBooksParams={this.state.filterBooksParams} 
                    handleFilterBooksParamsChange={this.handleBooksFilterParamsChange}
                    filterBooks={this.filterBooks} 
                    clearBooksFilter={this.clearBooksFilter} 
                    authors={this.props.authors} 
                    handleSelectChange={this.handleSelectChange} 
                    renderBookRowActionButtons={(book: IBook) => 
                        <ChooseBooksButtons addedBooks={this.state.addedBooks} handleCheckboxChanged={this.handleCheckboxChanged} book={book} />
                    }
                    languages={this.props.languages}
                    genres={this.props.genres} 
                    keywords={this.props.keywords} 
                    publishers={this.props.publishers} 
                    loggedInUser={this.props.loggedInUser} 
                    history={this.props.history}
                />

                <CheckoutSummary 
                    show={this.state.showCheckoutSummaryMenu} 
                    submitCheckouts={this.submitCheckouts} 
                    back={this.goToChooseBooksMenu} 
                    user={this.state.user} 
                    addedBooks={this.state.addedBooks} 
                    renderBookRowActionButtons={(book: IBook) => <RemoveBooksButtons removeBook={this.removeBook} book={book} />}
                    history={this.props.history} 
                />
            </div>
        ); 
    }

    goToChooseUserMenu = () => {
        this.setState({
            showChooseUserMenu: true,
            showChooseBooksMenu: false,
            showCheckoutSummaryMenu: false
        });
    }

    goToChooseBooksMenu = () => {
        this.setState({
            showChooseUserMenu: false,
            showChooseBooksMenu: true,
            showCheckoutSummaryMenu: false
        });
    }

    goToCheckoutsSummaryMenu = () => {
        this.setState({
            showChooseUserMenu: false,
            showChooseBooksMenu: false,
            showCheckoutSummaryMenu: true
        });
    }

    selectUser = (user: IUser) => {
        this.setState({
            user: user
        });
    }

    handleCheckboxChanged = (wasAddedBefore: boolean, book: IBook) => {
        let newList;
        if (wasAddedBefore) {
            newList = this.state.addedBooks.filter(b => b.id !== book.id);
        } else {
            newList = this.state.addedBooks.slice();
            newList.push(book);
        }
        this.setState({
            addedBooks: newList
        });
    }

    removeBook = (book: IBook) => {
        this.handleCheckboxChanged(true, book);
    }

    submitCheckouts = () => {
        if (!this.state.user) {
          const reason = 'You haven\'t chosen a user to checkout the books to.';
          toastr.error(reason);
          return Promise.reject(reason);
        }
        if (this.state.addedBooks.length === 0) {
            const reason = 'You haven\'t chosen any books.';
            toastr.error(reason);
            return Promise.reject(reason);
        }
        return this.props.checkoutBooks(this.state.user.id, this.state.addedBooks)
            .then((success: boolean) => {
                if (success) {
                    this.props.history.push('/checkouts/' + EMPTY_GUID + '/' + EMPTY_GUID);
                }
            });
    }

    handleUserFilterParamsChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newSearch = Object.assign({}, prevState.filterUsersParams, {
                [name]: value
            });
            return {filterUsersParams: newSearch};
        });
    }

    handleBooksFilterParamsChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newSearch = Object.assign({}, prevState.filterBooksParams, {
                [name]: value
            });
            return {filterBooksParams: newSearch};
        });
    }

    filterUsers = (event: IFormEvent) => {
        event.preventDefault();
        let {username, firstName, lastName, sortBy, asc} = this.state.filterUsersParams;
        this.props.searchActiveUsers(username, firstName, lastName, sortBy, asc);
    }

    filterBooks = (e: IFormEvent) => {
        e.preventDefault();
        let {name, startingRating, isbn13, isbn10, languageId, publisherId, genreId, keywordId, authorId, sortBy, asc} = this.state.filterBooksParams;
        if (isbn10 && !validateISBN(isbn10)) {
            toastr.error('Invalid ISBN10');
            return;
        }
        if (isbn13 && !validateISBN(isbn13)) {
            toastr.error('Invalid ISBN13');
            return;
        }
        if (isbn10.toString().indexOf('-') >= 0) {
            isbn10 = isbn10.replace(/[^\dX]/gi, '');
            if (isbn10.length !== 10) {
                toastr.error('Invalid ISBN10');
                return;
            }
        }
        if (isbn13.toString().indexOf('-') >= 0) {
            isbn13 = isbn13.replace(/[^\dX]/gi, '');
            if (isbn13.length !== 13) {
                toastr.error('Invalid ISBN13');
                return;
            }
        }
        this.props.searchAvailableBooks(name, startingRating, isbn13, isbn10, languageId, publisherId, genreId, keywordId, authorId, sortBy, asc);
    }

    clearUserFilter = () => {
        this.props.searchActiveUsers('', '', '', this.state.filterUsersParams.sortBy, this.state.filterUsersParams.asc);
        this.setState((prevState: ICreateCheckoutState) => {
            let newFilterUsersParams = Object.assign({}, prevState.filterUsersParams, {
                firstName: '',
                lastName: '',
                username: ''
            });
            return {filterUsersParams: newFilterUsersParams};
        });
    }

    clearBooksFilter = () => {
        this.props.searchAvailableBooks('', 0, '', '', '', '', '', '', '', 'name', true);
        this.setState((prevState: ICreateCheckoutState) => {
            let newFilterBooksParams = Object.assign({}, prevState.filterBooksParams, {
                name: '',
                startingRating: 0,
                isbn13: 0,
                isbn10: 0,
                languageId: null,
                publisherId: null,
                genreId: null,
                keywordId: null,
                authorId: null
            });
            return {filterBooksParams: newFilterBooksParams};
        });
    }

    //tslint:disable-next-line:no-any
    handleSelectChange = (value: any, type: string) => {
        this.setState((prevState) => {
            let newFilter = Object.assign({}, prevState.filterBooksParams, {[type]: value});
            return {filterBooksParams: newFilter};
        });
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        books: store.booksReducer.books,
        users: store.usersReducer.activeUsers,
        booksLoading: store.booksReducer.booksLoading,
        usersLoading: store.usersReducer.usersLoading,
        authors: store.authorsReducer.authors,
        keywords: store.keywordReducer.keywords,
        genres: store.genreReducer.genres,
        publishers: store.publisherReducer.publishers,
        languages: store.languageReducer.languages,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllAvailableBooks: (sortBy: string, asc: boolean) => dispatch(listAllAvailableBooks(sortBy, asc)),
    listAllActiveUsers: (sortBy: string, asc: boolean) => dispatch(listAllActiveUsers(sortBy, asc)),
    checkoutBooks: (userId: string, books: IBook[]) => dispatch(checkoutBooks(userId, books)),
    searchActiveUsers: (username: string, firstName: string, lastName: string, sortBy: string, asc: boolean) => 
        dispatch(searchActiveUsers(username, firstName, lastName, sortBy, asc)),
    searchAvailableBooks: (
        name: string, startingRating: number, isbn13: string, isbn10: string, languageId: string, publisherId: string,
        genreId: string, keywordId: string, authorId: string, sortBy: string, asc: boolean
    ) => dispatch(searchAvailableBooks(name, startingRating, isbn13, isbn10, languageId, publisherId, genreId, keywordId, authorId, sortBy, asc)),
    listAllAuthors: () => dispatch(listAllAuthors()),
    listAllGenres: () => dispatch(listAllGenres()),
    listAllLanguages: () => dispatch(listAllLanguages()),
    listAllKeywords: () => dispatch(listAllKeywords()),
    listAllPublishers: () => dispatch(listAllPublishers()),
});

export default connect(mapStateToProps, matchDispatchToProps)(CreateCheckout);
