import * as React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, NavItem } from 'react-bootstrap';
import { EMPTY_GUID } from '../constants/other_constants';
import { isAllowed } from '../helpers/checkAuthorization';
import { LinkContainer } from 'react-router-bootstrap';
import IUser from '../types/user_type';

interface INavMenuProps {
    loggedInUser: IUser | null;
    logout(): void;
}

const NavMenu = (props: INavMenuProps) => {
        if (!props.loggedInUser || window.location.href.endsWith('/login')) {
            return <div/>;
        }
        let user = props.loggedInUser;
        let userId = user ? user.id : '';
        return (
            <Navbar inverse={true} fluid={true}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link className="navbar-brand-link" to={'/'}>
                            <div className="vega-logo">
                                <img src={require('../../images/logo-white.png')} alt=""/>
                            </div>
                        </Link>
                    </Navbar.Brand>
                </Navbar.Header>
                <Nav>
                    <LinkContainer to={'/books'}>
                        <NavItem>
                            <span className="glyphicon glyphicon-book"/> Books
                            </NavItem>
                    </LinkContainer>
                    { isAllowed(user, 'IBookService.Update') ?
                    <NavDropdown id="bookStuffDropdown" title="Book properties">
                    { isAllowed(user, 'IAuthorService.Update') ?
                        <LinkContainer to={'/authors'}>
                            <NavItem>
                                <span className="glyphicon glyphicon-education"/> Authors
                            </NavItem>
                        </LinkContainer> : ''}
                    { isAllowed(user, 'ILanguageService.Update') ?
                        <LinkContainer to={'/languages'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-font"/> Languages
                            </NavItem>
                        </LinkContainer> : ''}
                    { isAllowed(user, 'IKeywordService.Update') ?
                        <LinkContainer to={'/keywords'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-paperclip"/> Keywords
                            </NavItem>
                        </LinkContainer> : ''}
                    { isAllowed(user, 'IGenreService.Update') ?
                        <LinkContainer to={'/genres'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-folder-open"/> Genres
                            </NavItem>
                        </LinkContainer> : ''}
                    { isAllowed(user, 'IPublisherService.Update') ?
                        <LinkContainer to={'/publishers'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-home"/> Publishers
                            </NavItem>
                        </LinkContainer> : ''}
                    </NavDropdown>
                    : ''}
                    { isAllowed(user, 'IUserService.ListAll') ?
                    <NavDropdown id="userStuffDropdown" title="Manage users">
                        <LinkContainer to={'/users'} >
                            <NavItem>	
                                <span className="glyphicon glyphicon-user"/> Users
                            </NavItem>
                        </LinkContainer>
                        { isAllowed(user, 'IRoleService.Update') ?
                        <LinkContainer to={'/roles'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-th-list"/> Roles
                            </NavItem>
                        </LinkContainer> : ''}
                        { isAllowed(user, 'IRoleService.Update') ?
                        <LinkContainer to={'/permissions'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-list-alt"/> Permissions
                            </NavItem>
                        </LinkContainer> : ''}
                        { isAllowed(user, 'IRankService.Update') ?
                        <LinkContainer to={'/ranks'} >
                            <NavItem>
                                <span className="glyphicon glyphicon-sort-by-attributes-alt"/> Ranks
                            </NavItem>
                        </LinkContainer> : ''}
                    </NavDropdown>
                    : ''}
                    { isAllowed(user, 'ICheckoutService.Get') ?
                    <NavDropdown id="checkoutStuffDropdown" title="Manage checkouts">
                        { isAllowed(user, 'IBookService.CheckoutBook') ?
                        <LinkContainer to={'/createCheckout'}>
                        <NavItem>
                            <span className="glyphicon glyphicon glyphicon-shopping-cart"/> Create Checkout
                            </NavItem>
                        </LinkContainer> : ''}
                        { isAllowed(user, 'ICheckoutService.ListAll') ?
                        <LinkContainer to={'/checkouts/' +  EMPTY_GUID + '/' + EMPTY_GUID} >
                            <NavItem>
                                <span className="glyphicon glyphicon glyphicon-stats"/> Checkouts
                            </NavItem>
                        </LinkContainer> : ''}
                        { isAllowed(user, 'ICheckoutService.History') ?
                        <LinkContainer to={'/checkouts/' + EMPTY_GUID + '/' + user.id} >
                            <NavItem>
                                <span className="glyphicon glyphicon-hourglass"/> My checkout history
                            </NavItem>
                        </LinkContainer> : ''}
                    </NavDropdown>
                    : ''}
                    <NavDropdown id="subscriptionStuffDropdown" title="Manage subscriptions">
                    { isAllowed(user, 'IBookService.ListAllSubscriptions') ?
                    <LinkContainer to={'/subscriptions/' + EMPTY_GUID + '/' + EMPTY_GUID} >
                            <NavItem>
                                <span className="glyphicon glyphicon glyphicon-stats"/> Subscriptions
                            </NavItem>
                    </LinkContainer> : ''}
                    { isAllowed(user, 'IBookService.SearchSubscriptions') ?
                    <LinkContainer to={'/subscriptions/' + EMPTY_GUID + '/' + user.id} >
                            <NavItem>
                                <span className="glyphicon glyphicon-hourglass"/> My subscriptions
                            </NavItem>
                    </LinkContainer> : ''}
                    </NavDropdown>
                    { isAllowed(user, 'IWishlistService.Create') ?
                    <LinkContainer to={'/wishlist'}>
                        <NavItem>
                            <span className="glyphicon glyphicon-star"/> Wishlist
                        </NavItem>
                    </LinkContainer> : ''}
                    { isAllowed(user, 'IConfigurationService.GetCheckoutDuration') ?
                    <LinkContainer to={'/configurations'}>
                        <NavItem>
                            <span className="glyphicon glyphicon-wrench"/> Configurations
                        </NavItem>
                    </LinkContainer> : ''}
                    <LinkContainer to={'/toplist'}>
                        <NavItem>
                            <span className="glyphicon glyphicon-flag"/> Top list
                        </NavItem>
                    </LinkContainer>
                </Nav>

                <Navbar.Form pullRight={true}>
                    <a href={'/profile/' + userId} className="no-decoration">
                        {(user && user.firstName) ? user.firstName + ' ' + user.lastName : ''}
                    </a>
                    &nbsp;&nbsp;
                    <Button type="button" onClick={() => props.logout()}>Log out</Button>
                </Navbar.Form>

            </Navbar>
        );
};

export default NavMenu;