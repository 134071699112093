import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IRole from '../../types/role_type';
import { listAllUsers, deactivateUser, activateUser, changeRole, searchUsers } from '../../actions/users_actions';
import { subscribeUserToAll, unsubscribeUserFromAll } from '../../actions/subscriptions_actions';
import { listAllRoles } from '../../actions/roles_actions';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import UserTable from './user_table';
import ChangeRoleModal from './change_role_modal';
import EditUserButtons from './edit_user_buttons';
import FilterUsersForm from './filter_users_form';
import SubscribeUserButtons from './subscribe_user_buttons';
import AreYouSureModal from '../are_you_sure_modal';
import ExpandButton from '../shared/expand_button';
import { History } from 'history';
import { IClickEvent, IAnyTargetType, IChangeEvent, IFormEvent, IThunkDispatch, IThunk } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IUsersProps {
    users: IUser[];
    usersLoading: boolean;
    roles: IRole[];
    rolesLoading: boolean;
    loggedInUser: IUser | null;
    history: History;
    listAllUsers(sortBy?: string, asc?: boolean): Promise<boolean>;
    deactivateUser(user: IUser): Promise<boolean>;
    activateUser(user: IUser): Promise<boolean>;
    changeRole(user: IUser, role: IRole): Promise<boolean>;
    listAllRoles(): Promise<boolean>;
    searchUsers(username: string, firstName: string, lastName: string, sortBy: string, asc: boolean): Promise<boolean>;
    subscribeUserToAll(userId: string): Promise<boolean>;
    unsubscribeUserFromAll(userId: string): Promise<boolean>;
}

interface IUsersState {
    isModalOpen: boolean;
    user: IUser | null;
    role: IRole | null | undefined;
    filterUsersParams: {
        username: string;
        firstName: string;
        lastName: string;
        sortBy: string;
        asc: boolean;
    };
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
    filterExpanded: boolean;
}

class Users extends React.Component<IUsersProps, IUsersState> {
    constructor(props: IUsersProps) {
        super(props);
        this.state = {
            isModalOpen: false,
            user: null,
            role: null,
            filterUsersParams: {
                username: '',
                firstName: '',
                lastName: '',
                sortBy: 'name',
                asc: true
            },
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            },
            filterExpanded: false
        };
    }

    public componentWillMount() {
        this.props.listAllUsers('name', true);
    }

    public render() {
        if (this.props.usersLoading) {
            return <h3>Loading...</h3>;
        } else {
            let modal = this.state.isModalOpen ? 
                (
                <ChangeRoleModal 
                    role={this.state.role} 
                    roles={this.props.roles} 
                    user={this.state.user} 
                    isModalOpen={this.state.isModalOpen} 
                    rolesLoading={this.props.rolesLoading} 
                    changeRole={this.changeRole}
                    handleSelectInModalChange={this.handleSelectInModalChange} 
                    closeModal={this.closeModal} 
                />)
                : <div/>;
            return (
                <div>
                    <div className="col-md-12">
                        <h1>List of users:</h1>
                        { isAllowed(this.props.loggedInUser, 'IAuthorService.Search') ?
                            <div>
                                <h3>
                                Filter users:&nbsp;&nbsp;
                                <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                                </h3>
                                {
                                this.state.filterExpanded ? 
                                    <FilterUsersForm 
                                        filterParams={this.state.filterUsersParams} 
                                        handleInputChange={this.handleUserFilterParamsChange}
                                        clearFilter={this.clearUserFilter} 
                                        filter={this.filterUsers} 
                                    />
                                : null
                                }
                            </div>
                            : <div/>
                        }
                        { isAllowed(this.props.loggedInUser, 'IAuthorService.ListAll') ?
                            <UserTable 
                                users={this.props.users} 
                                loggedInUser={this.props.loggedInUser} 
                                renderRowActionButtons={(user: IUser) => 
                                    <EditUserButtons
                                        activateUser={this.props.activateUser} 
                                        deactivateUser={this.props.deactivateUser} 
                                        openChangeRoleModal={this.openChangeRoleModal}
                                        openAreYouSureModal={this.openAreYouSureModal} 
                                        loggedInUser={this.props.loggedInUser}
                                        user={user}
                                    />
                                } 
                                renderRowSubscriptionButtons={(user: IUser) =>
                                    <SubscribeUserButtons 
                                        subscribeUserToAll={this.props.subscribeUserToAll} 
                                        unsubscribeUserFromAll={this.props.unsubscribeUserFromAll}
                                        loggedInUser={this.props.loggedInUser}
                                        user={user}
                                    />
                                }
                                rowClicked={this.rowClicked} 
                            />
                            : <div/>
                        }
                    </div>
                    {modal}
                    <AreYouSureModal 
                        title={this.state.areYouSureModalParams.title} 
                        isOpen={this.state.areYouSureModalParams.isOpen} 
                        action={this.state.areYouSureModalParams.action}
                        close={this.closeAreYouSureModal} 
                        description={null} 
                    />
                </div>
            );
        }
    }

    toggleExpandFilter = () => {
      this.setState(prevState => ({
        filterExpanded: !prevState.filterExpanded
      }));
    }

    rowClicked = (e: IClickEvent, user: IUser) => {
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'BUTTON') {
            this.props.history.push('/profile/' + user.id);
        }
    }

    handleUserFilterParamsChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newSearch = Object.assign({}, prevState.filterUsersParams, {
                [name]: value
            });
            return {filterUsersParams: newSearch};
        });
    }

    filterUsers = (event: IFormEvent) => {
        event.preventDefault();
        let {username, firstName, lastName, sortBy, asc} = this.state.filterUsersParams;
        this.props.searchUsers(username, firstName, lastName, sortBy, asc);
    }

    clearUserFilter = () => {
        this.props.searchUsers('', '', '', this.state.filterUsersParams.sortBy, this.state.filterUsersParams.asc);
        this.setState((prevState: IUsersState) => {
            let newFilterUsersParams = Object.assign({}, prevState.filterUsersParams, {
                firstName: '',
                lastName: '',
                username: ''
            });
            return {filterUsersParams: newFilterUsersParams};
        });
    }

    openChangeRoleModal = (user: IUser) => {
        this.props.listAllRoles()
            .then((good: boolean) => {
                if (good) {
                    this.setState({
                        isModalOpen: true,
                        user: user,
                        role: user.role ? this.props.roles.find((role) => role.id === user.role.id) : null
                    });
                }
            });
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    }

    handleSelectInModalChange = (e: IChangeEvent) => {
        const target: IAnyTargetType = e.target;
        let roleId = target.value;
        let newRole = this.props.roles.find((role) => role.id === roleId);
        this.setState({
            role: newRole
        });
    }

    changeRole = () => {
        if (this.state.user && this.state.role) {
            return this.props.changeRole(this.state.user, this.state.role)
            .then((good: boolean) => {
                if (good) {
                    this.closeModal();
                }
            });
        } else {
            return Promise.reject();
        }
    }

    subscribeUserToAll = (userId: string) => {
        this.props.subscribeUserToAll(userId);
    }

    unsubscribeUserFromAll = (userId: string) => {
        this.props.unsubscribeUserFromAll(userId);
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IUsersState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IUsersState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        users: store.usersReducer.users,
        usersLoading: store.usersReducer.usersLoading,
        roles: store.rolesReducer.roles,
        rolesLoading: store.rolesReducer.rolesLoading,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllUsers: (sortBy?: string, asc?: boolean) => dispatch(listAllUsers(sortBy, asc)),
    deactivateUser: (user: IUser) => dispatch(deactivateUser(user)),
    activateUser: (user: IUser) => dispatch(activateUser(user)),
    changeRole: (user: IUser, role: IRole) => dispatch(changeRole(user, role)),
    listAllRoles: () => dispatch(listAllRoles()),
    searchUsers: (username: string, firstName: string, lastName: string, sortBy: string, asc: boolean) =>
        dispatch(searchUsers(username, firstName, lastName, sortBy, asc)),
    subscribeUserToAll: (userId: string) => dispatch(subscribeUserToAll(userId)),
    unsubscribeUserFromAll: (userId: string) => dispatch(unsubscribeUserFromAll(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);