import { CREATE_RANK_SUCCESS, UPDATE_RANK_SUCCESS, DELETE_RANK_SUCCESS, 
    LIST_ALL_RANKS_LOADING, LIST_ALL_RANKS_SUCCESS } from '../constants/action_types';
import IRank from '../types/rank_type';
import { IAction } from '../types/react_types';

export interface IRanksReducer {
    ranks: IRank[];
    ranksLoading: boolean;
}

var defaultState: IRanksReducer = {
    ranks: [],
    ranksLoading: false
};

export default function (state: IRanksReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_RANKS_LOADING:
        case LIST_ALL_RANKS_SUCCESS:
            return Object.assign({}, state, action.payload);
        case CREATE_RANK_SUCCESS:
            let ranksAfterCreate: IRank[] = state.ranks.slice();
            ranksAfterCreate.push(action.payload.rank);
            return Object.assign({}, state, {ranks: ranksAfterCreate});
        case UPDATE_RANK_SUCCESS:
            let updatedRank = action.payload.rank;
            let ranksAfterUpdate = state.ranks.map((r: IRank) => r.id === action.payload.rank.id ? 
                Object.assign({}, r, updatedRank) : r);
            return Object.assign({}, state, {ranks: ranksAfterUpdate});
        case DELETE_RANK_SUCCESS:
            let deletedRank = action.payload.rank;
            let ranksAfterDelete: IRank[] = state.ranks.slice();
            let index = state.ranks.findIndex((rank: IRank) => rank.id === deletedRank.id);
            ranksAfterDelete.splice(index, 1);
            return Object.assign({}, state, {ranks: ranksAfterDelete});
        default:
            return state;
    }
}