import { LIST_ALL_KEYWORDS_SUCCESS, ADD_KEYWORD_SUCCESS, UPDATE_KEYWORD_SUCCESS, DELETE_KEYWORD_SUCCESS } from '../constants/action_types';
import  IKeyword from '../types/keyword_type';
import keywordsService from '../services/keywords_service';
import { catchError } from '../helpers/error_helper';
import * as toastr from 'toastr';
import { IThunkDispatch } from '../types/react_types';

export function listAllKeywords() {
    return function ( dispatch: IThunkDispatch ) {
        return keywordsService.listAllKeywords()
        .then(keywords => {
            dispatch(listAllKeywordsSuccess(keywords));
            return true;
        })
        .catch(catchError);
    };
}

export function listAllKeywordsSuccess (keywords: IKeyword[]) {
    return {
        type: LIST_ALL_KEYWORDS_SUCCESS,
        payload: {
            keywords: keywords
        }
    };
}

export function addKeyword(keyword: IKeyword) {
    return function (dispatch: IThunkDispatch) {
        return keywordsService.addKeyword(keyword)
        .then(keywordNew => {
            toastr.success('Keyword created');
            dispatch(addKeywordSuccess(keywordNew));
            return true;
        })
        .catch(catchError);
    };
}

export function addKeywordSuccess(keyword: IKeyword) {
    return {
        type: ADD_KEYWORD_SUCCESS,
        payload: {
            keyword: keyword
        }
    };
}

export function updateKeyword(keyword: IKeyword) {
    return function (dispatch: IThunkDispatch) {
        return keywordsService.updateKeyword(keyword)
        .then(keywordNew => {
            toastr.success('Keyword updated');
            dispatch(updateKeywordSuccess(keywordNew));
            return true;
        })
        .catch(catchError);
    };
}

export function updateKeywordSuccess(keyword: IKeyword) {
    return {
        type: UPDATE_KEYWORD_SUCCESS,
        payload: {
            keyword: keyword
        }
    };
}

export function deleteKeyword(keyword: IKeyword) {
    return function (dispatch: IThunkDispatch) {
        return keywordsService.deleteKeyword(keyword)
        .then( () => {
            toastr.success('Keyword deleted');
            dispatch(deleteKeywordSuccess(keyword));
            return true;
        })
        .catch(catchError);
    };
}

export function deleteKeywordSuccess (keyword: IKeyword) {
    return {
        type: DELETE_KEYWORD_SUCCESS,
        payload: {
            keyword: keyword
        }
    };
}