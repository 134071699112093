import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import ILanguage from '../../types/language_type';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface ILanguageRowProps {
    language: ILanguage;
    number: number;
    loggedInUser: IUser | null;
    edit(lang: ILanguage, e: IClickEvent): void;
    openAreYouSureModal(func: Function, title: string): void;
    delete(lang: ILanguage): Promise<boolean>;
}

const LanguageRow = (props: ILanguageRowProps) =>  {
    let language = props.language;
    return (
        <tr 
            key={language.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e) => isAllowed(props.loggedInUser, 'ILanguageService.Update') ? props.edit(language, e) : {}}
        >
            <td className="bold">{props.number}</td>
            <td>{language.name}</td>
            <td>
                { isAllowed(props.loggedInUser, 'ILanguageService.Delete') ?
                    <span className="remove-icon">
                        <i 
                            className="fa fa-trash-o fa-2x" 
                            onClick={() => props.openAreYouSureModal(() => props.delete(language), 'Deleting language ' + language.name)} 
                            aria-hidden="true"
                        />
                    </span> 
                    : <div/> 
                }
            </td>
        </tr>
    );
};

export default LanguageRow;