export const LIST_ALL_AUTHORS_LOADING = 'LIST_ALL_AUTHORS_LOADING';
export const CREATE_AUTHOR_SUCCESS = 'CREATE_AUTHOR_SUCCESS';
export const UPDATE_AUTHOR_SUCCESS = 'UPDATE_AUTHOR_SUCCESS';
export const DELETE_AUTHOR_SUCCESS = 'DELETE_AUTHOR_SUCCESS';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS';
export const LIST_ALL_USERS_LOADING = 'LIST_ALL_USERS_LOADING';
export const LIST_ALL_USERS_SUCCESS = 'LIST_ALL_USERS_SUCCESS';
export const LIST_ALL_ROLES_LOADING = 'LIST_ALL_ROLES_LOADING';
export const LIST_ALL_ROLES_SUCCESS = 'LIST_ALL_ROLES_SUCCESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_LOADING = 'GET_ROLE_LOADING';
export const LIST_ALL_PERMISSIONS_LOADING = 'LIST_ALL_PERMISSIONS_LOADING';
export const LIST_ALL_PERMISSIONS_SUCCESS = 'LIST_ALL_PERMISSIONS_SUCCESS';
export const LIST_ALL_RANKS_LOADING = 'LIST_ALL_RANKS_LOADING';
export const LIST_ALL_RANKS_SUCCESS = 'LIST_ALL_RANKS_SUCCESS';
export const CREATE_RANK_SUCCESS = 'CREATE_RANK_SUCCESS';
export const UPDATE_RANK_SUCCESS = 'UPDATE_RANK_SUCCESS';
export const DELETE_RANK_SUCCESS = 'DELETE_RANK_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LIST_ALL_AUTHORS_SUCCESS = 'LIST_ALL_AUTHORS_SUCCESS';
export const LIST_ALL_LANGUAGES_SUCCESS = 'LIST_ALL_LANGUAGES_SUCCESS';
export const ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';
export const LIST_ALL_KEYWORDS_SUCCESS = 'LIST_ALL_KEYWORDS_SUCCESS';
export const ADD_KEYWORD_SUCCESS = 'ADD_KEYWORD_SUCCESS';
export const DELETE_KEYWORD_SUCCESS = 'DELETE_KEYWORD_SUCCESS';
export const UPDATE_KEYWORD_SUCCESS = 'UPDATE_KEYWORD_SUCCESS';
export const LIST_ALL_GENRES_SUCCESS = 'LIST_ALL_GENRES_SUCCESS';
export const ADD_GENRE_SUCCESS = 'ADD_GENRE_SUCCESS';
export const UPDATE_GENRE_SUCCESS = 'UPDATE_GENRE_SUCCESS';
export const DELETE_GENRE_SUCCESS = 'DELETE_GENRE_SUCCESS';
export const LIST_ALL_PUBLISHERS_SUCCESS = 'LIST_ALL_PUBLISHERS_SUCCESS';
export const ADD_PUBLISHER_SUCCESS = 'ADD_PUBLISHER_SUCCESS';
export const UPDATE_PUBLISHER_SUCCESS = 'UPDATE_PUBLISHER_SUCCESS';
export const DELETE_PUBLISHER_SUCCESS = 'DELETE_PUBLISHER_SUCCESS';
export const LIST_ALL_BOOKS_SUCCESS = 'LIST_ALL_BOOKS_SUCCESS';
export const ADD_BOOK_SUCCESS = 'ADD_BOOK_SUCCESS';
export const GET_BOOK_SUCCESS = 'GET_BOOK_SUCCESS';
export const UPDATE_BOOK_SUCCESS = 'UPDATE_BOOK_SUCCESS';
export const DELETE_BOOK_SUCCESS = 'DELETE_BOOK_SUCCESS';
export const RATE_BOOK_SUCCESS = 'RATE_BOOK_SUCCESS';
export const GET_USER_RATING_SUCCESS = 'GET_USER_RATING_SUCCESS';
export const LOAD_USER_FROM_LOCAL_STORAGE = 'LOAD_USER_FROM_LOCAL_STORAGE';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_CHECKOUT_SUCCESS = 'DELETE_CHECKOUT_SUCCESS';
export const CHECKOUT_BOOKS_SUCCESS = 'CHECKOUT_BOOKS_SUCCESS';
export const LIST_ALL_BOOKS_LOADING = 'LIST_ALL_BOOKS_LOADING';
export const LIST_ALL_CHECKOUTS_LOADING = 'LIST_ALL_CHECKOUTS_LOADING';
export const LIST_ALL_CHECKOUTS_SUCCESS = 'LIST_ALL_CHECKOUTS_SUCCESS';
export const RETURN_BOOK_SUCCESS = 'RETURN_BOOK_SUCCESS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_LOADING = 'SEARCH_USERS_LOADING';
export const SEARCH_BOOKS_SUCCESS = 'SEARCH_BOOKS_SUCCESS';
export const SEARCH_BOOKS_LOADING = 'SEARCH_BOOKS_LOADING';
export const RECOMMEND_BOOKS_SUCCESS = 'RECOMMEND_BOOKS_SUCCESS';
export const SEARCH_AUTHORS_SUCCESS = 'SEARCH_AUTHORS_SUCCESS';
export const SEARCH_CHECKOUTS_SUCCESS = 'SEARCH_CHECKOUTS_SUCCESS';
export const IS_SUBSCRIBED_SUCCESS = 'IS_SUBSCRIBED_SUCCESS';
export const LIST_ALL_SUBSCRIPTIONS_START = 'LIST_ALL_SUBSCRIPTIONS_START';
export const LIST_ALL_SUBSCRIPTIONS_SUCCESS = 'LIST_ALL_SUBSCRIPTIONS_SUCCESS';
export const SAVE_SUBSCRIPTION_SUCCESS = 'SAVE_SUBSCRIPTION_SUCCESS';
export const CREATE_WISH_SUCCESS = 'CREATE_WISH_SUCCESS';
export const DELETE_WISH_SUCCESS = 'DELETE_WISH_SUCCESS';
export const LIST_ALL_WISHES_LOADING = 'LIST_ALL_WISHES_LOADING';
export const LIST_ALL_WISHES_SUCCESS = 'LIST_ALL_WISHES_SUCCESS';
export const SEARCH_WISHES_SUCCESS = 'SEARCH_WISHES_SUCCESS';
export const SEARCH_GOOGLE_BOOKS_LOADING = 'SEARCH_GOOGLE_BOOKS_LOADING';
export const SEARCH_GOOGLE_BOOKS_SUCCESS = 'SEARCH_GOOGLE_BOOKS_SUCCESS';
export const EMPTY_GOOGLE_BOOKS_SEARCH = 'EMPTY_GOOGLE_BOOKS_SEARCH';
export const GET_CHECKOUT_DURATION_SUCCESS = 'GET_CHECKOUT_DURATION_SUCCESS';
export const CHANGE_CHECKOUT_DURATION_SUCCESS = 'CHANGE_CHECKOUT_DURATION_SUCCESS';
export const SEARCH_AVAILABLE_BOOKS_LOADING = 'SEARCH_AVAILABLE_BOOKS_LOADING';
export const SEARCH_AVAILABLE_BOOKS_SUCCESS = 'SEARCH_AVAILABLE_BOOKS_SUCCESS';
export const LIST_ALL_AVAILABLE_BOOKS_LOADING = 'LIST_ALL_AVAILABLE_BOOKS_LOADING';
export const LIST_ALL_AVAILABLE_BOOKS_SUCCESS = 'LIST_ALL_AVAILABLE_BOOKS_SUCCESS';
export const LIST_ALL_ACTIVE_USERS_SUCCESS = 'LIST_ALL_ACTIVE_USERS_SUCCESS';
export const SEARCH_ACTIVE_USERS_SUCCESS = 'SEARCH_ACTIVE_USERS_SUCCESS';
export const EMPTY_CHECKOUTS_LIST = 'EMPTY_CHECKOUTS_LIST';
export const GET_TOP_5_MOST_READ_BOOKS_LOADING = 'GET_TOP_5_MOST_READ_BOOKS_LOADING';
export const GET_TOP_5_BEST_RATED_BOOKS_LOADING = 'GET_TOP_5_BEST_RATED_BOOKS_LOADING';
export const GET_TOP_5_MOST_READ_BOOKS_SUCCESS = 'GET_TOP_5_MOST_READ_BOOKS_SUCCESS';
export const GET_TOP_5_BEST_RATED_BOOKS_SUCCESS = 'GET_TOP_5_BEST_RATED_BOOKS_SUCCESS';
export const GET_TOP_5_MOST_ACTIVE_USERS_LOADING = 'GET_TOP_5_MOST_ACTIVE_USERS_LOADING';
export const GET_TOP_5_MOST_ACTIVE_USERS_SUCCESS = 'GET_TOP_5_MOST_ACTIVE_USERS_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_LOADING = 'GET_USER_LOADING';
export const CHANGE_WISH_STATUS = 'CHANGE_WISH_STATUS';
export const LIST_ROLES_WITH_PERMISSION_LOADING = 'LIST_ROLES_WITH_PERMISSION_LOADING';
export const LIST_ROLES_WITH_PERMISSION_SUCCESS = 'LIST_ROLES_WITH_PERMISSION_SUCCESS';
export const CHANGE_PERMISSION_ASSIGNMENT_SUCCESS = 'CHANGE_PERMISSION_ASSIGNMENT_SUCCESS';