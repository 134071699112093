import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import IRank from '../types/rank_type';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class RanksService {

    listAll() {
        return fetch(LINK_BASE + '/Ranks', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IRank[]>);
    }

    get(id: string) {
        return fetch(LINK_BASE + '/Ranks/' + id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IRank>);
    }

    create(rank: IRank) {
        let newRank = Object.assign({}, rank, {id: EMPTY_GUID});
        return fetch(LINK_BASE + '/Ranks', {
            method: 'POST',
            body: JSON.stringify(newRank),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IRank>);
    }

    update(rank: IRank) {
        return fetch(LINK_BASE + '/Ranks', {
            method: 'PUT',
            body: JSON.stringify(rank),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IRank>);
    }

    delete(rank: IRank) {
        return fetch(LINK_BASE + '/Ranks/' + rank.id, {
            method: 'DELETE',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

}

export default new RanksService();