import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IKeyword from '../../types/keyword_type';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IKeywordRowProps {
    keyword: IKeyword;
    loggedInUser: IUser | null;
    number: number;
    edit(keyword: IKeyword, e: IClickEvent): void;
    openAreYouSureModal(func: Function, title: string): void;
    delete(keyword: IKeyword): Promise<boolean>;
}

const KeywordRow = (props: IKeywordRowProps) => {
    let keyword = props.keyword;
    return (
        <tr 
            key={keyword.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e) => isAllowed(props.loggedInUser, 'IKeywordService.Update') ? props.edit(keyword, e) : {}}
        > 
            <td className="bold">{props.number}</td>
            <td>{keyword.name}</td>
            <td>
                { isAllowed(props.loggedInUser, 'IKeywordService.Delete') ?
                    <span className="remove-icon">
                        <i
                            className="fa fa-trash-o fa-2x" 
                            onClick={() => props.openAreYouSureModal(() => props.delete(keyword), 'Deleting keyword ' + keyword.name)} 
                            aria-hidden="true"
                        />
                    </span> 
                    : <div/> 
                }
            </td>
        </tr>
    );
};

export default KeywordRow;