import * as React from 'react';
import Modal from 'react-modal';
import PermissionsTable from './permissions_table';
import IRole from '../../types/role_type';
import IPermission from '../../types/permission_type';
import { IChangeEvent, IFormEvent } from '../../types/react_types';

interface ICreateUpdateRoleModalProps {
  role: IRole;
  isModalOpen: boolean;
  editing: boolean;
  permissions: IPermission[];
  submitRole(): Promise<void>;
  handleInputChange(e: IChangeEvent): void;
  cancel(): void;
  handleCheckboxChanged?(added: boolean, permission: IPermission): void;
}

interface ICreateUpdateRoleModalState {
  saveClicked: boolean;
}

class CreateUpdateRoleModal extends React.Component<ICreateUpdateRoleModalProps, ICreateUpdateRoleModalState> {
  state = {
    saveClicked: false
  };

  enableButton = () => {
    this.setState({ saveClicked: false });
  }

  submitClicked = (e: IFormEvent) => {
    e.preventDefault();
    this.setState({ saveClicked: true });
    this.props.submitRole().then(this.enableButton).catch(this.enableButton);
  }

  render() {
    const props = this.props;
    let role = props.role;
    return (
        <Modal 
            isOpen={props.isModalOpen}
            style={{content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '80%',
                minWidth: '30%'
            }}} 
        >
            <h1>{props.editing ? 'Update role' : 'Create new role'}</h1>
            <form onSubmit={this.submitClicked}>
                <div className="form-group row" style={{marginLeft: '1%', marginRight: '1%'}}>
                    <label className="required-field">Name: </label>
                    <input name="name" value={role.name} type="text" className="form-control" onChange={props.handleInputChange}/>
                </div>
                <br/>
                <div className="pull-right">
                    <button type="button" className="btn btn-cancel" onClick={props.cancel}>Cancel</button>&nbsp;
                    <input type="submit" className="btn btn-success" value="Save" disabled={this.state.saveClicked} />
                </div>
                <h4>Permissions:</h4>
                <PermissionsTable 
                    addedPermissions={role.permissions} 
                    handleCheckboxChanged={props.handleCheckboxChanged}  
                    permissions={props.permissions} 
                    showButtons={true} 
                />
                <div className="pull-right">
                    <button type="button" className="btn btn-cancel" onClick={props.cancel}>Cancel</button>&nbsp;
                    <input type="submit" className="btn btn-success" value="Save" disabled={this.state.saveClicked} />
                </div>
            </form>
        </Modal>
    );
  }
}

export default CreateUpdateRoleModal;