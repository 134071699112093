import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import IAuthor from '../../types/author_type';
import IUser from '../../types/user_type';
import { IClickEvent, IThunk } from '../../types/react_types';

interface IAuthorRowProps {
    author: IAuthor;
    loggedInUser: IUser | null;
    number: number;
    openAreYouSureModal(func: Function, title: string): void;
    delete(author: IAuthor): IThunk<boolean>;
    edit(author: IAuthor, e: IClickEvent): void;
}

const AuthorRow = (props: IAuthorRowProps) => {
    let author = props.author;
    return (
        <tr 
            key={author.id} 
            className="table-row" 
            id="tableRow" 
            onClick={(e) => isAllowed(props.loggedInUser, 'IAuthorService.Update') ? props.edit(author, e) : {}}
        >
            <td className="bold">{props.number}</td>
            <td>{author.name}</td>
            <td>{author.pseudonym}</td>
            <td>{author.yearOfBirth || ''}</td>
            <td>{author.nationality}</td>
            <td>
                { isAllowed(props.loggedInUser, 'IAuthorService.Delete') ?
                    <span className="remove-icon">
                        <i
                            className="fa fa-trash-o fa-2x" 
                            onClick={() => props.openAreYouSureModal(() => props.delete(author), 'Deleting author ' + author.name)} 
                            aria-hidden="true" 
                        />
                    </span> :
                    <div/> 
                }
            </td>
        </tr>
    );
}; 

export default AuthorRow;