import * as React from 'react';
import { connect } from 'react-redux';
import Book from './book';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import ViewChanger from './viewChanger';
import BookTable from './book_table';
import { isAllowed } from '../../helpers/checkAuthorization';
import { listAllBooks, deleteBook, subscribeToAll, unsubscribeFromAll, searchBooks } from '../../actions/books_actions';
import FilterBooksForm from './filter_books_form';
import { NavLink } from 'react-router-dom';
import AreYouSureModal from '../are_you_sure_modal';
import IAuthor from '../../types/author_type';
import { listAllAuthors } from '../../actions/authors_actions';
import ILanguage from '../../types/language_type';
import IGenre from '../../types/genre_type';
import IKeyword from '../../types/keyword_type';
import IPublisher from '../../types/publisher_type';
import { listAllLanguages } from '../../actions/languages_actions';
import { listAllPublishers } from '../../actions/publishers_actions';
import { listAllGenres } from '../../actions/genres_actions';
import { listAllKeywords } from '../../actions/keywords_actions';
import { validateISBN } from '../../helpers/ISBN_helper';
import * as toastr from 'toastr';
import ExpandButton from '../shared/expand_button';
import { History } from 'history';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { IThunkDispatch, IFormEvent, IChangeEvent, IAnyTargetType } from '../../types/react_types';

interface IBookProps {
    books: IBook[];
    booksLoading: boolean;
    loggedInUser: IUser | null;
    history: History;
    authors: IAuthor[];
    languages: ILanguage[];
    genres: IGenre[];
    keywords: IKeyword[];
    publishers: IPublisher[];
    listAllBooks(sortBy: string, asc: boolean): Promise<boolean>;
    deleteBook(book: IBook): Promise<boolean>;
    subscribeToAll(): Promise<boolean>;
    unsubscribeFromAll(): Promise<boolean>;
    listAllAuthors(): Promise<boolean>;
    searchBooks(
        name: string, startingRating: number, isbn13: string, 
        isbn10: string, languageId: string, publisherId: string, 
        genreId: string, keywordId: string, authorId: string, 
        sortBy: string, asc: boolean): Promise<boolean>;
    listAllLanguages(): Promise<boolean>;
    listAllPublishers(): Promise<boolean>;
    listAllKeywords(): Promise<boolean>;
    listAllGenres(): Promise<boolean>;
}

interface IBookState {
    loading: boolean;
    selectedView: string;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
    filterBooksParams: {
        name: string;
        startingRating: number;
        isbn13: string;
        isbn10: string;
        languageId: string;
        publisherId: string;
        genreId: string;
        keywordId: string;
        authorId: string;
        sortBy: string;
        asc: boolean;
    };
    filterExpanded: boolean;
}

class Books extends React.Component<IBookProps, IBookState> {
    constructor(props: IBookProps) {
        super(props);
        this.state = { 
            loading: true,
            selectedView: 'gridView',
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            },
            filterBooksParams: {
                name: '',
                startingRating: 0,
                isbn13: '',
                isbn10: '',
                languageId: '',
                publisherId: '',
                genreId: '',
                keywordId: '',
                authorId: '',
                sortBy: 'name',
                asc: true
            },
            filterExpanded: false,
        };
    }

    public componentWillMount() {
        this.props.listAllAuthors();
        this.props.listAllGenres();
        this.props.listAllKeywords();
        this.props.listAllLanguages();
        this.props.listAllPublishers();
        this.props.listAllBooks('name', true).then( () => this.setState( {loading: false} ));
    }

    public render() {
        let showBooks = this.state.selectedView === 'gridView' ? 
            <div className="card-items-wrap"> {this.listBooks()} </div> 
            : (
            <BookTable 
                books={this.props.books} 
                openAreYouSureModal={this.openAreYouSureModal} 
                delete={this.handleDelete} 
                loggedInUser={this.props.loggedInUser} 
                history={this.props.history}
            />
            );
        return (
            <div>
                <h1>Book catalogue</h1>
                { isAllowed(this.props.loggedInUser, 'IBookService.Search') ?
                  <div>
                    <h3>
                      Filter &nbsp;&nbsp;
                      <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                    </h3>
                    <br/>
                    { this.state.filterExpanded ? 
                        <FilterBooksForm 
                            filterParams={this.state.filterBooksParams} 
                            handleInputChange={this.handleBooksFilterParamsChange} 
                            filter={this.filterBooks} 
                            authors={this.props.authors}
                            clearFilter={this.clearBooksFilter} 
                            handleSelectChange={this.handleSelectChange} 
                            languages={this.props.languages} 
                            genres={this.props.genres}
                            publishers={this.props.publishers} 
                            keywords={this.props.keywords} 
                        />
                        : null
                    }
                  </div>
                  : ''
                }
                <ViewChanger 
                    handleSelectGrid={this.handleSelectGrid} 
                    handleSelectTable={this.handleSelectTable} 
                    selectedView={this.state.selectedView}
                />
                <div> 
                    { isAllowed(this.props.loggedInUser, 'IBookService.Create') ?
                    <NavLink exact={true} to={'/bookForm/'} activeClassName="active">
                    <button  className="btn btn-success btn-inline" >Create New   &nbsp;<span className="glyphicon glyphicon-plus"/></button>
                     </NavLink> : <div/>} 
                     &nbsp;&nbsp;&nbsp;
                     { isAllowed(this.props.loggedInUser, 'IBookService.SubscribeSelfToAll') ?
                    <button 
                        className="btn btn-warning btn-inline" 
                        onClick={this.subscribeToAll}
                    >
                        Subscribe to All  
                        &nbsp;<span className="glyphicon glyphicon-envelope"/>
                    </button>
                    : <div/>} &nbsp;
                    { isAllowed(this.props.loggedInUser, 'IBookService.UnsubscribeSelfFromAll') ?
                        <button 
                            className="btn btn-warning btn-inline " 
                            onClick={this.unsubscribeFromAll}
                        >
                            Unsubscribe from All   
                            &nbsp;<span className="glyphicon glyphicon-remove"/>
                        </button>
                    : <div/>}
                    <br/><br/>
                </div> 
                <br/><br/>
                { isAllowed(this.props.loggedInUser, 'IBookService.ListAll') ?
                  !this.props.booksLoading ?
                    showBooks :
                    <h4>Loading...</h4>
                : <div/>}
                <br /><br />
                <AreYouSureModal 
                    title={this.state.areYouSureModalParams.title} 
                    isOpen={this.state.areYouSureModalParams.isOpen} 
                    action={this.state.areYouSureModalParams.action}
                    close={this.closeAreYouSureModal} 
                    description={null} 
                />
            </div>
       );
    }

    toggleExpandFilter = () => {
      this.setState((prevState) => ({
        filterExpanded: !prevState.filterExpanded
      }));
    }

    handleBooksFilterParamsChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState((prevState: IBookState) => {
            let newSearch = Object.assign({}, prevState.filterBooksParams, {
                [name]: value
            });
            return {filterBooksParams: newSearch};
        });
    }

    filterBooks = (e: IFormEvent) => {
        e.preventDefault();
        let {name, startingRating, isbn13, isbn10, languageId, publisherId, genreId, keywordId, authorId, sortBy, asc} = this.state.filterBooksParams;
        if (isbn10 && !validateISBN(isbn10)) {
            toastr.error('Invalid ISBN-10');
            return;
        }
        if (isbn13 && !validateISBN(isbn13)) {
            toastr.error('Invalid ISBN-13');
            return;
        }
        if (isbn10.indexOf('-') >= 0) {
            isbn10 = isbn10.replace(/[^\dX]/gi, '');
            if (isbn10.length !== 10) {
                toastr.error('Invalid ISBN-10');
                return;
            }
        }
        if (isbn13.toString().indexOf('-') >= 0) {
            isbn13 = isbn13.replace(/[^\dX]/gi, '');
            if (isbn13.length !== 13) {
                toastr.error('Invalid ISBN-13');
                return;
            }
        }
        this.props.searchBooks(name, startingRating, isbn13, isbn10, languageId, publisherId, genreId, keywordId, authorId, sortBy, asc);
    }

    clearBooksFilter = () => {
        this.props.listAllBooks(this.state.filterBooksParams.sortBy, this.state.filterBooksParams.asc);
        this.setState((prevState: IBookState) => {
            let newFilterBooksParams = Object.assign({}, prevState.filterBooksParams, {
                name: '',
                startingRating: 0,
                isbn13: '',
                isbn10: '',
                languageId: null,
                publisherId: null,
                genreId: null,
                keywordId: null,
                authorId: null
            });
            return {filterBooksParams: newFilterBooksParams};
        });
    }

    handleSelectChange = (value: IChangeEvent, type: string) => {
        this.setState((prevState: IBookState) => {
            let newFilter = Object.assign({}, prevState.filterBooksParams, {[type]: value});
            return {filterBooksParams: newFilter};
        });
    }
    
    listBooks = () => {
        return this.props.books.map((book, index) => (
            <Book  
                key={book.id} 
                book={book} 
                delete={this.handleDelete} 
                loggedInUser={this.props.loggedInUser} 
                openAreYouSureModal={this.openAreYouSureModal} 
            />
        ));
    } 

    handleDelete = (book: IBook) => {
        return this.props.deleteBook(book);
    }

    handleSelectGrid = () => {
        this.setState((prevState) => {
            return { selectedView: 'gridView' };
        });
    }

    handleSelectTable = () => {
        this.setState((prevState ) => {
            return { selectedView: 'tableView' };
        });
    }

    subscribeToAll = () => {
        this.props.subscribeToAll();
    }

    unsubscribeFromAll = () => {
        this.props.unsubscribeFromAll();
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IBookState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IBookState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        books: store.booksReducer.books,
        booksLoading: store.booksReducer.booksLoading,
        loggedInUser: store.accountReducer.loggedInUser.user,
        authors: store.authorsReducer.authors,
        keywords: store.keywordReducer.keywords,
        genres: store.genreReducer.genres,
        publishers: store.publisherReducer.publishers,
        languages: store.languageReducer.languages
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllBooks: (sortBy: string, asc: boolean) => dispatch(listAllBooks(sortBy, asc)),
    deleteBook: (book: IBook) => dispatch(deleteBook(book)),
    subscribeToAll: () => dispatch(subscribeToAll()),
    unsubscribeFromAll: () => dispatch(unsubscribeFromAll()),
    listAllAuthors: () => dispatch(listAllAuthors()),
    searchBooks: (
        name: string, startingRating: number, isbn13: string, 
        isbn10: string, languageId: string, publisherId: string, 
        genreId: string, keywordId: string, authorId: string, 
        sortBy: string, asc: boolean
    ) => dispatch(searchBooks(
        name, startingRating, isbn13, isbn10, languageId, publisherId,
        genreId, keywordId, authorId, sortBy, asc)
    ),
    listAllGenres: () => dispatch(listAllGenres()),
    listAllLanguages: () => dispatch(listAllLanguages()),
    listAllKeywords: () => dispatch(listAllKeywords()),
    listAllPublishers: () => dispatch(listAllPublishers())
});

export default connect(mapStateToProps, mapDispatchToProps)(Books);
