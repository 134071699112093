import { LIST_ALL_AUTHORS_SUCCESS, LIST_ALL_AUTHORS_LOADING, CREATE_AUTHOR_SUCCESS, UPDATE_AUTHOR_SUCCESS, 
    DELETE_AUTHOR_SUCCESS, SEARCH_AUTHORS_SUCCESS } from '../constants/action_types';
import authorService from '../services/authors_service';
import IAuthor from '../types/author_type';
import * as toastr from 'toastr';
import { catchError } from '../helpers/error_helper';
import { IThunk, IDispatch, IThunkDispatch } from '../types/react_types';

export function listAllAuthors() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllAuthorsLoading());
        return authorService.listAll()
            .then(authors => {
                dispatch(listAllAuthorsSuccess(authors));
                return true;
            })
            .catch(catchError);
    };
} 

export function listAllAuthorsLoading() {
    return {
        type: LIST_ALL_AUTHORS_LOADING,
        payload: {
            authorsLoading: true
        }
    };
}

export function listAllAuthorsSuccess(authors: IAuthor[]) {
    return {
        type: LIST_ALL_AUTHORS_SUCCESS,
        payload: {
            authors: authors,
            authorsLoading: false
        }
    };
}

export function createAuthorSuccess(author: IAuthor) {
    return {
        type: CREATE_AUTHOR_SUCCESS,
        payload: {
            author: author
        }
    };
}

export function createAuthor(author: IAuthor) {
    return function(dispatch: IThunkDispatch) {
        return authorService.create(author)
            .then((result: IAuthor) => {
                toastr.success('Author created');
                dispatch(createAuthorSuccess(result));
                return true;
            })
            .catch(catchError);
    };
}

export function updateAuthorSuccess(author: IAuthor) {
    return {
        type: UPDATE_AUTHOR_SUCCESS,
        payload: {
            author: author
        }
    };
}

export function updateAuthor(author: IAuthor) {
    return function(dispatch: IDispatch) {
        return authorService.update(author)
            .then(authorResult => {
                toastr.success('Author updated');
                dispatch(updateAuthorSuccess(authorResult));
                return true;
            })
            .catch(catchError);
    };
}

export function deleteAuthorSuccess(author: IAuthor) {
    return {
        type: DELETE_AUTHOR_SUCCESS,
        payload: {
            author: author
        }
    };
}

export function deleteAuthor(author: IAuthor): IThunk<boolean> {
    return function(dispatch: IDispatch) {
        return authorService.delete(author)
            .then(() => {
                toastr.success('Author deleted');
                dispatch(deleteAuthorSuccess(author));
                return true;
            })
            .catch(catchError);
    };
}

export function searchAuthors(name: string, pseudonym: string, nationality: string) {
    return function(dispatch: IThunkDispatch) {
        return authorService.search(name, pseudonym, nationality)
            .then(authors => {
                dispatch(searchAuthorsSuccess(authors));
                return true;
            })
            .catch(catchError);
    };
} 

export function searchAuthorsSuccess(authors: IAuthor[]) {
    return {
        type: SEARCH_AUTHORS_SUCCESS,
        payload: {
            authors: authors,
            authorsLoading: false
        }
    };
}