import * as toastr from 'toastr';
import { catchError } from '../helpers/error_helper';
import ranksService from '../services/ranks_services';
import IRank from '../types/rank_type';
import { CREATE_RANK_SUCCESS, UPDATE_RANK_SUCCESS, DELETE_RANK_SUCCESS, LIST_ALL_RANKS_LOADING,
    LIST_ALL_RANKS_SUCCESS } from '../constants/action_types';
import { IThunkDispatch } from '../types/react_types';

export function listAllRanks() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllRanksLoading());
        return ranksService.listAll()
            .then(ranks => {
                dispatch(listAllRanksSuccess(ranks));
                return true;
            })
            .catch(catchError);
    };
} 

export function listAllRanksLoading() {
    return {
        type: LIST_ALL_RANKS_LOADING,
        payload: {
            ranksLoading: true
        }
    };
}

export function listAllRanksSuccess(ranks: IRank[]) {
    return {
        type: LIST_ALL_RANKS_SUCCESS,
        payload: {
            ranks: ranks,
            ranksLoading: false
        }
    };
}

export function createRankSuccess(rank: IRank) {
    return {
        type: CREATE_RANK_SUCCESS,
        payload: {
            rank: rank
        }
    };
}

export function createRank(rank: IRank) {
    return function(dispatch: IThunkDispatch) {
        return ranksService.create(rank)
            .then((returnRank: IRank) => {
                toastr.success('Rank created');
                dispatch(createRankSuccess(returnRank));
                return true;
            })
            .catch(catchError);
    };
}

export function updateRankSuccess(rank: IRank) {
    return {
        type: UPDATE_RANK_SUCCESS,
        payload: {
            rank: rank
        }
    };
}

export function updateRank(rank: IRank) {
    return function(dispatch: IThunkDispatch) {
        return ranksService.update(rank)
            .then(returnRank => {
                toastr.success('Rank updated');
                dispatch(updateRankSuccess(returnRank));
                return true;
            })
            .catch(catchError);
    };
}

export function deleteRankSuccess(rank: IRank) {
    return {
        type: DELETE_RANK_SUCCESS,
        payload: {
            rank: rank
        }
    };
}

export function deleteRank(rank: IRank) {
    return function(dispatch: IThunkDispatch) {
        return ranksService.delete(rank)
            .then(() => {
                toastr.success('Rank deleted');
                dispatch(deleteRankSuccess(rank));
                return true;
            })
            .catch(catchError);
    };
}