import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import IWish from '../types/wish_type';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';
import IBook from '../types/book_type';

class WishlistService {

    get(id: string) {
        return fetch(LINK_BASE + '/Wishlists/' + id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IWish>);
    }

    listAll() {
        return fetch(LINK_BASE + '/Wishlists', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IWish[]>);
    }

    search(userId: string, status: string) {
        return fetch(LINK_BASE + `/Wishlists/Search?userId=${userId}&status=${status}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IWish[]>);
    }

    delete(id: string) {
        return fetch(LINK_BASE + '/Wishlists/' + id, {
            method: 'DELETE',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    create(wish: IWish) {
        wish.id = EMPTY_GUID;
        return fetch(LINK_BASE + '/Wishlists', {
            method: 'post',
            headers: getAuthorizedContentTypeHeaders(),
            body: JSON.stringify(wish)
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IWish>);
    }

    //tslint:disable-next-line:no-any
    createCustomWish(wishParams: any) {
        var isbn13 = wishParams.isbn13 ? wishParams.isbn13.trim() : null;
        var isbn10 = wishParams.isbn10 ? wishParams.isbn10.trim() : null;
        if (wishParams.isbn10 && wishParams.isbn10.indexOf('-') >= 0) {
            isbn10 = wishParams.isbn10.replace(/[^\dX]/gi, '');
        }
        if (wishParams.isbn13 && wishParams.isbn13.indexOf('-') >= 0) {
            isbn13 = wishParams.isbn13.replace(/[^\dX]/gi, '');
        }
        let wish = {
            'id': EMPTY_GUID,
            'bookName': wishParams.bookName,
            'authors': wishParams.authors,
            'publisher': wishParams.publisher,
            'isbN13': isbn13,
            'isbN10': isbn10,
            'statusId': wishParams.statusId
        };
        return fetch(LINK_BASE + '/Wishlists', {
            method: 'post',
            headers: getAuthorizedContentTypeHeaders(),
            body: JSON.stringify(wish)
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IWish>);
    }

    changeWishStatus(newStatus: string, wishId: string) {
        return fetch(LINK_BASE + '/Wishlists/ChangeStatus/' + newStatus + '/' + wishId, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IWish>);
    }

    transformWish(wishId: string) {
        return fetch(LINK_BASE + '/Wishlists/TransformWish/' + wishId, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IBook>);
    }
}

export default new WishlistService();