import * as React from 'react';
import IRole from '../../types/role_type';
import IPermission from '../../types/permission_type';
import RoleTable from './role_table';
import Modal from 'react-modal';
import IUser from '../../types/user_type';
import ChooseRolesButtons from './choose_roles_buttons';
import { MODAL_STYLES } from '../../constants/other_constants';

interface IManagePermissionModalProps {
    permission: IPermission;
    rolesWithPermission: IRole[];
    rolesWithPermissionLoading: boolean;
    roles: IRole[];
    loggedInUser: IUser | null;
    changePermissionAssignment: Function;
    isOpen: boolean;
    closeModal(): void;
}

interface IManagePermissionModalState {
    chosenRoles: IRole[];
    saveClicked: boolean;
}

class ManagePermissionModal extends React.Component<IManagePermissionModalProps, IManagePermissionModalState> {
    constructor(props: IManagePermissionModalProps) {
        super(props);
        this.state = {
            chosenRoles: this.props.rolesWithPermission,
            saveClicked: false
        };
    }
    
    public render() {
        if (this.props.rolesWithPermissionLoading) {
            return <h3>Loading...</h3>;
        }
        return (
            <div>
            <Modal isOpen={this.props.isOpen} style={MODAL_STYLES}>
                <h3 className="text-center">Manage permission</h3>
                <h5 className="text-center">{this.props.permission.name}</h5>
                <h5 className="text-center">Assign this permission to roles: </h5>
                <RoleTable 
                    roles={this.props.roles} 
                    loggedInUser={this.props.loggedInUser} 
                    allowRowSelect={false} 
                    renderActionButtons={(role: IRole) =>
                        <ChooseRolesButtons 
                            role={role} 
                            addedRoles={this.state.chosenRoles} 
                            handleCheckboxChanged={this.handleCheckboxChanged} 
                        />
                    } 
                />
                <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                <button 
                    type="button" 
                    className="btn btn-success pull-right" 
                    value="Save"
                    onClick={this.savePermission} 
                    disabled={this.state.saveClicked}
                >
                    Save
                </button>
            </Modal>
            </div>
        );
    }

    handleCheckboxChanged = (wasAddedBefore: boolean, role: IRole) => {
        let newList;
        if (wasAddedBefore) {
            newList = this.state.chosenRoles.filter(r => r.id !== role.id);
        } else {
            newList = this.state.chosenRoles.slice();
            newList.push(role);
        }
        this.setState({
            chosenRoles: newList
        });
    }

    enableSave = () => {
      this.setState({ saveClicked: false });
    }

    savePermission = () => {
        this.setState({ saveClicked: true });
        let ids: string[] = [];
        this.state.chosenRoles.map((r: IRole) => ids.push(r.id));
        this.props.changePermissionAssignment(this.props.permission.id, ids)
            .then(() => {
                this.enableSave();
                this.props.closeModal();
            })
            .catch(() => {
              this.enableSave();
            });
    }
}

export default ManagePermissionModal;