import * as React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { searchGoogleBooks } from '../../actions/google_books_actions';
import { MODAL_STYLES } from '../../constants/other_constants';
import SuggestedImagesList from './suggested_images_list';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { IThunkDispatch } from '../../types/react_types';

interface ISuggestedImagesModalProps {
    //tslint:disable-next-line:no-any
    searchResult: any;
    searchGoogleBooks: Function;
    open: boolean;
    searchParams: Function;
    closeModal: Function;
    search: Function;
    chooseSuggestedImage(src: string): void;
}

interface ISuggestedImagesModalState {
    currentPage: number;
}

class SuggestedImagesModal extends React.Component<ISuggestedImagesModalProps, ISuggestedImagesModalState> {

    constructor(props: ISuggestedImagesModalProps) {
        super(props);
        this.state = {
            currentPage: 0
        };
    }

    public render() {
        return (
            <Modal isOpen={this.props.open} style={MODAL_STYLES}>
                <h3>Suggested images for the new book:</h3>
                <SuggestedImagesList 
                    currentPage={this.state.currentPage} 
                    searchResult={this.props.searchResult} 
                    changePage={this.changePage} 
                    chooseSuggestedImage={this.props.chooseSuggestedImage} 
                />
                <button type="button" className="btn btn-default pull-right" onClick={() => this.props.closeModal()}>Cancel</button>
            </Modal>
        );
    }

    changePage = (by: number) => {
        this.setState(
            (prevState: ISuggestedImagesModalState) => {
                let newCurrentPage = prevState.currentPage + by;
                return {currentPage: newCurrentPage};
            }, 
            () => this.props.search(this.state.currentPage));
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        searchResult: store.googleBooksReducer.searchResult
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    searchGoogleBooks: (params: string, page: number) => dispatch(searchGoogleBooks(params, page))
});

export default connect(mapStateToProps, matchDispatchToProps)(SuggestedImagesModal);