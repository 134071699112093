import { LINK_BASE } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import IPermission from '../types/permission_type';
import { getAuthorizedHeaders } from '../helpers/header_helper';

class PermissionsService {

    listAll() {
        return fetch(LINK_BASE + '/Permissions', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IPermission[]>);
    }

    get(id: string) {
        return fetch(LINK_BASE + '/Permissions/' + id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<IPermission>);
    }

}

export default new PermissionsService();