import * as React from 'react';

//tslint:disable:no-any
interface IGoogleBookCardProps {
  book: any;
  createWish: Function;
}

interface IGoogleBookCardState {
  clickedAdd: boolean;
}

class GoogleBookCard extends React.Component<IGoogleBookCardProps, IGoogleBookCardState> {

  state = {
    clickedAdd: false
  };

  onAdd = () => {
    this.setState({ clickedAdd: true });
    this.props.createWish(this.props.book);
  }

  render() {
    let book = this.props.book;
    return (
      <div className="card-item-box">
            <div className="card-item">
                <div className="card-img">
                    <img 
                        src={book.volumeInfo.imageLinks ? book.volumeInfo.imageLinks.smallThumbnail : require('../../../images/no_image.png')} 
                        height="180"
                    />
                </div>
                <div className="card-body">
                    <div className="entry">
                        <h4 className="main-title">{book.volumeInfo.title}</h4>
                        {book.volumeInfo.infoLink ? 
                            <div className="cart-link">
                                <a href={book.volumeInfo.infoLink} target="_blank"><span className="glyphicon glyphicon-new-window"/></a>
                            </div>
                            : ' '
                        }
                        <div className="trunc-5-lines">
                            <div>
                                {book.volumeInfo.description ? book.volumeInfo.description : 'No description.'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="row row-item">
                    <div className="col-md-6">
                        <div className="entry">
                            <div className="label-name">                
                                {book.volumeInfo.authors && book.volumeInfo.authors.length === 1 ? 'Author: ' : 'Authors: '}
                                
                                {book.volumeInfo.authors && book.volumeInfo.authors.length !== 0 ? 
                                    book.volumeInfo.authors.map((a: any, index: number) => 
                                        index !== book.volumeInfo.authors.length - 1 ? 
                                        a + ', ' : 
                                        a
                                    )
                                    : ''
                                }
                            </div>
                            <div className="label-name">
                                {'Publisher: '}

                                {book.volumeInfo.publisher ? book.volumeInfo.publisher : ''}
                                </div>
                            <div className="label-name">{'Date published: '}
                                {book.volumeInfo.publishedDate ? book.volumeInfo.publishedDate : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="entry">
                            <div className="label-name">{'Language: '}
                                {book.volumeInfo.language ? book.volumeInfo.language : ''}
                            </div>
                            <div className="label-name">{'ISBN 13: '}
                                {book.volumeInfo.industryIdentifiers && book.volumeInfo.industryIdentifiers.find((x: any) => x.type === 'ISBN_13') ? 
                                    book.volumeInfo.industryIdentifiers.filter((x: any) => x.type === 'ISBN_13').map((x: any) => x.identifier)
                                    : ''
                                }
                            </div>
                            <div className="label-name">
                                {'ISBN 10: '}
                                {book.volumeInfo.industryIdentifiers && book.volumeInfo.industryIdentifiers.find((x: any) => x.type === 'ISBN_10') ? 
                                    book.volumeInfo.industryIdentifiers.filter((x: any) => x.type === 'ISBN_10').map((x: any) => x.identifier)
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <button 
                    type="button" 
                    className="btn btn-success" 
                    onClick={this.onAdd} 
                    disabled={this.state.clickedAdd}
                >
                   Add to wishlist <span className="glyphicon glyphicon-plus"/>
                </button>
            </div>
        </div>
    );
  }

}

export default GoogleBookCard;