import * as React from 'react';
import IPermission from '../types/permission_type';
import NotAuthorized from '../components/not_authorized';
import IUser from '../types/user_type';

interface IWithAuthorizationProps {

}

// tslint:disable-next-line:no-any
const Authorization = (WrappedComponent: any, allowedPermissions: String[], user: IUser|null) => {
  return class WithAuthorization extends React.Component {
    constructor(props: IWithAuthorizationProps) {
      super(props);

      }

    render() {
      if (!user) {
          return (
            <h1>Not logged in</h1>
          );
      } else {
        let permissions: IPermission[] = user.role.permissions;
        let allowed = !allowedPermissions || !allowedPermissions.length || allowedPermissions.some(x => permissions.some(y => y.name === x));
        if (allowed) {
          return <WrappedComponent {...this.props} />;
        } else {
          return <NotAuthorized />;
        }
      }
    }
  };
};

export default Authorization;
