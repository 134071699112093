import * as React from 'react';
import GoogleBookCard from './google_book_card';
import { CARDS_BY_PAGE } from '../../constants/other_constants';

//tslint:disable:no-any
interface IGoogleBooksListProps {
    searchResult: any;
    currentPage: number;
    changePage(by: number): void;
    createWish(book: any): void;
}

const GoogleBooksList = (props: IGoogleBooksListProps) => {
    let searchResult = props.searchResult;
    let books = searchResult && searchResult.items ? searchResult.items : [];
    if (books.length !== 0) {
        return (
            <div>
                <div className="row row-item">
                    <div className="col-md-2" style={{paddingLeft: '1%'}}>
                        Found: {searchResult.totalItems} books.<br/>
                        Showing results: {1 + (CARDS_BY_PAGE) * props.currentPage} - {CARDS_BY_PAGE * (props.currentPage + 1)}
                    </div>
                    {searchResult.totalItems > 9 ?
                        <div className="col-md-offset-10">
                            <br/>
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                onClick={() => props.changePage(-1)} 
                                disabled={props.currentPage === 0}
                            >
                                <span className="glyphicon glyphicon-menu-left"/> Previous page
                            </button> 
                            &nbsp;
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                onClick={() => props.changePage(1)} 
                                disabled={(props.currentPage + 1) * CARDS_BY_PAGE >= searchResult.totalItems}
                            >
                                Next page <span className="glyphicon glyphicon-menu-right"/>
                            </button>
                        </div>
                        : ''
                    }
                </div>
                <div className="card-items-wrap">
                    {books.map((x: any, i: number) => <GoogleBookCard key={i} book={x} createWish={props.createWish} />)}
                </div>
                <div className="row row-item">
                    {searchResult.totalItems > 9 ?
                        <div className="col-md-offset-10">
                            <br/>
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                onClick={() => props.changePage(-1)} 
                                disabled={props.currentPage === 0}
                            >
                                <span className="glyphicon glyphicon-menu-left"/> Previous page
                            </button> 
                            &nbsp;
                            <button
                                type="button" 
                                className="btn btn-default" 
                                onClick={() => props.changePage(1)} 
                                disabled={(props.currentPage + 1) * CARDS_BY_PAGE >= searchResult.totalItems}
                            >
                                Next page <span className="glyphicon glyphicon-menu-right"/>
                            </button>
                        </div>
                        : ''
                    }
                </div>
            </div>
        );
    } else if (books.searchClicked) {
        return <h3>No books found.</h3>; 
    } else {
        return <div/>;
    }
};

export default GoogleBooksList;