import * as React from 'react';
import CheckoutRow from './checkout_row';
import ICheckout from '../../types/checkout_type';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';

interface ICheckoutTableProps {
    checkouts: ICheckout[];
    loggedInUser: IUser | null;
    returnBook(checkout: ICheckout): void;
    deleteCheckout(checkout: ICheckout): void;
    openAreYouSureModal(f: Function, title: string, description: string | React.ReactNode): void;
}

const CheckoutTable = (props: ICheckoutTableProps) => {
    let checkouts = props.checkouts;
    return (
        <table className="table table-striped">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">User</td>
                    <td className="bold">Book name</td>
                    <td className="bold">Authors</td>
                    <td className="bold">Edition</td>
                    <td className="bold">Checkout time</td>
                    <td className="bold">Expected return time</td>
                    <td className="bold">Return time</td>
                    <td className="bold">Time kept</td>
                    <td className="bold">Librarian</td>
                    { 
                        isAllowed(props.loggedInUser, 'IBookService.ReturnBook') || isAllowed(props.loggedInUser, 'ICheckoutService.Delete') ?
                            <td className="bold">Action</td>
                            : ''
                    }
                </tr>
            </thead>
            <tbody>
                { checkouts.map((checkout: ICheckout, index: number) => (
                    <CheckoutRow 
                        key={checkout.id} 
                        number={index + 1} 
                        checkout={checkout}
                        returnBook={props.returnBook} 
                        delete={props.deleteCheckout} 
                        loggedInUser={props.loggedInUser} 
                        openAreYouSureModal={props.openAreYouSureModal} 
                    />
                ))}
            </tbody>
        </table>
    );
};

export default CheckoutTable;