import { GET_CHECKOUT_DURATION_SUCCESS, CHANGE_CHECKOUT_DURATION_SUCCESS } from '../constants/action_types';
import configurationsService from '../services/configurations_service';
import * as toastr from 'toastr';
import { catchError } from '../helpers/error_helper';
import { IThunkDispatch } from '../types/react_types';

export function getCheckoutDuration() {
    return function(dispatch: IThunkDispatch) {
        return configurationsService.getCheckoutDuration()
            .then(value => {
                dispatch(getCheckoutDurationSuccess(value));
                return true;
            })
            .catch(catchError);
    };
} 

export function getCheckoutDurationSuccess(value: number) {
    return {
        type: GET_CHECKOUT_DURATION_SUCCESS,
        payload: {
            checkoutDuration: value
        }
    };
}

export function changeCheckoutDuration(value: number) {
    return function(dispatch: IThunkDispatch) {
        return configurationsService.changeCheckoutDuration(value)
            .then(returnValue => {
                toastr.success('Checkout duration changed.');
                dispatch(getCheckoutDurationSuccess(returnValue));
                return true;
            })
            .catch(catchError);
    };
} 

export function changeCheckoutDurationSuccess(value: number) {
    return {
        type: CHANGE_CHECKOUT_DURATION_SUCCESS,
        payload: {
            checkoutDuration: value
        }
    };
}
