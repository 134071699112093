import * as React from 'react';
import SubscriptionRow from './subscription_row';
import ISubscription from '../../types/subscription_type';
import IUser from '../../types/user_type';

interface ISubscriptionTableProps {
    subscriptions: ISubscription[];
    loggedInUser: IUser | null;
    deleteSubscription(userId: string, bookId: string, sameUser: boolean): void;
}

const SubscriptionTable = (props: ISubscriptionTableProps) => {
    let subscriptions = props.subscriptions;
    return (
        <table className="table table-striped">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">User</td>
                    <td className="bold">Book name</td>
                    <td className="bold">Action</td>
                </tr>
            </thead>
            <tbody>
                { subscriptions.map((subscription: ISubscription, index: number) => (
                    <SubscriptionRow 
                        key={subscription.user.id + subscription.book.id} 
                        number={index + 1} 
                        subscription={subscription} 
                        deleteSubscription={props.deleteSubscription} 
                        loggedInUser={props.loggedInUser}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default SubscriptionTable;