import { CREATE_WISH_SUCCESS, DELETE_WISH_SUCCESS, LIST_ALL_WISHES_LOADING, LIST_ALL_WISHES_SUCCESS, 
    SEARCH_WISHES_SUCCESS, CHANGE_WISH_STATUS } from '../constants/action_types';
import IWish from '../types/wish_type';
import { IAction } from '../types/react_types';

export interface IWishlistReducer {
    wishlist: IWish[];
    wishlistLoading: boolean;
}

var defaultState: IWishlistReducer = {
    wishlist: [],
    wishlistLoading: false
};

export default function (state: IWishlistReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_WISHES_LOADING:
        case LIST_ALL_WISHES_SUCCESS:
        case SEARCH_WISHES_SUCCESS:
            return Object.assign({}, state, action.payload);
        case CREATE_WISH_SUCCESS:
            let wishlistAfterCreate: IWish[] = state.wishlist.slice();
            wishlistAfterCreate.push(action.payload.wish);
            return Object.assign({}, state, {wishlist: wishlistAfterCreate});
        case DELETE_WISH_SUCCESS:
            let deletedWish = action.payload.wish;
            let wishlistAfterDelete: IWish[] = state.wishlist.slice();
            let index = state.wishlist.findIndex((wish: IWish) => wish.id === deletedWish.id);
            wishlistAfterDelete.splice(index, 1);
            return Object.assign({}, state, {wishlist: wishlistAfterDelete});
        case CHANGE_WISH_STATUS:
            let wishlistAfterChange = state.wishlist.map((a: IWish) => a.id === action.payload.wish.id ? 
                Object.assign({}, a, action.payload.wish) : a);
            return Object.assign({}, state, {wishlist: wishlistAfterChange});
        default:
            return state;
    }
}