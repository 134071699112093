import * as React from 'react';

interface ISuggestedImageProps {
    imgSrc: string;
    chooseSuggestedImage(src: string): void;
}

const SuggestedImage = (props: ISuggestedImageProps) => {
    return (
        <div className="col-md-4">
            <div className="align-center" style={{marginBottom: '10px'}}>
                <img src={props.imgSrc} className="box-img o-fit" onClick={() => props.chooseSuggestedImage(props.imgSrc)} />
            </div>
        </div>
    );
};

export default SuggestedImage;