import IRole from '../types/role_type';
import { LINK_BASE, EMPTY_GUID } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';
import { getAuthorizedHeaders, getAuthorizedContentTypeHeaders } from '../helpers/header_helper';

class RolesService {

    listAll() {
        return fetch(LINK_BASE + '/Roles', {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IRole[]>);
    }

    get(id: string) {
        return fetch(LINK_BASE + '/Roles/' + id, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IRole>);
    }

    create(role: IRole) {
        let newRole = Object.assign({}, role, {id: EMPTY_GUID});
        return fetch(LINK_BASE + '/Roles', {
            method: 'POST',
            body: JSON.stringify(newRole),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IRole>);
    }

    update(role: IRole) {
        return fetch(LINK_BASE + '/Roles', {
            method: 'PUT',
            body: JSON.stringify(role),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IRole>);
    }

    delete(role: IRole) {
        return fetch(LINK_BASE + '/Roles/' + role.id, {
            method: 'DELETE',
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors);
    }

    changePermissionAssignment(permissionId: string, roles: string[]) {
        return fetch(LINK_BASE + '/Roles/ChangePermissionAssignment', {
            method: 'PUT',
            body: JSON.stringify({
                permissionId: permissionId,
                roles: roles
            }),
            headers: getAuthorizedContentTypeHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IRole[]>);
    }

    listRolesWithPermission(permissionId: string) {
        return fetch(LINK_BASE + `/Roles/ListRolesWithPermission?permissionId=${permissionId}`, {
            headers: getAuthorizedHeaders()
        })
        .then(handleErrors)
        .then((response) => response.json() as Promise<IRole[]>);
    }

}

export default new RolesService();