import * as React from 'react';
import WishRow from './wish_row';
import IWish from '../../types/wish_type';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';

interface IWishlistTableProps {
    wishlist: IWish[];
    loggedInUser: IUser | null;
    deleteWish(wish: IWish): void;
    openAreYouSureModal(f: Function, title: string): void;
    changeStatus(status: string, id: string): Promise<boolean>;
    transform(id: string): Promise<void>;
}

const WishlistTable = (props: IWishlistTableProps) => {
    let wishlist = props.wishlist;
    return (
        <table className="table table-striped wishlistTable">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">User</td>
                    <td className="bold">Book name</td>
                    <td className="bold">Authors</td>
                    <td className="bold">Publisher</td>
                    <td className="bold">ISBN-13</td>
                    <td className="bold">ISBN-10</td>
                    <td className="bold">Link</td>
                    <td className="bold">Image</td>
                    <td className="bold">Status</td>
                    <td className="bold">Submit time</td>
                    { isAllowed(props.loggedInUser, 'IWishlistService.Delete') ?
                    <td className="bold">Action</td>
                    : ''}
                </tr>
            </thead>
            <tbody>
                {wishlist.map((wish: IWish, index: number) => (
                    <WishRow 
                        key={wish.id} 
                        number={index + 1} 
                        wish={wish} 
                        delete={props.deleteWish}
                        loggedInUser={props.loggedInUser} 
                        openAreYouSureModal={props.openAreYouSureModal} 
                        changeStatus={props.changeStatus} 
                        transform={props.transform}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default WishlistTable;