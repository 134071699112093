import { handleErrors } from '../helpers/error_helper';
import { GOOGLE_BOOKS_API_SEARCH_LINK, CARDS_BY_PAGE } from '../constants/other_constants';

class GoogleBooksService {

    searchBooks(searchParams: string, page: number) {
        let startIndex = page * CARDS_BY_PAGE;
        return fetch(GOOGLE_BOOKS_API_SEARCH_LINK + `${searchParams}&startIndex=${startIndex}&maxResults=${CARDS_BY_PAGE}`) 
            .then(handleErrors)
            //tslint:disable-next-line:no-any
            .then(response => response.json() as Promise<any>);
    }

}

export default new GoogleBooksService();