import * as React from 'react';
import { IChangeEvent, IFormEvent } from '../../types/react_types';

interface IFilterUsersFormProps {
    filterParams: {
        username: string;
        firstName: string;
        lastName: string;
        sortBy: string;
        asc: boolean;
    };
    handleInputChange(e: IChangeEvent): void;
    filter(e: IFormEvent): void;
    clearFilter(): void;
}

const FilterUsersForm = (props: IFilterUsersFormProps) => {
    return (
        <form className="filter-form" onSubmit={props.filter}>
            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Username:</label>
                    <input 
                        type="text" 
                        name="username" 
                        value={props.filterParams.username} 
                        className="form-control" 
                        onChange={props.handleInputChange} 
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>First name:</label>
                    <input 
                        type="text" 
                        name="firstName" 
                        value={props.filterParams.firstName}
                        className="form-control" 
                        onChange={props.handleInputChange} 
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Last name:</label>
                    <input 
                        type="text" 
                        name="lastName" 
                        value={props.filterParams.lastName} 
                        className="form-control" 
                        onChange={props.handleInputChange} 
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Sort by:</label>
                    <select name="sortBy" value={props.filterParams.sortBy} className="form-control" onChange={props.handleInputChange}>
                        <option value="name">Name</option>
                        <option value="username">Username</option>
                        <option value="role">Role</option>
                        <option value="readbooks">Number of read books</option>
                    </select>
                </div>
                <div className="filter-col col-md-4">
                    <label>Ascending:</label>
                    <input type="checkbox" name="asc" checked={props.filterParams.asc} className="checkbox" onChange={props.handleInputChange}/>
                </div>
            </div>
            <div className="row-item">
                <button type="submit" className="btn btn-primary btn-inline">Filter</button>
                <button type="button" className="btn btn-default btn-inline" onClick={props.clearFilter}>Clear filter</button>
            </div>
        </form>
    );
};

export default FilterUsersForm;