import * as React from 'react';
import IRole from '../../types/role_type';
import { connect } from 'react-redux';
import IPermission from '../../types/permission_type';
import { listAllRoles, listRolesWithPermission, changePermissionAssignment } from '../../actions/roles_actions';
import { listAllPermissions } from '../../actions/permissions_actions';
import PermissionsTable from './permissions_table';
import ManagePermissionsModal from './manage_permission_modal';
import IUser from '../../types/user_type';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { IThunkDispatch } from '../../types/react_types';

interface IPermissionsProps {
    permissions: IPermission[];
    permissionsLoading: boolean;
    roles: IRole[];
    rolesWithPermission: IRole[];
    rolesLoading: boolean;
    loggedInUser: IUser | null;
    rolesWithPermissionLoading: boolean;
    listAllRoles(): Promise<boolean>;
    listAllPermissions(): Promise<boolean>;
    listRolesWithPermission(permissionId: string): Promise<boolean>;
    changePermissionAssignment(permissionId: string, roles: string[]): Promise<boolean>;
}

interface IPermissionsState {
    isModalOpen: boolean;
    permission: IPermission;
}

class Permissions extends React.Component<IPermissionsProps, IPermissionsState> {
    constructor(props: IPermissionsProps) {
        super(props);
        this.state = {
            isModalOpen: false,
            permission: {
                name: '',
                id: ''
            }
        };
    }

    public componentWillMount() {
        this.props.listAllRoles();
        this.props.listAllPermissions();
    }

    public render() {
        let modal = this.state.isModalOpen ? 
            (
            <ManagePermissionsModal 
                permission={this.state.permission} 
                changePermissionAssignment={this.props.changePermissionAssignment} 
                loggedInUser={this.props.loggedInUser} 
                rolesWithPermission={this.props.rolesWithPermission} 
                rolesWithPermissionLoading={this.props.rolesWithPermissionLoading}
                closeModal={this.closeModal} 
                roles={this.props.roles} 
                isOpen={this.state.isModalOpen} 
            />)
            : '';
        if (this.props.permissionsLoading || this.props.rolesLoading) {
            return <h3>Loading...</h3>;
        }
        return (
            <div className="col-md-12">
                <h1>List of Permissions</h1>
                <PermissionsTable permissions={this.props.permissions} showButtons={false} handleRowClick={this.handleRowClick} />
                {modal}
            </div>
        );
    }

    handleRowClick = (permission: IPermission) => {
        this.props.listRolesWithPermission(permission.id)
            .then((success: boolean) => { 
                if (success) {
                    this.setState({isModalOpen: true, permission: permission});
                } 
            });
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        permissions: store.permissionsReducer.permissions,
        permissionsLoading: store.permissionsReducer.permissionsLoading,
        roles: store.rolesReducer.roles,
        rolesLoading: store.rolesReducer.rolesLoading,
        rolesWithPermission: store.rolesReducer.rolesWithPermission,
        loggedInUser: store.accountReducer.loggedInUser.user,
        rolesWithPermissionLoading: store.rolesReducer.rolesWithPermissionLoading
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllRoles: () => dispatch(listAllRoles()),
    listAllPermissions: () => dispatch(listAllPermissions()),
    listRolesWithPermission: (permissionId: string) => dispatch(listRolesWithPermission(permissionId)),
    changePermissionAssignment: (permissionId: string, roles: string[]) => dispatch(changePermissionAssignment(permissionId, roles))
});

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);