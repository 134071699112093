import * as React from 'react';
import { LINK_BASE } from '../../constants/other_constants';
import IUser from '../../types/user_type';

interface ITopUserProps {
    user: IUser;
    goToProfile(): void;
}

const TopUser = (props: ITopUserProps) => {
    let user = props.user;
    return (
        <div className="card-item-box" onClick={props.goToProfile}>
            <div className="card-item">
                <div className="card-img">
                    <img 
                        src={user.rank && user.rank.avatar ? 
                            LINK_BASE + user.rank.avatar : 
                            require('../../../images/no_image.png')} 
                        height="180" 
                        width="150"
                    />
                </div>
                <div className="card-body">
                    <div className="entry">
                        <h4 className="main-title">{user.firstName + ' ' + user.lastName}</h4>
                        <div className="label-name">Read books:</div>
                        {user.readBooks}
                        <div className="label-name">Rank:</div>
                        {user.rank ? user.rank.name : ''}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopUser;