import { LIST_ALL_BOOKS_SUCCESS, ADD_BOOK_SUCCESS, GET_BOOK_SUCCESS, UPDATE_BOOK_SUCCESS, DELETE_BOOK_SUCCESS,
    RATE_BOOK_SUCCESS, GET_USER_RATING_SUCCESS, ADD_COMMENT_SUCCESS, DELETE_COMMENT_SUCCESS, CHECKOUT_BOOKS_SUCCESS, 
    LIST_ALL_BOOKS_LOADING, SEARCH_BOOKS_LOADING, SEARCH_BOOKS_SUCCESS, IS_SUBSCRIBED_SUCCESS, LIST_ALL_AVAILABLE_BOOKS_LOADING, 
    LIST_ALL_AVAILABLE_BOOKS_SUCCESS, SEARCH_AVAILABLE_BOOKS_LOADING, SEARCH_AVAILABLE_BOOKS_SUCCESS, 
    GET_TOP_5_BEST_RATED_BOOKS_LOADING, GET_TOP_5_BEST_RATED_BOOKS_SUCCESS, GET_TOP_5_MOST_READ_BOOKS_LOADING,
     GET_TOP_5_MOST_READ_BOOKS_SUCCESS } from '../constants/action_types';
import IBook from '../types/book_type';
import IComment from '../types/comment_type';
import bookService from '../services/books_service';
import * as toastr from 'toastr';
import ICheckout from '../types/checkout_type';
import { catchError } from '../helpers/error_helper';
import { IThunkDispatch } from '../types/react_types';

export function listAllBooks(sortBy: string, asc: boolean) {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllBooksLoading());
        return bookService.listAllBooks(sortBy, asc)
            .then(books => {
                dispatch(listAllBooksSuccess(books));
                return true;
            })
            .catch(catchError);
    };
} 

export function listAllBooksSuccess(books: IBook[]) {
    return {
        type: LIST_ALL_BOOKS_SUCCESS,
        payload: {
            books: books,
            booksLoading: false
        }
    };
}

export function listAllAvailableBooks(sortBy: string, asc: boolean) {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllAvailableBooksLoading());
        return bookService.listAllAvailableBooks(sortBy, asc)
            .then(books => {
                dispatch(listAllAvailableBooksSuccess(books));
                return true;
            })
            .catch(catchError);
    };
} 

export function listAllAvailableBooksSuccess(books: IBook[]) {
    return {
        type: LIST_ALL_AVAILABLE_BOOKS_SUCCESS,
        payload: {
            books: books,
            booksLoading: false
        }
    };
}

export function listAllAvailableBooksLoading() {
    return {
        type: LIST_ALL_AVAILABLE_BOOKS_LOADING,
        payload: {
            booksLoading: true
        }
    };
}

export function addBook(book: IBook) {
    return function (dispatch: IThunkDispatch) {
        return bookService.addBook(book)
        .then(bookNew => {
            toastr.success('Book created');
            dispatch(addBookSuccess(bookNew));
            return true;
        })
        .catch(catchError);
    };
}

export function addBookSuccess(book: IBook) {
    return {
        type: ADD_BOOK_SUCCESS,
        payload: {
            book: book
        }
    };
}

export function getBook(id: string) {
    return function(dispatch: IThunkDispatch) {
        return bookService.get(id)
            .then((book: IBook) => {
                var bookEdited = addZerosToISBN(book);
                dispatch(getBookSuccess(bookEdited));
                return bookEdited; 
            })
            .catch(catchError);
    };
}

export function getBookSuccess(book: IBook) {
    return {
        type: GET_BOOK_SUCCESS,
        payload: {
            book: book,
            comments: book.comments
        }
    };
}

function addZerosToISBN(book: IBook) {
    while (book.isbN10 && book.isbN10.toString().length < 10 && book.isbN10.toString().length > 1) {
        book.isbN10 = '0' + book.isbN10;
    }
    while (book.isbN13 && book.isbN13.toString().length < 13 && book.isbN13.toString().length > 1) {
        book.isbN13 = '0' + book.isbN13;
    }
    return book;
}

export function updateBook(book: IBook) {
    return function (dispatch: IThunkDispatch) {
        return bookService.updateBook(book)
        .then(bookNew => {
            toastr.success('Book updated');
            dispatch(updateBookSuccess(bookNew));
            return true;
        })
        .catch(catchError);
    };
}

export function updateBookSuccess(book: IBook) {
    return {
        type: UPDATE_BOOK_SUCCESS,
        payload: {
            book: book
        }
    };
}

export function deleteBook(book: IBook) {
    return function (dispatch: IThunkDispatch) {
        return bookService.deleteBook(book)
        .then(() => {
            toastr.success('Book deleted');
            dispatch(deleteBookSuccess(book));
            return true;
        })
        .catch(catchError);
    };
}

export function deleteBookSuccess(book: IBook) {
    return {
        type: DELETE_BOOK_SUCCESS,
        payload: {
            book: book
        }
    };
}

export function rateBook(book: IBook, grade: number) {
    return function(dispatch: IThunkDispatch) {
        return bookService.rateBook(book, grade)
        .then((ratedBook: IBook) => {
            toastr.success('Book rated');
            dispatch(rateBookSuccess(ratedBook, grade));
            return true;
        })
        .catch(catchError);
    };
}

export function rateBookSuccess(book: IBook, grade: number) {
    return {
        type: RATE_BOOK_SUCCESS,
        payload: {
            book: book,
            grade: grade
        }
    };
}

export function getUserRating(book: IBook) {
    return function(dispatch: IThunkDispatch) {
        return bookService.getUserRating(book)
        //tslint:disable-next-line:no-any
        .then((rated: any) => {
          if (!rated) {
            dispatch(getUserRatingSuccess(0));
            return false;
          }
          dispatch(getUserRatingSuccess(rated.grade));
          return true;
        })
        .catch((error) => {
            if (!error || !error.errorData || error.errorData.status === 404) {
                dispatch(getUserRatingSuccess(0));
            }
            return false;
        });
    };
}

export function getUserRatingSuccess(grade: number) {
    let rated: boolean =  grade === null ? false : true;
    return {
        type: GET_USER_RATING_SUCCESS,
        payload: {
            rated: rated,
            grade: grade
        }
    };
}

export function addComment(comment: IComment) {
    return function(dispatch: IThunkDispatch) {
        return bookService.addComment(comment)
        .then((book: IBook) => {
            toastr.success('Comment created');
            dispatch(addCommentSuccess(book));
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
    };
}

export function addCommentSuccess(book: IBook) {
    return {
        type: ADD_COMMENT_SUCCESS,
        payload: {
            book: book
        }
    };
}
export function checkoutBooksSuccess(checkouts: ICheckout[]) {
    return {
        type: CHECKOUT_BOOKS_SUCCESS,
        payload: {
            checkouts: checkouts
        }
    };
}

export function updateComment(comment: IComment) {
    return function(dispatch: IThunkDispatch) {
        return bookService.updateComment(comment)
        .then((book: IBook) => {
            toastr.success('Comment updated');
            dispatch(addCommentSuccess(book));
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
    };
}

export function deleteComment(comment: IComment) {
    return function(dispatch: IThunkDispatch) {
        return bookService.deleteComment(comment)
        .then(() => {
            toastr.success('Comment deleted');
            dispatch(deleteCommentSuccess(comment));
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
    };
}

export function deleteCommentSuccess(comment: IComment) {
    return {
        type: DELETE_COMMENT_SUCCESS,
        payload: {
            comment: comment
        }
    };
}
export function checkoutBooks(userId: string, books: IBook[]) {
    return function(dispatch: IThunkDispatch) {
        return bookService.checkoutBooks(userId, books)
        .then((checkouts: ICheckout[]) => {
            if (checkouts.length > 1) {
                toastr.success('Books checked out successfully!');
            } else {
                toastr.success('Book checked out successfully!');
            }
            dispatch(checkoutBooksSuccess(checkouts));
            return true;
        })
        .catch(catchError);
    };
}

export function listAllBooksLoading() {
    return {
        type: LIST_ALL_BOOKS_LOADING,
        payload: {
            booksLoading: true
        }
    };
}

export function searchBooksLoading() {
    return {
        type: SEARCH_BOOKS_LOADING,
        payload: {
            booksLoading: true
        }
    };
}

export function searchBooksSuccess(books: IBook[]) {
    return {
        type: SEARCH_BOOKS_SUCCESS,
        payload: {
            books: books,
            booksLoading: false
        }
    };
}

export function searchBooks(
        name: string, startingRating: number, isbn13: string, isbn10: string, languageId: string, publisherId: string, 
        genreId: string, keywordId: string, authorId: string, sortBy: string, asc: boolean
    ) {
    return function(dispatch: IThunkDispatch) {
        dispatch(searchBooksLoading());
        return bookService.search(name, startingRating, isbn13, isbn10, languageId, publisherId, genreId, keywordId, authorId, sortBy, asc)
            .then(books => {
                dispatch(searchBooksSuccess(books));
                return true;
            })
            .catch(catchError);
    };
} 

export function searchAvailableBooksLoading() {
    return {
        type: SEARCH_AVAILABLE_BOOKS_LOADING,
        payload: {
            booksLoading: true
        }
    };
}

export function searchAvailableBooksSuccess(books: IBook[]) {
    return {
        type: SEARCH_AVAILABLE_BOOKS_SUCCESS,
        payload: {
            books: books,
            booksLoading: false
        }
    };
}

export function searchAvailableBooks(
        name: string, startingRating: number, isbn13: string, isbn10: string, languageId: string, publisherId: string,
        genreId: string, keywordId: string, authorId: string, sortBy: string, asc: boolean
    ) {
    return function(dispatch: IThunkDispatch) {
        //dispatch(searchBooksLoading());
        return bookService.searchAvailableBooks(
                name, startingRating, isbn13, isbn10, languageId, publisherId, genreId,
                keywordId, authorId, sortBy, asc
            )
            .then(books => {
                dispatch(searchAvailableBooksSuccess(books));
                return true;
            })
            .catch(catchError);
    };
} 

export function recommendBooks(bookId: string, fromUserId: string, toUserIds: string[]) {
    return function(dispatch: IThunkDispatch) {
        return  bookService.recommendBook(bookId, fromUserId, toUserIds)
                .then(() => {
                    toastr.success('Recomendation sent successfully');
                    return true;
                })
                .catch(catchError);
            };
}

export function isSubscribed(userId: string, bookId: string) {
    return function(dispatch: IThunkDispatch) {
        return bookService.isSubscribed(userId, bookId)
        .then((subscribedToBook: boolean) => {
            dispatch(isSubscribedSuccess(subscribedToBook));
            return true;
        })
        .catch(catchError);
    };
}

export function isSubscribedSuccess(subscribedToBook: boolean) {
    return {
        type:  IS_SUBSCRIBED_SUCCESS,
        payload: {
            subscribedToBook: subscribedToBook
        }
    };
}

export function subscribeToBook(bookId: string) {
    return function(dispatch: IThunkDispatch) {
        return bookService.subscribe(bookId)
        .then(() => {
            toastr.success('User subscribed.');
            dispatch(isSubscribedSuccess(true));
            return true;
        })
        .catch(catchError);
    };
}

export function unsubscribeToBook(bookId: string) {
    return function(dispatch: IThunkDispatch) {
        return bookService.unsubscribe(bookId)
        .then(() => {
            toastr.success('User unsubscribed.');
            dispatch(isSubscribedSuccess(false));
            return true;
        })
        .catch(catchError);
    };
}

export function subscribeToAll() {
    return function(dispatch: IThunkDispatch) {
        return bookService.subscribeToAll()
        .then(() => {
            toastr.success('User subscribed.');
            return true;
        })
        .catch(catchError);
    };
}

export function unsubscribeFromAll() {
    return function(dispatch: IThunkDispatch) {
        return bookService.unsubscribeFromAll()
        .then(() => {
            toastr.success('User unsubscribed.');
            return true;
        })
        .catch(catchError);
    };
}

export function subscribeUserToBook(userId: string, bookId: string) {
    return function(dispatch: IThunkDispatch) {
        return bookService.subscribeUserToBook(userId, bookId)
        .then(() => {
            toastr.success('User subscribed.');
            return true;
        })
        .catch(catchError);
    };
}
export function unSubscribeUserFromBook(userId: string, bookId: string) {
    return function(dispatch: IThunkDispatch) {
        return bookService.unsubscribeUserFromBook(userId, bookId)
        .then(() => {
            toastr.success('User unsubscribed.');
            return true;
        })
        .catch(catchError);
    };
}

export function getTop5MostReadBooksSuccess(books: IBook[]) { 
    return {
        type: GET_TOP_5_MOST_READ_BOOKS_SUCCESS,
        payload: {
            mostReadBooks: books,
            mostReadBooksLoading: false
        }
    };
}

export function getTop5MostReadBooksLoading() {
    return {
        type: GET_TOP_5_MOST_READ_BOOKS_LOADING,
        payload: {
            mostReadBooksLoading: true
        }
    };
}

export function getTop5MostReadBooks() {
    return function(dispatch: IThunkDispatch) {
        dispatch(getTop5MostReadBooksLoading());
        return bookService.getTop5MostReadBooks()
        .then((books: IBook[]) => {
            dispatch(getTop5MostReadBooksSuccess(books));
            return true;
        })
        .catch(catchError);
    };
}

export function getTop5BestRatedBooksSuccess(books: IBook[]) {
    return {
        type: GET_TOP_5_BEST_RATED_BOOKS_SUCCESS,
        payload: {
            bestRatedBooks: books,
            bestRatedBooksLoading: false
        }
    };
}

export function getTop5BestRatedBooksLoading() {
    return {
        type: GET_TOP_5_BEST_RATED_BOOKS_LOADING,
        payload: {
            bestRatedBooksLoading: true
        }
    };
}

export function getTop5BestRatedBooks() {
    return function(dispatch: IThunkDispatch) {
        dispatch(getTop5BestRatedBooksLoading());
        return bookService.getTop5BooksByRating()
        .then((books: IBook[]) => {
            dispatch(getTop5BestRatedBooksSuccess(books));
            return true;
        })
        .catch(catchError);
    };
}