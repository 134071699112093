import { LIST_ALL_LANGUAGES_SUCCESS, ADD_LANGUAGE_SUCCESS, UPDATE_LANGUAGE_SUCCESS, DELETE_LANGUAGE_SUCCESS } from '../constants/action_types';
import  ILanguage from '../types/language_type';
import languagesService from '../services/languages_service';
import { catchError } from '../helpers/error_helper';
import * as toastr from 'toastr';
import { IThunkDispatch } from '../types/react_types';

export function listAllLanguages() {
    return function ( dispatch: IThunkDispatch ) {
        return languagesService.listAllLanguages()
        .then(languages => {
            dispatch(listAllLanguagesSuccess(languages));
            return true;
        })
        .catch(catchError);
    }; 
}

export function listAllLanguagesSuccess (languages: ILanguage[]) {
    return {
        type: LIST_ALL_LANGUAGES_SUCCESS,
        payload: {
            languages: languages
        }
    };
}

export function addLanguage(language: ILanguage) {
    return function (dispatch: IThunkDispatch) {
        return languagesService.addLanguage(language)
        .then(languageNew => {
            toastr.success('Language created');
            dispatch(addLanguageSuccess(languageNew));
            return true;
        })
        .catch(catchError);
    };
}

export function addLanguageSuccess(language: ILanguage) {
    return {
        type: ADD_LANGUAGE_SUCCESS,
        payload: {
            language: language
        }
    };
}

export function updateLanguage(language: ILanguage) {
    return function (dispatch: IThunkDispatch) {
        return languagesService.updateLanguage(language)
        .then(languageNew => {
            toastr.success('Language updated');
            dispatch(updateLanguageSuccess(languageNew));
            return true;
        })
        .catch(catchError);
    };
}

export function updateLanguageSuccess(language: ILanguage) {
    return {
        type: UPDATE_LANGUAGE_SUCCESS,
        payload: {
            language: language
        }
    };
}

export function deleteLanguage(language: ILanguage) {
    return function (dispatch: IThunkDispatch) {
        return languagesService.deleteLanguage(language)
        .then( () => {
            toastr.success('Language deleted');
            dispatch(deleteLanguageSuccess(language));
            return true;
        })
        .catch(catchError);
    };
}

export function deleteLanguageSuccess (language: ILanguage) {
    return {
        type: DELETE_LANGUAGE_SUCCESS,
        payload: {
            language: language
        }
    };
}