import { LIST_ALL_AUTHORS_SUCCESS, LIST_ALL_AUTHORS_LOADING, CREATE_AUTHOR_SUCCESS, 
    UPDATE_AUTHOR_SUCCESS, DELETE_AUTHOR_SUCCESS, SEARCH_AUTHORS_SUCCESS } from '../constants/action_types';
import IAuthor from '../types/author_type';
import { IAction } from '../types/react_types';

export interface IAuthorsReducer {
    authors: IAuthor[];
    authorsLoading: boolean;
}

var defaultState: IAuthorsReducer = {
    authors: [],
    authorsLoading: false
};

export default function (state: IAuthorsReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_AUTHORS_LOADING:
        case LIST_ALL_AUTHORS_SUCCESS:
        case SEARCH_AUTHORS_SUCCESS:
            return Object.assign({}, state, action.payload);
        case CREATE_AUTHOR_SUCCESS:
            let authorsAfterCreate: IAuthor[] = state.authors.slice();
            authorsAfterCreate.push(action.payload.author);
            return Object.assign({}, state, {authors: authorsAfterCreate});
        case UPDATE_AUTHOR_SUCCESS:
            let updatedAuthor = action.payload.author;
            let authorsAfterUpdate = state.authors.map((a: IAuthor) => a.id === action.payload.author.id ? 
                Object.assign({}, a, updatedAuthor) : a);
            return Object.assign({}, state, {authors: authorsAfterUpdate});
        case DELETE_AUTHOR_SUCCESS:
            let deletedAuthor = action.payload.author;
            let authorsAfterDelete: IAuthor[] = state.authors.slice();
            let index = state.authors.findIndex((author: IAuthor) => author.id === deletedAuthor.id);
            authorsAfterDelete.splice(index, 1);
            return Object.assign({}, state, {authors: authorsAfterDelete});
        default:
            return state;
    }
    
}