import * as React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import IAuthor from '../../types/author_type';
import ILanguage from '../../types/language_type';
import IPublisher from '../../types/publisher_type';
import IGenre from '../../types/genre_type';
import IKeyword from '../../types/keyword_type';
import { IFormEvent, IChangeEvent } from '../../types/react_types';

interface IFilterBooksForm {
    filterParams: { 
        name: string, isbn13: string, isbn10: string, authorId: string, 
        publisherId: string, languageId: string, keywordId: string, genreId: string,
        startingRating: number, sortBy: string, asc: boolean 
    };
    authors: IAuthor[];
    languages: ILanguage[];
    publishers: IPublisher[];
    genres: IGenre[];
    keywords: IKeyword[];
    handleInputChange(e: IChangeEvent): void;
    clearFilter(): void;
    filter(e: IFormEvent): void;
    //tslint:disable-next-line:no-any
    handleSelectChange(e: any, id: string): void;
}

const FilterBooksForm = (props: IFilterBooksForm) => {
    return (
        <form className="filter-form" onSubmit={props.filter}>
            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Name: &nbsp;</label>
                    <input type="text" name="name" value={props.filterParams.name} className="form-control" onChange={props.handleInputChange} />
                </div>
                <div className="filter-col col-md-4">
                    <label>ISBN-13: &nbsp;</label>
                    <input type="text" name="isbn13" value={props.filterParams.isbn13} className="form-control" onChange={props.handleInputChange} />
                </div>
                <div className="filter-col col-md-4">
                    <label>ISBN-10: &nbsp;</label>
                    <input type="text" name="isbn10" value={props.filterParams.isbn10} className="form-control" onChange={props.handleInputChange} />
                </div>
            </div>

            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Author: &nbsp;</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(e: any) => props.handleSelectChange(e, 'authorId')}
                        placeholder="Select author"
                        simpleValue={true}
                        value={props.filterParams.authorId}
                        options={props.authors.map((a: IAuthor) => {return {label: a.name, value: a.id}; })}
                        name="authors"
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Language: &nbsp;</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(e: any) => props.handleSelectChange(e, 'languageId')}
                        placeholder="Select language"
                        simpleValue={true}
                        value={props.filterParams.languageId}
                        options={props.languages.map((a: ILanguage) => {return {label: a.name, value: a.id}; })}
                        name="languages"
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Publisher: &nbsp;</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(e: any) => props.handleSelectChange(e, 'publisherId')}
                        placeholder="Select publisher"
                        simpleValue={true}
                        value={props.filterParams.publisherId}
                        options={props.publishers.map((a: IPublisher) => {return {label: a.name, value: a.id}; })}
                        name="publishers"
                    />
                </div>
            </div>

            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Genre: &nbsp;</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(e: any) => props.handleSelectChange(e, 'genreId')}
                        placeholder="Select genre"
                        simpleValue={true}
                        value={props.filterParams.genreId}
                        options={props.genres.map((a: IGenre) => {return {label: a.name, value: a.id}; })}
                        name="genres"
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Keyword: &nbsp;</label>
                    <Select
                        closeOnSelect={true}
                        //tslint:disable-next-line:no-any
                        onChange={(e: any) => props.handleSelectChange(e, 'keywordId')}
                        placeholder="Select keyword"
                        simpleValue={true}
                        value={props.filterParams.keywordId}
                        options={props.keywords.map((a: IKeyword) => {return {label: a.name, value: a.id}; })}
                        name="keywords"
                    />
                </div>
                <div className="filter-col col-md-4">
                    <label>Min. rating: </label>
                    <div className="row">
                        <div className=" filter-col col-md-4">
                            <input 
                                type="number" 
                                name="startingRating" 
                                className="form-control" 
                                min="0" 
                                max="5" 
                                value={props.filterParams.startingRating} 
                                onChange={props.handleInputChange} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row row-item">
                <div className="filter-col col-md-4">
                    <label>Sort by: </label>
                    <select name="sortBy" value={props.filterParams.sortBy} className="form-control" onChange={props.handleInputChange}>
                        <option value="name">Name</option>
                        <option value="rating">Rating</option>
                        <option value="isbn10">ISBN-10</option>
                        <option value="isbn13">ISBN-13</option>
                        <option value="language">Language</option>
                        <option value="publisher">Publisher</option>
                        <option value="availablecopies">Available copies</option>
                        <option value="totalcopies">Total copies</option>
                    </select>
                </div>
                <div className="filter-col col-md-4">
                    <label>Ascending: </label>
                    <input type="checkbox" checked={props.filterParams.asc} name="asc" onChange={props.handleInputChange} className="checkbox" />
                </div>
            </div>

            <div>
                <button type="submit" className="btn btn-primary">Filter</button>&nbsp;
                <button type="button" className="btn btn-default" onClick={props.clearFilter}>Clear filter</button>
            </div>
        </form>
    );
};

export default FilterBooksForm;