import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IRank from '../../types/rank_type';
import { MODAL_STYLES, LINK_BASE } from '../../constants/other_constants';
import Modal from 'react-modal';
import { IFormEvent, IThunkDispatch, IClickEvent, IChangeEvent } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface ICreateUpdateRankProps {
    rank: IRank;
    editing: boolean;
    isModalOpen: boolean;
    avatar: string;
    imageChanged: boolean;
    removeImage(e: IClickEvent): void;
    // tslint:disable-next-line:no-any
    callFileChooser(e: any): void;
    handleSubmitSave(): Promise<void>;
    handleInputChange(e: IChangeEvent): void;
    closeModal(): void;
    chooseImage(e: IChangeEvent): void;
}

interface ICreateUpdateRankState {
  saveClicked: boolean;
}

class CreateUpdateRankModal extends React.Component<ICreateUpdateRankProps, ICreateUpdateRankState> {

    state = {
      saveClicked: false
    };

    enableSave = () => {
      this.setState({ saveClicked: false });
    }

    onFormSubmit = (e: IFormEvent) => {
      e.preventDefault();
      this.setState({ saveClicked: true });
      this.props.handleSubmitSave().then(this.enableSave).catch(this.enableSave);
    }

    render() {
        let rank = this.props.rank; 
        let hiddenIcon =  this.props.avatar ? 'glyphicon glyphicon-picture icon-padding-xxl hidden' : 'glyphicon glyphicon-picture icon-padding-xxl';
        const imagePrefix = this.props.imageChanged ? 'data:image/jpeg;base64, ' : LINK_BASE;
        let image = 
        (
            <div className="align-center" onClick={() => this.props.callFileChooser(this)}>
                <span id="contact-icon" className={hiddenIcon}/>
                {this.props.avatar ?
                    <img id="contact-photo" src={imagePrefix + this.props.avatar}  className="img-sm"  alt="" />
                : ''}
            </div>
        ); 
        let xMark = this.props.avatar === '' ? 
            <div/> :
            (
                <i 
                    className="image-remove-icon glyphicon glyphicon-remove fa-2x" 
                    onClick={this.props.removeImage}  
                    aria-hidden="true"
                />
            );
    
        return (
            <Modal isOpen={this.props.isModalOpen} style={MODAL_STYLES}>
                <h3>{this.props.editing ? 'Edit rank' : 'Create new rank'}</h3>
                <form onSubmit={this.onFormSubmit}>
                    <div className="form-group">
                        <label className="required-field">Name: </label>
                        <input name="name" value={rank.name} type="text" className="form-control" onChange={this.props.handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label className="required-field">Minimum checkouts: </label>
                        <input 
                            name="minimumCheckouts" 
                            value={rank.minimumCheckouts} 
                            type="number" 
                            min="0" 
                            className="form-control" 
                            onChange={this.props.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="required-field">New rank email message: </label>
                        <textarea 
                            name="newRankMessage" 
                            value={rank.newRankMessage} 
                            rows={5} 
                            className="form-control description-textarea" 
                            onChange={this.props.handleInputChange} 
                        />
                    </div>
                    <label>Avatar: </label>
                    <div className="form-group text-center center-block img-wrap">
                        {image}{xMark}
                        <input 
                            type="file" 
                            accept="image/jpeg, image/png" 
                            id="photoUpload" 
                            // tslint:disable-next-line:jsx-no-string-ref
                            ref="photoUpload" 
                            onChange={this.props.chooseImage} 
                            name="image" 
                            className="btn btn-file form-control hidden" 
                        />
                    </div>
                    <br/>
                    <button type="button" className="btn btn-default" onClick={this.props.closeModal}>Cancel</button>
                    <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {

    };
}

function matchDispatchToProps(dispatch: IThunkDispatch) {
    return bindActionCreators(
        { }, 
        dispatch
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(CreateUpdateRankModal);