import { LOGIN_SUCCESS, LOAD_USER_FROM_LOCAL_STORAGE, LOGOUT_SUCCESS } from '../constants/action_types';
import accountService from '../services/account_service';
import * as toastr from 'toastr';
import { catchError } from '../helpers/error_helper';
import { IThunkDispatch } from '../types/react_types';

export function login(username: string, password: string) {
    return function(dispatch: IThunkDispatch) {
        return accountService.login(username, password)
            //tslint:disable-next-line no-any
            .then((loginResult: any) => {
                toastr.success('You are now logged in!');
                let user = {
                    jwt: loginResult.jwt,
                    user: loginResult.user
                };
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(loginSuccess(user));
                return true;
            })
            .catch(catchError);
    };
} 

//tslint:disable-next-line no-any
export function loginSuccess(user: any) {
    return {
        type: LOGIN_SUCCESS,
        payload: {
            loggedInUser: user
        }
    };
}

export function loadUserFromLocalStorage() {
    let userString = localStorage.getItem('user');
    if (userString == null) {
        return {
            type: LOAD_USER_FROM_LOCAL_STORAGE,
            payload : {
                loggedInUser: null
            }
        };
    }
    let user = JSON.parse(userString ? userString : '');
    if (user && user.jwt) {
        return {
            type: LOAD_USER_FROM_LOCAL_STORAGE,
            payload: {
                loggedInUser: user
            }
        };
    } else {
        return {
            type: LOAD_USER_FROM_LOCAL_STORAGE,
            payload: {
                loggedInUser: null
            }
        };
    }
}

export function logout(showToastr: boolean = true) {
    localStorage.removeItem('user');
    if (showToastr) {
        toastr.success('Logged out.');
    }
    return {
        type: LOGOUT_SUCCESS,
        payload: {
            loggedInUser: null
        }
    };
}