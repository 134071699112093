export function formatDate(dateString: string) {
    if (!dateString || /^\s+$/.test(dateString)) {
        return '';
    }
    if (isNaN(Date.parse(dateString))) {
        return dateString;
    }
    let date = new Date(dateString);

    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return `${year}-${month}-${day}`;
}

export function stringToDate(dateString: string) {
    if (!dateString || /^\s+$/.test(dateString)) {
        return null;
    }
    let date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return null;
    }
    return date;
}

export function printDate (date: Date) {
    let newDate = new Date(date);
  /*  var monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
      ];*/
    let returnValue = newDate.getFullYear() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getDate();
    return returnValue;
}
export function compareStringDates(dateString1: string, dateString2: string) {
    let date1 = stringToDate(dateString1);
    let date2 = stringToDate(dateString2);
    if (date1 == null || date2 == null) {
        return 0;
    }
    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
}

export function parseDateStringFromPicker(dateString: string) {
    let dateVals = dateString.split('-');
    let year: number = Number(dateVals[0]);
    let month: number = Number(dateVals[1]) - 1;
    let day: number = Number(dateVals[2]);
    return new Date(year, month,  day);
}

export function parseDateToString(date: Date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}
export function calucalatePeriod(dateStringFrom: string, dateStringTo: string) {
    let dateFrom = Date.parse(dateStringFrom);
    let dateTo = Date.parse(dateStringTo);
    let difference = dateTo - dateFrom;
    let diffDays = Math.ceil(difference / (24 * 60 * 60 * 1000));
    let weeks = Math.floor(diffDays / 7);
    let restOfDays = diffDays - weeks * 7;
    let weeksWord = weeks === 1 ? ' week ' : ' weeks ';
    let daysWord = restOfDays === 1 ? ' day' : ' days';
    if (weeks === 0) {
        return diffDays + daysWord;
    } else  {
        if (restOfDays === 0) {
            return weeks + weeksWord; 
        } else {
            return weeks + weeksWord + restOfDays + daysWord;
        }
    }
}

const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'May', 'Jun', 'Jul',
    'Aug', 'Sept', 'Oct',
    'Nov', 'Dec'
];
export function printDateWithMonthName(dateString: string) {
    if (!dateString || /^\s+$/.test(dateString)) {
        return '';
    }
    if (isNaN(Date.parse(dateString))) {
        return dateString;
    }
    let date = new Date(dateString);

    let month = monthNames[date.getMonth()];
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    if (day.length < 2) {
        day = '0' + day;
    }

    return `${day}-${month}-${year}`;
}