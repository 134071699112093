import { LINK_BASE, DEFAULT_CONTENT_TYPE_HEADERS } from '../constants/other_constants';
import { handleErrors } from '../helpers/error_helper';

class AccountService {

    login(username: string, password: string) {
        return fetch(LINK_BASE + '/Account', {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            }),
            headers: DEFAULT_CONTENT_TYPE_HEADERS
        })
        .then(handleErrors)
        .then(response => response.json() as Promise<string>);
    }

}

export default new AccountService();