import * as React from 'react';
import Authors from './authors/authors';
import Users from './users/users';
import Roles from './roles/roles';
import Ranks from './ranks/ranks';
import { Route, Switch } from 'react-router-dom';
import Languages from './languages/languages';
import Keywords from './keywords/keywords';
import Genres from './genres/genres';
import Publishers from './publishers/publishers';
import Books from './books/books';
import BookDetails from './books/bookDetails';
import BookForm from './books/bookForm';
import CreateCheckout from './checkouts/create_checkout';
import Checkouts from './checkouts/checkouts';
import Subscriptions from './subscriptions/subscriptions';
import Wishlist from './wishlist/wishlist';
import CreateWish from './wishlist/create_wish';
import Configurations from './configurations/configurations';
import Authorization from '../helpers/authorizationHOC';
import NotAuthorized from './not_authorized';
import Toplist from './top_list/toplist';
import Profile from './users/profile';
import Permissions from './roles/permissions';
import NotFound from './not_found';
import IUser from '../types/user_type';

interface IPrivateRoutesProps {
    loggedInUser: IUser | null;
}

export const PrivateRoutes = (props: IPrivateRoutesProps) => {
    let user = props.loggedInUser;
    return (
        <div>
            <Switch>
                <Route 
                    exact={true} 
                    path="/" 
                    component={Authorization(Books, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.ListAll'], user)} 
                />
                <Route 
                    path="/books" 
                    component={Authorization(Books, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.ListAll'], user)} 
                />
                <Route 
                    path="/authors" 
                    component={Authorization(Authors, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IAuthorService.ListAll'], user)}
                />
                <Route 
                    path="/users" 
                    component={Authorization(Users, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IUserService.ListAll'], user)}
                />
                <Route 
                    path="/roles" 
                    component={Authorization(Roles, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IRoleService.ListAll'], user)}
                />
                <Route 
                    path="/ranks" 
                    component={Authorization(Ranks, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IRankService.ListAll'], user)}
                />
                <Route 
                    path="/languages" 
                    component={Authorization(Languages, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.ILanguageService.ListAll'], user)}
                />
                <Route 
                    path="/keywords" 
                    component={Authorization(Keywords, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IKeywordService.ListAll'], user)} 
                />
                <Route 
                    path="/genres" 
                    component={Authorization(Genres, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IGenreService.ListAll'], user)}
                />
                <Route 
                    path="/publishers" 
                    component={Authorization(
                        Publishers, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IPublisherService.ListAll'], 
                        user)
                    } 
                />
                <Route 
                    path="/bookDetails/:id?" 
                    component={Authorization(BookDetails, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.Get'], user)}
                />
                <Route 
                    path="/bookForm/:id?" 
                    component={Authorization(
                        BookForm, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.Update', 
                        'Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.Create'], 
                        user)
                    } 
                />
                <Route 
                    path="/createCheckout" 
                    component={Authorization(
                        CreateCheckout, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.CheckoutBook'], 
                        user)
                    } 
                />
                <Route 
                    path="/checkouts/:bookId?/:userId?" 
                    component={Authorization(
                        Checkouts, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.ICheckoutService.ListAll', 
                        'Vega.Librarian.BusinessLogic.Services.Contracts.Services.ICheckoutService.History'], 
                        user)
                    } 
                />
                <Route
                    path="/subscriptions/:bookId?/:userId?" 
                    component={Authorization(
                        Subscriptions, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IBookService.SearchSubscriptions'], 
                        user)
                    } 
                />
                <Route
                    path="/wishlist" 
                    component={Authorization(Wishlist, ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IWishlistService.ListAll'], user)} 
                />
                <Route 
                    path="/createWish" 
                    component={Authorization(
                        CreateWish, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IWishlistService.Create'], 
                        user)
                    } 
                />
                <Route 
                    path="/configurations" 
                    component={Authorization(
                        Configurations, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IConfigurationService.GetCheckoutDuration'], 
                        user)
                    } 
                />
                <Route 
                    path="/permissions"
                    component={Authorization(
                        Permissions, 
                        ['Vega.Librarian.BusinessLogic.Services.Contracts.Services.IPermissionService.ListAll'], 
                        user)
                    }
                />
                <Route path="/toplist" component={Toplist} />
                <Route path="/profile/:id" component={Authorization(Profile, [], user)} />

                <Route path="/notAuthorized" component={NotAuthorized} />

                <Route path="/login" component={() => <div/>} />
                <Route component={NotFound} />
            </Switch>
        </div>
    );
};
