import * as React from 'react';
import UserRow from './user_row';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IUserTableProps {
    users: IUser[];
    loggedInUser: IUser | null;
    rowClicked?(e: IClickEvent, user: IUser): void;
    renderRowActionButtons(user: IUser): JSX.Element;
    renderRowSubscriptionButtons?(user: IUser): JSX.Element;
}

const UserTable = (props: IUserTableProps) => {
    return (
        <table className="table table-striped">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">Username</td>
                    <td className="bold">First name</td>
                    <td className="bold">Last name</td>
                    <td className="bold">Email</td>
                    <td className="bold">Role</td>
                    <td className="bold">Read books</td>
                    <td className="bold">Rank</td>
                    <td className="bold">Active</td>
                    <td className="bold">Action</td>
                    <td/>
                </tr>
            </thead>
            <tbody>
                {props.users.map((user: IUser, index: number) => 
                    <UserRow 
                        key={user.id} 
                        number={index + 1} 
                        loggedInUser={props.loggedInUser} 
                        user={user} 
                        actionButtons={props.renderRowActionButtons(user)}
                        subscriptionButtons={props.renderRowSubscriptionButtons && props.renderRowSubscriptionButtons(user)} 
                        rowClicked={props.rowClicked} 
                    />)}
            </tbody>
        </table>
    );
};

export default UserTable;