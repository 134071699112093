import * as React from 'react';
import ILanguage from '../../types/language_type';
import { connect } from 'react-redux';
import { MODAL_STYLES } from '../../constants/other_constants';
import Modal from 'react-modal';
import * as toastr from 'toastr';
import { addLanguage, updateLanguage } from '../../actions/languages_actions';
import { IFormEvent, IChangeEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface ILanguageModalState {
    language: ILanguage;
    editing: boolean;
    closing: boolean;
    saveClicked: boolean;
}

interface ILanguageModalProps {
    editing: boolean;
    editedLanguage: ILanguage;
    closing: boolean;
    addLanguage(lang: ILanguage): Promise<boolean>;
    updateLanguage(lang: ILanguage): Promise<boolean>;
    closeModal(): void;
}

class LanguageModal extends React.Component<ILanguageModalProps, ILanguageModalState> {
    constructor(props: ILanguageModalProps) {
        super(props);
        this.state = {
            language: {
                id: this.props.editedLanguage.id ? this.props.editedLanguage.id : '', 
                name: this.props.editedLanguage.name ? this.props.editedLanguage.name : ''
            },
            editing: props.editing,
            closing: props.closing,
            saveClicked: false
        };
    }
    
    render() {
        if (!this.state.closing) {
            return (
                <Modal isOpen={!this.state.closing} style={MODAL_STYLES}>
                    <h3>{this.state.editing ? 'Edit language ' : 'Create new language'}</h3>
                    <form onSubmit={this.handleSubmitSave}>
                        <div className="form-group">
                            <label className="required-field"> Name:</label>
                            <input 
                                type="text"  
                                value={this.state.language.name}  
                                name="name" 
                                className="form-control" 
                                onChange={this.handleInputChange}
                            />
                        </div>
                            <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                            <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                        </form>
                </Modal>
            );
        } else { 
            return <div/>;
        }
    }

    handleSubmitSave = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.validateForm(this.state.language)) {
            return;
        }
        this.setState({ saveClicked: true });
        if (this.state.editing) {
             this.props.updateLanguage(this.state.language).then((success: boolean) => {
                this.setState({
                    editing: false, language : {id: '', name: ''}, saveClicked: false
                });
                this.props.closeModal();
            }).catch(() => this.setState({ saveClicked: false }));
        } else {
            this.props.addLanguage(this.state.language).then((success: boolean) => {
                if (success) {
                    this.setState({
                        editing: false, language : {id: '', name: ''}, saveClicked: false
                    });
                    this.props.closeModal();
                }
            }).catch(() => this.setState({ saveClicked: false }));
        }
    }

    handleInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newLanguage = Object.assign({}, prevState.language, {
                [name]: value
            });
            return {language: newLanguage};
        });
    }

    validateForm = (language: ILanguage) => {
        if (!language.name || language.name.trim() === '') {
            toastr.error('Language name cannot be empty.');
            return false;
        }
        return true;
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    addLanguage: (lang: ILanguage) => dispatch(addLanguage(lang)),
    updateLanguage: (lang: ILanguage) => dispatch(updateLanguage(lang))
});

export default connect(mapStateToProps, matchDispatchToProps)(LanguageModal);