import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import ISubscription from '../../types/subscription_type';
import IUser from '../../types/user_type';

interface ISubscriptionRowProps {
    subscription: ISubscription;
    loggedInUser: IUser | null;
    number: number;
    deleteSubscription(userId: string, bookId: string, sameUser: boolean): void;
}

const SubscriptionRow = (props: ISubscriptionRowProps) => {
    let subscription = props.subscription;
    let sameUser = props.loggedInUser && props.loggedInUser.id === subscription.user.id;
    return (
        <tr key={subscription.user.id + subscription.book.id}>
            <td className="bold">{props.number}</td>
            <td><a href={`/profile/${subscription.user.id}`}>{subscription.user.firstName + ' ' + subscription.user.lastName}</a></td>
            <td><a href={`/bookDetails/${subscription.book.id}`}>{subscription.book.name}</a></td>
            <td>
                { sameUser && isAllowed(props.loggedInUser, 'IBookService.UnsubscribeSelf') ?
                    <button 
                        type="button" 
                        className="btn btn-danger" 
                        onClick={() => props.deleteSubscription(subscription.user.id, subscription.book.id, true)}
                    >
                        Unsubscribe
                    </button>
                    : <div/>
                }
                { !sameUser && isAllowed(props.loggedInUser, 'IBookService.UnsubscribeUser') ?
                    <button 
                        type="button" 
                        className="btn btn-danger"
                        onClick={() => props.deleteSubscription(subscription.user.id, subscription.book.id, false)}
                    >
                        Unsubscribe
                    </button>
                    : <div/>
                }
            </td>
        </tr>
    );
}; 

export default SubscriptionRow;