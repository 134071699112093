import * as React from 'react';
import { connect } from 'react-redux';
import IUser from '../../types/user_type';
import { getUser, changeRole, activateUser, deactivateUser } from '../../actions/users_actions';
import { EMPTY_GUID, LINK_BASE } from '../../constants/other_constants';
import { isAllowed } from '../../helpers/checkAuthorization';
import ChangeRoleModal from './change_role_modal';
import IRole from '../../types/role_type';
import AreYouSureModal from '../are_you_sure_modal';
import { listAllRoles } from '../../actions/roles_actions';
import { subscribeUserToAll, unsubscribeUserFromAll } from '../../actions/subscriptions_actions';
import { IFormEvent, IChangeEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { RouteComponentProps } from 'react-router';

interface IProfileRouteParams {
    id: string;
}

interface IProfileProps extends RouteComponentProps<IProfileRouteParams> {
    user: IUser | null;
    roles: IRole[];
    loggedInUser: IUser | null;
    getUser(id: string): Promise<boolean>;
    listAllRoles(): Promise<boolean>;
    subscribeUserToAll(userId: string): Promise<boolean>;
    unsubscribeUserFromAll(userId: string): Promise<boolean>;
    changeRole(user: IUser, role: IRole): Promise<boolean>;
    activateUser(user: IUser): Promise<boolean>;
    deactivateUser(user: IUser): Promise<boolean>;
}

interface IProfileState {
    isChangeRoleModalOpen: boolean;
    role: IRole | null | undefined;
    areYouSureModalParams: {
        title: string;
        isOpen: boolean;
        action: Function
    };
    rolesLoading: boolean;
    userLoading: boolean;
}

class Profile extends React.Component<IProfileProps, IProfileState> {
    constructor(props: IProfileProps) {
        super(props);
        this.state = {
            isChangeRoleModalOpen: false,
            role: null,
            areYouSureModalParams: {
                title: '',
                isOpen: false,
                action: () => {}
            },
            rolesLoading: true,
            userLoading: true
        };
    }

    public componentWillMount() {
        let id = this.props.match.params.id;
        if (id) {
            this.props.getUser(id)
                .then((success: boolean) => {
                  if (success) {
                     this.setState({userLoading: false}); 
                  } else {
                    this.props.history.push('/notfound');
                  }
                });
        }
    }
    
    public render() {
        if (this.state.userLoading) {
            return <h3>Loading...</h3>;
        }
        if (!this.props.user) {
            return null;
        }
        let changeRoleModal = this.state.isChangeRoleModalOpen ? 
            (
            <ChangeRoleModal 
                role={this.state.role} 
                roles={this.props.roles} 
                user={this.props.user} 
                isModalOpen={this.state.isChangeRoleModalOpen}
                rolesLoading={this.state.rolesLoading} 
                changeRole={this.changeRole}
                handleSelectInModalChange={this.handleSelectInModalChange} 
                closeModal={this.closeChangeRoleModal} 
            />)
            : <div/>;
        return (
            <div className="row">
                <div className="col-md-offset-1 col-md-10">
                    <div className="entry">
                        <div className="row-item">
                            <h1 className="h2 title-underline">Profile: {this.props.user.firstName} {this.props.user.lastName}</h1>
                            <div className="row-item">
                                <a href={'/checkouts/' + EMPTY_GUID + '/' + this.props.user.id}>
                                    <button type="button" className="btn btn-default">See checkout history</button>
                                </a>
                            </div>
                            {
                                this.props.user.isActive && isAllowed(this.props.loggedInUser, 'IUserService.Deactivate') ?
                                <div className="row-item">
                                    <button 
                                        type="button" 
                                        className="btn btn-danger" 
                                        onClick={() => this.openAreYouSureModal(
                                            () => this.props.user && this.props.deactivateUser(this.props.user), 'Deactivating user'
                                        )}
                                    >
                                        Deactivate user
                                    </button>
                                </div> : ''
                            }
                            {
                                !this.props.user.isActive && isAllowed(this.props.loggedInUser, 'IUserService.Activate') ?
                                <div className="row-item">
                                    <button 
                                        type="button" 
                                        className="btn btn-danger" 
                                        onClick={() => this.openAreYouSureModal(
                                            () => this.props.user && this.props.activateUser(this.props.user), 
                                            'Activating user')}
                                    >
                                        Activate user
                                    </button>
                                </div> : ''
                            }
                            {
                                this.props.user.isActive && isAllowed(this.props.loggedInUser, 'IUserService.ChangeRole') ?
                                <div className="row-item">
                                    <button type="button" className="btn btn-warning" onClick={this.openChangeRoleModal}>Change role</button>
                                </div> : ''
                            }
                            {
                                this.props.user.isActive && isAllowed(this.props.loggedInUser, 'IBookService.SubscribeUserToAll') ?
                                <div className="row-item">
                                    <button type="button" className="btn btn-warning" onClick={this.subscribeUserToAll}>Subscribe user to all</button>
                                </div> : ''
                            }
                            {
                                this.props.user.isActive && isAllowed(this.props.loggedInUser, 'IBookService.UnsubscribeUserFromAll') ?
                                <div className="row-item">
                                    <button 
                                        type="button" 
                                        className="btn btn-warning" 
                                        onClick={this.unsubscribeUserFromAll}
                                    >
                                        Unsubscribe user from all
                                    </button>
                                </div> : ''
                            }
                        </div>
                        <h4>Profile details:</h4>
                        <div className="row row-item">
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">First name:</div>
                                    {this.props.user.firstName}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">Last name:</div>
                                    {this.props.user.lastName}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">Username:</div>
                                    {this.props.user.username}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">E-mail:</div>
                                    {this.props.user.email}
                                </div>
                            </div>
                                          
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">Role:</div>
                                    {this.props.user.role ? this.props.user.role.name : ''}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">Read books:</div>
                                    {this.props.user.readBooks}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">Status:</div>
                                    {this.props.user.isActive ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row-item">
                                    <div className="label-name">Rank:</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {this.props.user.rank ? this.props.user.rank.name : ''}
                                        { 
                                            this.props.user.rank &&
                                            <img 
                                                className="tiny-rank-image" 
                                                src={LINK_BASE + this.props.user.rank.avatar}
                                                title={
                                                    this.props.user.rank ? 
                                                        this.props.user.firstName + ' ' + this.props.user.lastName 
                                                            + '\'s rank is "' + this.props.user.rank.name 
                                                        : '"'
                                                } 
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {changeRoleModal}
                        <AreYouSureModal 
                            title={this.state.areYouSureModalParams.title} 
                            isOpen={this.state.areYouSureModalParams.isOpen} 
                            action={this.state.areYouSureModalParams.action}
                            close={this.closeAreYouSureModal} 
                            description={null} 
                        />
                    </div>
                </div>
            </div>
        );
    }

    openChangeRoleModal = () => {
        this.props.listAllRoles()
            .then((success: boolean) => {
                if (success) {
                    this.setState({
                        isChangeRoleModalOpen: true,
                        role: this.props.user && this.props.user.role ? 
                            this.props.roles.find((role) => !!this.props.user && role.id === (this.props.user.role.id)) 
                            : null,
                        rolesLoading: false
                    });
                }
            });
    }

    closeChangeRoleModal = () => {
        this.setState({
            isChangeRoleModalOpen: false
        });
    }

    handleSelectInModalChange = (e: IChangeEvent) => {
        const target: IAnyTargetType = e.target;
        let roleId = target.value;
        let newRole = this.props.roles.find((role) => role.id === roleId);
        this.setState({
            role: newRole
        });
    }

    changeRole = (e?: IFormEvent) => {
        if (e) {
            e.preventDefault();
        }
        if (this.props.user && this.state. role) {
            return this.props.changeRole(this.props.user, this.state.role)
            .then((good: boolean) => {
                if (good) {
                    this.closeChangeRoleModal();
                }
            });
        }
        return Promise.reject();
    }

    subscribeUserToAll = () => {
        if (this.props.user) {
            this.props.subscribeUserToAll(this.props.user.id);
        }
    }

    unsubscribeUserFromAll = () => {
        if (this.props.user) {
            this.props.unsubscribeUserFromAll(this.props.user.id);
        }
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IProfileState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IProfileState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        user: store.usersReducer.user,
        loggedInUser: store.accountReducer.loggedInUser.user,
        roles: store.rolesReducer.roles
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    getUser: (id: string) => dispatch(getUser(id)),
    listAllRoles: () => dispatch(listAllRoles()),
    subscribeUserToAll: (userId: string) => dispatch(subscribeUserToAll(userId)),
    unsubscribeUserFromAll: (userId: string) => dispatch(unsubscribeUserFromAll(userId)),
    changeRole: (user: IUser, role: IRole) => dispatch(changeRole(user, role)),
    activateUser: (user: IUser) => dispatch(activateUser(user)),
    deactivateUser: (user: IUser) => dispatch(deactivateUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);