import * as React from 'react';
import jwt_decode from 'jwt-decode';
import { PrivateRoutes } from './private_routes';
import NavMenu from './nav_menu';
import { connect } from 'react-redux';
import { loadUserFromLocalStorage, logout } from '../actions/account_actions';
import IUser from '../types/user_type';
import { History } from 'history';
import { ILibrarianStore } from '../reducers/all_reducers';
import { IThunkDispatch } from '../types/react_types';

interface IPrivateAppProps {
    loggedInUser: IUser | null;
    history: History;
    loadUserFromLocalStorage(): void;
    logout(showToastr: boolean): void;
}

class PrivateApp extends React.Component<IPrivateAppProps, {}> {
    constructor(props: IPrivateAppProps) {
        super(props); 
        this.state = {};
    }

    JWTValid = () => {
        const user = localStorage.getItem('user');
        if (!user) {
            this.logout(false);
            return;
        }
        try {
            const jwt = JSON.parse(user || '').jwt;
            // tslint:disable-next-line:no-any
            const jwtObject: any = jwt_decode(jwt);
            if (new Date(jwtObject.exp * 1000) < new Date()) {
                this.logout(false);
            }
        } catch (e) {
            this.logout(false);
        }
    }

    public componentWillMount() {
        this.props.loadUserFromLocalStorage();
        this.JWTValid();
    }

    public render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div>
                        <NavMenu logout={this.logout} loggedInUser={this.props.loggedInUser} />
                    </div>
                    <div className="main">
                        <PrivateRoutes loggedInUser={this.props.loggedInUser} />
                    </div>
                </div>
            </div>
        );
    }

    logout = (showToastr: boolean = true) => {
        this.props.logout(showToastr);
        this.props.history.push('/login');
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    loadUserFromLocalStorage: () => dispatch(loadUserFromLocalStorage()),
    logout: (showToastr: boolean) => dispatch(logout(showToastr))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateApp);