import * as React from 'react';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IUserRowProps {
    user: IUser;
    loggedInUser: IUser| null;
    number: number;
    actionButtons: JSX.Element;
    subscriptionButtons?: JSX.Element;
    rowClicked?(e: IClickEvent, user: IUser): void;
}

const UserRow = (props: IUserRowProps) => {
    let user = props.user;

    return (
        <tr 
            key={user.id} 
            className="mark-row" 
            onClick={(e: IClickEvent) => { 
                    if (props.rowClicked) {
                        props.rowClicked(e, user);
                    }
                }
            }
        >
            <td className="bold">{props.number}</td>
            <td>{user.username}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.role != null ? user.role.name : ''}</td>
            <td>{user.readBooks}</td>
            <td>{user.rank != null ? user.rank.name : ''}</td>
            <td>{user.isActive ? 'Yes' : 'No'}</td>
            <td>
                <div style={{minHeight: '100%', height: '100%', overflow: 'hidden', padding: '0px', fill: 'true', fontSize: '15px'}}>
                    {props.actionButtons}
                </div>
            </td>
            <td>
            {
                user.isActive && props.subscriptionButtons
            }
            </td>
        </tr>
    );
};

export default UserRow;