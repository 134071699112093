import { LOGIN_SUCCESS, LOAD_USER_FROM_LOCAL_STORAGE, LOGOUT_SUCCESS } from '../constants/action_types';
import IUser from '../types/user_type';
import { IAction } from '../types/react_types';

export interface IAccountUser {
    jwt: string;
    user: IUser | null;
}

export interface IAccountReducer {
    loggedInUser: IAccountUser;
}

var defaultState: IAccountReducer = {
    loggedInUser: {
        jwt: '',
        user: null
    }
};

export default function (state: IAccountReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LOGIN_SUCCESS:
        case LOAD_USER_FROM_LOCAL_STORAGE:
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}