import { LIST_ALL_PUBLISHERS_SUCCESS, ADD_PUBLISHER_SUCCESS, UPDATE_PUBLISHER_SUCCESS, DELETE_PUBLISHER_SUCCESS } from '../constants/action_types';
import IPublisher from '../types/publisher_type';
import { IAction } from '../types/react_types';

export interface IPublishersReducer {
    publishers: IPublisher[];
}

var defaultState: IPublishersReducer = {
    publishers : []
};

export default function (state: IPublishersReducer = defaultState, action: IAction) {
    switch (action.type) {
        case LIST_ALL_PUBLISHERS_SUCCESS:
            return Object.assign({}, state, {publishers: action.payload.publishers});
        case ADD_PUBLISHER_SUCCESS:
            let publishersAfterCreate: IPublisher[] = state.publishers.slice();
            publishersAfterCreate.push(action.payload.publisher);
            let publisher = Object.assign({}, state, {publishers: publishersAfterCreate});
            return publisher;
        case UPDATE_PUBLISHER_SUCCESS:
            let updatedPublisher = action.payload.publisher;
            let publishersAfterUpdate = state.publishers.map((p: IPublisher) => 
                (p.id === updatedPublisher.id) ? 
                    Object.assign({}, p, updatedPublisher) : 
                    p
            );
            return Object.assign({}, state, {publishers: publishersAfterUpdate});
        case DELETE_PUBLISHER_SUCCESS:
            let deletedPublisher = action.payload.publisher;
            let publishersAfterDelete = state.publishers.filter((p: IPublisher) => p.id !== deletedPublisher.id);
            return Object.assign({} , state, {publishers: publishersAfterDelete});
        default:
            return state;
    }
}