import * as React from 'react';
import IPermission from '../../types/permission_type';

interface IPermissionRowProps {
    permission: IPermission;
    addedPermissions?: IPermission[];
    number: number;
    showButtons: boolean;
    handleCheckboxChanged?(added: boolean, permission: IPermission): void;
    handleRowClick?(permission: IPermission): void;
}

class PermissionRow extends React.Component<IPermissionRowProps, {}> {
    handleRowClick = () => {
        if (this.props.handleRowClick) {
            this.props.handleRowClick(this.props.permission);
        }
    }

    handleCheckboxChanged = (added: boolean) => {
        if (this.props.handleCheckboxChanged) {
            this.props.handleCheckboxChanged(added, this.props.permission);
        }
    }

    render() {
        const props = this.props;
        let permission = props.permission;
        let added = !!props.addedPermissions && !!props.addedPermissions.find((p: IPermission) => p.id === permission.id);
        return (
            <tr 
                key={permission.id} 
                className="table-row" 
                id="tableRow" 
                onClick={this.handleRowClick}
            >
                <td className="bold">{props.number}</td>
                <td>{permission.name}</td>
                {props.showButtons ? 
                    <td>
                        <div className="checkbox">
                            <label>
                                <input 
                                    type="checkbox" 
                                    onChange={() => this.handleCheckboxChanged(added)} 
                                    defaultChecked={added} 
                                    value="" 
                                />
                            </label>
                        </div>
                    </td>
                : null}
            </tr>
        );
    }
}

export default PermissionRow;