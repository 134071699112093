import allReducers from './all_reducers';
import { LOGOUT_SUCCESS } from '../constants/action_types';
import { IAction } from '../types/react_types';

//tslint:disable-next-line:no-any
export default function (state: any, action: IAction) {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return undefined;
        default:
            return allReducers(state, action);
    }
}