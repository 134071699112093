//export const LINK_BASE = 'http://localhost:57525/api';
//export const LINK_BASE = 'http://testbooks.vegait.rs/api';
export const LINK_BASE = '/api';
export const MODAL_STYLES = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80%',
        width: '450px'
    }
};
export const MODAL_STYLES_WISH = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80%',
        maxWidth: '40%',
        width: '30%'
    }
};
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const DEFAULT_CONTENT_TYPE_HEADERS = new Headers({
    'Content-Type': 'application/json; charset=utf-8'
});
export const GOOGLE_BOOKS_API_SEARCH_LINK = 'https://www.googleapis.com/books/v1/volumes?q=';
export const CARDS_BY_PAGE = 9; 