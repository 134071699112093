import * as React from 'react';
import BookTable from '../books/book_table';
import FilterBooksForm from '../books/filter_books_form';
import { isAllowed } from '../../helpers/checkAuthorization';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import ExpandButton from '../shared/expand_button';
import IPublisher from '../../types/publisher_type';
import ILanguage from '../../types/language_type';
import IAuthor from '../../types/author_type';
import IKeyword from '../../types/keyword_type';
import IGenre from '../../types/genre_type';
import { IChangeEvent, IFormEvent } from '../../types/react_types';
import { History } from 'history';

interface IChooseBooksProps {
  show: boolean;
  isNextButtonDisabled: boolean;
  filterBooksParams: {
    name: string, isbn13: string, isbn10: string, authorId: string, 
    publisherId: string, languageId: string, keywordId: string, genreId: string,
    startingRating: number, sortBy: string, asc: boolean 
  };
  history: History;
  books: IBook[];
  publishers: IPublisher[];
  languages: ILanguage[];
  authors: IAuthor[];
  loggedInUser: IUser | null;
  keywords: IKeyword[];
  genres: IGenre[];
  renderBookRowActionButtons: (book: IBook) => JSX.Element;
  filterBooks(e: IFormEvent): void;
  clearBooksFilter(): void;
  handleFilterBooksParamsChange(e: IChangeEvent): void;
  //tslint:disable-next-line:no-any
  handleSelectChange(e: any, id: string): void;
  back(): void;
  next(): void;
}

interface IChooseBooksState {
  filterExpanded: boolean;
}

class ChooseBooks extends React.Component<IChooseBooksProps, IChooseBooksState> {
  constructor(props: IChooseBooksProps) {
    super(props);
    this.state = {
      filterExpanded: false,
    };
  }

  toggleExpandFilter = () => {
    this.setState((prevState: IChooseBooksState) => ({ filterExpanded: !prevState.filterExpanded }));
  }

  render() {
    const props = this.props;
    if (props.show) {
        return (
            <div className={props.show ? 'fade-component show' : 'fade-component'}>
                <div className="head-stripe">
                    {isAllowed(props.loggedInUser, 'IBookService.CheckoutBookForSomeoneElse') ? 
                        <button 
                          type="button" 
                          className="btn btn-default btn-inline" 
                          onClick={props.back}
                        >
                          <span className="glyphicon glyphicon-menu-left"/>
                          Back
                        </button>
                    : ''}
                    <button 
                      type="button" 
                      className="btn btn-primary btn-inline" 
                      onClick={props.next} 
                      disabled={props.isNextButtonDisabled}
                    >
                      Next &nbsp;
                      <span className="glyphicon glyphicon-menu-right"/>
                    </button>
                </div>
                <div className="row-item">
                    <h3>Choose books:</h3>
                    <br/>
                    <h4>
                      Filter &nbsp;&nbsp;
                      <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                    </h4>
                    <br/><br/>
                    {
                      this.state.filterExpanded &&
                      <FilterBooksForm 
                        filterParams={props.filterBooksParams} 
                        handleInputChange={props.handleFilterBooksParamsChange} 
                        filter={props.filterBooks} 
                        authors={props.authors}
                        clearFilter={props.clearBooksFilter} 
                        languages={props.languages} 
                        genres={props.genres} 
                        keywords={props.keywords}
                        publishers={props.publishers}  
                        handleSelectChange={props.handleSelectChange} 
                      />
                    }
                    <BookTable 
                      books={props.books} 
                      renderRowActionButtons={props.renderBookRowActionButtons} 
                      loggedInUser={props.loggedInUser} 
                      history={this.props.history} 
                    />
                </div>
            </div>
        );
    } else {
        return <div/>;
    }
  }
    
}

export default ChooseBooks;