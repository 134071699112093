import { GET_CHECKOUT_DURATION_SUCCESS, CHANGE_CHECKOUT_DURATION_SUCCESS } from '../constants/action_types';
import { IAction } from '../types/react_types';

export interface IConfigurationsReducer {
    checkoutDuration: number;
}

var defaultState: IConfigurationsReducer = {
    checkoutDuration: 0
};

export default function (state: IConfigurationsReducer = defaultState, action: IAction) {
    switch (action.type) {
        case GET_CHECKOUT_DURATION_SUCCESS:
        case CHANGE_CHECKOUT_DURATION_SUCCESS:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}