import * as React from 'react';
import UserTable from '../users/user_table';
import FilterUsersForm from '../users/filter_users_form';
import ExpandButton from '../shared/expand_button';
import IUser from '../../types/user_type';
import { IChangeEvent, IFormEvent } from '../../types/react_types';

interface IChooseUserProps {
  show: boolean;
  isNextButtonDisabled: boolean;
  users: IUser[];
  loggedInUser: IUser | null;
  filterUsersParams: {
    username: string;
    firstName: string;
    lastName: string;
    sortBy: string;
    asc: boolean;
  };
  renderRowActionButtons(user: IUser): JSX.Element;
  handleUserFilterParamsChange(e: IChangeEvent): void;
  clearUserFilter(): void;
  filterUsers(e: IFormEvent): void;
  next(): void;
}

interface IChooseUserState {
  filterExpanded: boolean;
}

class ChooseUser extends React.Component<IChooseUserProps, IChooseUserState> {
  constructor(props: IChooseUserProps) {
    super(props);
    this.state = {
      filterExpanded: false
    };
  }

  toggleExpandFilter = () => {
    this.setState((prevState: IChooseUserState) => ({ filterExpanded: !prevState.filterExpanded }));
  }

  render() {
    const props = this.props;
    if (props.show) {
        return (
            <div>
                <div className="head-stripe">
                    <button 
                      type="button" 
                      className="btn btn-primary" 
                      onClick={props.next} 
                      disabled={props.isNextButtonDisabled}
                    >
                      Next&nbsp;
                      <span className="glyphicon glyphicon-menu-right"/>
                    </button>
                </div>
                <div className="row-item">
                    <h3>Choose user: </h3>
                    <br/>
                    <h4>
                      Filter &nbsp;&nbsp; 
                      <ExpandButton isExpanded={this.state.filterExpanded} toggleExpand={this.toggleExpandFilter} />
                    </h4>
                    <br/><br/>
                    {
                      this.state.filterExpanded &&
                      <FilterUsersForm 
                        filterParams={props.filterUsersParams}
                        handleInputChange={props.handleUserFilterParamsChange}
                        clearFilter={props.clearUserFilter} 
                        filter={props.filterUsers} 
                      />
                    }
                    <UserTable 
                      users={props.users} 
                      renderRowActionButtons={props.renderRowActionButtons} 
                      loggedInUser={props.loggedInUser}
                    />
                </div>
            </div>
        );
    } else {
        return <div/>;
    }
  }
    
}

export default ChooseUser;