import * as React from 'react';
import IAuthor from '../../types/author_type';
import { connect } from 'react-redux';
import { MODAL_STYLES } from '../../constants/other_constants';
import Modal from 'react-modal';
import * as toastr from 'toastr';
import { createAuthor, updateAuthor } from '../../actions/authors_actions';
import { IChangeEvent, IFormEvent, IThunkDispatch } from '../../types/react_types';

interface IAuthorModalState {
    author: IAuthor;
    editing: boolean;
    closing: boolean;
    saveClicked: boolean;
}

interface IAuthorModalProps {
    closing: boolean;
    editing: boolean;
    editedAuthor: IAuthor;
    closeModal(): void;
    addAuthor(author: IAuthor): Promise<boolean>;
    updateAuthor(author: IAuthor): Promise<boolean>;
}

class AuthorModal extends React.Component<IAuthorModalProps, IAuthorModalState> {
    constructor(props: IAuthorModalProps) {
        super(props);
        this.state = { 
            author: { 
                id: this.props.editedAuthor.id ? this.props.editedAuthor.id : '', 
                name: this.props.editedAuthor.name ? this.props.editedAuthor.name : '', 
                pseudonym: this.props.editedAuthor.pseudonym ? this.props.editedAuthor.pseudonym : '', 
                yearOfBirth: this.props.editedAuthor.yearOfBirth,
                nationality: this.props.editedAuthor.nationality ? this.props.editedAuthor.nationality : '' 
            },
            editing: props.editing,
            closing: props.closing,
            saveClicked: false
        };
    }
    
    render() {
        if (!this.state.closing) {
            return (
                <div>
                 <Modal isOpen={!this.state.closing} style={MODAL_STYLES}>
                    <h3>{this.state.editing ? 'Edit author' : 'Create new author'}</h3>
                    <form onSubmit={this.handleSubmitSave}>
                        <div className="form-group">
                            <label className="required-field">Name: </label>
                            <input name="name" value={this.state.author.name} type="text" className="form-control" onChange={this.handleInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>Pseudonym: </label>
                            <input 
                                name="pseudonym" 
                                value={this.state.author.pseudonym} 
                                type="text" 
                                className="form-control" 
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Born in: </label>
                            <input 
                                type="number" 
                                name="yearOfBirth" 
                                min={0} 
                                value={this.state.author.yearOfBirth} 
                                className="form-control"  
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Nationality: </label>
                            <input 
                                name="nationality" 
                                value={this.state.author.nationality} 
                                type="text" 
                                className="form-control" 
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                        <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                    </form>
                </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }

    handleSubmitSave = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.validateForm(this.state.author)) {
            return;
        }
        this.setState({ saveClicked: true });
        if (this.state.editing) {
             this.props.updateAuthor(this.state.author).then((success: boolean) => {
                this.setState({
                    editing: false, author : {id: '', name: '', pseudonym: '', yearOfBirth: undefined, nationality: '', saveClicked: false}
                });
                this.props.closeModal();
            }).catch(() => this.setState({ saveClicked: false }));
        } else { 
            this.props.addAuthor(this.state.author).then((success: boolean) => {
                if (success) {
                    this.setState({
                        editing: false, author : {id: '', name: '', pseudonym: '', yearOfBirth: undefined, nationality: '', saveClicked: false}
                    });
                }
                this.props.closeModal();
            }).catch(() => this.setState({ saveClicked: false }));
        }
    }

    handleInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newAuthor = Object.assign({}, prevState.author, {
                [name]: value
            });
            return {author: newAuthor};
        });
    }

    validateForm = (author: IAuthor) => {
        if (!author.name || author.name.trim() === '') {
            toastr.error('Author name cannot be empty.');
            return false;
        }
        return true;
    }

}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    addAuthor: (author: IAuthor) => dispatch(createAuthor(author)),
    updateAuthor: (author: IAuthor) => dispatch(updateAuthor(author))
});

export default connect(null, matchDispatchToProps)(AuthorModal);
