import * as React from 'react';
import IPermission from '../../types/permission_type';
import PermissionRow from './permission_row';

interface IPermissionsTableProps {
    permissions: IPermission[];
    showButtons: boolean;
    addedPermissions?: IPermission[];
    handleCheckboxChanged?(added: boolean, permission: IPermission): void;
    handleRowClick?(permission: IPermission): void;
}

const PermissionsTable = (props: IPermissionsTableProps) => {
    return (
        <table className="table table-striped">
            <thead className="scrollable">
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">Name</td>
                    {props.showButtons ? <td className="bold">Add/Remove</td> : null}
                </tr>
            </thead>
            <tbody className="scrollable">
                {props.permissions.map((permission: IPermission, index: number) => 
                    <PermissionRow 
                        handleCheckboxChanged={props.handleCheckboxChanged} 
                        addedPermissions={props.addedPermissions} 
                        permission={permission} 
                        number={index + 1} 
                        key={permission.id} 
                        handleRowClick={props.handleRowClick} 
                        showButtons={props.showButtons} 
                    />
                )}
            </tbody>
        </table>
    );
};

export default PermissionsTable;