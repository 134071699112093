import * as React from 'react';
import { connect } from 'react-redux';
import IGenre from '../../types/genre_type';
import GenreRow from './genre_row';
import { listAllGenres, deleteGenre } from '../../actions/genres_actions';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import GenreModal from './genre_modal';
import AreYouSureModal from '../are_you_sure_modal';
import Modal from 'react-modal';
import { History } from 'history';
import { IClickEvent, IAnyTargetType, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IGenreProps {
    genres: IGenre[];
    loggedInUser: IUser | null;
    history: History;
    listAllGenres(): Promise<boolean>;
    deleteGenre(genre: IGenre): Promise<boolean>;
}

interface IGenreState {
    openModal: boolean; 
    editing: boolean;
    genre: IGenre;
    loading: boolean;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
}

class Genres extends React.Component<IGenreProps, IGenreState> {

    constructor(props: IGenreProps) {
        super(props);
        this.state = {
            openModal: false, editing: false, genre: {id: '', name: ''}, loading: true,
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            }
        };
    }

    public componentWillMount() {
        this.props.listAllGenres().then(() => this.setState({ loading: false}));
    }

    public render() {
        let createForm = this.state.openModal ? this.renderModal(this.state.genre) : <div/>;
        let loader = this.state.loading ? <p><em>Loading...</em></p> : <div/>;
        return (
            <div >
            <h1>List of Genres</h1>
            {loader}
            { isAllowed(this.props.loggedInUser, 'IGenreService.Create') ?
            <button onClick={this.createButton} className="btn btn-default">Create New   <span className="glyphicon glyphicon-plus"/></button>
            : <div/>}
            <br /><br />
            { isAllowed(this.props.loggedInUser, 'IGenreService.ListAll') ?
            <table className="table table-striped"> 
                <thead>
                    <tr>
                        <td className="bold">#&nbsp;</td>
                        <td className="bold">Name</td>
                        <td className="bold">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {this.listGenres()}
                </tbody>
            </table>
            : <div/>}
            <div id="divModal">
            { isAllowed(this.props.loggedInUser, 'IGenreService.Create') ?
            createForm
            : <div/>}
            </div>
            <AreYouSureModal 
                title={this.state.areYouSureModalParams.title} 
                isOpen={this.state.areYouSureModalParams.isOpen} 
                action={this.state.areYouSureModalParams.action}
                close={this.closeAreYouSureModal} 
                description={null} 
            />
        </div>
        );
    }

    renderModal = (genre: IGenre) => {
        return (
            <GenreModal 
                closing={!this.state.openModal}  
                closeModal={this.closeModal} 
                editing={this.state.editing} 
                editedGenre={this.state.genre} 
            />
        );
    }

    listGenres = () => {
        return this.props.genres.map((genre, index) => (
            <GenreRow 
                key={genre.id} 
                openAreYouSureModal={this.openAreYouSureModal} 
                number={index + 1}
                genre={genre} 
                edit={this.editButton} 
                delete={this.handleDelete} 
                loggedInUser={this.props.loggedInUser} 
            />
        ));
    }

    createButton = () => {
        this.setState({
            openModal: true,
            editing: false,
            loading: false
        });
        Modal.setAppElement('#divModal');
    }

    editButton = (genre: IGenre, e: IClickEvent) => {
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({
                openModal: true,
                editing: true,
                genre: genre,
                loading: false
            });
        }
        Modal.setAppElement('#divModal');
    }

    closeModal = () => {
        this.setState({
            openModal: false, genre: {id: '', name: ''}
        });
    }

    handleDelete = (genre: IGenre) => {
        return this.props.deleteGenre(genre);
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IGenreState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IGenreState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        genres: store.genreReducer.genres,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllGenres: () => dispatch(listAllGenres()),
    deleteGenre: (genre: IGenre) => dispatch(deleteGenre(genre))
}); 

export default connect(mapStateToProps, matchDispatchToProps)(Genres);