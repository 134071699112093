import { LIST_ALL_ROLES_LOADING, LIST_ALL_ROLES_SUCCESS, DELETE_ROLE_SUCCESS, GET_ROLE_SUCCESS, GET_ROLE_LOADING,
     CREATE_ROLE_SUCCESS, UPDATE_ROLE_SUCCESS, LIST_ROLES_WITH_PERMISSION_SUCCESS, LIST_ROLES_WITH_PERMISSION_LOADING,
      CHANGE_PERMISSION_ASSIGNMENT_SUCCESS } from '../constants/action_types';
import rolesService from '../services/roles_service';
import IRole from '../types/role_type';
import { catchError } from '../helpers/error_helper';
import * as toastr from 'toastr';
import { IThunkDispatch } from '../types/react_types';

export function listAllRolesSuccess(roles: IRole[]) {
    return {
        type: LIST_ALL_ROLES_SUCCESS,
        payload: {
            roles: roles,
            rolesLoading: false
        }
    };
}

export function listAllRolesLoading() {
    return {
        type: LIST_ALL_ROLES_LOADING,
        payload: {
            rolesLoading: true
        }
    };
}

export function listAllRoles() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllRolesLoading());
        return rolesService.listAll()
            .then(roles => {
                dispatch(listAllRolesSuccess(roles));
                return true;
            })
            .catch(catchError);
    };
}

export function deleteRoleSuccess(role: IRole) {
    return {
        type: DELETE_ROLE_SUCCESS,
        payload: {
            role: role
        }
    };
}

export function deleteRole(role: IRole) {
    return function(dispatch: IThunkDispatch) {
        return rolesService.delete(role)
            .then(() => {
                toastr.success('Role deleted.');
                dispatch(deleteRoleSuccess(role));
                return true;
            })
            .catch(catchError);
    };
}

export function createRoleSuccess(role: IRole) {
    return {
        type: CREATE_ROLE_SUCCESS,
        payload: {
            role: role
        }
    };
}

export function createRole(role: IRole) {
    return function(dispatch: IThunkDispatch) {
        return rolesService.create(role)
            .then((returnRole: IRole) => {
                toastr.success('Role created.');
                dispatch(createRoleSuccess(returnRole));
                return true;
            })
            .catch(catchError);
    };
}

export function updateRoleSuccess(role: IRole) {
    return {
        type: UPDATE_ROLE_SUCCESS,
        payload: {
            role: role
        }
    };
}

export function updateRole(role: IRole) {
    return function(dispatch: IThunkDispatch) {
        return rolesService.update(role)
            .then((returnRole: IRole) => {
                toastr.success('Role updated.');
                dispatch(updateRoleSuccess(returnRole));
                return true;
            })
            .catch(catchError);
    };
}

export function getRoleSuccess(role: IRole) {
    return {
        type: GET_ROLE_SUCCESS,
        payload: {
            role: role,
            roleLoading: false
        }
    };
}

export function getRoleLoading() {
    return {
        type: GET_ROLE_LOADING,
        payload: {
            roleLoading: true
        }
    };
}

export function getRole(id: string) {
    return function(dispatch: IThunkDispatch) {
        dispatch(getRoleLoading());
        return rolesService.get(id)
            .then((role: IRole) => {
                dispatch(getRoleSuccess(role));
                return role;
            })
            .catch(catchError);
    };
}

export function listRolesWithPermissionSuccess(roles: IRole[]) {
    return {
        type: LIST_ROLES_WITH_PERMISSION_SUCCESS,
        payload: {
            rolesWithPermission: roles,
            rolesWithPermissionLoading: false
        }
    };
}

export function listRolesWithPermissionLoading() {
    return {
        type: LIST_ROLES_WITH_PERMISSION_LOADING,
        payload: {
            rolesWithPermissionLoading: true
        }
    };
}

export function listRolesWithPermission(permissionId: string) {
    return function(dispatch: IThunkDispatch) {
        dispatch(listRolesWithPermissionLoading());
        return rolesService.listRolesWithPermission(permissionId)
            .then((roles: IRole[]) => {
                dispatch(listRolesWithPermissionSuccess(roles));
                return true;
            })
            .catch(catchError);
    };
}

export function changePermissionAssignmentSuccess(roles: IRole[]) {
    return {
        type: CHANGE_PERMISSION_ASSIGNMENT_SUCCESS,
        payload: {
            roles: roles
        }
    };
}

export function changePermissionAssignment(permissionId: string, roles: string[]) {
    return function(dispatch: IThunkDispatch) {
        return rolesService.changePermissionAssignment(permissionId, roles)
            .then((returnRoles: IRole[]) => {
                toastr.success('Permission assignments changed!');
                dispatch(changePermissionAssignmentSuccess(returnRoles));
                return true;
            })
            .catch(catchError);
    };
}