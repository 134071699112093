import * as React from 'react';
import { IClickEvent, IAnyTargetType } from '../../types/react_types';
import IBook from '../../types/book_type';

interface IRatingsProps {
    rated: boolean;
    grade: number;
    book: IBook | null;
    rateBook(book: IBook, grade: number): Promise<boolean>;
    setCurrentBook(): void;
}

interface IRatingsState {
    stateStars: string[];
    ratingInProgress: boolean;
}

class Ratings extends React.Component<IRatingsProps, IRatingsState> {
    constructor(props: IRatingsProps) {
        super(props);
        this.state = {
            stateStars: ['fa fa-star-o fa-3x ', 'fa fa-star-o fa-3x ', 'fa fa-star-o fa-3x ', 'fa fa-star-o fa-3x ', 'fa fa-star-o fa-3x '],
            ratingInProgress: false
        };
        this.rating = this.rating.bind(this);
        this.rate = this.rate.bind(this);
        this.setStars = this.setStars.bind(this);
        this.setStars();
    }

    public componentDidUpdate(prevProps: IRatingsProps) {
        if ((!prevProps.rated && this.props.rated) || (prevProps.grade !== this.props.grade)) {
            this.setStars();
        }
    }

    render() {
        let userRate = this.props.grade ? 
            <div>You rated this book with grade: {this.props.grade}</div> : 
            <div>You didn't rate this book.</div>;
        return (
            <div className="rating" id="rating">
                <div className="label-name">Rate a book: </div>
                <div className="rating-stars">
                    <span 
                        className={'' + this.state.stateStars[0]} 
                        id="0" 
                        onClick={this.rate} 
                        onMouseEnter={this.rating} 
                        onMouseLeave={this.setStars}
                    />
                    <span 
                        className={this.state.stateStars[1]} 
                        id="1" 
                        onClick={this.rate} 
                        onMouseEnter={this.rating} 
                        onMouseLeave={this.setStars} 
                    />
                    <span 
                        className={this.state.stateStars[2]} 
                        id="2" 
                        onClick={this.rate} 
                        onMouseEnter={this.rating} 
                        onMouseLeave={this.setStars}
                    />
                    <span 
                        className={this.state.stateStars[3]} 
                        id="3" 
                        onClick={this.rate} 
                        onMouseEnter={this.rating} 
                        onMouseLeave={this.setStars}
                    />
                    <span 
                        className={this.state.stateStars[4]} 
                        id="4" 
                        onClick={this.rate} 
                        onMouseEnter={this.rating} 
                        onMouseLeave={this.setStars}
                    />
                </div>
                {userRate}
            </div>
        );
    }

    rate = (event: IClickEvent) => {
        if (this.state.ratingInProgress || !this.props.book) {
            return;
        }
        const target: IAnyTargetType = event.target;
        this.setState({ ratingInProgress: true });
        let grade = Number(target.id) + 1;
        this.props.rateBook(this.props.book, grade)
        .then(() => {
            this.props.setCurrentBook();
            this.setStars();
            this.setState({ ratingInProgress: false });
        })
        .catch(() => {
          this.setState({ ratingInProgress: false });
        });
    }

    rating = (event: React.MouseEvent) => {
        const target: IAnyTargetType = event.target;
        let starId = target.id;
        let newStars = this.state.stateStars.map((starState: string, index) => 
            index <= Number(starId) ? 
                'fa fa-star fa-3x  checked' : 
                'fa fa-star-o fa-3x'
        );
        this.setState({
            stateStars: newStars
        });
    }

    setStars = () => {
        let rate = this.props.grade ? this.props.grade : 0;
        let newStars = this.state.stateStars.map((starState: string, index) => 
            index < Number(rate) ? 
                'fa fa-star fa-3x  checked' :
                'fa fa-star-o fa-3x'
        );
        this.setState({
            stateStars: newStars
        });
    }

} 

export default Ratings;