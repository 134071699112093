import googleBooksService from '../services/google_books_service';
import { SEARCH_GOOGLE_BOOKS_LOADING, SEARCH_GOOGLE_BOOKS_SUCCESS, EMPTY_GOOGLE_BOOKS_SEARCH } from '../constants/action_types';
import { catchError } from '../helpers/error_helper';
import { IThunkDispatch } from '../types/react_types';

export function searchGoogleBooks(params: string, page: number) {
    return function(dispatch: IThunkDispatch) {
        dispatch(searchGoogleBooksLoading());
        return googleBooksService.searchBooks(params, page)
            //tslint:disable-next-line:no-any
            .then((searchResult: any) => {
                dispatch(searchGoogleBooksSuccess(searchResult));
                return true;
            })
            .catch(catchError);
    };
} 

export function searchGoogleBooksLoading() {
    return {
        type: SEARCH_GOOGLE_BOOKS_LOADING,
        payload: {
            booksLoading: true
        }
    };
}

//tslint:disable-next-line:no-any
export function searchGoogleBooksSuccess(searchResult: any) {
    return {
        type: SEARCH_GOOGLE_BOOKS_SUCCESS,
        payload: {
            booksLoading: false,
            searchResult: searchResult
        }
    };
}

export function emptyGoogleBooksSearch() {
    return {
        type: EMPTY_GOOGLE_BOOKS_SEARCH,
        payload: {
            booksLoading: false,
            searchResult: null
        }
    };
}
