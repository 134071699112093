import * as React from 'react';
import IAuthor from '../../types/author_type';
import { isAllowed } from '../../helpers/checkAuthorization';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import { LINK_BASE } from '../../constants/other_constants';
import { IClickEvent } from '../../types/react_types';

interface IBookProps {
  book: IBook;
  loggedInUser: IUser | null;
  openAreYouSureModal(f: Function, title: string): void;
  delete(book: IBook): Promise<boolean>;
}

interface IBookState {

}

class Book extends React.Component<IBookProps, IBookState> {

  onDeleteClick = (event: IClickEvent) => {
    event.preventDefault();
    this.props.openAreYouSureModal(() => this.props.delete(this.props.book), 'Deleting book "' + this.props.book.name + '"');
  }

  render() {
    let book = this.props.book;
    let props = this.props;
    return (
        <div className={book.availableCopies !== 0 ? 'card-item-box' : 'card-item-box is-unavailable'}>
            <a href={'/bookDetails/' + book.id} className="a-no-decorations">
              <div className="card-item" id="bookDiv">
                  <div className="card-img" id="bookImgDiv">
                        <img 
                            src={book.image ? LINK_BASE + book.image : require('../../../images/no_image.png')}
                            className="bookImg" 
                            alt="Image not available" 
                        />
                  </div>
                  <div className="card-remove">
                  { isAllowed(props.loggedInUser, 'IBookService.Delete') ?
                        <span className="remove-icon">
                            <i className="fa fa-trash-o fa-2x"  onClick={this.onDeleteClick} aria-hidden="true" />
                        </span>
                        : <div/>
                  }
                  </div>
                  <div className="card-body" id="bookInfoDiv">
                      <div className="entry">
                          <div className="label-name">Name:</div>
                          <h4 className="main-title">{book.name}</h4>
                          <div className="label-name">Authors:</div>
                          <ul>
                              {book.authors.map(function(author: IAuthor, index: number) {
                                  return <li key={index}>{author.name}</li>;
                              })}
                          </ul>
                      </div>
                      <div className="text-right">
                          {book.availableCopies !== 0 ? '' : <span className="label label-danger">Currently unavailable</span>}
                      </div>
                  </div>
              </div>
            </a>
        </div>
    );
  }
    
}

export default Book;