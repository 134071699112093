import * as React from 'react';
import { connect } from 'react-redux';
import { getCheckoutDuration, changeCheckoutDuration } from '../../actions/configurations_actions';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import { ILibrarianStore } from '../../reducers/all_reducers';
import { IChangeEvent, IThunkDispatch } from '../../types/react_types';

interface IConfigurationsProps {
    checkoutDuration: number;
    loggedInUser: IUser | null;
    getCheckoutDuration(): Promise<boolean>;
    changeCheckoutDuration(value: number): Promise<boolean>;
}

interface IConfigurationsState {
    checkoutDurationValue: number;
}

class Configurations extends React.Component<IConfigurationsProps, IConfigurationsState> {

    constructor(props: IConfigurationsProps) {
        super(props);
        this.state = {
            checkoutDurationValue: 0
        };
    }

    public componentDidMount() {
        this.props.getCheckoutDuration()
            .then(() => this.setState({checkoutDurationValue: this.props.checkoutDuration}));
    }

    public render() {
        return (
            <div>
                <h1>Configurations</h1>
                <div className="row row-item">
                    <div className="col-md-4">
                        <label>Checkout duration: </label>
                        <input 
                            type="number" 
                            min={1} 
                            value={this.state.checkoutDurationValue} 
                            className="form-control" 
                            onChange={this.handleInputChange} 
                            name="checkoutDurationValue" 
                        />
                    </div>
                </div>
                { isAllowed(this.props.loggedInUser, 'IConfigurationService.ChangeCheckoutDuration') ?
                    <div className="row-item">
                        <button type="button" className="btn btn-primary" onClick={this.changeCheckoutDuration}>Save</button>
                    </div>
                    : <div/>
                }
                <div className="row row-item">
                    <div className="col-md-4">
                        <p>This configuration marks how many days a user is permitted to keep a book after checkout. <br/>
                            After this period, the user will start receiving e-mails to remind him/her to return the book.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    changeCheckoutDuration = () => {
        this.props.changeCheckoutDuration(this.state.checkoutDurationValue);
    }

    handleInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        const value = target.value;

        this.setState({ checkoutDurationValue: parseInt(value, 10) });
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        checkoutDuration: store.configurationsReducer.checkoutDuration,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    getCheckoutDuration: () => dispatch(getCheckoutDuration()),
    changeCheckoutDuration: (value: number) => dispatch(changeCheckoutDuration(value))
});

export default connect(mapStateToProps, matchDispatchToProps)(Configurations);