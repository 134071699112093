import * as React from 'react';
import { connect } from 'react-redux';
import { login } from '../actions/account_actions';
import { IThunkDispatch, IChangeEvent, IAnyTargetType, IFormEvent } from '../types/react_types';
import { History } from 'history';

interface ILoginProps {
    login: Function;
    history: History;
}

interface ILoginState {
    username: string;
    password: string;
    loginClicked: boolean;
}

class Login extends React.Component<ILoginProps, ILoginState> {

    constructor(props: ILoginProps) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginClicked: false
        };
    }

    public render() {
        return (
        <div className="wrap-sm">
            <div className="logo">
                <img src={require('../../images/logo.png')} alt="" />
            </div>
            <hr/>
            <h2>Login</h2>
            <br/>
            <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <input
                        name="username"
                        placeholder="Username" 
                        value={this.state.username} 
                        type="text" 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <br/>
                <div className="row">
                    <input 
                        name="password" 
                        placeholder="Password" 
                        value={this.state.password} 
                        type="password" 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                <br/>
                <div className="row">
                    <input type="submit" className="btn btn-vega pull-right" value="Login" disabled={this.state.loginClicked} />
                </div>
            </form>
        </div>
        );
    }

    handleSubmit = (event: IFormEvent) => {
        event.preventDefault();
        this.setState({ loginClicked: true });
        if (!this.state.username || this.state.username.trim() === '') {
            return;
        }
        this.props.login(this.state.username, this.state.password)
            .then((done: boolean) => {
                if (done) {
                    this.props.history.push('/');
                } else {
                    this.setState({username: '', password: ''});
                }
                this.enableLogin();
            })
            .catch(this.enableLogin);
    }

    enableLogin = () => {
      this.setState({ loginClicked: false });
    }

    handleInputChange = (event: IChangeEvent) => {
        const target: IAnyTargetType = event.target;
        let value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newState = Object.assign({}, prevState, {
                [name]: value
            });
            return newState;
        });
    }

}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    login: (username: string, password: string) => dispatch(login(username, password))
});

export default connect(null, mapDispatchToProps)(Login);