import * as React from 'react';
import Select, { Option } from 'react-select';
import 'react-select/dist/react-select.css';
import IUser from '../../types/user_type';
import IBook from '../../types/book_type';
import { IFormEvent } from '../../types/react_types';

interface IFilterSubscriptionsProps {
    filtering: boolean;
    filterParams: {
        user: string;
        book: string;
    };    
    books: IBook[];
    users: IUser[];
    filter(e: IFormEvent): void;
    handleInputChange(type: string, value: string): void;
    clearFilter(): void;
}

const FilterSubscriptions = (props: IFilterSubscriptionsProps) => {
    let submitText = props.filtering ? 'Filter' : 'Save Subscription';
    let cancelText = props.filtering ? 'Clear filter' : 'Back to Filter';
    return (
        <div>
            <form onSubmit={props.filter}>
            <div className="row">
                <div className="col-md-1">
                    <label>User: &nbsp;</label>
                </div>
                <div className="col-md-3">
                    <Select
                        closeOnSelect={true}
                        // tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('user', value)}
                        placeholder="Select user"
                        simpleValue={true}
                        value={props.filterParams.user}
                        options={props.users.map((u: IUser) => {return {label: u.firstName + ' ' + u.lastName, value: u.id}; })}
                        name="users"
                    />
                </div>
                <div className="col-md-1">
                    <label>Book: &nbsp;</label>
                </div>
                <div className="col-md-3">
                    <Select
                        closeOnSelect={true}
                        // tslint:disable-next-line:no-any
                        onChange={(value: any) => props.handleInputChange('book', value)}
                        placeholder="Select book"
                        simpleValue={true}
                        value={props.filterParams.book}
                        options={props.books.map((b: IBook) => {return {label: b.name, value: b.id}; })}
                        name="books"
                    />
                </div>
            </div><br/>  
            <div className="row">
                <div className="col-md-3">
                    <button type="submit" className="btn btn-primary">{submitText}</button>&nbsp;
                    <button type="button" className="btn btn-default" onClick={props.clearFilter}>{cancelText}</button>
                </div><br/><br/>
            </div>
        </form>
        </div>
    );
};

export default FilterSubscriptions;