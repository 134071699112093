import * as React from 'react';
import RoleRow from './role_row';
import IRole from '../../types/role_type';
import '../../../css/site.css';
import IUser from '../../types/user_type';
import { IClickEvent } from '../../types/react_types';

interface IRoleTableProps {
    roles: IRole[];
    renderActionButtons: (role: IRole) => JSX.Element;
    loggedInUser: IUser | null;
    allowRowSelect: boolean;
    openEditRoleModal?(role: IRole, e: IClickEvent): void;
}

const RoleTable = (props: IRoleTableProps) => {
    return (
        <table className="table table-striped">
            <thead >
                <tr>
                    <td className="bold">#&nbsp;</td>
                    <td className="bold">Name</td>
                    <td className="bold">Action</td>
                </tr>
            </thead>
            <tbody>
                {props.roles.map((role: IRole, index: number) => (
                    <RoleRow 
                        key={role.id} 
                        role={role}
                        number={index + 1} 
                        actionButtons={props.renderActionButtons(role)}
                        openEditRoleModal={props.openEditRoleModal} 
                        loggedInUser={props.loggedInUser} 
                        allowRowSelect={props.allowRowSelect} 
                    />
                ))}
            </tbody>
        </table>
    );
};

export default RoleTable;