import * as React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { connect } from 'react-redux';
import IBook from '../../types/book_type';
import IUser from '../../types/user_type';
import 'react-select/dist/react-select.css';
import { IFormEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IRecommendBookProps {
    book: IBook;
    closing: boolean;
    users: IUser[];
    user: IUser | null;
    closeModal(): void;
    recommendBooks(bookId: string, fromUserId: string, toUserIds: string[]): Promise<boolean>;
}

interface IRecommendBookState {
    selectedUsers: string;
    selectedUsersArray: string[];
    sendClicked: boolean;
}

class RecommendBook extends React.Component<IRecommendBookProps, IRecommendBookState> {
    constructor(props: IRecommendBookProps) {
        super(props);
        this.state = {
             selectedUsers: '',
             selectedUsersArray: [],
             sendClicked: false
        };
    }
    render () {
        return (
            <div>
                <Modal 
                    isOpen={!this.props.closing} 
                    style={{content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        minHeight: '30%'
                    }}} 
                >
                    <h3>Choose a friend to send him email recommendation for this book:</h3>
                    <form onSubmit={this.handleRecommendBook}>
                        <div className="form-group">
                            <Select
                                className="selectList"
                                closeOnSelect={true}
                                multi={true}
                                onChange={this.handleUserSelected}
                                placeholder="Select people"
                                simpleValue={true}
                                value={this.state.selectedUsers}
                                options={this.listUsers()}
                                name="users"
                            />
                        </div>
                        <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                        <input type="submit" className="btn btn-success pull-right" value="Send" disabled={this.state.sendClicked} />
                    </form>
                </Modal>
            </div>
        );
    }

    listUsers = () => {
        const values: { label: string, value: string }[] = [];
        this.props.users.forEach(user => values.push({ label: `${user.firstName} ${user.lastName}`, value: user.id}));
        return values;
    }

    enableSave = () => {
      this.setState({ sendClicked: false });
    }

    handleRecommendBook = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.state.selectedUsersArray || !this.state.selectedUsersArray.length) {
          return;
        }
        this.setState({ sendClicked: true });
        this.props.recommendBooks(this.props.book.id, this.props.user ? this.props.user.id : '', this.state.selectedUsersArray)
        .then((success: boolean) => {
            if (success) {
                this.enableSave();
                this.props.closeModal();
            }
        })
        .catch(this.enableSave);
    }

    // tslint:disable-next-line:no-any
    handleUserSelected = (value: any) => {
        this.setState({ selectedUsers: value  });
        let values = value.split(',');
        let newSelectedUsersArray: string[] = [];

        for (let selectedUser of values) {
            let id: string = selectedUser;
            newSelectedUsersArray.push(id);
        }
        this.setState({
           selectedUsersArray: newSelectedUsersArray
        });
    }
}

function mapStateToProps(store: ILibrarianStore) {
    return {
        users: store.usersReducer.users,
        user: store.accountReducer.loggedInUser.user
    };
}

export default connect(mapStateToProps, null)(RecommendBook);