import * as React from 'react';
import { isAllowed } from '../../helpers/checkAuthorization';
import { printDate } from '../../helpers/date_helper';
import { LINK_BASE } from '../../constants/other_constants';
import IComment from '../../types/comment_type';
import IUser from '../../types/user_type';

interface ICommentRowProps {
    comment: IComment;
    user: IUser | null;
    delete(comment: IComment): void;
    openAreYouSureModal(f: Function, title: string): void;
    edit(comment: IComment): void;
}

const CommentRow = (props: ICommentRowProps) => {
    let comment = props.comment;
    let modified = comment.lastModifiedOn ? 'Last modified on: ' + printDate(comment.lastModifiedOn) : '';
    let editDiv = 
        (props.user && props.user.id === comment.user.id && isAllowed(props.user, 'IBookService.UpdateComment')) || 
        (props.user && props.user.id !== comment.user.id && isAllowed(props.user, 'IBookService.UpdateSomeoneElsesComment'))
        ?   (
            <span className="btn btn-warning btn-inline" onClick={() => props.edit(comment)} aria-hidden="true">
                Edit <span className="glyphicon glyphicon-edit" />
            </span>
            )
        : <div/>;
    let deleteDiv = ((props.user && props.user.id === comment.user.id)  ||
        (props.user && props.user.role.name === 'Admin') && isAllowed(props.user, 'IBookService.DeleteComment')) ||
        ((props.user && props.user.id !== comment.user.id) && isAllowed(props.user, 'IBookService.DeleteSomeoneElsesComment'))
        ?  (
            <span className="remove-icon">
                <i 
                    className="fa fa-trash-o fa-2x" 
                    onClick={() => props.openAreYouSureModal(() => props.delete(comment), 'Deleting comment ')} 
                    aria-hidden="true" 
                />
            </span>
            ) 
            : <div/>;
    return (
            <div key={comment.id} className="card-item">
                <div className="card-remove">
                    {deleteDiv}
                </div>
                <div className="card-body">
                    <div className="entry">
                        { comment.user.rank && 
                            <div className="commentRankContainer">
                                <div className="label-name">
                                    Rank: <span className="commentRankNameText">{comment.user.rank.name}</span>
                                </div>
                                <img className="commentRankImage" src={LINK_BASE + comment.user.rank.avatar} />
                            </div>
                        }
                        <h4 className="main-title">{comment.user.firstName + ' ' + comment.user.lastName}</h4>
                        <p>{comment.text}</p>
                        <p>{modified}</p>
                        {editDiv}
                    </div>
                </div>
            </div>
    );
};

export default CommentRow;