import * as React from 'react';

interface IViewChangerProps {
    selectedView: string;
    handleSelectGrid(): void;
    handleSelectTable(): void;
}

const ViewChanger = (props: IViewChangerProps) => {
    let classNameGrid = props.selectedView === 'gridView' ? 'btn btn-default active' : 'btn btn-default';
    let classNameTable = props.selectedView === 'tableView' ? 'btn btn-default active' : 'btn btn-default';
    return (
        <div className="align-right">
            <label>Change layout: </label>&nbsp;&nbsp;
            <button className={classNameGrid} onClick={props.handleSelectGrid} >
                Grid view &nbsp;
                <span className="glyphicon glyphicon-th"/>
            </button>
            &nbsp;
            <button  
                className={classNameTable}  
                onClick={props.handleSelectTable}
            >
                Table view &nbsp;
                <span className="glyphicon glyphicon-align-justify"/>
            </button>
        </div>
    );
};

export default ViewChanger;