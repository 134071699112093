import * as toastr from 'toastr';
import ICheckout from '../types/checkout_type';
import checkoutsService from '../services/checkouts_service';
import { DELETE_CHECKOUT_SUCCESS, LIST_ALL_CHECKOUTS_LOADING, LIST_ALL_CHECKOUTS_SUCCESS, RETURN_BOOK_SUCCESS,
     SEARCH_CHECKOUTS_SUCCESS, EMPTY_CHECKOUTS_LIST } from '../constants/action_types';
import { catchError } from '../helpers/error_helper';
import bookService from '../services/books_service';
import { IThunkDispatch } from '../types/react_types';

export function deleteCheckoutSuccess(checkout: ICheckout) {
    return {
        type: DELETE_CHECKOUT_SUCCESS,
        payload: {
            checkout: checkout
        }
    };
}

export function deleteCheckout(checkout: ICheckout) {
    return function(dispatch: IThunkDispatch) {
        return checkoutsService.delete(checkout)
            .then(() => {
                toastr.success('Checkout deleted');
                dispatch(deleteCheckoutSuccess(checkout));
                return true;
            })
            .catch(catchError);
    };
}

export function listAllCheckoutsLoading() {
    return {
        type: LIST_ALL_CHECKOUTS_LOADING,
        payload: {
            checkoutsLoading: true
        }
    };
}

export function listAllCheckoutsSuccess(checkouts: ICheckout[]) {
    return {
        type: LIST_ALL_CHECKOUTS_SUCCESS,
        payload: {
            checkouts: checkouts,
            checkoutsLoading: false
        }
    };
}

export function listAllCheckouts() {
    return function(dispatch: IThunkDispatch) {
        dispatch(listAllCheckoutsLoading());
        return checkoutsService.listAll()
            .then((checkouts: ICheckout[]) => {
                dispatch(listAllCheckoutsSuccess(checkouts));
                return true;
            })
            .catch(catchError);
    };
}

export function returnBookSuccess(checkout: ICheckout) {
    return {
        type: RETURN_BOOK_SUCCESS,
        payload: {
            checkout: checkout
        }
    };
}

export function returnBook(checkout: ICheckout) {
    return function(dispatch: IThunkDispatch) {
        return bookService.returnBook(checkout)
            .then((checkoutResult: ICheckout) => {
                toastr.success('Book returned.');
                dispatch(returnBookSuccess(checkoutResult));
                return true;
            })
            .catch(catchError);
    };
}

export function searchCheckoutsSuccess(checkouts: ICheckout[]) {
    return {
        type: SEARCH_CHECKOUTS_SUCCESS,
        payload: {
            checkouts: checkouts,
            checkoutsLoading: false
        }
    };
}

export function searchCheckouts(
        bookId: string, userId: string, fromCheckoutTime: Date|string|null, toCheckoutTime: Date|string|null, 
        fromReturnTime: Date|string|null, toReturnTime: Date|string|null, librarianId: string, returned: string|null
    ) {
    
    return function(dispatch: IThunkDispatch) {
        return checkoutsService.search(bookId, userId, fromCheckoutTime, toCheckoutTime, fromReturnTime, toReturnTime, librarianId, returned)
            .then((checkouts: ICheckout[]) => {
                dispatch(searchCheckoutsSuccess(checkouts));
                return true;
            })
            .catch(catchError);
    };
}

export function emptyCheckoutsList() {
    return {
        type: EMPTY_CHECKOUTS_LIST,
        payload: {
            checkouts: [],
            checkoutsLoading: false
        }
    };
}