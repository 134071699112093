import * as React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import IUser from '../../types/user_type';
import { IFormEvent } from '../../types/react_types';

interface IFilterWishesFormProps {
    filterParams: {
        user: string;
        status: string;
    };
    users: IUser[];
    //tslint:disable-next-line:no-any
    handleStatusChange: any;
    filter(e: IFormEvent): void;
    //tslint:disable-next-line:no-any
    handleInputChange(e: any): void;
    clearFilter(): void;
}

const FilterWishesForm = (props: IFilterWishesFormProps) => {
    return (
        <form className="filter-form" onSubmit={props.filter}>
            <div className="row row-item">
                <div className="filter-col col-md-3">
                    <Select
                        closeOnSelect={true}
                        onChange={props.handleInputChange}
                        placeholder="Select user"
                        simpleValue={true}
                        value={props.filterParams.user}
                        options={props.users.map((u: IUser) => {return {label: u.firstName + ' ' + u.lastName, value: u.id}; })}
                        name="user"
                    />
                </div>
                <div className="filter-col col-md-3">
                    <select 
                        className="form-control"
                        onChange={props.handleStatusChange}
                        value={props.filterParams.status}
                        name="status"
                    >
                        <option value="Pending">Pending</option>
                        <option value="Ordered">Ordered</option>
                        <option value="Fulfilled">Fulfilled</option>
                    </select>
                </div>
            </div>
            <button type="submit" className="btn btn-primary btn-inline">Filter</button>
            <button type="button" className="btn btn-default btn-inline" onClick={props.clearFilter}>Clear filter</button>
        </form>
    );
};

export default FilterWishesForm;