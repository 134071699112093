import * as React from 'react';
import IRole from '../../types/role_type';
import { connect } from 'react-redux';
import RoleTable from './role_table';
import { listAllRoles, deleteRole, createRole, updateRole } from '../../actions/roles_actions';
import IPermission from '../../types/permission_type';
import { listAllPermissions } from '../../actions/permissions_actions';
import CreateUpdateRoleModal from './create_update_role_modal';
import { isAllowed } from '../../helpers/checkAuthorization';
import IUser from '../../types/user_type';
import * as toastr from 'toastr';
import AreYouSureModal from '../are_you_sure_modal';
import ManageRolesButtons from './manage_roles_buttons';
import { History } from 'history';
import { IClickEvent, IAnyTargetType, IChangeEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IRolesProps {
    roles: IRole[];
    rolesLoading: boolean;
    loggedInUser: IUser | null;
    history: History;
    permissions: IPermission[];
    permissionsLoading: boolean;
    listAllRoles(): Promise<boolean>;
    deleteRole(role: IRole): Promise<boolean>;
    listAllPermissions(): Promise<boolean>;
    createRole(role: IRole): Promise<boolean>;
    updateRole(role: IRole): Promise<boolean>;
    
}

interface IRolesState {
    role: IRole;
    isModalOpen: boolean;
    editing: boolean;
    areYouSureModalParams: {
        title: string;
        action: Function;
        isOpen: boolean;
    };
}

class Roles extends React.Component<IRolesProps, IRolesState> {
    constructor(props: IRolesProps) {
        super(props);
        this.state = {
            role: {
                id: '',
                name: '',
                permissions: []
            },
            isModalOpen: false,
            editing: false,
            areYouSureModalParams: {
                title: '',
                action: () => {},
                isOpen: false
            }
        };
    }

    public componentWillMount() {
        this.props.listAllRoles();
        this.props.listAllPermissions();
    }

    public render() {
        if (this.props.rolesLoading || this.props.permissionsLoading) {
            return <h3>Loading...</h3>;
        } else {
            return this.renderTable();
        }
    }

    renderTable = () => {
        return (
            <div className="col-md-12">
                <h1>User Roles</h1>
                <div className="row-item">
                    <h3>Create new role</h3>
                    { isAllowed(this.props.loggedInUser, 'IRoleService.Create') ?
                    <button type="button" className="btn btn-default" onClick={this.openCreateRoleModal}>
                        Create new <span className="glyphicon glyphicon-plus"/>
                    </button> : <div/>}
                </div>
                <h3>List of Roles:</h3>
                { isAllowed(this.props.loggedInUser, 'IRoleService.ListAll') ?
                    <RoleTable 
                        roles={this.props.roles}  
                        openEditRoleModal={this.openEditRoleModal} 
                        loggedInUser={this.props.loggedInUser} 
                        renderActionButtons={(role: IRole) =>
                            <ManageRolesButtons 
                                role={role} 
                                loggedInUser={this.props.loggedInUser} 
                                openAreYouSureModal={this.openAreYouSureModal} 
                                deleteRole={this.props.deleteRole} 
                            />
                        } 
                        allowRowSelect={true} 
                    />
                    : <div/>
                }
                { isAllowed(this.props.loggedInUser, 'IRoleService.Create') || isAllowed(this.props.loggedInUser, 'IRoleService.Update') ?
                    <CreateUpdateRoleModal 
                        isModalOpen={this.state.isModalOpen} 
                        editing={this.state.editing} 
                        submitRole={this.submitRole} 
                        handleInputChange={this.handleInputChange} 
                        handleCheckboxChanged={this.handleCheckboxChanged} 
                        permissions={this.props.permissions}
                        cancel={this.closeModal} 
                        role={this.state.role}  
                    />
                    : <div/>
                }
                <AreYouSureModal 
                    title={this.state.areYouSureModalParams.title} 
                    isOpen={this.state.areYouSureModalParams.isOpen} 
                    action={this.state.areYouSureModalParams.action}
                    close={this.closeAreYouSureModal} 
                    description={null} 
                />
            </div>
        );
    }

    openCreateRoleModal = () => {
        this.setState({
            isModalOpen: true,
            editing: false,
            role: {
                id: '',
                name: '',
                permissions: []
            }
        });
    }

    openEditRoleModal = (role: IRole, e: IClickEvent) => {
        const target: IAnyTargetType = e.target;
        if (target.tagName !== 'I') {
            this.setState({
                isModalOpen: true,
                editing: true,
                role: role
            });
        }
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    }

    handleInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        let value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newRole = Object.assign({}, prevState.role, {
                [name]: value
            });
            return {role: newRole};
        });
    }

    handleCheckboxChanged = (added: boolean, permission: IPermission) => {
        let newList;
        if (added) {
            newList = this.state.role.permissions.filter(p => p.id !== permission.id);
        } else {
            newList = this.state.role.permissions.slice();
            newList.push(permission);
        }
        let newRole = Object.assign({}, this.state.role, {permissions: newList});
        this.setState({
            role: newRole
        });
    }

    submitRole = () => {
        if (!this.state.role.name || this.state.role.name.trim() === '') {
            const reason = 'Role name cannot be empty';
            toastr.error(reason);
            return Promise.reject(reason);
        }
        if (!this.state.editing) {
            return this.props.createRole(this.state.role)
                .then((success: boolean) => {
                    if (success) {
                        this.closeModal();
                    }
                });
        } else {
            return this.props.updateRole(this.state.role)
                .then((success: boolean) => {
                    if (success) {
                        this.closeModal();
                    }
                });
        }
    }

    openAreYouSureModal = (action: Function, title: string) => {
        this.setState((prevState: IRolesState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: true, action: action, title: title});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

    closeAreYouSureModal = () => {
        this.setState((prevState: IRolesState) => {
            let newAreYouSureModalParams = Object.assign({}, prevState.areYouSureModalParams, {isOpen: false});
            return {areYouSureModalParams: newAreYouSureModalParams};
        });
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        roles: store.rolesReducer.roles,
        rolesLoading: store.rolesReducer.rolesLoading,
        permissions: store.permissionsReducer.permissions,
        permissionsLoading: store.permissionsReducer.permissionsLoading,
        loggedInUser: store.accountReducer.loggedInUser.user
    };
}

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
    listAllRoles: () => dispatch(listAllRoles()),
    deleteRole: (role: IRole) => dispatch(deleteRole(role)),
    listAllPermissions: () => dispatch(listAllPermissions()),
    createRole: (role: IRole) => dispatch(createRole(role)),
    updateRole: (role: IRole) => dispatch(updateRole(role))
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);