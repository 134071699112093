import * as React from 'react';
import IPublisher from '../../types/publisher_type';
import { connect } from 'react-redux';
import { MODAL_STYLES } from '../../constants/other_constants';
import Modal from 'react-modal';
import * as toastr from 'toastr';
import { addPublisher, updatePublisher } from '../../actions/publishers_actions';
import { IFormEvent, IChangeEvent, IThunkDispatch } from '../../types/react_types';
import { ILibrarianStore } from '../../reducers/all_reducers';

interface IPublisherModalState {
    publisher: IPublisher;
    editing: boolean;
    closing: boolean;
    saveClicked: boolean;
}

interface IPublisherModalProps {
    editing: boolean;
    editedPublisher: IPublisher;
    closing: boolean;
    addPublisher(pub: IPublisher): Promise<boolean>;
    updatePublisher(pub: IPublisher): Promise<boolean>;
    closeModal(): void;
}

class PublisherModal extends React.Component<IPublisherModalProps, IPublisherModalState> {
    constructor(props: IPublisherModalProps) {
        super(props);
        this.state = {
            publisher: {
                id: this.props.editedPublisher.id ? this.props.editedPublisher.id : '', 
                name: this.props.editedPublisher.name ? this.props.editedPublisher.name : '', 
                country: this.props.editedPublisher.country ? this.props.editedPublisher.country : ''
            },
            editing: props.editing,
            closing: props.closing,
            saveClicked: false
        };
    }
    
    render() {
        if (!this.state.closing) {
            return (
                <div>
                <Modal isOpen={!this.state.closing} style={MODAL_STYLES}>
                <h3>{this.state.editing ? 'Edit publisher ' : 'Create new publisher'}</h3>
                <form onSubmit={this.handleSubmitSave}>
                <div className="form-group">
                    <label className="required-field"> Name:</label>
                    <input type="text"  value={this.state.publisher.name}  name="name" className="form-control" onChange={this.handleInputChange}/>
                </div>
                <div className="form-group">
                    <label > Country:</label>
                    <input 
                        type="text"  
                        value={this.state.publisher.country} 
                        name="country" 
                        className="form-control" 
                        onChange={this.handleInputChange}
                    />
                </div>
                    <button type="button" className="btn btn-default" onClick={() => this.props.closeModal()}>Cancel</button>
                    <input type="submit" className="btn btn-success pull-right" value="Save" disabled={this.state.saveClicked} />
                </form>
                 </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }

    handleSubmitSave = (event: IFormEvent) => {
        event.preventDefault();
        if (!this.validateForm(this.state.publisher)) {
            return;
        }
        this.setState({ saveClicked: true });
        if (this.state.editing) {
             this.props.updatePublisher(this.state.publisher).then((success: boolean) => {
                this.setState({
                    editing: false, publisher : {id: '', name: '', country: ''}, saveClicked: false
                });
                this.props.closeModal();
            }).catch(() => this.setState({ saveClicked: false }));
        } else {
            this.props.addPublisher(this.state.publisher).then((success: boolean) => {
                if (success) {
                    this.setState({
                        editing: false, publisher : {id: '', name: '', country: ''}, saveClicked: false
                    });
                    this.props.closeModal();
                }
            }).catch(() => this.setState({ saveClicked: false }));
        }
    }

    handleInputChange = (event: IChangeEvent) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;

        this.setState((prevState) => {
            let newPublisher = Object.assign({}, prevState.publisher, {
                [name]: value
            });
            return {publisher: newPublisher};
        });
    }

    validateForm = (publisher: IPublisher) => {
        if (!publisher.name || publisher.name.trim() === '') {
            toastr.error('Publisher name cannot be empty.');
            return false;
        }
        return true;
    }

}

function mapStateToProps(store: ILibrarianStore) {
    return {
        
    };
}

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
    addPublisher: (pub: IPublisher) => dispatch(addPublisher(pub)),
    updatePublisher: (pub: IPublisher) => dispatch(updatePublisher(pub))
});

export default connect(mapStateToProps, matchDispatchToProps)(PublisherModal);